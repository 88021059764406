<template>
  <div v-if="integrations" style="width:600px;margin:auto;">
    <h4>Add Registration</h4>

    <b-form>
      <sf-input type="list" :options="integrationList" label="Integration Type" v-model="d_ra.integrationKey"  @change="integrationChange()"/>

      <sf-input type="list" :options="badgeList" label="Badge" v-model="d_ra.user.badgeNumber"  @change="badgeNumberChange()" />
      <sf-input type="text"  label="Logon ID" v-model="d_ra.login.email"  />
      <sf-input type="text"  label="Title" v-model="d_ra.user.title"  />

    </b-form>
    <div style="display:flex;">
      <b-button @click="add" class="btn btn-sm btn-success btn-fill mr-2" >Add Registration</b-button>
      <b-button @click="cancel" class="btn btn-sm btn-warning btn-fill">Cancel</b-button>
    </div>
  </div>
</template>
<script>

import Currency     from "currency.js";
import moment       from "moment-timezone";
import Card         from "/src/components/UIComponents/Cards/Card.vue";
import SfInput      from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable       from "/src/components/UIComponents/Table.vue";
import TenantService from "/src/Services/TenantService.js";


/*


 {
   active        : { type: Boolean, default: true },

   integrationKey  : { type: String, default: "" },                // when we're using integrations, this defines the INTEGRATION we are connecting too
   integrationType : { type: String, default: "myschool" },        // for quick reference here
   integrationTitle: { type: String, default: "mySchool Cafe" },
   source          : { type: String, default: "myschool" },        // for quick reference here
   created         : { type: Date, default: Date.now },

   login           : {
     email                   : { type: String, default: "" },
     password                : { type: String },
     tokenSequence           : { type: Number, default: 1 },        // enables expiring all current logins for this member only...
     lastIP                  : String,
     logonCount              : { type: Number, default: 0 },
     lastLogon               : { type: Date, default: Date.now },
     failedLogonCount        : { type: Number, default: 0 },        // with each failure, we bump this; lockout after x for 5 minutes
     passwordExpire          : Date,
     passwordLastChange      : Date,
     passwordResetRequestDate: Date,
     passwordResetRequests   : { type: Number, default: 0 },
     passwordResetToken      : { type: String, default: "" },
     passwordResetIP         : { type: String, default: "" },
     isVerified              : { type: Boolean, default: false },   // set true when the email is verified.
     whiteList               : [String],                            // let's keep track of where we have seen this account accessed from.
     blackList               : [String],
     ipList                  : [String],                            // todo: if we get an IP that's not in this list, send the user an email about new login. (unless it's mobile)
     status                  : {
     isNeedChangePassword: { type: Boolean, default: false },
     isLockedOut         : { type: Boolean, default: false }
     }
  },
   isAccountOwner: { type: Boolean, default: false },
   user: {      // everything for the "user" in here so we can just grab the user object
     title       : { type: String, default: "" },
     badgeNumber : { type: String },                // if we have one -- NOTE: Everything should point back to the built-in BADGE or ACCOUNT - this is access/login only
     role        : { type: String, default: "" },
     relationship: { type: String, default: "" },
     options     : {
       allowRegister       : { type: Boolean, default: true },   // we can set these to "false" for the 2nd user if primary holder wants to restrict secondary access
       allowLookup         : { type: Boolean, default: true },
       allowAddValue       : { type: Boolean, default: true },
       allowViewTransaction: { type: Boolean, default: true }
       }
     }
   }


 */


export default {
  components: {
    LTable,
    Card,
    SfInput
  },
  props     : {
    remoteAccess: {type: Object, default: () => {}},
    badges: { type: Array, default: () => [] }
  },
  data() {
    return {
      isDataChanged: false,
      integrations: [],
      d_ra: {
        active        : true,

        integrationKey  : "",
        integrationType : "",
        integrationTitle: "",
        source          : "manual",
        created         : Date.now(),

        login           : {
          email                   : "",
          // password                : "superduper!"+Math.random(),
          tokenSequence           : 1,
          lastIP                  : "",
          logonCount              : 0,
          lastLogon               : Date.now(),
          failedLogonCount        : 0,
          passwordExpire          : null,
          passwordLastChange      : null,
          passwordResetRequestDate: null,
          passwordResetRequests   : 0,
          passwordResetToken      : "",
          passwordResetIP         : "",
          isVerified              : false,
          whiteList               : [],
          blackList               : [],
          ipList                  : [],
          status                  : {
            isNeedChangePassword: true,
            isLockedOut         : false
          }
        },
        isAccountOwner: false,
        user: {      // everything for the "user" in here so we can just grab the user object
          title       : "",
          badgeNumber : "",
          role        : "",
          relationship: "self",
          options     : {
            allowRegister       : true,
            allowLookup         : true,
            allowAddValue       : true,
            allowViewTransaction: true
          }
        }
      }
    }
  },
  async mounted() {
    await this.getIntegrations();

    if( this.remoteAccess) {
      console.log( "Got this and setting d_ra" , this.d_ra , this.badges );
      this.d_ra = this.remoteAccess ;
    }

    this.verifyInformation();
  },
  computed: {
    integrationList() {
      return this.integrations.map( i => {
        return {
          text: i.title,
          value  : i.integrationKey
        }
      });
    },
    badgeList() {
      return this.badges.map( b => {
        return {
          text: b.alias,
          value: b.badgeNumber
        }
      });
    }
  },
  methods   : {
    currency(c) {
      return Currency(c);
    },
    has(o, i) {
      return _.has(o, i);
    },
    Moment(v) {
      return moment(v);
    },
    async add() {
      if( !this.d_ra.user.badgeNumber ) {
        return await this.$root.alert("Please select a badge number", "warning");
      }
      if( !this.d_ra.integrationKey ) {
        return await this.$root.alert("Please select an integration", "warning");
      }
      console.log( "hit save()" , this.d_ra );
      this.$emit("add", this.d_ra);
    },
    cancel() {
      console.log( "hit cancel()" );
      this.$emit("cancel");
    },
    async getIntegrations() {
      let response = await TenantService.getIntegrations();
      this.integrations = response.data;

      console.log( "PortalRegistrationCard->getIntegrations()" , this.integrations );
    },
    verifyInformation( badge ) {

      console.log( "hit verifyInformation()" , badge );

      if( this.d_ra.user.badgeNumber ) {

        if(!badge) {
          badge = _.find(this.badges, {badgeNumber: this.d_ra.user.badgeNumber});
        }

        if(!this.d_ra.login.email || this.d_ra.login.email === "") {
          this.d_ra.login.email = badge.email || badge.email2;
        }
      }

      // more verifications here...
    },
    integrationChange() {
      console.log( "integrationChange()" , this.d_ra.integrationKey );

      let integration = _.find( this.integrations, { integrationKey: this.d_ra.integrationKey } );

      this.d_ra.integrationType = integration.integrationType;
      this.d_ra.integrationTitle = integration.title;
    },
    badgeNumberChange() {
      console.log( "badgeNumberChange()" , this.d_ra.user.badgeNumber );

      let badge = _.find( this.badges, { badgeNumber: this.d_ra.user.badgeNumber } );

      this.d_ra.user.title = badge.firstName + " " + badge.lastName;  // the name for sure changes when the badge changes.

      this.verifyInformation( badge );

    }

  }
};
</script>
<style>

</style>
