<template>
  <footer class="footer noprint">
    <div class="container-fluid">
      <nav>
        <ul class="footer-menu" >
          <li>
            <router-link :to="{path:'/app'}">Dashboard</router-link>
          </li>
        </ul>
      </nav>
      <div class="footer-copyright">
        Coded With <i class="fa fa-heart" style="color:red;"></i> By Us For You    &copy; Copyright 2018-{{new Date().getFullYear()}}, Newcare International <span> {{$root.appVersion}}  </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
  /* See app.css */
</style>
