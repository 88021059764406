<template>
  <div class="content container-fluid">
    <div class="row">

     <div class="col-xl-3 col-md-6 show-hand" @click="" >
        <stats-card class="stats-card-shadow" style="background:#7ab63f;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-home " style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" ># Available Units</p>
            <h4 class="card-title" style="color:black" >{{_.sumBy( occupancyItems, "available" )}}</h4>
          </div>
        </stats-card>
         <stats-card class="stats-card-shadow" style="background:#7ab63f;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-usd" style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Potential Income</p>
            <h4 class="card-title" style="color:black" >{{calculateValue( "available" ).toLocaleString()}}</h4>
          </div>
        </stats-card>
      </div>


      <div class="col-xl-3 col-md-6 show-hand" @click="" >
        <stats-card class="stats-card-shadow" style="background:#ffcc00;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-home " style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Bookable</p>
            <h4 class="card-title" style="color:black" >{{_.sumBy( occupancyItems, "canBook")}}</h4>
          </div>
        </stats-card>
        <stats-card class="stats-card-shadow" style="background:#ffcc00;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-usd" style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Potential Income</p>
            <h4 class="card-title" style="color:black" >{{calculateValue( "canBook" ).toLocaleString()}}</h4>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6 show-hand" @click="" >
        <stats-card class="stats-card-shadow" style="background:#3e9ade;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-home " style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Under Renovation</p>
            <h4 class="card-title" style="color:black" >{{_.sumBy( occupancyItems, "underRenovation" )}}</h4>
          </div>
        </stats-card>
        <stats-card class="stats-card-shadow" style="background:#3e9ade;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-usd" style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Potential Income</p>
            <h4 class="card-title" style="color:black" >{{calculateValue( "underRenovation" ).toLocaleString()}}</h4>
          </div>
        </stats-card>
      </div>

        <!-- <div class="col-xl-3 col-md-6 show-hand" @click="" >
         <stats-card class="stats-card-shadow" style="background:#ff9933;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-home " style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Projected 30 Days</p>
            <h4 class="card-title" style="color:black" >4 Units</h4>
          </div>
        </stats-card>
        <stats-card class="stats-card-shadow" style="background:#ff9933;border-radius:.75em;">
          <div slot="header" class="icon-info" >
            <i class="fa fa-usd" style="color:white;"></i>
          </div>
          <div slot="content">
            <p class="card-category" style="color:black" >Projected Investment</p>
            <h4 class="card-title" style="color:black" >$825,800</h4>
          </div>
        </stats-card>
      </div> -->

    </div>

    <div class="row">
      <!-- <div class="col-md-4 col-sm-6 noprint" style="background:#eee;border-radius:1em;">
        <h4 style="margin-top:.5em;">Unit Overview</h4>
        <ul class="no-bullets" >
          <li><b-link :to="{name: 'NewProspect', path: '/marketing/newProspect'}"><i class="fa fa-first-order mr-2"></i>New Prospect</b-link></li>
          <li><b-link :to="{name: 'ModelInformation', path: '/marketing/modelInformation'}"><i class="fa fa-first-order mr-2"></i>Site Visit/Tour Calendar</b-link></li>
          <li><b-link :to="{name: 'ModelInformation', path: '/marketing/modelInformation'}"><i class="fa fa-first-order mr-2"></i>IL Model Information</b-link></li>
          <li><b-link :to="{name: 'AvailableUnits', path: '/marketing/availableUnits'}"><i class="fa fa-first-order mr-2"></i>IL Available Units</b-link></li>
          <li><b-link :to="{name: 'CurrentDeals', path: '/marketing/currentDeals'}"><i class="fa fa-first-order mr-2"></i>Current Deals</b-link></li>
        </ul>
      </div> -->

        <div class="col-xl-9 col-md-12 col-sm-12">
          <status-widget v-if="occupancyItems.length > 0" 
            title="Available Models" 
            :enableFilter="true"
            @filter="modelFilter=$event"
            chartColor="lightgray" 
            itemStyle="color:black;" 
            titleStyle="text-align:left;">
            <template slot="body">
              <div style="overflow:auto;">
                <!-- // .map((m) => { return {title: m.title, units: m.units, occupied: m.occupied, occupancy: ((m.occupied/m.units)*100).toLocaleString()+'%',renovation:m.underRenovation , contract: m.underContract, canBook: m.canBook,available: m.available, _rowVariant: (m.available+m.canBook>0) ? 'success' : (m.underRenovation) ? 'warning' : '' }}).sort((a,b)=>{return (a.title===b.title) ? 0 : (a.title>b.title) ? 1 : -1 })" -->
                <b-table striped hover @row-clicked="selectModel($event)" 
                  :filter="modelFilter"
                   responsive="sm"
                   sort-by="title"
                  :items="occupancyItems" 
                  :fields="occupancyItemFields"
                />
              </div>
            </template>
          </status-widget>
        </div>

    </div>

  </div>

</template>
<script>

  import RoomService from "/src/Services/RoomService";
  import TenantService from "/src/Services/TenantService";
  import Moment from "moment";
  import Numeral from "numeral";
  import Currency from "currency.js";
  import StatsCard from '/src/components/UIComponents/Cards/StatsCard.vue';
  import StatusWidget from '/src/components/UIComponents/Widgets/StatusWidget.vue';
  import Card        from '/src/components/UIComponents/Cards/Card.vue';
  import SfInput     from "/src/components/UIComponents/Inputs/SFormInput.vue";

  export default {
    components: {
      Card,
      SfInput,
      StatsCard,
      StatusWidget
    },
    data() {
      return {
        variants           : [ 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark' ],
        headerBgVariant    : 'dark',
        headerTextVariant  : 'light',
        bodyBgVariant      : 'light',
        bodyTextVariant    : 'dark',
        footerBgVariant    : 'warning',
        footerTextVariant  : 'dark',
        loading            : false,
        modelFilter        : "",
        buildingStats      : null,                                                                                  // todo: Cache this once we get it as these stats are not likely to change often
        occupancyStats     : null,
        occupancyItems     : [],
        occupancyItemFields: [
          // renovation:m.underRenovation , contract: m.underContract, canBook: m.canBook,available: m.available, _rowVariant: (m.available+m.canBook>0) ? 'success' : (m.underRenovation) ? 'warning' : '' }}).sort((a,b)=>{return (a.title===b.title) ? 0 : (a.title>b.title) ? 1 : -1 })
          {key: "title", label: "Title", sortable: true},
          {key: "available", label: "Aval Now?", sortable: true},
          {key: "canBook", label: "Bookable", sortable: true},
          {key: "underContract", label: "Contracts", sortable: true},
          {key: "underRenovation", label: "Reno", sortable: true},
          {key: "units", label: "# Units", sortable: true},
          {key: "occupied", label: "# Occupied", sortable: true},          
          {key: "_rowVariant" , label: "",  sortable: false , formatter: (a,b,m) => { m._rowVariant = (m.available+m.canBook>0) ? 'success' : (m.underRenovation) ? 'warning' : '' }},
        ],
        totalSqFt        : 0,
        totalUnits       : 0,
        totalBuildings   : 0,
        totalValue       : 0,
        isLoading        : "fa fa-spinner  fa-refresh-animate mr-3",
        type             : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications    : {
          topCenter: false
        }
      }
    },
    async created() {
      await this.getHousingConfig(); // have to have this before we can do anything -- 
      await Promise.all([
                          this.getBuildingStats(),
                          this.getBuildingOccupancyStats()
                        ])

    },
    computed  : {
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      numeral(n) {
        return Numeral(n);
      },
      categoryName( value ) {
        let m = _.find(this.housingConfig.buildingCategories, {value: value });
        if( m ) {
          return m.text;
        }
        return "**" + value;
      },
      calculateValue( field ) {
        let value = 0;
        
        this.occupancyItems.map((m) => { 
          let qty = parseInt(m[field]);
          if( qty > 0 ) {
            let v = parseFloat( this.modelValue( m.roomModel ) * qty);
            value += v;
          }
        })
        return Currency(value, { precision: 2, symbol: ' ' } ).format();
      },
      selectModel( model ) {
        this.$router.push( "availableUnits/" + model.roomModel )
      },
      modelName( value ) {
        if(! this.housingConfig ) {
          return "*"+value;
        }
        let m = _.find(this.housingConfig.models, {value: value });
        if( m ) {
          return m.text;
        }
        return "**" + value;
      },
      modelValue( roomModel ) {
         let m = _.find(this.housingConfig.models, { value: roomModel });
         if( m ) {
           let price =  parseFloat(m.prices[0].initiationFee + m.prices[0].price + m.prices[0].annualMaintenance + (m.prices[0].monthlyMaintenance*12));
           return price;
         }
         return 0;
       },
       async getBuildingOccupancyStats() {

        try {

          let response = await RoomService.getBuildingOccupancyStats( "" , this.housingConfig.unitTypes.map( (u) => { return u.value }).join(","));

          if(response.data) {
            this.occupancyItems = response.data.map((s) => {
              s[ "title" ] = this.modelName(s.roomModel);
              if(s.available <= 0) {
                s[ "itemStyle" ] = "color:gray;"
              }
              return s;
            })
          } else {
            this.occupancyItems = [];
          }

        } catch(error) {
          console.log(error.message);
        }
      },
      async getHousingConfig() {
        try {
          let hc =  JSON.parse(localStorage.getItem( "housingConfig" ));
          if ( hc ) {
            this.housingConfig = hc;
          } else {
            let response = await TenantService.getHousingConfig();
            this.housingConfig = response.data;
            localStorage.setItem( "housingConfig" , JSON.stringify(this.housingConfig));
          }

        } catch (error) {
          console.log(error);
        }
      },
      async getBuildingStats() {
        try {
          let response = await RoomService.getBuildingStats();

          this.buildingStats  = response.data;

          this.totalSqFt      = 0;
          this.totalUnits     = 0;
          this.totalBuildings = 0;
          this.totalValue     = 0;
          this.buildingStats.map(building => {
            this.totalSqFt      += building.sqft;
            this.totalBuildings += building.count;
            this.totalUnits     += building.units;
            this.totalValue     += building.value;
          });

        } catch (error) {
          console.log(error);
        }
      }
    }
  }
</script>
<style language="scss" scoped>

  @media print {
    .noprint {
      display: none;
    }
  }

  .table-block {
  }

  /* building summary */
  .table-block-1  {
    /*display:block;*/
    border:1px rgb(232, 167, 67) solid;
    border-radius:.5em;
    padding-left:0;
    padding-right:0;
    min-height:128px;
    min-width:200px;
    height:128px;
    width:200px;
    margin:.25em;
    box-sizing: border-box;
  }

  /* buildings */
  .table-block-2 {
    /*display:block;*/
    border:1px rgb(0, 120 , 180) solid;
    border-radius:.5em;
    padding-left:0;
    padding-right:0;
    /*min-height:128px;*/
    min-width:200px;
    height:128px;
    width:200px;

    margin:.25em;
    box-sizing:border-box;
  }

  /* occupancy  */
  .table-block-3 {
    /*display:block;*/
    border:1px rgb(86, 188, 74) solid;
    border-radius:.5em;
    padding-left:0;
    padding-right:0;
    /*min-height:128px;*/
    min-width:200px;
    height:128px;
    width:200px;

    margin:.25em;
    box-sizing:border-box;
  }

  /* building summary header */
  .table-header-1 {
    /*display:inline-block;*/
    border-radius:.25em;
    background:rgba(232, 167, 67, 1);
    color:white;
    text-align:center;
    font-weight:600;
    font-size:14px;
    text-shadow: -1px 1px 1px rgba(0,0,0,0.3);
    /*text-align:right;
    padding-right:.5em;*/

    span {
      float:left;
      padding-top:0;
      padding-left:10px;
      /*font-size:.7em;*/
    }
  }

  /* buildings headers */
  .table-header-2 {
    /*display:inline-block;*/
    border-radius:.25em;
    background:rgba(0, 120 , 180 , .8);
    color:white;
    text-align:center;
    font-weight:600;
    font-size:14px;
    text-shadow: -1px 1px 1px rgba(0,0,0,0.3);
    /*text-align:right;
    padding-right:.5em;*/

    span {
      float:left;
      padding-top:0px;
      padding-left:10px;
      /*font-size:.6em;*/
    }
  }

  /* buildings headers */
  .table-header-3 {
    /*display:inline-block;*/
    border-radius:.25em;
    background:rgba(86, 188, 74 , .8);
    color:black;
    text-align:center;
    font-weight:600;
    font-size:14px;
    text-shadow: -1px 1px 1px rgba(255,255,255,0.3);
    /*text-shadow: -1px 1px 1px rgba(0,0,0,0.3);*/
  /*text-align:right;
  padding-right:.5em;*/

    span:first-child {
      float:left;
      /*font-size:1.25em;*/
      padding-top:0px;
      padding-left:10px;
      /*position:relative;
      background:white;
      border:1px black solid;
      padding:.25em;*/
      /*font-size:.6em;*/
    }
  }

  .table-data-title {
    width:25%;
    text-align:right;
    font-size:14px;
    /*border-top:1px #bbb solid;*/
  }

  .table-data {
    width:25%;
    text-align:right;
    padding-right:.5em;
    font-weight:600;
    font-size:14px;
    /*border-top:1px #bbb solid;*/
  }

  .table-row {
     /*background:salmon;*/
  }

  @media screen and (max-width: 1280px) {
    .table-block-1 {
      min-width:300px;
    }
    .table-block-2 {
      min-width:300px;
    }
  }

   @media screen and (max-width: 375px) {
    .table-block-1 {
      min-width:255px;
    }
    .table-block-2 {
      min-width:255px;
    }
  }

   @media screen and (max-width: 350px) {
    .table-block-1 {
      min-width:210px;
    }
    .table-block-2 {
      min-width:210px;
    }
  }

</style>
