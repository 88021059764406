///-------------------------------------------------------------------------------------------------
// summary:	Revenue Center service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getRevenueCenters( all = false ) {
    const response = Api().get(`/api/1/revenueCenters` , { params: { all: all }});
    return response;
  },
  getRevenueCenter( id ) {
    const response = Api().get(`/api/1/revenueCenter/${id}` );
    return response;
  },
  addRevenueCenter( revenueCenter ) {
    const response = Api().post(`/api/1/revenueCenter`, revenueCenter );
    return response;
  },
  updateRevenueCenter( revenueCenter ) {
    const response = Api().patch(`/api/1/revenueCenter/${revenueCenter.revenueCenterId}`, revenueCenter );
    return response;
  },
  removeRevenueCenter(revenueCenterObjectId) {
    const response = Api().delete(`/api/1/revenueCenter/${revenueCenterObjectId}`);
    return response;
  }



}
