<template>
  <div class="content">
    <div class="container-fluid">

      <b-row>
        <b-col class="col-md-12">
          <h2>Reports & Queries</h2>
        </b-col>
      </b-row>

      <!----------------------------- -->

      <b-row v-if="reports" class="row col-md-12" sytle="">
        <div v-for="(r,index) in reports" :key="index" class="report-card shadow" :style="r.cardStyle" >
          <div class="report-card-header">
            <img  :src="'/img/'+r.image"/>
            <div class="report-card-controls">
              <b-button v-if="r.actionType=='report' && r.actionLink" variant="success" @click="runReport(r)">{{r.actionText}}</b-button>
              <b-button v-if="r.actionType=='action' && r.actionLink" variant="success" @click="runAction(r)">{{r.actionText}}</b-button>
            </div>
         </div>
          <div class="report-card-title">{{r.title}}</div>
          <div class="report-card-description">{{r.description}}</div>
        </div>
      </b-row>

      <!----------------------------- -->

    </div>
  </div>
</template>

<script>

  import ReportService from "/src/Services/ReportService";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import LTable from "/src/components/UIComponents/Table.vue";

  export default {
    components: {
      Card,
      LTable
    },
    data() {
      return {
        name: "Report Menu",
        reports: null
          //{
          //  title      : "Account Balance",
          //  description: "Listing of account and balance information by your criteria",
          //  image      : "Report-icon2.png",
          //  buttonText : "Go!",
          //  linkType   : "report",
          //  query      : "",
          //  link       : "TestReport"
          //}, {
          //  title      : "Resident Master",
          //  description: "Resident master reportg.",
          //  image      : "Abstract-People.png",
          //  buttonText : "Go!",
          //  linkType   : "action",
          //  link       : ""
          //},
          //{
          //  title      : "Transaction Master",
          //  description: "Listing of transaction related information",
          //  image      : "Report-PNG2.png",
          //  buttonText : "Go!",
          //  linkType   : "action",
          //  link       : ""
          //},
          //{
          //  title      : "Transaction Detail",
          //  description: "Listing of transaction with all the detail",
          //  image      : "report-icon.jpg",
          //  buttonText : "Show DETAILS",
          //  linkType   : "action",
          //  link       : ""
          //},
          //{
          //  title      : "LOG Master",
          //  description: "Listing of log entries that we want to show for the system based on the log stuff we have on hand.",
          //  image      : "report-icon.jpg",
          //  buttonText : "LOG Listing",
          //  linkType   : "action",
          //  link       : ""
          //}
      };
    },
    async created() {
      await this.getReportFormats();
    },
    methods   : {
      runReport(r) {
        this.$router.push(`/reports/${r.actionLink}/${r._id}`);
      },
      runAction(actionName) {
        alert(`This feature has not yet been implemented, ${this.$root.user.alias}`);
      },
      async getReportFormats() {
        try {
          let results = await ReportService.getActiveReportFormats();
          this.reports = results.data;
        } catch (error) {
          console.log( error )
        }
      }
    }
  };

</script>
<style language="scss" scoped>

  .report-card {
    padding:1em;
    margin:1em 1em 0 0;
    border-radius:.75em;
    width: 360px;
    height:225px;
  }

  .report-card-title {
    margin-top:1em;
  }

  .report-card-description {
    display:flex;
    width:100%;
    height:100px;
    border-top:1px black solid;
    font-size:10px;
    padding:.5em;
    margin-top:2px;
    white-space:pre-line;
    /*font-style:italic;*/
    line-height:1.25em;
    overflow-y:auto;
  }

  .report-card-header {
    /* background:pink; */
    width:100%;
    display:flex;
    font-size:20px;
  }

  .report-card-controls {
    width:100%;
  
  }

  .report-card-controls button{

      /* position:relative; */
      float:right;
      border-radius:.5em;
      padding: .25em .5em .25em .5em;
      background-color:green;
      color:white;
  }

  .report-card-header img {
    height: 64px;
  }

  .video-insert {
    position        : absolute;
    right           : 0;
    bottom          : 0;
    min-width       : 100%;
    min-height      : 100%;
    width           : auto;
    height          : auto;
    z-index         : -100;
    background-size : cover;
    overflow        : hidden;
  }

</style>
