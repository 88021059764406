///-------------------------------------------------------------------------------------------------
// summary:	Tenant service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  saveTenant(tenantData) {
    const response = Api().patch("/api/1/tenant", tenantData);
    return response;
  },

  getTenant() {
    const response = Api().get("/api/1/tenant");
    return response;
  },

  getAccountClasses() {
    const response = Api().get("/api/1/accountClassMasterList");
    return response;
  },

  getSites( siteType = "" ) {
    const response = Api().get(`/api/1/sites/${siteType}`);
    return response;
  },
  getPortalConfig() {
    const response = Api().get("/api/1/portalConfig");
    return response;
  },
  // getAccountClasses() {
  //   const response = Api().get(`/api/1/accountClasses`);
  //   return response;
  // },
  getAccountClassesWithCounts() {
    return Api().get(`/api/1/accountClassesWithCounts`);
  },
  saveSites( sites ) {
    const response = Api().patch(`/api/1/sites`, sites );
    return response;
  },
  getInterfaceTypes() {
    const response = Api().get("/api/1/interfaceTypes");
    return response;
  },
  getCategoryTypes() {
    const response = Api().get("/api/1/categoryTypes");
    return response;
  },
  getItemTypes() {
    const response = Api().get("/api/1/itemTypes");
    return response;
  },
  getTicketConfig() {
    const response = Api().get("/api/1/tenantTicketConfig");
    return response;
  },
  saveTicketConfig( data ) {
    const response = Api().patch("/api/1/tenantTicketConfig", data );
    return response;
  },
  getHousingConfig() {
    const response = Api().get("/api/1/tenantHousingConfig");
    return response;
  },
  saveHousingConfig( data ) {
    const response = Api().patch("/api/1/tenantHousingConfig", data );
    return response;
  },
  getAssetConfig() {
    const response = Api().get("/api/1/tenantAssetConfig");
    return response;
  },
  saveAssetConfig( data ) {
    const response = Api().patch("/api/1/tenantAssetConfig", data );

    return response;
  },
  getDonationsConfig() {
    const response = Api().get("/api/1/tenantDonationsConfig");
    return response;
  },
  saveDonationsConfig( data ) {
    const response = Api().patch("/api/1/tenantDonationsConfig", data );
    return response;
  },
  getEventsConfig() {
    const response = Api().get("/api/1/tenantEventsConfig");
    return response;
  },
  saveEventsConfig( data ) {
    const response = Api().patch("/api/1/tenantEventsConfig", data );
    return response;
  },
  getIntegrations( ) {
    const response = Api().get(`/api/1/integrations`);
    return response;
  },
  getIntegration(id ) {
    const response = Api().get(`/api/1/integration/${id}`);
    return response;
  },
  addIntegration( data ) {
    const response = Api().post("/api/1/integration", data );
    return response;
  },
  saveIntegration( data ) {
    const response = Api().patch("/api/1/integration", data );
    return response;
  },
  deleteIntegration( id) {
    const response = Api().delete(`/api/1/integration/${id}`);
    return response;
  },
}
