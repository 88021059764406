<template>
  <!-- <div class="content"> -->
  <div class="content container-fluid">
    <h4 style="margin-top: 0">{{ $root.user.alias }}'s Dashboard</h4>
    <div class="row" v-if="$root.isFeature('dashboard')">
      <template v-if="hasDashboard('occupancy')">
        <simple-stats-card
          title="Overall Occupancy"
          :subTitle="siteOccupancyPercent() + '%'"
          iconImage="fa fa-users"
          footer="Updated Now"
          footerIcon="fa fa-clock-o"
          cardStyle="background:#ffcc00;"
          @click="$root.alert($event)"
        />

        <simple-stats-card
          v-for="(section, index) in sectionOccupancySummary()"
          :key="index"
          :title="sectionName(section.title)"
          :subTitle="currency(section.occupancy).toString() + '%'"
          :detail1="`${section.occupied} of ${section.units}`"
          iconImage="fa fa-users"
          footer="Updated Now"
          footerIcon="fa fa-clock-o"
          cardStyle="background:#ffcc00;"
          @click="$root.alert($event)"
        />
      </template>

      <simple-stats-card
        v-if="hasDashboard('daily-sales')"
        title="Daily Sales"
        :subTitle="currency(totalSales).toString()"
        iconImage="fa fa-dollar"
        footer="Updated Now"
        footerIcon="fa fa-clock-o"
        cardStyle="background:#7ab63f;"
        @click="$root.alert($event)"
      />

      <simple-stats-card
        v-if="ticketSummary.length > 0 && hasDashboard('new-tickets')"
        title="# New Tickets"
        :subTitle="(_.sumBy(ticketSummary, 'new') || 0).toString()"
        iconImage="fa fa-ticket"
        footer="Updated Now"
        footerIcon="fa fa-clock-o"
        cardStyle="background:#7ab63f;"
        @click="$root.alert($event)"
      />

      <simple-stats-card
        v-if="ticketSummary.length > 0 && hasDashboard('closed-tickets')"
        title="# Closed Tickets"
        :subTitle="(_.sumBy(ticketSummary, 'complete') || 0).toString()"
        iconImage="fa fa-ticket"
        footer="Updated Now"
        footerIcon="fa fa-clock-o"
        cardStyle="background:#7ab63f;"
        @click="$root.alert($event)"
      />

      <simple-stats-card
        v-if="ticketSummary.length > 0 && hasDashboard('oldest-ticket')"
        title="Oldest Ticket"
        :subTitle="
          momentTZ().diff(
            momentTZ(_.max(ticketSummary, 'oldestTicket').oldestTicket),
            'days'
          ) + ' Days'
        "
        iconImage="fa fa-ticket"
        footer="Updated Now"
        footerIcon="fa fa-clock-o"
        cardStyle="background:#7ab63f;"
        @click="$root.alert($event)"
      />

      <simple-stats-card
        v-if="hasDashboard('storage-used')"
        title="Storage Used"
        subTitle="1,259 MB"
        iconImage="fa fa-save"
        footer="Updated Now"
        footerIcon="fa fa-clock-o"
        cardStyle="background:#ff9933;"
        @click="$root.alert($event)"
      />

      <!--        <div class="col-xl-3 col-md-6 show-hand" @click="" >-->
      <!--          <stats-card class="stats-card-shadow" style="background:#ffcc00;border-radius:.75em;">-->
      <!--            <div slot="header" class="icon-info" >-->
      <!--              <i class="fa fa-certificate " style="color:white;"></i>-->
      <!--            </div>-->
      <!--            <div slot="content">-->
      <!--              <p class="card-category" style="color:black" >Occupancy</p>-->
      <!--              <h4 class="card-title" style="color:black" >0% </h4>-->
      <!--            </div>-->
      <!--            <div slot="footer" style="color:black" >-->
      <!--&lt;!&ndash;              <i class="fa fa-clock-o"></i>&ndash;&gt;-->
      <!--              No Data Available-->
      <!--            </div>-->
      <!--          </stats-card>-->
      <!--        </div>-->

      <!--        <div class="col-xl-3 col-md-6">-->
      <!--          <stats-card class="stats-card-shadow" style="background:#7ab63f;border-radius:.75em;">-->
      <!--            <div slot="header" class="icon-success"  >-->
      <!--              <i class="nc-icon nc-vector " style="color:white;" ></i>-->
      <!--            </div>-->
      <!--            <div slot="content" >-->
      <!--              <p class="card-category" style="color:black" >Resident Participation</p>-->
      <!--              <h4 class="card-title" style="color:black;">0%</h4>-->
      <!--            </div>-->
      <!--            <div slot="footer" style="color:black;">-->
      <!--&lt;!&ndash;              <i class="fa fa-clock-o"></i>Updated Now&ndash;&gt;-->
      <!--              No Data Available-->
      <!--            </div>-->
      <!--          </stats-card>-->
      <!--        </div>-->

      <!--        <div class="col-xl-3 col-md-6">-->
      <!--          <stats-card class="stats-card-shadow" style="background:#3e9ade;border-radius:.75em;">-->
      <!--            <div slot="header" class="icon-success">-->
      <!--              <i class="nc-icon nc-light-3" style="color:white;" ></i>-->
      <!--            </div>-->
      <!--            <div slot="content">-->
      <!--              <p class="card-category" style="color:black" >Events</p>-->
      <!--              <h4 class="card-title" style="color:black" >No Events</h4>-->
      <!--            </div>-->
      <!--            <div slot="footer" style="color:black" >-->
      <!--              <i class="fa fa-clock-o"></i>Updated Now-->
      <!--            </div>-->
      <!--          </stats-card>-->
      <!--        </div>-->

      <!--        <div class="col-xl-3 col-md-6">-->
      <!--          <stats-card class="stats-card-shadow" style="background:#ff9933;border-radius:.75em;">-->
      <!--            <div slot="header" class="icon-success">-->
      <!--              <i class="nc-icon nc-chart " style="color:white" ></i>-->
      <!--            </div>-->
      <!--            <div slot="content" style="padding-right:1em;">-->
      <!--              <p class="card-category" style="color:black" >Space Available</p>-->
      <!--              <h5 class="card-title" style="color:black" >1.87 Gig</h5>-->
      <!--              &lt;!&ndash; <table class="account-stats card-category" style="margin-bottom:1em;">-->
      <!--                <tbody>-->
      <!--                <tr v-for="(c,index) in accountTypeCounts" :key="index">-->
      <!--                  <td style="width:60%;text-align:right;color:black;text-transform:capitalize;">{{c.accountType}}</td>-->
      <!--                  <td style="width:30%;text-align:right;color:#777;padding-left:1em;font-weight:bold;">{{c.count}}</td>-->
      <!--                </tr>-->
      <!--                </tbody>-->
      <!--              </table> &ndash;&gt;-->
      <!--            </div>-->
      <!--              <div slot="footer" style="color:black" >-->
      <!--              <i class="fa fa-clock-o"></i>Updated Now-->
      <!--            </div>-->
      <!--          </stats-card>-->
      <!--        </div>-->
    </div>

    <div class="row" v-if="$root.isFeature('dashboard')">
      <!--        <div class="col-8">-->

      <bar-widget
        v-if="hasDashboard('housing-model-available')"
        title="Models Bookable"
        chartColor="lightgray"
        chartDataBackgroundColor="rgba(128,192,0,.4)"
        :chartLabels="
          occupancyItems
            .filter((o) => {
              return o.canBook !== 0;
            })
            .map((o) => {
              return modelName(o.roomModel);
            })
        "
        :chartData="
          occupancyItems
            .filter((o) => {
              return o.canBook !== 0;
            })
            .map((o) => {
              return o.canBook;
            })
        "
      />
      <bar-widget
        v-if="hasDashboard('housing-model-available')"
        title="Models Available Now"
        chartColor="lightgray"
        chartDataBackgroundColor="rgba(128,192,0,.4)"
        :chartLabels="
          occupancyItems
            .filter((o) => {
              return o.available !== 0;
            })
            .map((o) => {
              return modelName(o.roomModel);
            })
        "
        :chartData="
          occupancyItems
            .filter((o) => {
              return o.available !== 0;
            })
            .map((o) => {
              return o.available ? o.available : null;
            })
        "
      />

      <pie-widget
        v-if="hasDashboard('housing-model-bookable')"
        title="Under Renovation"
        chartColor="lightgray"
        chartDataBackgroundColor="rgba(192,0,128,.4)"
        :chartLabels="
          occupancyItems
            .filter((i) => {
              return i.underRenovation > 0;
            })
            .map((o) => {
              return modelName(o.roomModel);
            })
        "
        :chartData="
          occupancyItems
            .filter((i) => {
              return i.underRenovation > 0;
            })
            .map((o) => {
              return o.underRenovation;
            })
        "
      />

      <!--        <bar-widget-->
      <!--          title="Concierge 2020"-->
      <!--          chartColor="lightgray"-->
      <!--          :chartLabels='["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]'-->
      <!--          :chartData='[1020,2000,3500,9050,3500,550,2000,800,1100,2560,0,0]'-->
      <!--        />-->

      <!--        <bar-widget-->
      <!--          title="Litebites 2020"-->
      <!--          chartColor="lightgray"-->
      <!--          :chartLabels='["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]'-->
      <!--          :chartData='[510,1000,1700,2750,1900,280,900,700,350,1290,0,0]'-->
      <!--          :chartDataBackgroundColor="['red','white','blue','green','blue','yellow','orange','red','rgba(255, 127, 64, 0.8)','black' ]"-->
      <!--        />-->

      <!--        <line-widget-->
      <!--          title="2020 Cafe Trend"-->
      <!--          :fill="false"-->
      <!--          chartColor="lightgray"-->
      <!--          :chartLabels='["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]'-->
      <!--          :chartData='[1020,2000,3500,9050,3500,550,2000,800,1100,2560,200,0 ]'-->
      <!--        />-->

      <!-- <pie-widget v-if="hasDashboard('housekeeping')"
                  title="Houskeeping"
                  chartColor="lightgray"
                  :cutoutPercentage="40"
                  :chartDataBorderWidth="1"
                  :chartLabels='["Open","Closed","In-Process", "Done"]'
                  :chartData='[1020,2000,100,200]'
      /> -->

      <!--        <pie-widget-->
      <!--          title="2020 Sales Breakdown"-->
      <!--          chartColor="rgba(255,230,0,.2)"-->
      <!--          :cutoutPercentage="40"-->
      <!--          :chartDataBorderWidth="1"-->
      <!--          :chartLabels='["Landings","Cafe","Salon", "Concierge"]'-->
      <!--          :chartData='[24020,12000,3500,2800]'-->
      <!--        />-->

      <!--        <pie-widget-->
      <!--          title="2019/2020 Sales Analysis"-->
      <!--          chartColor="rgba(255,230,0,.2)"-->
      <!--          :cutoutPercentage="65"-->
      <!--          :chartDataBorderWidth="1"-->
      <!--          :chartLabels='["Landings","Cafe","Salon", "Concierge"]'-->
      <!--          :chartData='[24020,12000,3500,2800]'-->
      <!--        />-->

      <!--        <bar-widget-->
      <!--          title="2019/20 Lighthouse "-->
      <!--          chartColor="rgba(64, 64, 64, 0.3)"-->
      <!--          :chartLabels='["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]'-->
      <!--          :datasets='[{label:"2019",fill:false,backgroundColor:"rgba(0, 127, 255, 0.6)",data:[610,1300,1400,2300,1600,2280,2900,2700,650,1090,2100,3200]},{label:"2020",fill:false,backgroundColor:"rgba(255, 127, 0, 0.6)",data:[510,1000,1700,2750,1900,280,900,700,350,1290,0,0]}]'-->
      <!--        />-->

      <!--      </div>-->
      <!--      <div class="row" v-if="$root.isFeature('dashboard')">-->
      <!--        -->
      <!--        <status-widget-->
      <!--          title="Exceptions"-->
      <!--          :subTitle="Moment().format('dddd, MMMM Do')"-->
      <!--          chartColor="rgba(64, 64, 64, 0.6)"-->
      <!--          :items="statusItems"-->
      <!--        />-->

      <!--        </div>-->
    </div>

    <div class="row" v-if="$root.isFeature('dashboard')">
      <div
        class="col-xl-6 col-md-12 col-sm-12"
        v-if="hasDashboard('housing-model-summary')"
      >
        <status-widget
          v-if="occupancyItems.length > 0"
          title="Models"
          chartColor="lightgray"
          itemStyle="color:black;"
          titleStyle="text-align:left;"
        >
          <template slot="body">
            <!-- <b-table-lite class="grayGridTable" summarize style="width:100%;text-align:right;">
              <thead>
              <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>Avail</td>
              </tr>
              </thead>
              <tr v-for="(item,index) in occupancyItems" :key="index" :style="(item.available==0) ? 'color:gray;' : 'color:black;'">
                <td>{{ item.icon }}</td>
                <td>{{ modelName(item.title) }}</td>
                <td style="text-align:right;">{{ item.units }}</td>
                <td style="text-align:right;">{{ item.available }}</td>
              </tr>
            </b-table-lite> -->
            <!-- canBook:m.booking.canBook, renovation: m.booking.underRenovation, -->
            <div style="overflow: auto">
              <b-table
                striped
                hover
                :items="
                  occupancyItems
                    .map((m) => {
                      return {
                        title: m.title,
                        units: m.units,
                        occupancy:
                          ((m.occupied / m.units) * 100).toLocaleString() + '%',
                        renovation: m.underRenovation,
                        contract: m.underContract,
                        canBook: m.canBook,
                        available: m.available,
                        _rowVariant:
                          m.available + m.canBook > 0
                            ? 'success'
                            : m.underRenovation
                            ? 'warning'
                            : '',
                      };
                    })
                    .sort((a, b) => {
                      return a.title === b.title
                        ? 0
                        : a.title > b.title
                        ? 1
                        : -1;
                    })
                "
              >
              </b-table>
            </div>
          </template>
        </status-widget>

      </div>
    </div>

    <!------------------------------------------------------------ Hourly Sales -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 " style="border-radius: 1em; padding: 0; margin: 1em;" v-if="hasDashboard('hourly-sales')">
        <chart-card
          :chart-data="hourlySales.data"
          :chart-options="hourlySales.options"
          :responsive-options="hourlySales.responsiveOptions"
          @initialized="initHourlySalesChart"
          class="shadow"
          style="border-radius: 1em; margin: 0; padding: 0"
        >
          <template slot="header">
            <h4 class="card-title">Sales Performance<span class="float-right" >Total Sales: {{ totalSales }}</span></h4>
            <p class="card-category">Hourly</p>
          </template>
          <template slot="footer">
            <div class="legend" style="display: inline-block">
              <template v-for="(tag, tagIndex) in hourlySales.data.seriesLabels" >
                <span style="display: inline-block; padding-right: 1em" :key="tagIndex">
                  <i class="fa fa-circle" :style="['color:#12d9e3;','color:#d63820','color:#e69720','color:#7640ff','color:#7ad948',][tagIndex]"></i>
                  {{ tag }}
                </span>
              </template>
            </div>
            <hr />
            <div class="stats">
              <i class="fa fa-history"></i
              >{{ Moment(hourlySales.lastUpdate).fromNow() }}
            </div>
          </template>
        </chart-card>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" >
        <div v-if="lastMonthlySalesResult">
          <chart-widget
            :title          = "Moment().tz($root.user.TZ).subtract(1,'month').format('MMMM') + ' Sales Performance'"
              subTitle      = "Sales by day"
              labelType     = "month"
              promptTotal   = "$ Total Sales: "
            :currencyFormat = "{ precision: 2, symbol: ' ' }"
              chartType     = "Line"
            :stackBars      = "false"
            :isShowTotal    = "true"
            :isShowGrid     = "true"
            :isShowArea     = "false"
            :series         = "calcMonthlySalesSeries(lastMonthlySalesResult, -1)"
            :seriesLabels   = "xlatRevenueCentersFromArray( getUniqueRevenueCentersFromArray( lastMonthlySalesResult ))"
          ></chart-widget>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" >
        <div v-if="monthlySalesResult">
          <chart-widget
            :title          = "Moment().tz($root.user.TZ).format('MMMM') + ' Sales Performance'"
              subTitle      = "Sales by day 222"
              labelType     = "month"
              promptTotal   = "$ Total Sales: "
            :currencyFormat = "{ precision: 2, symbol: ' ' }"
            :isShowTotal    = "true"
            :isShowGrid     = "true"
            :isShowArea     = "false"
            :series         = "calcMonthlySalesSeries(monthlySalesResult,0)"
            :seriesLabels   = "xlatRevenueCentersFromArray( getUniqueRevenueCentersFromArray( monthlySalesResult ))"
          ></chart-widget>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" >
        <div v-if="lastMonthlySalesResult">
          <chart-widget
            :title          = "Moment().tz($root.user.TZ).subtract(1,'month').format('MMMM') + ' Orders'"
              subTitle      = "# of orders by day"
              labelType     = "month"
              promptTotal   = "# Total Orders: "
            :currencyFormat = "{ precision: 0, symbol: ' ' }"
              chartType     = "Line"
            :stackBars      = "false"
            :isShowTotal    = "true"
            :isShowGrid     = "true"
            :isShowArea     = "false"
            :series         = "calcOrdersSeries(lastMonthlySalesResult, -1)"
            :seriesLabels   = "xlatRevenueCentersFromArray( getUniqueRevenueCentersFromArray( lastMonthlySalesResult ))"
          ></chart-widget>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" >
        <div v-if="monthlySalesResult">
          <chart-widget
            :title          = "Moment().tz($root.user.TZ).format('MMMM') + ' Orders'"
              subTitle      = "# of orders by day"
              labelType     = "month"
              promptTotal   = "# Total Orders: "
            :currencyFormat = "{ precision: 0, symbol: ' ' }"
            :isShowTotal    = "true"
            :isShowGrid     = "true"
            :series         = "calcOrdersSeries(monthlySalesResult)"
            :seriesLabels   = "xlatRevenueCentersFromArray( getUniqueRevenueCentersFromArray( monthlySalesResult ))"
          ></chart-widget>
        </div>
      </div>




      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div v-if="monthlySalesResult">
          <chart-widget
            title="Weekly Order Tracking"
            subTitle="# of orders by day - not full width"
            labelType="week"
            :labels="['Sun','Mon','Tue','Wed','Thu','Fri','Sat']"
            :seriesLabels="['Current','Week -1','Week -2', 'Week -3']"
            promptTotal="# Total Orders: "
            :currencyFormat="{ precision: 0, symbol: ' ' }"
            :isShowTotal="true"
            :isShowGrid="true"
            :isFullWidth="true"
            :series="[[1,22,3,4,12,44,2],[7,90,50,24,3,2,1],[3,7,2,6,1,5,4],[4,5,6,7,1,2,3]]"
          ></chart-widget>
        </div>
      </div> -->

      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div v-if="monthlySalesResult">
          <chart-widget
            :title="Moment().tz($root.user.TZ).format('MMMM') + ' Week Test'"
            subTitle="# of orders by day"
            chartType="Bar"
            :stackBars="true"
            labelType="week"
            :labels="['Sun','Mon','Tue','Wed','Thu','Fri','Sat']"
            :seriesLabels="['Cafe','Rest','Salon','Concierge']"
            promptTotal="# Total Orders: "
            :currencyFormat="{ precision: 0, symbol: ' ' }"
            :isShowTotal="true"
            :isShowGrid="true"
            :isFullWidth="true"
            :series="[[1,2,3,4,5,6,7],[7,6,5,4,3,2,1],[3,4,5,6,7,1,2],[4,5,6,7,1,2,3]]"
          ></chart-widget>
        </div>
      </div> -->

      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div v-if="monthlySalesResult">
          <chart-widget
            :title="Moment().tz($root.user.TZ).format('MMMM') + ' Week Test'"
            subTitle="# of orders by day"
            chartType="Bar"
            :stackBars="false"
            labelType="week"
            :labels="['Sun','Mon','Tue','Wed','Thu','Fri','Sat']"
            :seriesLabels="['Cafe','Rest','Salon','Concierge']"
            promptTotal="# Total Orders: "
            :currencyFormat="{ precision: 0, symbol: ' ' }"
            :isShowTotal="true"
            :isShowGrid="true"
            :isFullWidth="true"
            :series="[[1,2,3,4,5,6,7],[7,6,5,4,3,2,1],[3,4,5,6,7,1,2],[4,5,6,7,1,2,3]]"
          ></chart-widget>
        </div>
      </div> -->

      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div v-if="monthlySalesResult">
          <chart-widget
            :title="Moment().tz($root.user.TZ).format('MMMM') + ' Week Test'"
            subTitle="# of orders by day"
            labelType="week"
            :seriesLabels="['Sun','Mon','Tue','Wed','Thu','Fri','Sat']"
            promptTotal="# Total Orders: "
            :currencyFormat="{ precision: 0, symbol: ' ' }"
            :isShowTotal="true"
            :isShowGrid="true"
            :isFullWidth="true"
            :series="[[1,2,3,4,5,6,7],[7,6,5,4,3,2,1],[3,7,2,6,1,5,4]]"
          ></chart-widget>
        </div>
      </div> -->

      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div v-if="monthlySalesResult">
          <chart-widget
            :title="Moment().tz($root.user.TZ).format('MMMM') + ' Week Test'"
            subTitle="# of orders by day"
            labelType="week"
            :seriesLabels="['Sun','Mon','Tue','Wed','Thu','Fri','Sat']"
            promptTotal="# Total Orders: "
            :currencyFormat="{ precision: 0, symbol: ' ' }"
            :isShowTotal="true"
            :isShowGrid="true"
            :isFullWidth="true"
            :series="[[1,2,3,4,5,6,7],[7,6,5,4,3,2,1],[3,7,2,6,1,5,4]]"
          ></chart-widget>
        </div>-->


      </div>



      <!-------------------------------------------------------------------- Ticket Summary -->

      <b-col class="card col-md-6" v-if="hasDashboard('ticket-standings')" style="background: rgba(255, 140, 40, 0.1); border-radius: 0.75em" >
        <h4 class="card-title mt-2 mb-0">Current Ticket Standings</h4>
        <div v-if="ticketSummary.length > 0">
          <table class="paleGreenRows">
            <thead>
              <tr>
                <th>Type</th>
                <th>New</th>
                <th>Open</th>
                <th>InProc</th>
                <th>Stuck</th>
                <th>Parked</th>
                <th>Other</th>
                <th>Pending<br />Close</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, index) in ticketSummary" :key="index">
                <td style="padding-left: 1em; cursor: pointer" @click="$router.push('/case/tickets/' + t.ticketType)" >
                  {{ t.ticketType }}
                </td>
                <td>{{ t.new != 0 ? t.new : "-" }}</td>
                <td>{{ t.open != 0 ? t.open : "-" }}</td>
                <td>{{ t.inprocess != 0 ? t.inprocess : "-" }}</td>
                <td>{{ t.stuck != 0 ? t.stuck : "-" }}</td>
                <td>{{ t.parked != 0 ? t.parked : "-" }}</td>
                <td>{{ t.other != 0 ? t.other : "-" }}</td>
                <td>{{ t.complete != 0 ? t.complete : "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <h5>No Details Available</h5>
        </div>
      </b-col>

      <!---------------------------------------------------------------------------------  Ticket Summary By User -->
      <b-col
        class="card col-md-6"
        v-if="ticketSummary.length > 0 && hasDashboard('ticket-summary')"
        style="background: rgba(255, 140, 40, 0.1); border-radius: 0.75em"
      >
        <h4 class="card-title mt-2 mb-0">
          User Ticket Summary
          <span
            style="
              font-size: 0.7em;
              font-style: italic;
              text-transform: capitalize;
            "
            >(activity this {{ ticketPeriod }})</span
          >
        </h4>
        <table v-if="ticketSummaryByUser.length > 0" class="paleGreenRows">
          <thead>
            <tr>
              <th>User</th>
              <!--<th>Created</th>-->
              <th>New</th>
              <th>Open</th>
              <th>InProc</th>
              <th>Stuck</th>
              <th>Parked</th>
              <th>Other</th>
              <th>Complete</th>
              <th>Closed</th>
              <th>Hrs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(t, index) in ticketSummaryByUser" :key="index">
              <td style="padding-left: 1em">{{ t.assignedTo }}</td>
              <!--                <td>{{(t.created!=0)?t.created:'-'}}</td>-->
              <td>{{ t.new != 0 ? t.new : "-" }}</td>
              <td>{{ t.open != 0 ? t.open : "-" }}</td>
              <td>{{ t.inprocess != 0 ? t.inprocess : "-" }}</td>
              <td>{{ t.stuck != 0 ? t.stuck : "-" }}</td>
              <td>{{ t.parked != 0 ? t.parked : "-" }}</td>
              <td>{{ t.other != 0 ? t.other : "-" }}</td>
              <td>{{ t.complete != 0 ? t.complete : "-" }}</td>
              <td>{{ t.closed != 0 ? t.closed : "-" }}</td>
              <td>{{ t.closedHours }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </div>

    <!-- -------------------------- -->

    <!--     <div class="row">-->
    <!--        <div class="col-md-6">-->
    <!--          <chart-card-->
    <!--            :chart-data="barChart.data"-->
    <!--            :chart-options="barChart.options"-->
    <!--            :chart-responsive-options="barChart.responsiveOptions"-->
    <!--            chart-type="Bar">-->
    <!--            <template slot="header">-->
    <!--              <h4 class="card-title">History Sales Analysis</h4>-->
    <!--              <p class="card-category">Year Comparison</p>-->
    <!--            </template>-->
    <!--            <template slot="footer">-->
    <!--              <div class="legend">-->
    <!--                <i class="fa fa-circle text-info"></i> This Year (2020)-->
    <!--                <i class="fa fa-circle text-danger"></i> Last Year (2019)-->
    <!--              </div>-->
    <!--              <hr>-->
    <!--              <div class="stats">-->
    <!--                <i class="fa fa-check"></i> Data information certified-->
    <!--              </div>-->
    <!--            </template>-->
    <!--          </chart-card>-->
    <!--        </div>-->

    <!--        <div class="col-md-6">-->
    <!--          <card>-->
    <!--            <template slot="header">-->
    <!--              <h5 class="title">Tasks</h5>-->
    <!--              <p class="category">Kitchen & Counter</p>-->
    <!--            </template>-->
    <!--            <l-table :data="tableData.data"-->
    <!--                     :columns="tableData.columns">-->
    <!--              <template slot="columns"></template>-->

    <!--              <template slot-scope="{row}">-->
    <!--                <td>-->
    <!--                  <Checkbox v-model="row.checked"></Checkbox>-->
    <!--                </td>-->
    <!--                <td>{{row.title}}</td>-->
    <!--                <td class="td-actions text-right">-->
    <!--                  <button type="button" class="btn-simple btn btn-xs btn-info" v-tooltip.top-center="editTooltip">-->
    <!--                    <i class="fa fa-edit"></i>-->
    <!--                  </button>-->
    <!--                  <button type="button" class="btn-simple btn btn-xs btn-danger" v-tooltip.top-center="deleteTooltip">-->
    <!--                    <i class="fa fa-times"></i>-->
    <!--                  </button>-->
    <!--                </td>-->
    <!--              </template>-->
    <!--            </l-table>-->
    <!--            <div class="footer">-->
    <!--              <hr>-->
    <!--              <div class="stats">-->
    <!--                role: {{$root.isInRole("rep")}}-->
    <!--                <i class="fa fa-history"></i> Updated {{ Math.floor( (501 - $root.nextEventTimer ) / 60 )}} minutes ago-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </card>-->

    <!--        </div>-->

    <!--      </div>-->
  </div>
  <!-- </div> -->
</template>
<script>
import AccountService from "/src/Services/AccountService";
import QueryService from "/src/Services/QueryService";
import RevenueCenterService from "/src/Services/RevenueCenterService";
import RoomService from "/src/Services/RoomService";
import TenantService from "/src/Services/TenantService";
import StatsService from "/src/Services/StatsService";
import currency from "currency.js";

import Moment from "moment";
import MomentTZ from "moment-timezone";
import Numeral from "numeral";
import Card from "/src/components/UIComponents/Cards/Card.vue";
import ChartCard from "/src/components/UIComponents/Cards/ChartCard.vue";
import ChartWidget from "/src/components/UIComponents/Widgets/ChartWidget.vue";
import SimpleStatsCard from "/src/components/UIComponents/Cards/SimpleStatsCard.vue";
import StatsCard from "/src/components/UIComponents/Cards/StatsCard.vue";
import Checkbox from "/src/components/UIComponents/Inputs/Checkbox.vue";
import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable from "/src/components/UIComponents/Table.vue";

import BarWidget from "/src/components/UIComponents/Widgets/BarWidget.vue";
import LineWidget from "/src/components/UIComponents/Widgets/LineWidget.vue";
import PieWidget from "/src/components/UIComponents/Widgets/PieWidget.vue";
import StatusWidget from "/src/components/UIComponents/Widgets/StatusWidget.vue";
import iLIB from "/src/Lib/ioihanLib.js";
import LIB          from "/src/Lib/rootLib.js";

export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    ChartWidget,
    StatsCard,
    SimpleStatsCard,
    SfInput,
    BarWidget,
    LineWidget,
    PieWidget,
    StatusWidget,
  },
  data() {
    return {
      LIB,
      Moment,
      currency,
      ticketSummary: [],
      ticketSummaryByUser: [],
      ticketPeriod: "month",
      revenueCenters: null,
      accountTypeCounts: [],
      myData: 0,
      autoUpdateSeconds: 900, //,          // # seconds for timer   // fixme: there is likely a better way to do this.
      autoRefreshTimer: 8, // 8 = run the auto update for 2 hours, then stop
      autoRefreshTime: Moment(),
      totalSales: 0.0,
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      chart1: null,
      chart1LastUpdated: "Updated Now",
      hourlySalesChart        : null,
      hourlySalesChartUpdated : "Updated Now",
      statusItems             : [
        { icon: "", iconStyle: "", itemStyle: "", title: "Line 1" },
        { icon: "", iconStyle: "", itemStyle: "color:pink;", title: "Line 1" },
        { icon: "", iconStyle: "", itemStyle: "", title: "Line 1" },
      ],

      occupancyItems: [],
      housingConfig: null,
      totalSalesByClass: {
        lastUpdate: Moment(),
        lastUpdateMessage: "Updated Now",
        update: 0,
        data: {
          labels: ["0%", "0%"],
          series: [0, 0],
        },
      },

      lastMonthlySalesResult  : null,
      monthlySalesResult      : null,
      totalMonthlySales       : 0.00,
      monthlySalesSummaryChart: null,
      monthlySalesChartUpdated: "Updated Now",

      monthlySalesSummary: {
        lastUpdate: "",
        lastUpdateMessage: "Updated Now",
        data: {
          labels: [],
          series: [],
          seriesLabels: [],
        },
        options: {
          low     : 0,
          high    : 1000,
          showArea: true,
          height  : "300px",
          axisX   : {
            showGrid: true,
          },
          lineSmooth  : true,
          showLine    : true,
          showPoint   : true,
          fullWidth   : false,
          chartPadding: {
            right: 20,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      hourlySales: {
        lastUpdate: "",
        lastUpdateMessage: "Updated Now",
        data: {
          labels: [
            "<6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            ">",
          ],
          series: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ],
          seriesLabels: [],
        },
        options: {
          low: 0,
          // high    : 400,
          showArea: false,
          height: "300px",
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 20,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      barChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          series: [
            [542, 443, 320, 780, 553, 625, 211, 918, 822, 1146, 17, 0],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
          ],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: "245px",
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      tableData: {
        data: [
          { title: "Sysco order received and stocked", checked: false },
          { title: "New menus review complete", checked: true },
          { title: "Test recipe: Apple Fritters with new crust sent to accounting dept for trial", checked: true },
          { title: "Update new-hire orientation packet", checked: true },
          { title: "Vending machine #2934-21 service call", checked: true },
          { title: "New table-tent cards to the printer", checked: false },
        ],
      },
    };
  },
  computed: {
    totalSalesData: function () {
      return this.totalSalesByClass.data;
    }
  },
  async created() {
    // this.$options.interval = setInterval(this.doUpdates, this.autoUpdateSeconds * 1000);
    try {
      this.getRevenueCenters();   // no need to await, this can come in whenever it wants too
      await this.getHousingConfig();
      await Promise.all([
        this.getDailySalesByTotalClass(),
        this.getHourlySales(),
        this.getMonthlySalesSummary(),
        this.getTicketSummary(),
        this.getAccountTypeCounts(),
        this.getBuildingOccupancyStats(),
      ]);
    } catch (err) {
      console.error(err);
    }
  },
  beforeDestroy() {
    if (this.$options.interval) {
      clearInterval(this.$options.interval);
    }
  },
  mounted() {},
  methods: {
    hasDashboard(d) {
      if (!this.$root.user) {
        return false;
      }
      d = _.find(this.$root.user.dashboardOptions.dashboards, { dashboard: d });
      if (d) {
        return true;
      }
      return false;
    },
    momentTZ(a, b) {
      return MomentTZ(a, b);
    },
    moment(a, b) {
      return moment(a, b);
    },
    nop() {
      alert("coming soon...");
    },
    sectionName(value) {
      if (!this.housingConfig) {
        return "*" + value;
      }
      let m = _.find(this.housingConfig.sections, { value: value });
      if (m) {
        return m.text;
      }
      return "**" + value;
    },
    modelName(value) {
      if (!this.housingConfig) {
        return "*" + value;
      }
      let m = _.find(this.housingConfig.models, { value: value });
      if (m) {
        return m.text;
      }
      return "**" + value;
    },
    siteOccupancyPercent() {
      if (this.occupancyItems.length === 0) {
        return "No Data";
      }

      let units = _.sumBy(this.occupancyItems, (r) => {
        return r.units;
      });
      let occupied = _.sumBy(this.occupancyItems, (r) => {
        return r.occupied;
      });
      // let occupancy = currency((occupied / units) * 100, "0.0") + "%";
      let occupancy = occupied > 0 ? currency((occupied / units) * 100).value : 100;
      return occupancy.toString();
    },
    sectionOccupancySummary() {
      let sections = _.uniq(_.map(this.occupancyItems, "section"));
      let results = sections.map((b) => {
        let units = _.sumBy(this.occupancyItems, (s) => {
          return s.section === b ? s.units : 0;
        });
        let available = _.sumBy(this.occupancyItems, (s) => {
          return s.section === b ? s.available : 0;
        });
        let occupied = _.sumBy(this.occupancyItems, (s) => {
          return s.section === b ? s.occupied : 0;
        });

        let occupancy = occupied > 0 ? currency((occupied / units) * 100).value : 100;
        return {
          title: b,
          units: units,
          available: available,
          occupied: occupied,
          occupancy: occupancy,
        };
      });
      return results; // results.map((b) => { return {title: b.available + ' / ' + b.units + ' :: ' + b.title }});
    },
    buildingOccupancySummary() {
      let buildings = _.uniq(_.map(this.occupancyItems, "building"));
      let results = buildings.map((b) => {
        let units = _.sumBy(this.occupancyItems, (s) => {
          return s.building === b ? s.units : 0;
        });
        let available = _.sumBy(this.occupancyItems, (s) => {
          return s.building === b ? s.available : 0;
        });
        let occupancy =
          available > 0 ? currency((1 - available / units) * 100).value : 100;
        return {
          title: b,
          units: units,
          available: available,
          occupancy: occupancy,
        };
      });
      return results; // results.map((b) => { return {title: b.available + ' / ' + b.units + ' :: ' + b.title }});
    },
    calcOrdersSeries(salesResult, monthOffset = 0 ) {

      if(!salesResult || salesResult.length === 0 ) {
        return[];
      }

      let results = [];
      let month   = Moment().add( monthOffset,'month').format('M');
      let year    = Moment().add( monthOffset,'month').format('YYYY');

      let rcXref =  this.getUniqueRevenueCentersFromArray( salesResult);
      let numDays = iLIB.daysInMonth( month, year );

      for (let i = 0; i < rcXref.length; i++) {

        let rc     = rcXref[i];
        let series = Array.from({length: numDays}, (v, k) => 0);

// console.log(month,year, series );


        for( let j=0; j<salesResult.length; j++) {   // loop through all the DATA elements
          let h = salesResult[j];
          if( h.revenueCenter === rc ) {
            series[h.day-1] += h.count;
          }
        }
        results.push( series );
      }
      // console.log( "results" , results );
      return results;
    },
    calcMonthlySalesSeries(salesResult, monthOffset = 0 ) {

      if(!salesResult || salesResult.length === 0 ) {
        return[];
      }

      let results = [];
      let month   = Moment().add( monthOffset,'month').format('M');
      let year    = Moment().add( monthOffset,'month').format('YYYY');

      let rcXref =  this.getUniqueRevenueCentersFromArray( salesResult);
      let numDays = iLIB.daysInMonth( month, year );

      for (let i = 0; i < rcXref.length; i++) {

        let rc     = rcXref[i];
        let series = Array.from({length: numDays}, (v, k) => 0);

      // console.log(month,year, series );

        for( let j=0; j<salesResult.length; j++) {   // loop through all the DATA elements
          let h = salesResult[j];
          if( h.revenueCenter === rc ) {
            series[h.day-1] += h.total;
          }
        }
        results.push( series );
      }
      // console.log( "results" , results );
      return results;

    },
    doUpdates() {
      if (this.autoRefreshTimer) {
        // if its on, let's do this
        this.autoRefreshTimer--; // count down so at some point, it exhausts and this auto update stops.

        try {
          Promise.all([
            //this.getDailySalesByTotalClass(),
            this.getHourlySales(),
            // this.getMonthlySalesSummary(),
            // this.getTicketSummary(),
          ]);
        } catch (err) {
          console.error(err);
        }
      }

      //this.totalSalesByClass.lastUpdateMessage = Moment(this.totalSalesByClass.lastUpdate).fromNow();
      this.hourlySales.lastUpdateMessage = Moment(
        this.hourlySales.lastUpdate
      ).fromNow(); // the timer updates regardless of if we are getting data or have long stopped.
    },
    updateZero() {
      let t = this;
      // t.totalSales.data.series[0] += 20;
      // t.lineChart.data.series[0] += 20;
    },
    initTotalSalesChart(a, b) {
      this.chart1 = a;
    },
    initMonthlySalesSummaryChart(a, b) {
      // this.monthlySalesSummaryChart = a;
    },
    initHourlySalesChart(a, b) {
      this.hourlySalesChart = a;
    },
    bld(hour) {
      b = Math.floor((hour - 4) / 6);
      return b < 0 ? 3 : b;
    },
    async getTicketSummary() {
      try {
        let [response1, response2] = await Promise.all([
          QueryService.getOpenTicketSummary(),
          QueryService.getTicketSummaryByUser(this.ticketPeriod),
        ]);
        //          let response = await QueryService.getOpenTicketSummary();
        this.ticketSummary = response1.data;
        this.ticketSummaryByUser = response2.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAccountTypeCounts() {
      try {
        let response = await AccountService.getAccountTypeCounts();
        this.accountTypeCounts = response.data;
        this.$root.accountTypeCounts = response.data; // get the current values here;
      } catch (error) {
        console.log(error);
      }
    },
    async getHousingConfig() {
      if( !this.hasDashboard('housing-model-available') && !this.hasDashboard('occupancy')){
        return;
      }
      try {
        let hc = localStorage.getItem("housingConfig");

        if (hc) {
          this.housingConfig = JSON.parse(hc);
        } else {
          let response = await TenantService.getHousingConfig();
          this.housingConfig = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBuildingOccupancyStats() {
      if( !this.hasDashboard('housing-model-available') && !this.hasDashboard('occupancy')) {
        console.log( "getBuildingOccupancyStats() not available");
        return;
      }
      try {
        let response = await RoomService.getBuildingOccupancyStats(
          "",
          this.housingConfig.unitTypes
            .map((u) => {
              return u.value;
            })
            .join(",")
        );

        if (response.data) {
          this.occupancyItems = response.data.map((s) => {
            s["title"] = this.modelName(s.roomModel);
            if (s.available <= 0) {
              s["itemStyle"] = "color:gray;";
            }
            return s;
          });
        } else {
          this.occupancyItems = [];
        }

        // console.log( "occupancyItems", this.occupancyItems );

      } catch (error) {
        console.log( "getBuildingOccupancyStats() Error" , error.message);
      }
    },
    async getRevenueCenters() {
      try {
        if (this.$root.revenueCenters) {
          this.revenueCenters = this.$root.revenueCenters;
          return;
        }
        let response = await RevenueCenterService.getRevenueCenters();
        this.revenueCenters = response.data;
        this.$root.revenueCenters = response.data; // get the current values here;
      } catch (error) {
        console.log(error);
      }
    },
    revenueCenterName( id ) {
      let rc = this.revenueCenters.find((r) => { return r.revenueCenterId === id; });
      return rc ? rc.title : id.toString() + " not found";
    },
    getUniqueRevenueCentersFromArray( a ) {
      let rcList = _.uniq(a.map((r) => {return r.revenueCenter;}),"revenueCenter").sort();
      return rcList;
    },
    xlatRevenueCentersFromArray( a ) {
      let result = [];

      if( a ) {
        a.forEach( rc => {
            result.push( this.revenueCenterName( rc ) );
          }
        )
      }
      return result;
    },
    async getMonthlySalesSummary() {
      let t=this;
      if (!this.hasDashboard("monthly-sales-summary")) {
        return;
      }
      try {
        let response = await QueryService.getDailySalesSummaryByRevenueCenter();
        this.monthlySalesResult = response.data;

        response = await QueryService.getDailySalesSummaryByRevenueCenter( 'all', Moment().subtract(1,"month").format("YYYY-MM-DD").toString() );
        this.lastMonthlySalesResult = response.data;

// console.log( "this.lastMonthlySalesResult", this.lastMonthlySalesResult );

        // if (response.data) {


          // var hs       = this.monthlySalesResult;
          // let maxTotal = 0;

          // let rcXref   = _.uniq(hs.map((r) => {return r.revenueCenter;}),"revenueCenter").sort();
          // let numDays  = iLIB.daysInMonth();

          // t.monthlySalesTotal        = 0;
          // t.monthlySalesSummary.data = {seriesLabels:[], labels: [], series: []};                                // setup a new data object.

          // t.monthlySalesSummary.data.labels = Array.from({length: numDays}, (v, k) => (k + 1).toString());

          // for (let i = 0; i < rcXref.length; i++) {

          //   let rc     = rcXref[i];
          //   let series = Array.from({length: numDays}, (v, k) => 0);

          //   t.monthlySalesSummary.data.seriesLabels.push(this.revenueCenterName(rc));

          //   for( let j=0; j<hs.length; j++) {   // loop through all the DATA elements
          //     let h = hs[j];
          //     if( h.revenueCenter === rc ) {
          //       series[h.day-1]     += h.total;
          //       t.totalMonthlySales += h.total;

          //       if( series[h.day-1] > maxTotal ) {
          //         maxTotal = series[h.day-1];
          //       }
          //     }
          //   }

          //   t.monthlySalesSummary.data.series.push( series );

          // }

          // t.monthlySalesSummary.options.high = 0.0 + Math.floor(maxTotal*1.1);
          // t.monthlySalesSummary.lastUpdate = Moment();

          // if (t.monthlySalesSummaryChart) {
          //   t.monthlySalesSummaryChart.update();
          // }

        // }
      } catch (error) {
        console.log(error);
      }
    },
    async getHourlySales() {
      var t = this;
      var response;

      if( !this.hasDashboard('hourly-sales')) {
        return;
      }

      try {
        response = await StatsService.getDailySales();
        if (response.data) {
          var maxTotal = 0;
          var hs = response.data;
          var i;
          var series;
          var index;

          let rcXref = _.uniq(hs.map((r) => {return r.revenueCenter;}),"revenueCenter").sort();

          let total = 0;

          // prep the chart data
          t.hourlySales.data.seriesLabels = [];
          t.hourlySales.data.series = [];
          //for( i=0; i<t.hourlySales.data.series.length; i++) {
          for (i = 0; i < rcXref.length; i++) {
            t.hourlySales.data.series.push([
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ]);
            t.hourlySales.data.seriesLabels.push("Other");
          }

          // loop through the incoming hourly sales array
          for (i = 0; i < hs.length; i++) {
            series = rcXref.indexOf(hs[i].revenueCenter);
            if (series < 0 || series >= t.hourlySales.data.series.length) {
              series = t.hourlySales.data.series.length - 1;
            }

            index = hs[i].hour - 6;

            if (index < 0) {
              index = 0;
            }

            if (index >= t.hourlySales.data.series[series].length) {
              index = t.hourlySales.data.series[series].length - 1;
            }

            t.hourlySales.data.series[series][index] += hs[i].total;

            total += hs[i].total;

            let r = _.find(t.revenueCenters, {revenueCenterId: hs[i].revenueCenter,});
            if (r) {
              t.hourlySales.data.seriesLabels[series] = r.title;
            } else {
              t.hourlySales.data.seriesLabels[series] = hs[i].revenueCenter.toString();
            }

            if (hs[i].total > maxTotal) {
              maxTotal = hs[i].total;
            }
          }

          this.totalSales = Numeral(total).format("0.00");

          t.hourlySales.options.high = 0.0 + (Math.floor(maxTotal * 1.1)) ;

          this.hourlySales.lastUpdate = Moment();
          if (t.hourlySalesChart) {
            t.hourlySalesChart.update();
          }

        }
      } catch (err) {
        console.error("hourlySales: ", err);
        //t.$root.errorMessage( "HOURLY SALES" , "Unable to retrieve summary");
      }

      //this.hourlySales.lastUpdateMessage = Moment(this.hourlySales.lastUpdate).fromNow();
    },
    async getDailySalesByTotalClass() {
      var t = this;
      var total = 0;
      var response;

      try {
        response = await StatsService.getDailySalesByTotalClass();

        if (response.data) {
          total = response.data.reduce(function (total, obj) {
            return total + obj.total;
          }, 0);

          //this.totalSales = Numeral(total).format("0.00");

          t.totalSalesByClass.data.series = []; //10,20,30,30,10,20];
          t.totalSalesByClass.data.labels = []; // '','','','','',''];

          if (total) {
            for (var i = 0; i < response.data.length; i++) {
              t.totalSalesByClass.data.series.push(response.data[i].total);
              t.totalSalesByClass.data.labels.push(
                "#" + response.data[i].totalClassId.toString()
              ); //Numeral( (response.data[i].total/total)*100 ).format("0.0") + "%");
            }
          }

          this.totalSalesByClass.lastUpdate = Moment();
          if (t.chart1) {
            t.chart1.update();
          }
        }
      } catch (err) {
        console.error("totalClass : ", err);
        //t.$root.errorMessage( "TOTAL CLASS" , "Unable to retrieve summary");
      }
    },
  },
};
</script>
<style lang="scss">
table.paleGreenRows {
  /*font-family: "Arial", serif;*/
  border: 1px solid #eee;
  /*width: 350px;*/
  /*height: 200px;*/
  text-align: center;
  border-collapse: collapse;
}

table.paleGreenRows td,
table.paleGreenRows th {
  border: 1px solid #000;
  padding: 3px 2px;
}

table.paleGreenRows tbody td {
  font-size: 13px;
  /*font-weight:bold;*/
}

table.paleGreenRows tr:nth-child(even) {
  background: rgba(135, 232, 34, 0.26);
}

table.paleGreenRows thead {
  background: #589916;
  color: #eee;
  /*font-size:14px;*/
  border-left: 1px solid #000;
  border-bottom: 2px solid #000;
}

table.paleGreenRows thead th {
  font-size: 14px;
  /*font-weight: bold;*/
  /*color: #FFFFFF;*/
  text-align: center;
  border-left: 1px solid rgba(135, 232, 34, 0.26);
}

table.paleGreenRows thead th:first-child {
  border-left: none;
}

table.paleGreenRows tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: rgba(135, 232, 34, 0.26);
  border-top: 3px solid #444444;
}

table.paleGreenRows tfoot td {
  font-size: 14px;
}

table.greyGridTable {
  border: 2px solid #ffffff;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

table.greyGridTable td,
table.greyGridTable th {
  border: 1px solid #ffffff;
  padding: 3px 4px;
}

table.greyGridTable tbody td {
  font-size: 13px;
}

table.greyGridTable td:nth-child(even) {
  background: #ebebeb;
}

table.greyGridTable thead {
  background: #ffffff;
  border-bottom: 4px solid #333333;
}

table.greyGridTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  border-left: 2px solid #333333;
}

table.greyGridTable thead th:first-child {
  border-left: none;
}

table.greyGridTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  border-top: 4px solid #333333;
}

table.greyGridTable tfoot td {
  font-size: 14px;
}

.stats-card-shadow {
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.35);
}

.account-stats {
  float: right;
  /*table {*/
  td {
    font-size: 12pt;
  }

  /*}*/
}
</style>
