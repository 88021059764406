<template>
  <b-container v-if="data" class="content container-fluid col-12" style="padding-top:.5em;overflow:auto;">
    <page-edit-header-card
      :title="'Template: ' + data.title"
      :enableClone="true"
      :isChanged="isDataChanged"
      :enableEditIcon="!isEditing"
      @clone="clone"
      @save="save"
      @cancel="cancel"
      @done="done"
      @edit="toggleEditMode()"
    >
      <template slot="title">
        <h3 class="card-title" style="text-transform:capitalize;">Template: <span style="font-size:.8em;font-weight:800;">{{data.title}}</span></h3>
      </template>
    </page-edit-header-card>

    <b-tabs style="margin-top:2em;" class="tab-hover" active-nav-item-class="selected-tab" v-model="selectedTab">
      <b-tab title="General" style="margin-top:2em;">
        <b-row >
          <b-col xl="6" style="border-right:2px solid gray;">
            <b-form @input="dataChanged()" style=""  >
              <h4 style="margin:0 0 1em 0;padding:0;">General</h4>
              <!-- <div style="float:right;">Terms: <span style="font-weight:800;">{{data.terms || 'open'}}</span></div> -->
              <b-checkbox style="" v-model="data.active" :disabled="isReadOnly()">Active</b-checkbox>
              <br>
              <!-- <b-form-tags style="background:lightgray;margin-bottom:1em;" :disabled="isReadOnly()"  separator=" ,;" tag-pills size="md" :tag-validator="tagValidator" v-model="data.tags"></b-form-tags> -->
              <sf-input v-model="data.templateName" :disabled="isReadOnly() || data.title.length>0" :labelCols="2" cstyle="" label="Template Name" type="text" @change="data.templateName=data.templateName.replaceAll(' ','-').toLowerCase()" required/>
              <sf-input v-model="data.templateType" :disabled="isReadOnly()"  :labelCols="2"  cstyle="width:15em;" label="Type" type="list" :options="templateTypes" />
              <sf-input v-model="data.category" :disabled="isReadOnly()"  :labelCols="2"  cstyle="width:15em;" label="Category" type="list" :options="categoryList" />
              <sf-input v-model="data.title"  :disabled="isReadOnly()" :labelCols="2"  label="Title" type="text" required/>
              
              <sf-input v-model="data.description"  :readonly="isReadOnly()" :labelCols="2" :rows="12" label="Notes" type="textarea" />
            </b-form>
          </b-col>
          <b-col xl="6">
            <b-form @input="dataChanged()" style="text-align:left;">
              <h4 style="margin:0 0 1em 0;padding:0;">two</h4>

              <!-- <sf-input v-model="data.primary.name" :disabled="isReadOnly()"  :labelCols="2"  cstyle="" label="Name" type="text" />
              <sf-input v-model="data.primary.address1"  :disabled="isReadOnly()" :labelCols="2"  cstyle="" label="Address" type="text" />
              <sf-input v-model="data.primary.address2" :disabled="isReadOnly()"  :labelCols="2"  cstyle="" label="" type="text" />
              <sf-input v-model="data.primary.address3" :disabled="isReadOnly()"  :labelCols="2"  cstyle="" label="" type="text" />
              <sf-input v-model="data.primary.city" :disabled="isReadOnly()"  :labelCols="2"  cstyle="width:18em;" label="City" type="text" />
              <sf-input v-model="data.primary.state"  :disabled="isReadOnly()" :labelCols="2" cstyle="width:3em;" label="State" type="text" />
              <sf-input v-model="data.primary.zip" :disabled="isReadOnly()"  :labelCols="2"   cstyle="width:8em;" label="Zip" type="text" />
              <br>
              <sf-input v-model="data.primary.phone" :disabled="isReadOnly()"  :labelCols="2"  label="Phone" type="text" />
              <sf-input v-model="data.primary.cell" :disabled="isReadOnly()"  :labelCols="2"   label="Cell" type="text" />
              <sf-input v-model="data.primary.email" :disabled="isReadOnly()"  :labelCols="2"  label="Email" type="text" />
              <sf-input v-model="data.primary.website"  :disabled="isReadOnly()" :labelCols="2" label="Website" type="text" /> -->
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
      
      <b-tab title="Query" >
        <b-row>
          <b-col col-md="6">
            <b-form @input="dataChanged()" style="">
              <b-button class="btn btn-primary btn-small" @click="testJson()">Test</b-button>
              <b-checkbox style="" v-model="lineNumbers">Line Numbers</b-checkbox>
              <prism-editor id="query-editor" class="my-editor" :readonly="isReadOnly()" v-model="data.query" :highlight="mongoHighlighter" :line-numbers="lineNumbers"></prism-editor>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Template Data" >
        <b-row>
          <b-col col-md="6">
            <b-form @input="dataChanged()" style="">
              <b-checkbox style="" v-model="lineNumbers">Line Numbers</b-checkbox>
              <prism-editor id="code-editor" class="my-editor" :readonly="isReadOnly()" v-model="data.templateData" :highlight="codeHighlighter" :line-numbers="lineNumbers"></prism-editor>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>

    
      <!-- <b-tab title="Active Tasks" @click="getVendorTasks()">
        <list-card
            title="Tasks"
            titleHelp="Listing of tasks assigned to this vendor"
            :items="_.sortBy(taskList, ['title'])"
            :fields="fields"
            @click="editTask()"
            noDataPrompt="No tasks assigned to this vendor"
          >
          <template v-slot:action="data">
              <span>
                <i v-if="data.item.active && data.item.steps.complete"  class="ml-2 fa fa-check show-hand" style="color:green;"></i>
                <i v-if="data.item.closed || !data.item.steps.complete"  class="ml-2 fa fa-square-o show-hand" style="color:green;"></i>
              </span>
          </template>
        </list-card>
      </b-tab> -->
      
      
    </b-tabs>
    <!-- <b-button @click="save" class="btn btn-sm btn-fill btn-primary">Save</b-button> -->
  </b-container>
</template>
<script>


  // import PieWidget          from "/src/components/UIComponents/Widgets/PieWidget.vue";
  // import BarWidget          from "/src/components/UIComponents/Widgets/BarWidget.vue";
  // import TemplateService    from "/src/Services/TemplateService";
  import iLib               from "/src/Lib/ioihanLib.js";
  import Currency           from "currency.js";
  import gravatar           from "gravatar";
  import Moment             from "moment";
  import Card               from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput            from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import ChecklistEditor    from "/src/components/UIComponents/ChecklistEditor";
  import PageEditHeaderCard from '/src/components/UIComponents/Cards/PageEditHeaderCard.vue';
  import VueSimpleSuggest   from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  import ListCard           from '/src/components/UIComponents/Cards/ListCard.vue';

  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/components/prism-markup';
  import 'prismjs/components/prism-mongodb';
  import 'prismjs/components/prism-textile';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
  // import 'prismjs/themes/prism-sol-light.css'; // import syntax highlighting styles
  // import 'prismjs/themes/prism-dark.css';
  // import 'prismjs/themes/prism-twilight.css';
 

  var propId;
  //var propUser;

  export default {
    directives: {
    },
    components: {
      Card,
      SfInput,
      VueSimpleSuggest,
      ChecklistEditor,
      // PieWidget,
      // BarWidget,
      ListCard,
      PageEditHeaderCard,
      PrismEditor
    },
    props: {
      template: {type: Object, default: () => {}},
      changed : {type: Boolean, default: false},
      editing : {type: Boolean, default: false},
    },
    watch: {
    },
    data() {
      return {
        isDataChanged: false,
        isLoading    : false,
        isEditing    : false,
        data         : null,
        selectedTab  : 0,
        lineNumbers  : true,
        templateTypes: [
          {text: 'Import Template', value: 'import'},
          {text: 'Export Template', value: 'export'},
          {text: 'Function Template', value: 'function'},
        ],
        categoryList   : [
          {text: 'Process Template', value: 'template'},
          {text: 'Data Template', value: 'data'},
          {text: 'Generic', value: 'general'},
        ],
      }
    },
    async created() {
    },
    mounted() {
      this.data          = this.template;

      if( ! this.data.category === "template" ) {
        if( ! this.data.templateData ) {
          this.data.templateData = {};
        }
      } else {
        if( ! this.data.templateData ) {
          this.data.templateData = "";
        }
      }

      this.data.templateData = this.makeStringFromObject(this.data.templateData);


      this.isDataChanged = this.changed;
      this.isEditing     = this.editing;
      console.log( "templateEdit.mounted()", this.isDataChanged , this.data );
    },
    computed: {
    },
    methods: {
      showit( e ) {
        console.log( "showit", e );
      },
      testJson() {
        console.log( iLib.makeJSONFromString(this.data.query))
      },
      codeHighlighter(code) {
        return highlight(code, languages.javascript); // languages.<insert language> to return html with markup
      },
      mongoHighlighter(code) {
        return highlight(code, languages.mongodb); // languages.<insert language> to return html with markup
      },
      makeStringFromObject(obj) {
        if( typeof obj === "object" ) {
          return JSON.stringify(obj,null,2);
        }
        return obj.toString();
      },
      currency(c) {
        return Currency(c);
      },
      USD( value ) {
        return Currency(value, { precision: 2, symbol: ' ' }).format();
      },
      dataChanged() {
        this.isDataChanged = true;
        this.$emit('changed');
      },
      tagValidator(tag) {
        return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
      },
      done() {
        this.$emit("done" , this.data );
      },
      async save() {
        if( this.isDataChanged ) {
          try { // convert back to object
            if( this.data.category === "template" ) {
              this.data.templateData = JSON.parse(this.data.templateData);
            }
          }catch( error ) {

            // let snippet = this.data.templateData.substring(100,130);
            // console.log( "snippet" , snippet );
            await this.$root.alert( `Data Error<br>Check the data<br>${error.message}` );
            return;
          }

          this.$emit("save", this.data, this.isDataChanged );
          this.isDataChanged = false;
        } else {
          this.done();
        }
      },
       createRange(node, chars, range) {
          if (!range) {
              range = document.createRange()
              range.selectNode(node);
              range.setStart(node, 0);
          }

          if (chars.count === 0) {
              range.setEnd(node, chars.count);
          } else if (node && chars.count >0) {
              if (node.nodeType === Node.TEXT_NODE) {
                  if (node.textContent.length < chars.count) {
                      chars.count -= node.textContent.length;
                  } else {
                      range.setEnd(node, chars.count);
                      chars.count = 0;
                  }
              } else {
                for (var lp = 0; lp < node.childNodes.length; lp++) {
                      range = createRange(node.childNodes[lp], chars, range);

                      if (chars.count === 0) {
                          break;
                      }
                  }
              }
          } 

          return range;
      },
      toggleEditMode() {
        this.isEditing = !this.isEditing;
      },
      isReadOnly(){
        return !this.isEditing;
      },
      async clone() {
        // console.log( "clone" );
        if( ! await this.$root.confirmYesNo( "Clone this record?" )) {
            return;
        }
        this.dataChanged();
        this.$emit("clone", this.data );
      },
      async cancel() {
        // console.log( "cancel" );
        if( this.isDataChanged ) {
          if( ! await this.$root.confirmYesNo( "You made changes, continue to leave?" )) {
            return;
          }
        }
        this.isDataChanged = false;
        this.$emit("cancel", this.data );
      }
    }
  }

</script>
<style lang="scss" >

  // .outline-shadow {
  //   -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  //   box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.35);
  // }

  // .collapsible {
  //   background-color: #eee;
  //   margin-top: .5em;
  //   border-radius: 1em;
  //   color: #444;
  //   cursor: pointer;
  //   padding: 10px;
  //   border: none;
  //   text-align: left;
  //   outline: none;
  // }

 

  
 .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    // background: #2d2d2d;
    // background:#c9b882;
    background:#444;
    // color: #fff !important;
    border-radius:1em;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }


  .prism-editor-wrapper pre  {
    color: white; 
    font-weight:bold;
  }


  /* optional class for removing the outline */
  .prism-editor:focus {
    outline: none;
    color: white;
  }

  // code[class*="language-"],
  // pre[class*="language-"] {
  //   color: #fff;
  //   // background: #2d2d2d;
  //   // text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  //   // font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  //   // font-size: 14px;
  //   // line-height: 1.5;
  //   // white-space: pre;
  //   // word-spacing: normal;
  //   // word-break: normal;
  //   // word-wrap: normal;
  //   // line-height: 1.5;
  //   // tab-size: 4;
  //   // hyphens: none;
  // }

  // @media only screen and (max-device-width: 1700px) {
  //   .hide-too-small {
  //     display:none;
  //   }
  // }


  // @media print {

  //   @page {
  //     size: letter portrait;
  //   }

  //   body {
  //     -webkit-print-color-adjust: exact !important;
  //     width: 100%;
  //   }

  //   .logo {
  //     width: 300px !important;
  //     max-width: 300px !important;
  //   }

  //   .noprint {
  //     display: none;
  //   }

  //   .container {
  //     width: 100%;
  //     max-width: 100%;
  //   }
  // }

</style>
