<template>
  <div class="sidebar"
       :style="sidebarStyle"
       :data-color="backgroundColor"
       :data-image="backgroundImage"
       :data-active-color="activeColor">

    <div class="sidebar-wrapper" ref="sidebarScrollArea" >
      <div class="logo">
        <a href="/" class="simple-text">
          <div class="" style="text-align:center;">
            <img v-if="$root.tenant && $root.tenant.branding && $root.tenant.branding.appLogo" :src="'/img/'+$root.tenant.branding.appLogo" alt="ADMIN Panel">
            <img v-else src="/img/icare-cafe-logo-150.png" alt="ADMIN Panel">
            <!-- <img :src="$root.tenant.logo" /> -->
          </div>
          <span>{{title}}</span>
        </a>
      </div>

           <!-- <slot name="content"></slot> -->
      <slot></slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
                   <!-- <sidebar-link v-for = "(link,index) in sidebarLinks"-->
          <!--                        :key  = "link.name + index"-->
          <!--                        :to   = "link.path"-->
          <!--                        @click= "closeNavbar"-->
          <!--                        :link = "link">-->
          <!--            <i :class="link.icon"></i>-->
          <!--            <p>{{link.name}}</p>-->
          <!--          </sidebar-link> -->
          <sidebar-link v-for="(link, tIndex) in sidebarLinks" :key="'navItem'+ tIndex.toString()" :link="link">
            <sidebar-link v-for="(subLink, sIndex) in link.children" class="subNav" :key="'subNaveItem' + tIndex.toString() + sIndex.toString()" :link="subLink">
            </sidebar-link>
          </sidebar-link>

        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
  import SidebarLink from "./SidebarLink.vue";
  // import VuePerfectScrollbar from "vue-perfect-scrollbar";

  export default {
    name: "sidebar",
    props     : {
      title          : { type: String,default: "Newcare" },
      backgroundImage: { type: String,default: "/img/sidebar-5.jpg" },
      backgroundColor: { type: String, default  : "black", validator: value => {
                                                                                        let acceptedValues = [
                                                                                          "",
                                                                                          "default",
                                                                                          "blue",
                                                                                          "azure",
                                                                                          "green",
                                                                                          "orange",
                                                                                          "red",
                                                                                          "purple",
                                                                                          "black"
                                                                                        ];
                                                                                        return acceptedValues.indexOf(value) !== -1;
                                                                                      }
      },
      activeColor    : { type     : String,default  : "success", validator: value => {
                                                                                        let acceptedValues = [
                                                                                          "primary",
                                                                                          "info",
                                                                                          "success",
                                                                                          "warning",
                                                                                          "danger"
                                                                                        ];
                                                                                        return acceptedValues.indexOf(value) !== -1;
                                                                                      }
      },
      sidebarLinks: { type: Array, default: () => [] },
      autoClose   : { type: Boolean,default: true}
    },  //-----------added below here...
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    computed  : {
      sidebarStyle() {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        };
      }
    },
    methods   : {
      async initScrollBarAsync() {
      },
      displaySidebar( x ) {
        console.log( "sidebar.vue::displaySidebar" , x );
      },
      showSidebar() {
        console.log( "sidebar.showSidebar() hit");
      }
    },
    mounted() {
    },

    beforeDestroy() {
      if(this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  };
</script>
<style>
  @media (min-width : 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display : none;
    }
  }
</style>
