<template>
  <div >
    <div class="greyGridTable">
      <span v-html="markdown(text)"></span>
    </div>
  </div>
</template>

<script>

import {marked} from 'marked';

// window.hljs = hljs;

export default {
  props: {
     text: { type: String, default: ""  },
     lang: { type: String, default: "markdown" },
  },
  data() {
    return {
      nothing: ""
    };
  },
  computed: {
  },
  created() {

    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'markdown';
        return hljs.highlight(code, { language }).value;
      },
      langPrefix : 'hljs language-',   // highlight.js css expects a top-level 'hljs' class.
      pedantic   : false,
      gfm        : true,
      breaks     : false,
      sanitize   : false,
      smartLists : true,
      smartypants: true,
      xhtml      : false
    });
  },
  methods: {
    markdown(d) {
      if( !marked) {
        return "<h4>check markdown parser</h4>";
      }
      return marked.parse(d);
    }
  }
};
</script>

<style lang="scss"  >

   table.greyGridTable {
    border: 2px solid #FFFFFF;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
  }
   table.greyGridTable td,  table.greyGridTable  th {
    border: 1px solid #FFFFFF;
    padding: 3px 4px;
  }
   table.greyGridTable tbody td {
    font-size: 13px;
  }
   table.greyGridTable td:nth-child(even) {
    background: #EBEBEB;
  }
   table.greyGridTable thead {
    background: #FFFFFF;
    border-bottom: 4px solid #333333;
  }
   table.greyGridTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    border-left: 2px solid #333333;
  }
   table.greyGridTable thead th:first-child {
    border-left: none;
  }

   table.greyGridTable tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    border-top: 4px solid #333333;
  }
   table.greyGridTable tfoot td {
    font-size: 14px;
  }

</style>
