<template>
  <div style="position:relative;">
    <div style="width:100%;">
      <b-button class="btn btn-success btn-sm btn-fill float-left" :disabled="!isDataChanged" @click="saveTenant()">Update Tenant</b-button>
      <b-button class="btn btn-danger btn-sm btn-fill ml-2 mr-3 float-left" @click="cancelEdit()">Cancel</b-button>
      <h4>{{ tenant.tenantName }}</h4>
    </div>
    <img :src="tenant.logo" class="" style="position:absolute;top:-15px;right:0;" width="200px" />
    <div>
      <b-tabs class="tab-hover" content-class="mt-3" active-nav-item-class="selected-tab" v-model="activeTab">

        <b-tab title="General" active>
          <b-form horizontal breakpoint="lg" label="General Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0" @input="dataChanged()">
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Tenant Name" label-for="tenantName">
              <b-form-input id="tenantName" type="text" v-model="tenant.tenantName" required placeholder="Tenant Name"></b-form-input>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Domain" label-for="domainName">
              <b-form-input id="domainName" readonly type="text" v-model="tenant.domainName" required placeholder="Domain Name"></b-form-input>
            </b-form-group>
          </b-form>
        </b-tab>

        <b-tab title="References">
          <!-- <b-tabs class="tab-hover" content-class="mt-2 ml-3" active-nav-item-class="selected-tab"> -->
          <!-- <b-tab title="Site Contacts"> -->
          <div style="margin-top:2em;margin-left:2em;width:650px;">
            <b-form-group horizontal breakpoint="lg" label="Primary Contact" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Name" label-for="primaryContact">
                <b-form-input id="primaryContact" type="text" v-model="tenant.primaryContact" placeholder="Name" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="eMail" label-for="primaryEmail">
                <b-form-input id="primaryEmail" type="text" v-model="tenant.primaryEmail" placeholder="eMail" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Phone" label-for="primaryPhone">
                <b-form-input id="primaryPhone" type="text" v-model="tenant.primaryPhone" placeholder="Phone" @input="dataChanged()"></b-form-input>
              </b-form-group>
            </b-form-group>
            <hr>
            <b-form-group horizontal breakpoint="lg" label="Administrative Contact" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Name" label-for="administrativeContact">
                <b-form-input id="administrativeContact" type="text" v-model="tenant.administrativeContact" placeholder="Name" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="eMail" label-for="administrativeEmail">
                <b-form-input id="administrativeEmail" type="text" v-model="tenant.administrativeEmail" placeholder="eMail" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Phone" label-for="administrativePhone">
                <b-form-input id="administrativePhone" type="text" v-model="tenant.administrativePhone" placeholder="Phone" @input="dataChanged()"></b-form-input>
              </b-form-group>
            </b-form-group>
            <hr>
            <b-form-group horizontal breakpoint="lg" label="Billing Contact" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Name" label-for="billingContact">
                <b-form-input id="billingContact" type="text" v-model="tenant.billingContact" placeholder="Name" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="eMail" label-for="billingEmail">
                <b-form-input id="billingEmail" type="text" v-model="tenant.billingEmail" placeholder="eMail" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Phone" label-for="billingPhone">
                <b-form-input id="billingPhone" type="text" v-model="tenant.billingPhone" placeholder="Phone" @input="dataChanged()"></b-form-input>
              </b-form-group>
            </b-form-group>
            <hr>
            <b-form-group horizontal breakpoint="lg" label="Technical Contact" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Name" label-for="primaryContact">
                <b-form-input id="primaryContact" type="text" v-model="tenant.technicalContact" placeholder="Name" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="eMail" label-for="primaryEmail">
                <b-form-input id="primaryEmail" type="text" v-model="tenant.technicalEmail" placeholder="eMail" @input="dataChanged()"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Phone" label-for="primaryPhone">
                <b-form-input id="primaryPhone" type="text" v-model="tenant.technicalPhone" placeholder="Phone" @input="dataChanged()"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
        </b-tab>

        <b-tab title="Contacts">
          <div style="position:relative;">
          <i class="fa fa-plus-square" style="position:absolute;right:0;font-size:1.25em;" @click="addressAdd()" v-b-popover.hover.top="'Add new contact'"></i>
            <div style="display:flex;flex-wrap:wrap;justify-content:flex-start;">
              <template v-for="(contact,index) in tenant.address" >
                <contact-card2
                  :key="'contact'+index"
                  :index="index"
                  :contact="contact"
                  :isInlineNotes="true"
                  @remove="addressRemove($event)"
                  @change="contactsChanged()"/>
              </template>
            </div>
           </div>
        </b-tab>

        <b-tab title="Features" v-if="tenant && $root.user.isSA">
          <h4>Feature Selection</h4>
          <b-row>
            <b-col>
              <div>
                <b-checkbox-group v-model="tenant.features" stacked :options="featureOptions" @change="dataChanged()"></b-checkbox-group>
              </div>
              <hr />
              <div style="font-size:8pt;float:right;">{{ env() }}</div>
            </b-col>
            <b-col>
              <p style="color:red;width:70%;">NOTE: If you enable a feature here you should also enable the feature for the users who need it.  Otherwise, they won't see it. Not every feature has a corresponding user-level feature flag.</p>
              <template v-if="tenant.branding">
                <b-form-group label="Branding App Title">
                  <b-form-input v-model="tenant.branding.appTitle" @change="dataChanged();$root.tenant.branding=tenant.branding;"></b-form-input>
                </b-form-group>
                <b-form-group label="Branding Logo (blank = iCare)">
                  <b-form-input v-model="tenant.branding.appLogo" @change="dataChanged();$root.tenant.branding=tenant.branding;"></b-form-input>
                </b-form-group>
              </template>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Teams" style="border-left:4px solid #5555cc;">
          <p class="help-text">Teams are useful for grouping people together when performing tasks - useful for Helpdesk and other tasks</p>
          <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
            <option-management-table
              title="Teams"
              :tableData="tenant.teams"
              @change="dataChanged()"
            />
          </div>
        </b-tab>

        <b-tab title="Settings">
          <b-form @change="dataChanged()">
          <b-form-group label="Settings" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3">
              <b-form-checkbox id="setup_allow_signup" v-model="tenant.allowSignup">Allow Signups</b-form-checkbox>
              <template v-if="$root.user.isSA">
                <b-form-checkbox id="active_tenant" v-model="tenant.active">Is Active Tenant</b-form-checkbox>
                <b-form-checkbox id="setup_trial" v-model="tenant.isTrial">Is Trial Tenant</b-form-checkbox>
                <b-form-checkbox id="setup_onhold" v-model="tenant.isOnHold">Is On Hold</b-form-checkbox>
              </template>
            </b-form-group>
            <b-form-group label="Theme" label-for="theme" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
              <b-form-select id="theme" type="text" style="width:8em;" @input="updateTheme(tenant.theme)" :key="tenant.theme" :options="themes" v-model="tenant.theme"></b-form-select>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Fiscal Year" label-class="text-sm-right" label-for="FiscalYear">
              <b-form-select id="FiscalYear" style="width:8em;" v-model="tenant.fiscalYearOffset" :options="fiscalYearOffsets" ></b-form-select>
            </b-form-group>
            <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Timezone" label-class="text-sm-right" label-for="timezone">
              <b-form-select id="timezone" style="width:18em;" v-model="tenant.TZ" :options="timezones" ></b-form-select>
            </b-form-group>
            <b-form-group label="Logo" label-for="logo" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
              <b-form-input id="logo" type="text" v-model="tenant.logo" placeholder="Logo" ></b-form-input>
            </b-form-group>
            <hr>
            <b-form-group label="Global API Key" label-for="apiKey" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
              <b-form-input :id="'setup_api_key'" type="text" v-model="tenant.apiKey" placeholder="API Key" ></b-form-input>
            </b-form-group>
          </b-form-group>
          </b-form>
        </b-tab>

        <b-tab title="Transaction" v-show="$root.user.isSA && tenant.transaction && tenant.transaction.nextTicketNumber">
          <b-row>
            <b-col cols="7">
              <b-form style="margin-top:2em;width:100%;">
                <b-form-group label="Next Ticket#" label-for="nextTicketNumber" label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextTicketNumber'" type="number" v-model="tenant.transaction.nextTicketNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Next Work Order #" label-for="nextWorkorderNumber" horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextWorkorderNumber'" type="number" v-model="tenant.transaction.nextWorkorderNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Next Asset #" label-for="nextAssetNumber" horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextAssetNumber'" type="number" v-model="tenant.transaction.nextAssetNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Next Project #" label-for="nextProjectNumber" label-cols-sm="4" label-cols-lg="4" horizontal label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextProjectNumber'" type="number" v-model="tenant.transaction.nextProjectNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Next Sensor #" label-for="nextSensorNumber" label-cols-sm="4" label-cols-lg="4" horizontal label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextSensorNumber'" type="number" v-model="tenant.transaction.nextSensorNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Next Catering #" label-for="nextCaterhingNumber" label-cols-sm="4" label-cols-lg="4" horizontal label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextCateringNumber'" type="number" v-model="tenant.transaction.nextCateringOrderNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Credit Cards Next Invoice #" label-for="nextInvoiceNumber" label-cols-sm="4" label-cols-lg="4" horizontal label-class="text-sm-right">
                  <b-form-input style="width:10em;" :id="'nextInvoiceNumber'" :disabled="!$root.user.isSA" type="number" v-model="tenant.transaction.nextInvoiceNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="5">
              <h3 style="color:red;font-weight:1000;">Caution</h3>
              <p>Take care when editing these values. <br><br>Making these numbers less than the value in the fields can cause processing failures as that can create duplicate
                document ID's. Change with caution!</p>
              <p style="color:red;font-weight:800;">NOTE: You MUST remain on this TAB when you press SAVE to save the transaction values otherwise they will NOT be saved.</p>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Portal">
          <b-form-group label="Portal Details" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group horizontal>
              <b-form-checkbox id="'portal_active'" v-model="tenant.portal.active">
                Portal Active
              </b-form-checkbox>
            </b-form-group>
            <b-tabs v-if="tenant.portal.active" content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-normal">
              <b-tab title="Settings">
                <b-form>
                  <b-form-group label="Portal Signup Code" label-for="signupCode" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                    <b-form-input :id="'signupCode'" type="text" v-model="tenant.portal.signupCode" @blur="tenant.portal.signupCode=tenant.portal.signupCode.toUpperCase()" placeholder="Signup Code"></b-form-input>
                  </b-form-group>
                  <b-form-group label="ABOUT Url" label-for="aboutUrl" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                    <b-form-input :id="'aboutUrl'" type="url" v-model="tenant.portal.aboutUrl" placeholder="ABOUT Url" @input="dataChanged()"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Transaction Fee %" label-for="transactionFee" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                    <b-form-input :id="'transactionFee'" type="number" v-model="tenant.portal.transactionFee" placeholder="Transaction Fee" @input="dataChanged()"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                    <b-checkbox v-model="tenant.portal.options.isShowTeacher">Show Teacher Name on mySchool.Cafe Profile Page</b-checkbox>
                  </b-form-group>
                  <div v-if="importTags" style="">
                    <hr>
                    <h5>Allowed Student Import Tags</h5>
                    <b-form-checkbox-group label-cols="4" label="Import Tags" v-model="tenant.portal.options.importTags" :options="importTags">
                    </b-form-checkbox-group>
                  </div>
                </b-form>
              </b-tab>
              <b-tab title="SIGNUP Details">
                <b-form-group label="Account Type" label-for="accountType" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'accountType'" style="width:14em;" type="text" v-model="tenant.portal.accountType" placeholder="Account Type" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Account Class ID" label-for="accountClassId" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'accountClassId'" style="width:14em;" type="number" v-model="tenant.portal.accountClassId" placeholder="Account Class ID" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="GL Account" label-for="glAccount" horizontallabel-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'glAccount'" style="width:14em;" type="text" v-model="tenant.portal.glAccount" placeholder="GL Account #" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="AR Type" label-for="arType" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'arType'" style="width:14em;" type="text" v-model="tenant.portal.arType" placeholder="AR Type" @input="dataChanged()"></b-form-input>
                </b-form-group>

              </b-tab>
              <b-tab title="RELOAD Details">
                <b-form-group label="Reload Title" label-for="transTitle" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'transTitle'" type="text" v-model="tenant.portal.reloadTransaction.title" placeholder="Transaction Title" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Low Threshold" label-for="lowValue" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'lowValue'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.lowThreshold" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="MIN Reload Value" label-for="minReload" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'maxReload'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.minReloadValue" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="MAX Reload Value" label-for="maxReload" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'maxReload'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.maxReloadValue" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Total Class ID" label-for="totalClassId" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'totalClassId'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.totalClassId" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Revenue Center" label-for="revenueCenter" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'revenueCenter'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.revenueCenter" @input="dataChanged()"></b-form-input>
                </b-form-group>
                <b-form-group label="Tender Number" label-for="tenderNumber" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                  <b-form-input :id="'tenderNumber'" style="width:10em;" type="number" v-model="tenant.portal.reloadTransaction.tenderNumber" @input="dataChanged()"></b-form-input>
                </b-form-group>

              </b-tab>
            </b-tabs>
          </b-form-group>
        </b-tab>

        <b-tab title="Merchant" v-if="$root.isAdmin() && tenant.private">
          <b-form-group horizontal>
            <b-form-checkbox id="merchantId" v-model="tenant.private.merchantActive" @change="dataChanged()">
              Enable Merchant Account
            </b-form-checkbox>

          </b-form-group>
          <template v-if="tenant.private.merchantActive">
            <template v-if="tenant.private.ccAccess.isAuthorized">
              <b-form-group label="Merchant Setup Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-normal">
                  <b-tab title="Merchant Info">

                    <b-button class="btn btn-fill btn-warning btn-sm float-right mt-1" @click="deAuthorizeMerchant()">DE-Activate</b-button>
                    <div class="col-md-10 mr-2">
                      <b-form-group label="Merchant Processor" label-for="merchantProcessor" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="merchantProcessor" type="text" v-model="tenant.private.merchantProcessor" readonly placeholder="Merchant Processor"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Auth Merchant" label-for="authMerchant" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="authMerchant" type="text" v-model="tenant.private.ccMerchant" readonly placeholder="Auth Merchant"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Merchant Account" label-for="merchantAccount" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="merchantAccount" type="text" v-model="tenant.private.merchant.name" readonly placeholder="Merchant Account Name"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Serial #" label-for="serialNumber" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="serialNumber" type="text" v-model="tenant.private.merchant.serialNumber" readonly placeholder="Serial #"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Revision" label-for="revision" horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right">
                        <b-form-input id="revision" type="text" v-model="tenant.private.merchant.revision" readonly placeholder="Revision"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-tab>
                  <b-tab title="Auth Info">
                    <b-form-group horizontal>
                      <table class="table striped">
                        <tr>
                          <td>Authorization Date</td>
                          <td v-if="tenant.private.ccAccess.authorizationDate">{{ moment(tenant.private.ccAccess.authorizationDate).format("dddd DD-MMM-YY hh:mm:ssa") }}</td>
                          <td v-else>-none-</td>
                        </tr>
                        <tr>
                          <td>Interface Name</td>
                          <td>{{ tenant.private.ccAccess.interfaceName }}</td>
                        </tr>
                        <tr>
                          <td>Interface Version</td>
                          <td>{{ tenant.private.ccAccess.interfaceVersion }}</td>
                        </tr>
                        <tr>
                          <td>Authorization Source</td>
                          <td>{{ tenant.private.ccAccess.authorizationSource }}</td>
                        </tr>
                        <tr>
                          <td>Authorization Server</td>
                          <td>{{ tenant.private.ccAccess.authorizationServer }}</td>
                        </tr>
                      </table>
                    </b-form-group>
                  </b-tab>
                  <b-tab title="Accepted Cards">
                    <b-form-group horizontal>
                      <b-table striped hover responsive :items="tenant.private.merchant.cardTypes" :fields="cardTypeFields"></b-table>
                    </b-form-group>
                  </b-tab>
                </b-tabs>
                <hr>
                <label class="float-right">Merchant Account:
                  <b style="color:green;">ACTIVE</b>
                </label>
              </b-form-group>
              <br>

            </template>
            <template v-else>
              <b style="color:red;">NOT ACTIVE</b>
              <br>
              <sf-input label="Application" type="list" :options="[{'text':'mySchool Cafe','value':'myschool'},{'text':'Donations Express','value':'donationsexpress'}]" v-model="ccMerchant"></sf-input>
              <sf-input label="Auth Token" type="text" v-model="authToken"></sf-input>
              <p class="mt-3 description">Please contact support if you have questions on ths step</p>
              <b-button :disabled="(authToken.length <10)" class="btn btn-fill btn-success mt-3" @click="authorizeMerchant()">
                <span v-show="isAuthorizing" :class="animatedSpinner"></span> Authorize This Merchant Account
              </b-button>
            </template>
          </template>
        </b-tab>

      </b-tabs>
      <div class="clearfix"></div>
    </div>
    <br />
  </div>
</template>

<script>
import TenantService             from "/src/Services/TenantService";
import PortalRegistrationService from "/src/Services/PortalRegistrationService";
import Payments                  from "/src/Services/Payments";
import Moment                    from "moment";
import Card                      from "/src/components/UIComponents/Cards/Card.vue";
import ContactCard               from "/src/components/UIComponents/Cards/ContactCard.vue";
import ContactCard2               from "/src/components/UIComponents/Cards/ContactCard2.vue";
import SfInput                   from "/src/components/UIComponents/Inputs/SFormInput.vue";
import OptionManagementTable     from "/src/components/UIComponents/Cards/OptionManagementTable.vue";

export default {
  components: {
    Card,
    ContactCard,
    ContactCard2,
    SfInput,
    OptionManagementTable
  },
  data() {
    return {
      activeTab      : 0,
      isDataChanged  : false,
      isAuthorizing  : false,
      animatedSpinner: "fa fa-spinner  fa-refresh-animate mr-3",
      importTags     : null,
      featureOptions : [
        {text: "Account Management", value: "accounts"},
        {text: "Account Lists", value: "accountlists"},
        {text: "Asset Module", value: "assets"},
        {text: "Catering Module", value: "catering"},
        {text: "Certification Module", value: "certifications"},
        {text: "Dashboard", value: "dashboard"},
        {text: "Display Dog", value: "displaydog"},
        {text: "Donation Module", value: "donations"},
        {text: "Environmental Module", value: "environmental"},
        {text: "Events Module", value: "events"},
        {text: "Functions", value: "functions"},
        {text: "Help Module", value: "help"},
        {text: "Helpdesk", value: "tickets"},
        {text: "Housing Module", value: "housing"},
        {text: "iPOS Cafe", value: "ipos"},
        {text: "Kiosk Module", value: "kiosks"},
        {text: "Links Module", value: "links"},
        {text: "Marketing Module", value: "marketing"},
        {text: "Menu Board", value: "menuboard"},
        {text: "Policies & Procedures Module", value: "procedures"},
        {text: "Portal (mySchool)", value: "portal"},
        {text: "Project Management", value: "projects"},
        {text: "Reports & Queries", value: "reports"},
        {text: "Team Tasks", value: "teamtasks"},
        {text: "Transaction Managemnet", value: "transactions"},
        {text: "Vendor Management", value: "vendors"},
      ],

      saveDisabled     : true,
      changeCount      : 0,
      authToken        : "",
      ccMerchant       : "shift4",
      cardTypeFields   : [
        {key: "type", label: "Card Type"},
        {key: "voiceCenter.accountNumber", label: "Account Number"},
        {key: "voiceCenter.phoneNumber", label: "Phone Number"}
      ],
      fiscalYearOffsets: [
        {text: "January", value: 0},
        {text: "February", value: 1},
        {text: "March", value: 2},
        {text: "April", value: 3},
        {text: "May", value: 4},
        {text: "June", value: 5},
        {text: "July", value: 6},
        {text: "August", value: 7},
        {text: "September", value: 8},
        {text: "October", value: 9},
        {text: "November", value: 10},
        {text: "December", value: 11}
      ],
      timezones        : [
        // todo: get this list from the API (or even maybe from the TZ server)
        {text: "Please select a Timezone", value: null},
        {text: "America/New York (EDT/EST)", value: "America/New_York"},
        {text: "America/Chicago (CST CDT)", value: "America/Chicago"},
        {text: "America/Denver (MST)", value: "America/Denver"},
        {text: "America/Los_Angeles (PST PDT)", value: "America/Los_Angeles"},
        {text: "America/Anchorage (AKST AKDT)", value: "America/Anchorage"},
        {text: "America/Phoenix (MST)", value: "America/Phoenix"},
        {text: "America/Fort_Nelson (PST PDT MST)", value: "America/Fort_Nelson"},
        {text: "Pacific/Honolulu (HST)", value: "Pacific/Honolulu"},
        {text: "Europe/Paris (CET/CEST)", value: "Europe/Paris"}
      ],
      themes           : [ "default", 'azure', 'green', 'blue', 'orange', 'red', 'purple' ],
      tenant           : {
        tenantName           : "",
        primaryContact       : "",
        primaryPhone         : "",
        primaryEmail         : "",
        billingContact       : "",
        billingPhone         : "",
        billingEmail         : "",
        administrativeContact: "",
        administrativePhone  : "",
        administrativeEmail  : "",
        address              : [ {} ],
        features             : [ {} ],
        sites                : [ {} ],
        interfaceLink        : [ {} ],
        portal               : {},
        apiKey               : "",
        domainName           : "",
        private              : {
          merchantActive: false
        },
        transaction          : {
          nextInvoiceNumber  : 0,
          nextWorkorderNumber: 0,
          nextAssetNumber    : 0
        },
        theme                : "",
        landingPage          : "",
        logo                 : "",
        allowSignup          : false
      }
    };
  },

  async mounted() {
    await this.getTenant();
    await this.getImportTags();
  },
  async beforeRouteLeave(to, from, next) { // this does not work and I "think" it is because the admin route is using the topBar nav which isn't wired in to the router
    if(!this.isDataChanged) {
      return next();
    }
    if(await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    dataChanged() {
      this.isDataChanged = true;
    },
    contactsChanged() {
      this.dataChanged();
      this.tenant.address = _.sortBy( this.tenant.address, ['name','title']);
    },
    env() {
      return process.env[ "NODE_ENV" ];
    },
    async cancelEdit() {
      if(this.isDataChanged) {
        if(!await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
          return;
        }
      }
      this.$router.go(-1)
    },
    async authorizeMerchant() {

      this.isAuthorizing = true;
      let response       = "";
      try {
        let authToken = this.authToken;
        let merchant  = this.ccMerchant;
        response      = await Payments.authorizeMerchant(authToken, merchant);
        this.$root.successMessage("AUTHORIZATION", response.data.message);
        await this.getTenant();
        this.tenant.private.merchantActive = true;
        this.dataChanged();
      } catch(err) {
        this.isAuthorizing = false;
        if(err.response.data) {
          this.$root.errorMessage("AUTHORIZATION", err.response.data.message);
        } else {
          this.$root.errorMessage("AUTHORIZATION", err.message);
        }
      }
      this.authToken     = ""
      this.isAuthorizing = false;
    },
    async deAuthorizeMerchant() {
      let response = "";

      if(!confirm("De-Authorize This Merchant Account?")) {
        return;
      }

      this.isAuthorizing = true;

      try {
        response = await Payments.deAuthorizeMerchant();
        this.$root.successMessage("DE-AUTHORIZATION", response.data.message);
        await this.getTenant();
      } catch(err) {
        console.log(JSON.stringify(err, null, 2));
        if(err.response.data) {
          this.$root.errorMessage("DE-AUTHORIZATION", err.response.data.message);
        } else {
          this.$root.errorMessage("DE-AUTHORIZATION", err.message);
        }
      }
      this.isAuthorizing = false;
    },
    addressAdd() {
      let address = {contactType:"contact", title: "", name: "", alias:"", address1:"", city:"",  state:"" , zip:"", website: "", phone: "", email: "", cell: "", sms: "", smsCarrier: "" , TZ: "America/New_York" }
      this.tenant.address.unshift(address);
      this.dataChanged();
    },
    async addressRemove(id) {
      let index = _.findIndex(this.tenant.address, {_id: id});
      if(index < 0) {
        return;
      }
      if(!await this.$root.confirmYesNo(`Remove [${this.tenant.address[ index ].name || ""}]?`)) {
        return;
      }
      this.tenant.address.splice(index, 1);
      this.dataChanged();
    },
    updateTheme(value) {
      this.$root.setTheme(value);
      this.dataChanged();
    },
    async getImportTags() {
      try {
        let response    = await PortalRegistrationService.getPortalAuthorizationsImportTags();
        this.importTags = response.data.map((t) => { return {text: t.importTag, value: t.importTag}});
      } catch(error) {
        console.log(error.message);
      }
    },
    async getTenant() {
      var t = this;
      var response;
      try {
        response    = await TenantService.getTenant();
        this.tenant = response.data;
        if(this.tenant.address) {
          this.tenant.address = _.orderBy(this.tenant.address, [ 'address1' ])
        }
        this.isDataChanged = false;
      } catch(err) {
        console.error("getTenant error: ", err);
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant");
        }

      }
    },
    async saveTenant() {
      var t = this;
      var response;
      try {

        let tenant = Object.assign({}, this.tenant);

        if(this.activeTab !== 6) {    // only if ON the transaction tab
          delete tenant.transaction;
        }

        if(tenant.address) {
          tenant.address = _.orderBy(tenant.address, [ 'address1' ])
        }

        response = await TenantService.saveTenant(tenant);

        this.isDataChanged = false;

        t.$root.tenant = t.tenant;    // $root.tenant gets used in quite a few places. it's our global config
        delete t.$root.tenant.private;
        t.$root.setTheme(t.tenant.theme);

        t.$root.successMessage("SUCCESS", "Tenant Updated");
        t.$router.go(-1);

      } catch(err) {

        if(err.response) {
          console.error("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if(err.request) {
          console.error("Error Request ", err.request);
        } else {
          console.error("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Tenant Data", err);
      }
    },
    moment(d) {
      return Moment(d);
    }
  }
};
</script>

<style lang="scss">
</style>
