///-------------------------------------------------------------------------------------------------
// summary:	Order service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getOrderCalendars(all="") {
    return Api().get(`/api/1/orderCalendars/${all}`);
  },
  getOrderCalendar(id) {
    return Api().get(`/api/1/orderCalendar/${id}`);
  },
  getOrderCount(calendarNumber, orderDate = null) {
    if( !orderDate ){
      return Api().get(`/api/1/orderCalendarOrderCount/${calendarNumber}`);
    } else {
      return Api().get(`/api/1/orderCount/${calendarNumber}/${orderDate}`);
    }
  },
  getOrder(id) {
    return Api().get(`/api/1/order/${id}`);
  },
  updateOrder(id,data) {
    return Api().patch(`/api/1/order/${id}`, data);
  },
  getOrdersByAccount(accountNumber, beginDate , endDate) {
    return Api().get(`/api/1/ordersByAccount/${accountNumber}` , {params: {beginDate: beginDate, endDate: endDate}});
  },
  addOrderCalendar(newCalendar) {
    return Api().post("/api/1/orderCalendar", newCalendar);
  },
  updateOrderCalendar(orderCalendar) {
    return Api().patch("/api/1/orderCalendar", orderCalendar);
  },
  removeOrderCalendar(id) {
    return Api().delete(`/api/1/orderCalendar/${id}`);
  },
  getOrdersSummary(calendar, day) {
    return Api().get(`/api/1/ordersSummary/${encodeURI(calendar)}/${encodeURI(day)}`)
  },
  getOrdersSummaryBySchedule(calendar, day, schedule, byPriceLevel = 0 ) {
    return Api().get(`/api/1/ordersSummaryBySchedule/${encodeURI(calendar)}/${encodeURI(day)}`, { params: { mealperiod: schedule, byPriceLevel: byPriceLevel }})
  },
  sendOrdersSummary(calendar, day) {
    return Api().get(`/api/1/ordersSummary/${encodeURI(calendar)}/${encodeURI(day)}`, {
      params: {
        email: true
      }
    });
  },
  getOrdersList(calendar, day) {
    return Api().get(`/api/1/ordersList/${encodeURI(calendar)}/${encodeURI(day)}`)
  },
  sendOrdersList(calendar, day) {
    return Api().get(`/api/1/ordersList/${encodeURI(calendar)}/${encodeURI(day)}`, {
      params: {
        email: true
      }
    });
  }
}
