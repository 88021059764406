<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <template slot="header">
          <h3 class="card-title">Authorization Lists
            <button class="float-left mr-3 btn btn-success btn-sm btn-fill" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Done</button>
          </h3>
          <hr>
        </template>
        <div class="row">
          <div class="col-md-12">
            <b-button class="btn-warning btn-sm btn-fill float-right" @click="switchList()">{{listButtonLabel}}</b-button>
            <h4><i v-if="activeList===0" class="fa fa-plus-square show-hand mr-2" @click="addAuthorization()"></i>{{listLabel}}</h4>
            <b-form-input id="filterString" type="search"  style="float:right;width:250px;border-radius:2em;margin-bottom:.5em;" v-model="filterString" placeholder="Filter This List" />
            <b-table v-if="registrations.length>0"  style="font-size:12px" head-variant="dark" :filter="filterString" striped hover :items="registrations" :fields="fields">
              <template v-slot:cell(action)="data">
                <!--                <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove This Registration'" @click="removeAccount(data.item)"></i>-->
                <i v-if="data.item.registration.accountNumber>''" class="fa fa-user show-hand" v-b-popover.hover.top="'Go To Account Record'" @click="goToAccount(data.item)"></i>
                <i v-if="data.item.registration.accountNumber===''" class="fa fa-trash show-hand" v-b-popover.hover.top="'Delete this registration'" @click="removeAuthorization(data.item)"></i>
              </template>
              <template v-slot:cell(idNumber)="data">
                <span class="show-hand" @click="editAuthorization(data.item)">{{data.item.idNumber}}</span>
              </template>
              <template v-slot:cell(dob)="data">
                {{moment(data.item.dob).format("MM-DD-YYYY")}}
              </template>
              <template v-slot:cell(createdAt)="data">
                {{moment(data.item.createdAt).fromNow()}}
              </template>
            </b-table>
            <div v-else>
              <h5 v-if="loading">Loading Registrations, Please Wait...</h5>
              <h5 v-else>No Registrations</h5>
            </div>
          </div>
        </div>
      </card>

      <b-modal
        ref="registrationDialog"
        :title="registrationTitle"
        size="lg"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-container fluid v-if="registration">
          <!--          <b-checkbox type="checkbox" style="position:absolute;right:50px;" v-model="task.isComplete"> Complete</b-checkbox>-->
          <sf-input :readonly="registration.registration.isRegistered" v-model="registration.idNumber" cstyle="width:15em;" type="text" label="ID Number" />
          <sf-input v-model="registration.firstName" cstyle="width:15em;" type="text" label="First Name" />
          <sf-input v-model="registration.lastName" cstyle="width:15em;" type="text" label="Last Name" />
          <sf-input v-model="registration.dob" cstyle="width:15em;" type="date" label="DOB" />

          <hr>
          <!-- <sf-input v-model="registration.teacher" cstyle="width:15em;" type="list" @change="teacherChanged()" :options="teacherList.map((t) => {return t.teacher})" label="Teacher" /> -->
          <form @submit.prevent="addNewTeacher">
            <vue-simple-suggest
                v-model="registration.teacher"
                :list = "teacherList"
                display-attribute="teacher"
                value-attribute="id"
                :filter-by-query="true"
                @select="assignTeacher"
              >

              <div style="width:100%;display:flex;">
                  <!-- <label>Teacher</label> -->
                  <input placeholder="Teacher search..." :value="registration.teacher" type="search" style="margin-left:15.25em;margin-bottom:.25em;height:42px;width:20em;" />
              </div>

              <div slot="suggestion-item" slot-scope="{suggestion}" style="min-width:800px;">
                <div style="line-height:32px;">
                  <span style="font-weight:700;">{{ suggestion.teacher || "" }}</span>
                  <span style="font-size:.8em;font-weight:300;">/ Grade: {{ suggestion.grade }}</span>
                  <span style="font-size:.8em;font-weight:300;">/ Home Room: {{suggestion.homeRoom}}</span>
                </div>
              </div> 
            </vue-simple-suggest>
          </form>
          <sf-input v-model="registration.homeRoom" cstyle="width:8em;" type="text" label="Home Room" />
          <sf-input v-model="registration.grade" cstyle="width:8em;" type="text" label="Grade" />
          

          <sf-input v-model="registration.priceLevel" cstyle="width:5em;" type="number" min=0 max=9 label="Price Level" />
          <sf-input v-model="registration.importTag" cstyle="width:15em;" type="list" :options="importTags" label="Import Tag"/>
          <!-- <hr>
          <div v-if="!registration.registration.isRegistered" >
            <sf-input v-model="registration.arType" cstyle="width:15em;" type="text" label="AR Type" />
            <sf-input v-model="registration.glAccount" cstyle="width:15em;" type="text" label="GL Account" />
            <hr>

            <sf-input v-model="registration.accountType" cstyle="width:15em;" type="list" :options="accountTypes" label="Account Type" />
            <sf-input v-model="registration.accountClassId" type="number" label="Account Class ID" />
            <sf-input v-model="registration.totalClassId" type="number" label="Total Class ID" />
          </div> -->
        </b-container>
        <div slot="modal-footer" class="mt-4">
          <b-btn variant="success" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="updateAuthorization(registration)">Save/Done</b-btn>
          <b-btn variant="warning" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="hideDialog()">Cancel</b-btn>
          <b-btn variant="error" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="removeAuthorization(registration)">Delete</b-btn>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>

  // -------------------------------------------------------------------------------------------------------------
  //
  //  Definition of Terms
  //
  //    Authorization = NOT YET Registered (NOT CONNECTED to an Account Record -- work waiting to happen)
  //    Registration  = A REGISTERED record (connected to an Account record - the action HAS been performed)
  //
  // -------------------------------------------------------------------------------------------------------------


  import Moment                    from "moment";
  import AccountService            from "/src/Services/AccountService";
  import TenantService             from "/src/Services/TenantService";
  import PortalRegistrationService from "/src/Services/PortalRegistrationService";
  import Card                      from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput                   from "/src/components/UIComponents/Inputs/SFormInput.vue";

  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import VueSimpleSuggest   from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css'  // Optional CSS

  export default {
    components: {
      Card,
      Moment,
      SfInput,
      VueSimpleSuggest
    },
    data() {
      return {
        isResult         : false,
        isBusy           : false,
        filterString     : "",
        accountTypes     : [],
        accountClasses   : [],
        registrationTitle: "Edit Registration Info",
        registrations    : [],
        registration     : null,
        activeList       : 1,
        listLabel        : "Available Registrations",
        listButtonLabel  : "See Registered",
        importTags       : [],
        portalConfig     : null,
        teacherList      : null,
        headerBgVariant  : 'dark',
        headerTextVariant: 'light',
        bodyBgVariant    : 'light',
        bodyTextVariant  : 'dark',
        footerBgVariant  : 'warning',
        footerTextVariant: 'dark',
        fields           : [
          "action",
          {key: 'idNumber', label: "ID", sortable: true},
          {key: 'lastName', sortable: true},
          {key: 'firstName', sortable: true},
          {key: 'dob', label: "DOB", sortable: true},
          {key: 'grade', label: "Grade", sortable: true},
          {key: 'homeRoom', sortable: true},
          {key: 'teacher', sortable: true},
          {key: 'priceLevel', label: "Price Level", sortable: true},
          {key: 'registration.title', label: "Parent", sortable: true},
          {key: 'importTag', label: "Import TAG", sortable: true}
        ],
        loading          : false,
        isLoading        : "fa fa-spinner  fa-refresh-animate mr-3",
        type             : [ '', 'info', 'success', 'warning', 'danger' ],
        notifications    : {
          topCenter: false
        }
      }
    },
    async created() {
      await Promise.all([
                          this.getAccountTypes(),
                          this.getAccountClasses(),
                          this.getPortalRegisteredAuthorizations(),
                          this.getPortalConfig(),
                          this.getTeacherList()
                        ])

    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      goToAccount(record) {
        if(record.registration) {
          this.$router.push("/app/accountEdit/" + record.registration.accountNumber);
        }
      },
      async teacherSearch( searchString ) {
        this.isResult = false
        let results = this.teacherList.filter((t) => {
          return t.teacher.toLowerCase().indexOf( searchString.toLowerCase() ) >= 0;
        });
        this.isResult = true;
        return results;
      },
      addNewTeacher( t ) {
        let nt = {
          teacher : t.teacher,
          grade   : t.grade,
          homeRoom: t.homeRoom,
          id     : this.teacherList.length
        }
        this.teacherList.push(nt);
      },
      assignTeacher(t) {
        if( t ) {
          let r = this.registration;

          r.teacher = t.teacher;
          r.grade = t.grade;
          r.homeRoom = t.homeRoom;

          if( parseInt(r.grade) >= 2 )  {   // fixme: this should come from the tenant portal configuration
            r.priceLevel = 1;
          } else {
            r.priceLevel = 0;
          }

          // if this is a teacher, set the priceLevel to 1 (adult price)      fixme: should come from the tenant portal configuration
          let rn = (r.firstName+r.lastName).toLowerCase().replaceAll(" ","");
          if( r.teacher.toLowerCase().replaceAll(" ","").indexOf( rn ) >= 0 )  {
            r.priceLevel = 1;
          }

        }
      },
      async switchList() {
        if(this.activeList === 0) {
          this.getPortalRegisteredAuthorizations();
          this.activeList = 1;
        } else {
          this.getPortalAvailableAuthorizations();
          this.activeList = 0;
        }
      },
      showDialog() {
        this.$refs.registrationDialog.show();
      },
      hideDialog() {
        this.$refs.registrationDialog.hide();
      },

      async addAuthorization() {

        this.registrationTitle = "ADD Registration Info";

        let t = {
          "registration": {
          "isRegistered": false,
            "accountNumber": "",
            "title": ""
        },
          "table": "student",
          "idNumber": "",
          "firstName": "",
          "lastName": "",
          "homeRoom": "",
          "grade": "",
          "teacher": "",
          "title": "",
          "priceLevel": 1,
          "importTag": this.importTags[ this.importTags.length-1 ],
          "accountType": this.portalConfig.accountType || "student",
          "accountClassId": this.portalConfig.accountClassId || 100,
          "totalClassId": this.portalConfig.totalClassId || 100,
          "arType": this.portalConfig.arType || "",
          "glAccount": this.portalConfig.glAccount || "",
          "dob": "01/01/2000"
        }

        this.registration = t;
        this.showDialog();
      },
      async editAuthorization(item) {
        // if( item.registration.accountNumber > "" ) {
        //   alert( "Cannot edit a registered authorization" );
        //   return;
        // }

        this.registrationTitle = "EDIT Registration Info";
        await this.getAuthorization( item._id );
        this.showDialog();
      },
      async getAuthorization( id ) {
        try {
          let response = await PortalRegistrationService.getAuthorization( id );
          this.registration = response.data;
        } catch( error ) {
          //todo: tell the user
          console.log( error.message );
          await this.$root.alert( error.message , "warning" );
        }
      },
      async getTeacherList() {
        if( this.teacherList ) {
          return;
        }
        try {
          let response = await PortalRegistrationService.getPortalRegistrationsTeachers();
          this.teacherList = response.data;
          let index = 0;
          this.teacherList = this.teacherList.map((t) => { t.id = index++; return t; })
        } catch( error ) {
          console.log( error.message );
          await this.$root.alert( error.message ,"warning");
        }  
      },
      async getPortalConfig() {
        if( this.portalConfig ) {
          return;
        }
        try {
          let response = await TenantService.getPortalConfig();
          this.portalConfig = response.data;
        } catch( error ) {
          console.log( error.message );
          await this.$root.alert( error.message ,"warning");
        }
      },
      async updateAuthorization( authorization ) {
        try {

          if( !authorization.idNumber ) {
            await this.$root.alert( "ID number is required", 'warning' );
            return;
          }

          if( !authorization.teacher) {
            await this.$root.alert( "Teacher selection is required. If this record IS a teacher, select their record (or type it in)" , 'warning' );
            return;
          }

          let result;
          if( ! authorization._id ) {
            result = await PortalRegistrationService.addAuthorization( authorization );
          } else {
            let id     = authorization._id;
            result = await PortalRegistrationService.updateAuthorization(authorization);
            _.remove(this.registrations, {_id: id});
          }
          this.registrations.push(result.data);
          this.hideDialog();
        } catch( error ) {
          // todo: TEll the user
          console.log( error.message );
          this.$root.alert( error.message, "warning" );
        }
      },
      async removeAuthorization( authorization ) {

        if( ! authorization._id  ) {      // new record doesn't exist yet anyway...
          return;
        }

        if(! this.$root.confirm( "Remove " + authorization.idNumber + ", " + authorization.firstName + " " + authorization.lastName + ", from this list?" )) {
          return;
        }

        try {
          let id = authorization._id;
          await PortalRegistrationService.removeAuthorization( id );
          _.remove( this.registrations , { _id: id });
          this.registrations.push({});      // force the vue instance to update the array.
          this.registrations.pop();
          this.hideDialog();
        } catch( error ) {
          // todo: TEll the user
          console.log( error.message );
        }
      },
      async getAccountTypes() {
        try {
          let response      = await AccountService.getAccountTypes();
          this.accountTypes = response.data;
        } catch(error) {
          // todo: tell the user
          console.log(error.message);
          await this.$root.alert( error.message , "warning" );
        }
      },
      async getAccountClasses() {
        try {
          let response = await AccountService.getAccountClasses();
          this.accountClasses = response.data; //.accountClass;
        } catch( error ) {
          // todo: tell the user
          console.log( error.message );
          await this.$root.alert( error.message , "warning" );
        }
      },
      async getPortalAvailableAuthorizations() {
        var response;
        var t = this;

        t.loading = true;

        try {
          this.listLabel       = "Available (un-registered) Authorizations";
          this.listButtonLabel = "See Registered";
          response             = await PortalRegistrationService.getPortalAvailableAuthorizations("student");
          this.registrations   = response.data;
          this.importTags = [ ...new Set( this.registrations.map( (r) => { return r.importTag }))];
          t.loading            = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
      async getPortalRegisteredAuthorizations() {
        var response;
        var t = this;

        t.loading = true;

        try {

          this.listLabel       = "Registered Authorizations";
          this.listButtonLabel = "See Un-Registered";
          response             = await PortalRegistrationService.getPortalRegisteredAuthorizations("student");
          this.registrations   = response.data;
          this.importTags = [ ...new Set( this.registrations.map( (r) => { return r.importTag }))];

          t.loading            = false;
        } catch(err) {
          if(err) {
            console.error(err);
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      }

    }
  }

</script>
<style lang="scss" scoped>

.table-container {
  position: relative;
  width: 100%;
}
.b-table[aria-busy="true"] + .table-spinner {
  /* this assumes that the spinner component has a width and height */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* make sure spinner is over table */
}

</style>
