"use strict";

//
//  rootLib
//
//  author: wjscott 20-Nov-18
//
//  Some global functions to be used where ever as a MIXIN
//

import _       from "lodash";
import Numeral from "numeral";
import Moment  from "moment-timezone";

String.prototype.replaceAll = function( str1, str2, ignore ){
  return this.replace( new RegExp( str1.replace( /([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&" ),
                                   (ignore ? "gi" : "g") ),
                       (typeof str2 === "string") ? str2.replace( /\$/g, "$$$$" ) : str2 );
};

export default {


  sayWolfie() {
    this.$root.errorMessage( "oops" , "this is from wolfie");
    alert( "Hello Wolfie");
  },

  functionTwo() {
    this.sayWolfie();
  },

  cleanName( s ) {
    return s.replaceAll("/" , "-" )
            .replaceAll("%", "-" )
            .replaceAll("?", "-" )
            .replaceAll("&", "-" )
            .replaceAll("'", "" )
            .replaceAll("@", "-" );
  },
  formatFileSize(bytes) {
    if (bytes < 10000) {    // Just bytes
      return Numeral(bytes).format("0,0") + " b";
    }
    if (bytes < 10000000) {  // K
      return Numeral(Math.round(bytes/1000)).format("0,0") + " KB"
    }
    if (bytes < 100000000000) {  // m
      return Numeral(bytes/1000000 ).format("0,0.00") + " M"
    }
    return Numeral(bytes/1000000000).format("0,0.00") + " G"
  },


};
