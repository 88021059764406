<template>
  <div class="module container my-5 py-5" style="background: black">
    <h1>Privacy Policy of {{ company }}</h1>

    <h4>EFFECTIVE AS OF: APRIL 8TH, 2020</h4>

    <p>
      {{ company }} operates this website and the {{ url }} application, which
      provides the SERVICE. Usage of the SERVICE signifies your acceptance of
      this policy.
    </p>

    <p>
      This page is used to inform visitors regarding our policies with the
      collection, use, and disclosure of Personal Information.
    </p>

    <p>
      If you choose to use our Service, then you agree to the collection and use
      of information in relation with this policy. The Personal Information that
      we collect is used for providing and improving the Service. We will not
      use or share your information with anyone except as described in this
      Privacy Policy.
    </p>

    <p>
      The terms used in this Privacy Policy have the same meanings as in our
      Terms of Service, which is accessible at {{ url }}, unless otherwise
      defined in this Privacy Policy.
    </p>

    <h2>Information Collection and Use</h2>

    <p>
      For a better experience while using our Service, we may require you to
      provide us with certain personally identifiable information, including but
      not limited to name, phone number, and postal address, and in some cases,
      date of birth and other details to help provide the service. The
      information that we collect will be used to contact or identify you and
      the parties within the system for the purpose of providing the service.
    </p>

    <h2>Log Data</h2>

    <p>
      We want to inform you that whenever you visit our Service, we collect
      information that your browser sends to us that is called Log Data. This
      Log Data may include information such as your computer’s Internet Protocol
      ("IP") address, browser version, pages of our Service that you visit, the
      time and date of your visit, the time spent on those pages, and other
      statistics.
    </p>

    <h2>Cookies</h2>

    <p>
      Cookies are files with small amount of data that is commonly used an
      anonymous unique identifier. These are sent to your browser from the
      website that you visit and are stored on your computer’s hard drive.
    </p>

    <p>
      Our website uses these "cookies" to collection information and to improve
      our Service. You have the option to either accept or refuse these cookies,
      and know when a cookie is being sent to your computer. If you choose to
      refuse our cookies, you may not be able to use some portions of our
      Service.
    </p>

    <h2>Service Providers</h2>

    <p>
      We may employ third-party companies and individuals due to the following
      reasons:
    </p>

    <ul>
      <li>To facilitate or host our Service; or</li>
      <li>To perform Service-related duties, troubleshooting, and maintenace; or</li>
      <li>To assist us in analyzing how our Service is used and/or performing.</li>
    </ul>

    <p>
      We want to inform our Service users that these third parties are bound by
      privacy policies but may have access to your Personal Information as
      required to perfrom their duties. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to copy,
      disclose, or use, the information for any other purpose.
    </p>

    <h2>Security</h2>

    <p>
      We value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it to the best
      of our ability. But remember that no method of transmission over the
      internet, or method of electronic storage is 100% secure and reliable, and
      we cannot guarantee its absolute security.
    </p>

    <h2>Links to Other Sites</h2>

    <p>
      Our Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by us. Therefore, we strongly advise you
      to review the Privacy Policy of these websites. We have no control over,
      and assume no responsibility for the content, privacy policies, or
      practices of any third-party sites or services.
    </p>

    <h2>Changes to This Privacy Policy</h2>

    <p>
      We may update our Privacy Policy from time to time. Thus, we advise you to
      review this page periodically for any changes. We will notify you of any
      changes by posting the new Privacy Policy on this page. These changes are
      effective immediately, after they are posted on this page.
    </p>

    <h2>Contact Us</h2>

    <p>
      If you have any questions or suggestions about our Privacy Policy, do not
      hesitate to contact us.
    </p>
  </div>
</template>
<script>
export default {
  props: {
    company: { type: String, default: "Newcare International, LLC" },
    url: { type: String, default: "https://icare.cafe" },
    website: { type: String, default: "iCare" },
  },
  data() {
    return {
      // terms: terms
    };
  },
};
</script>
<style scoped lang="scss">
.small {
  font-size: 12pt;
}

.container {
  text-align: left;
}

p {
  max-width: 80%;
  font-weight:400;
  display: inline-block;
  color: gray;
}

ol {
  max-width: 80%;
  color: gray;
}

ul {
  max-width: 80%;
  color: gray;
}

h1 {
  color: moccasin;
}

h2 {
  max-width: 80%;
  color: moccasin;
}

h3 {
  max-width: 80%;
  color: moccasin;
}

h4 {
  max-width: 80%;
  color: mediumseagreen;
  font-weight: 800;
}

h5 {
  max-width: 80%;
  color: lightgoldenrodyellow;
}

.noteworthy {
  background: lightgray;
  text-align: left;
  padding: 2em;
  margin-bottom: 3em;
  border-radius: 2em;
  color: moccasin;
  p {
    min-width: 100%;
    padding: 1em 8em 0 1em;
    text-align: left;
    font-size: 1.25em;
    margin-left: 2em;
    font-weight: 200;
    text-decoration: italic;
  }
}

.ist {
  color: gray;
  float: right;
  max-width: 30%;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 2em;
}
</style>
