<template>
  <div class="container-fluid main-content">

    <div class="row noprint" v-if="!calendar && !isPrintTeacherSheets">
      <card class="col col-md-10 ml-auto mr-auto shadow">
        <template slot="header">
          <h3 class="card-title">
            Orders Listing - Calendar Selection
            <button class="float-left mr-3 btn btn-sm btn-success btn-fill" @click="$router.push('/app');">
              <span v-show="isLoading" :class="isLoadingStyle"></span>Done
            </button>
          </h3>
          <hr>
        </template>
        <div class="row">
          <div class="col-md-12">
            <!--            <button class="btn btn-default btn-sm float-right" @click="addOrderCalendar()">Add Menu Calendar</button>-->
            <h4 class="mt-0">Select the calendar </h4>

            <button class="float-right mt-0 mr-3 mb-3 btn btn-default btn-sm btn-fill" style="border-radius:.75em;" v-b-popover.hover.top="'Get ALL Calendars, Even Expired Ones'" @click="getOrderCalendars('all')">
              <span v-show="isLoading" :class="isLoading"></span>Show Expired Calendars
            </button>

            <b-table ref="mytable" v-if="orderCalendars.length>0" striped hover head-variant="dark" small class="show-hand" :items="orderCalendars" :fields="fields" @row-clicked="getOrderCalendar">
              <template v-slot:cell(title)="data">{{ data.item.title }}</template>
              <template v-slot:cell(active)="data">{{ (data.item.active === true) ? 'Yes' : 'No' }}</template>
              <template v-slot:cell(activeDate)="data">{{
                  (data.item.activeDate) ?
                  moment(data.item.activeDate).format("MMM Do") : 'Not Set'
                }}
              </template>
              <template v-slot:cell(calendarStartDate)="data">{{
                  (data.item.calendarStartDate) ?
                  moment(data.item.calendarStartDate).format("MMM Do") : 'Not Set'
                }}
              </template>
              <template v-slot:cell(calendarEndDate)="data">{{
                  (data.item.calendarEndDate) ?
                  moment(data.item.calendarEndDate).format("MMM Do") : 'Not Set'
                }}
              </template>
              <template v-slot:cell(createdAt)="data">{{ moment(data.item.createdAt).tz($root.TZ).fromNow() }}
              </template>
            </b-table>
          </div>
        </div>
      </card>
    </div>

    <div class="row noprint" v-if="calendar && !isPrintTeacherSheets">
      <card style="border-radius:1em;" class="col-md-4 ml-auto mr-auto shadow">
        <button class="float-left mt-0 mr-2 btn btn-sm btn-success btn-fill" style="border-radius:.75em;" @click="$router.push('/app')">
          <span v-show="isLoading" :class="isLoadingStyle"></span>Done
        </button>
        <div>
          <h3 class="mt-1" style="text-align:center">Calendar</h3>

          <sweet-calendar
              :eventCategories="eventCategories"
              :events="events"
              :selectedDate="selectedDay"
              :initialDate="calendar.calendarStartDate"
              :beginDate="calendar.calendarStartDate"
              :endDate="calendar.calendarEndDate"
              cursor="pointer"
              :enableControls="enableCalendarControls"
              eventCursor="crosshair"
              @click="selectDay($event)"
              ref="calendar"
          />

        </div>
      </card>

      <card v-if="!isPrintTeacherSheets" class="col-md-7 ml-auto mr-auto shadow" style="border-radius:1em;">
        <template slot="header">
          <h3 class="card-title">Orders Listing</h3>
          <hr>
          <sf-input style="float:right;" type="list" :options="[1,2,5,10,25] " :label-cols="0" cstyle="width:4em;" label="Print Batch" v-model="printBatchSize" />
          <div v-if="transactionConfig !== null" style="margin:0 3em 0 0;">
            <div v-if="orderPrinterIp.length !== 0">
              Your Ticket Printer: <strong><a target='_blank' :href="'https://epson:epson@'+orderPrinterIp   || 'undefined'">{{ orderPrinterIp }}</a></strong>
              <!--              Your Ticket Printer: <span @click="verifyPrinter(orderPrinterIp)">{{orderPrinterIp}}</span>-->
              <p style="font-size:.8em;margin-top:1em;color:red;">NOTE: If your orders are not printing on the roll-printer, please click the printer IP above and return here to
                try your print again.</p>
            </div>
            <div v-if="orderPrinterIp.length === 0">
              <br>
              <i style="color:red;font-weight:900;">
                (No EPSON Ticket Roll-Printer Found)
              </i>
            </div>
            <br><br>
          </div>
          <div v-if="orders.length === 0 ">
            <p>Choose a calendar day</p>
            <p>NOTE: <br>After selecting a day, the orders will be listed below.</p>
          </div>
        </template>
        <div style="">
          <b-button :disabled="isPrinting" v-if="orders && orders.length>0 && orderPrinterIp.length > 0 " style="background:#ff813a;border-radius:.75em;margin-bottom:2px;" class="btn btn-sm btn-fill" @click="printOrders()">
            <div v-if="isPrinting"><span :class="isPrintingStyle"> </span>Printing Order # {{ currentOrder }}</div>
            <div v-else>Print: ({{ orders.length }}) Orders For {{ productionDateTitle }} <span style="font-size:.8em;font-weight:400;margin-left:2em;">(Roll Printer)</span></div>
          </b-button>
          <br>
          <b-button :disabled="isPrinting" v-if="orders && orders.length>0 && orderPrinterIp.length > 0 " style="margin-top:.5em;background:#ff813a;border-radius:.75em;" class="btn btn-fill btn-sm" @click="isPrinting=true;askPrintTeacherOrders()">
            <div v-if="isPrinting"><span :class="isPrintingStyle"> </span>Printing Teacher {{ currentTeacher }}</div>
            <div v-else>Print Teacher Summary Orders <span style="font-size:.8em;font-weight:400;margin-left:2em;">(Roll Printer)</span></div>
          </b-button>

          <div v-if="orders && orders.length>0" style="width:100%;">
            <hr>
            <table style="width:100%;display:flex;text-align:center;">
              <tr style="width:100%;">
                <td style="">
                  <b-button :disabled="isPrinting" style="margin-top:.5em;background:#033E8C;border-radius:.75em;" class="btn btn-fill btn-sm" @click="askPrintOrderSheets()">
                    Print Teacher Order Sheets <span style="font-size:.8em;font-weight:400;margin-left:2em;">(8.5 x 11)</span>
                  </b-button>
                </td>
                <td style="">
                  <!--                  <b-form style="margin-left:2em;">-->
                  <!--                    <sf-input type="list" :options="['never','6am','7am','8am','9am','10am']" :label-cols="4" cstyle="width:10em;" label="Auto Send"></sf-input>-->
                  <!--                  </b-form>-->
                </td>
              </tr>
            </table>
            <hr>
            <b-checkbox class="noprint" style="margin-top:.5em;" v-model="isActiveOrdersOnly">Active Orders Only</b-checkbox>
          </div>

        </div>
        <div v-if="isPrinting">
          <b-button class="btn btn-danger btn-fill btn-sm mt-1" style="border-radius:.75em;" @click="cancelPrint"> Cancel Print</b-button>
        </div>

        <b-checkbox v-if="orders && orders.length>0" style="position:absolute;bottom:10px;right:30px;" v-model="isShowItems">Show Items</b-checkbox>

      </card>
    </div>
    <div class="row" v-if="calendar && !isPrintTeacherSheets">

      <div class="col-sm-12 col-md-11 ml-auto mr-auto shadow printable " style="border-radius:1em;">
        <span class="noprint show-hand" style="float:right;" v-b-popover.hover.top="'Print this on the big printer'" @click="printList()">
          <i class="fa fa-print" style=""></i>
          Print List
        </span>
        <span class="noprint show-hand" style="float:right;margin-right:10px;" v-b-popover.hover.top="'Send this to me'" @click="emailOrderSummary()">
          <i class="fa fa-envelope" style=""></i>
          Email
        </span>

        <h3 class="card-title" style="">Orders For: {{ productionDateTitle }}
          <!--          <b-button v-if="items" class="btn btn-sm btn-fill" style="border-radius:.75em;float:right;" @click="sendOrders()">Send it to me</b-button>-->
        </h3>
        <hr>

        <template v-if="orders">
          <div ref="ordersummary" style="overflow:auto;">
            <h4>Orders Display {{ orders.length }}</h4>

            <b-table class="table-data" striped hover stacked="sm" head-variant="dark" small :busy="isPrinting" :items="orders" :fields="orderFields" @row-clicked="showOrder($event)">
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Printing...</strong>
                </div>
              </template>

              <template v-slot:cell(action)="row" v-if="orderPrinterIp.length !== 0">
                <i class="fa fa-print noprint show-hand" @click.stop="askPrintOrder(row,row.item.ticketNumber)"></i>
              </template>

              <template v-slot:cell(items)="row">
                <div v-if="isShowItems" style="width:3.125in;border-style:solid;border-width:1px;text-align:center;">
                  <table style="width:100%;font-size:10pt;">
                    <tr v-for="(item,index) in row.item.items" :key="index">
                      <td style="width:15%;">{{ item.qty }}</td>
                      <td style="width:180px;text-align:left;">{{ item.title }}</td>
                      <td style="width:20%;text-align:right;">{{ currency(item.qty * item.price) }}</td>
                    </tr>
                  </table>
                </div>
                <div v-else>
                  {{ row.item.items.length }}
                </div>
              </template>

              <template v-slot:cell(createdAt)="row">
                <span class="noprint">{{ moment(row.item.createdAt).fromNow() }}</span>
              </template>

              <template v-slot:cell(total)="row">
                {{ currency(row.item.total) }}
              </template>

              <!--          <div id="student-orders" v-for="(order,index) in orders" :key="index">-->
              <!--            <div style="width:3.125in;border-style:solid;border-width:1px;text-align:center;">-->
              <!--              <span style="margin-top:5px;margin-bottom:0;">Ticket Number: {{ index+1 }}</span>-->
              <!--              <h3 style="margin-top:0;">{{order.teacher}}</h3>-->
              <!--              <h4 style="margin-top:0;">{{order.alias}}</h4>-->
              <!--              <small>Grade {{order.grade}}</small>-->
              <!--              <div style="margin-top:0;">{{moment(order.orderDate).format( "dddd, DD-MMM-YY")}}</div>-->
              <!--              <hr>-->
              <!--              <table>-->
              <!--                <tr v-for="item in order.items">-->
              <!--                  <td style="width:15%;">{{item.qty}}</td>-->
              <!--                  <td style="width:180px;text-align:left;">{{item.title}}</td>-->
              <!--                  <td style="width:20%;text-align:right;">{{currency( item.qty * item.price)}}</td>-->
              <!--                </tr>-->
              <!--              </table>-->
              <!--              <hr>-->
              <!--              <span style="font-family:Code39AzaleaFont;font-size:48pt;">*{{order.badgeNumber}}*</span>-->
              <!--              <br>{{order.badgeNumber}}<br>-->
              <!--              <h3>{{itemCount(order.items)}} Items</h3>-->
              <!--              <br>-->
              <!--              <small style="line-height:.8em;">Daily Order Ticket<br>{{$root.tenant.tenantName}}<br>By https://www.mySchool.cafe</small>-->
              <!--            </div>-->

              <!--            <hr>-->
              <!--          </div>-->
            </b-table>
          </div>
        </template>

      </div>
    </div>


    <b-modal
        ref="orderDialog"
        :title="orderDialogTitle"
        size="lg"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
    >
      <b-container fluid v-if="orderDialogData">
        <!--          <b-checkbox type="checkbox" style="position:absolute;right:50px;" v-model="task.isComplete"> Complete</b-checkbox>-->
        <sf-input readonly v-model="orderDialogData.orderNumber" cstyle="width:15em;" type="text" label="Order Number" />
        <sf-input readonly v-model="orderDialogData.accountNumber" cstyle="width:15em;" type="text" label="Account Number" />
        <sf-input readonly v-model="orderDialogData.badgeNumber" cstyle="width:15em;" type="text" label="Badge Number" />
        <div style="border:1px solid lightgray;border-radius:1em;margin-top:.5em;padding:.5em 0;">
          <sf-input readonly v-model="orderDialogData.grade" cstyle="width:15em;" type="text" label="Grade" />
          <sf-input readonly v-model="orderDialogData.teacher" cstyle="width:15em;" type="text" label="Teacher" />
          <sf-input readonly v-model="orderDialogData.homeRoom" cstyle="width:15em;" type="text" label="Home Room" />
        </div>
        <p style="font-size:8px;color:lightgray;float:right;margin-right:2em;">**Fields in the above box are used for schedule matching</p>
        <!--        <sf-input readonly v-model="orderDialogData.updatedAt" cstyle="width:15em;" type="text" label="Order Placed" />-->
        <hr>
        <h4 style="margin-bottom:0;">Order Details</h4>
        <h5 style="margin-top:0;font-style:italic;color:lightgray;">Order Date: {{ moment(orderDialogData.orderDate).tz($root.user.TZ).format("LLLL") }}</h5>
        <h5 style="margin-top:-1em;font-style:italic;color:gray;">Placed: {{ moment(orderDialogData.updatedAt).tz($root.user.TZ).format("LLLL") }}</h5>
        <div style="display:flex;margin-left:auto;margin-right:auto;">

          <table style="width:350px;font-size:10pt;">
            <tr v-for="(item,index) in orderDialogData.items" :key="index" style="border-bottom:1px solid lightgray">
              <td style="width:15%;vertical-align:top;">{{ item.qty }}</td>
              <td style="width:180px;text-align:left;"><span style="font-weight:600;">{{ item.title }}</span>
                <div v-if="item.description" style="padding-left:.5em;white-space:pre-line;line-height:16px;">{{ item.description }}</div>
              </td>
              <td style="width:20%;text-align:right;vertical-align:top;">{{ currency(item.qty * item.price) }}</td>
            </tr>
          </table>
        </div>
      </b-container>
      <div slot="modal-footer" class="mt-4">
        <!--        <b-btn variant="success" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="updateAuthorization(registration)">Save/Done</b-btn>-->
        <b-btn variant="success" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="hideDialog()">Done</b-btn>
        <!--        <b-btn variant="warning" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="saveOrder(orderDialogData)">Save/Done</b-btn>-->
        <!--        <b-btn variant="error" size="sm" class="btn btn-fill float-right mr-1" style="border-radius:.75em;" @click="removeAuthorization(registration)">Delete</b-btn>-->
      </div>
    </b-modal>

    <div v-if="isPrintTeacherSheets && dailyRoster">
      <div class="noprint" style="display:flex;float:right;">
        <form>
          <b-checkbox class="noprint" v-model="isShowDescription" @change="saveOptions()">Show Description</b-checkbox>
          <b-checkbox class="noprint" v-model="isShowOrderSheet" @change="saveOptions()">Show Order Sheet</b-checkbox>
          <b-checkbox class="noprint" v-model="isShowAllTeachers" @change="saveOptions()">Show All Teachers</b-checkbox>
        </form>
      </div>
      <div class="noprint" style="display:flex;justify-content:left;align-items:center;width:100%;">
        <b-button @click="isPrintTeacherSheets=false" class="noprint mr-3 btn btn-sm btn-success btn-fill" style="float:left;">Done</b-button>
        <i class="fa fa-print show-hand noprint" style="position:relative;font-size:1.5em;top:5px;margin-left:.5em;margin-right:.5em;" @click="printList"></i>
        <i class="fa fa-envelope show-hand noprint" style="position:relative;font-size:1.5em;top:5px;margin-left:.5em;margin-right:1em;" @click="emailTeacherSummary"></i>
        <h4 class="noprint" style="float:left;">Teacher Order Sheets</h4>
      </div>
      <!--      <vue-html2pdf ref="teacherorder"-->
      <!--                    :show-layout="false"-->
      <!--                    :enable-download="true"-->
      <!--                    :preview-modal="true"-->
      <!--                    :paginate-elements-by-height="1400"-->
      <!--                    filename="TeacherSummary"-->
      <!--                    :pdf-quality="4"-->
      <!--                    :manual-pagination="false"-->
      <!--                    pdf-format="letter"-->
      <!--                    pdf-orientation="portrait"-->
      <!--                    pdf-content-width="900px"-->
      <!--                    >-->
      <!--        <section slot="pdf-content">-->

      <div id="teacher-order-list" class="teacher-order" v-for="(t,rosterIndex) in dailyRoster" :key="rosterIndex">
        <teacher-summary v-if="isShowTeacher(t)"
                         :id="t.id"
                         :data="t"
                         :rotationLocation="rotationLocation"
                         :menuItems="menuItems"
                         :isShowDescription="isShowDescription"
                         :isShowOrderSheet="isShowOrderSheet"
                         :productionDate="productionDateTitle"
        >
        </teacher-summary>
      </div>

      <!--      <b-row  :id="t.id"  class="printable teacher-order" style="margin-left:.5em;width:100%;" v-for="(t,index) in dailyRoster" :key="index" >-->
      <!--        <b-col class="col-12 w-100 " >-->
      <!--          <hr class="noprint">-->
      <!--          <h2 style="margin:0;">Teacher Summary <span style="margin-left:1.5em;font-weight:600;">{{t.teacher}}</span><span style="font-weight:400;margin-left:1em;">({{t.homeRoom}})</span></h2>-->
      <!--          <span style="margin:0;">{{productionDateTitle}}</span><span style="margin-left:2em;">Orders: </span><span style="font-weight:700;font-size:1.1em;">{{t.orders.length}} </span>-->
      <!--          <div style="float:right;">-->
      <!--            <span style="font-weight:400;font-style:italic;padding-right:2em;">({{t.classroomDescription}})</span>-->
      <!--            <span style="margin-right:2em;font-size:1.25em;font-weight:600;">{{ rotationLocation( t.homeRoom )}}</span>-->
      <!--          </div>-->
      <!--          <b-row class="w-100" style="font-size:9.5pt;margin-top:1em;">-->
      <!--            <b-col class="col-12" >-->
      <!--              <table class="student-table" v-if="t.students.length>0" >-->
      <!--                <col><col><col><col><col><col><col>-->
      <!--                <thead>-->
      <!--                <tr>-->
      <!--                  <td>Name</td>-->
      <!--                  <template v-for="(mi,miIndex) in menuItems" >-->
      <!--                    <td :key="miIndex">{{mi.title}}</td>-->
      <!--                  </template>-->
      <!--                </tr>-->
      <!--                </thead>-->
      <!--                <tr  v-for="(s,studentIndex) in t.students" :key="studentIndex" >-->
      <!--                  <td style="text-align:right;padding-right:1em;"><span >{{s.lastName}}, {{s.firstName}}</span><span style="padding-left:1em;font-weight:600;">{{s.idNumber}}</span></td>-->
      <!--                  <template v-for="(mi,miIndex) in menuItems" >-->
      <!--                    <td style="width:75px;" :key="miIndex"></td>-->
      <!--                  </template>-->
      <!--                </tr>-->
      <!--              </table>-->
      <!--            </b-col>-->
      <!--          </b-row>-->

      <!--          <b-row style="width:100%;margin-top:2em;">-->
      <!--            <b-col class="col-3 w-100 class-order" style="font-size:9.5pt;padding:.25em;" v-for="(o,orderIndex) in t.orders"  :key="orderIndex">-->
      <!--              <div class="" style="margin-left:1em;text-align:center;width:95%;border:1px solid gray;border-radius:.5em;background:#eee;">-->
      <!--                <div style="font-size:1.1em;font-weight:400;margin:0;"><span>Ticket #</span><span style="font-weight:700;">{{o.ticketNumber}}</span></div>-->
      <!--                <div style="font-weight:700;font-size:1.1em;margin:0;">{{o.alias}}<span style="margin-left:2em;font-size:.9em;font-weight:400;">({{ o.badgeNumber }})</span></div>-->
      <!--              </div>-->
      <!--              <div class="" style="width:95%;margin-left:1em;padding:.5em;border:1px solid gray;border-radius:.5em;">-->
      <!--              <table style="width:100%;font-size:9.5pt;">-->
      <!--                <tr v-for="(item,index) in o.items" :key="index">-->
      <!--                  <td style="width:15%;vertical-align:top;font-weight:600;">{{item.qty}}</td>-->
      <!--                  <td style="width:180px;text-align:left;">-->
      <!--                    <div>-->
      <!--                      <span style="font-weight:600;">{{item.title}}</span>-->
      <!--                      <div v-if="item.description && isShowDescription" style="padding-left:1em;"><span  style="display:block;white-space:pre-line;font-size:10px;line-height:1em;">{{item.description}}</span></div>-->
      <!--                    </div>-->
      <!--                  </td>-->
      <!--                  <td style="width:20%;text-align:right;vertical-align:top;">{{currency( item.qty * item.price)}}</td>-->
      <!--                </tr>-->
      <!--                <tr>-->
      <!--                  <td colspan=2 style="text-align:right;">Total</td>-->
      <!--                  <td style="width:20%;text-align:right;">{{currency( o.total )}}</td>-->
      <!--                </tr>-->
      <!--              </table>-->
      <!--              </div>-->
      <!--            </b-col>-->
      <!--          </b-row>-->

      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--        </section>-->
      <!--      </vue-html2pdf>-->
    </div>
  </div>
</template>
<script>
import GeneralService            from "/src/Services/GeneralService";
import MenuItemService           from "/src/Services/MenuItemService";
import OrderService              from "/src/Services/OrderService";
import PortalRegistrationService from "/src/Services/PortalRegistrationService";
import RotationService           from "/src/Services/RotationService";
import TenantService             from "/src/Services/TenantService";
import UserService               from "/src/Services/UserService";
import Currency                  from "currency.js";
// import jsPDF from "jspdf";
import axios                     from "axios";
import Moment                    from "moment";
import Card                      from "/src/components/UIComponents/Cards/Card.vue";
import SfInput                   from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable                    from "/src/components/UIComponents/Table.vue";
// import VueHtml2pdf               from "vue-html2pdf";
import { Calendar }              from "vue-sweet-calendar";
import 'vue-sweet-calendar/dist/SweetCalendar.css';
import TeacherSummary            from "./TeacherSummaryComponent.vue";

// import Epson        from "/src/Services/epos-2.12.0.js";

export default {
  components: {
    Card,
    SfInput,
    LTable,
    SweetCalendar: Calendar,
    // VueHtml2pdf,
    TeacherSummary
  },
  data() {
    return {
      activeTeachers      : null,
      builder             : null,
      calendar            : null,
      classrooms          : null,
      currentCategory     : null,
      currentItem         : null,
      currentOrder        : 0,
      currentTeacher      : "",
      dailyRoster         : null,
      day                 : null,
      deliveryLocations   : null,
      epos                : null,
      importTags          : null,
      isLoading           : false,
      isLoadingStyle      : "fa fa-spinner  fa-refresh-animate mr-3",
      isPrintTeacherSheets: false,
      isPrinting          : false,
      isPrintingStyle     : "fa fa-spinner  fa-refresh-animate mr-3",
      isShowDescription   : true,
      isShowOrderSheet    : false,
      isShowAllTeachers   : true,
      isActiveOrdersOnly  : false,
      isShowItems         : false,
      items               : null,
      menuItemCategories  : [],
      menuItems           : null,
      noSummary           : "",
      noprint             : "",
      orderCalendars      : [],
      orderPrinterIp      : "",                                         //gets overwritten with the getTransactionConfig() call.
      orders              : [],
      print               : "",
      printAll            : false,
      printError          : false,
      printerAddress      : "",
      productionDate      : null,
      printBatchSize      : 10,
      selectedDay         : new Date(),
      productionDateTitle : "(Select Day)",
      rotation            : null,
      students            : null,
      transactionConfig   : null,
      orderDialogTitle    : "Order Dialog",
      orderDialogData     : null,
      headerBgVariant     : 'dark',
      headerTextVariant   : 'light',
      bodyBgVariant       : 'light',
      bodyTextVariant     : 'dark',
      footerBgVariant     : 'warning',
      footerTextVariant   : 'dark',
      orderFields         : [
        {key: 'action', label: "Action"},
        {key: 'ticketNumber', label: "Ticket #", sortable: true},
        {key: 'badgeNumber', label: "Badge #", sortable: true},
        {key: 'alias', label: "Student", sortable: true},
        {key: 'grade', label: "Grade", sortable: true},
        // {key: 'priceLevel', label: "Price Level", sortable: true},
        {key: 'homeRoom', label: "Home Room", sortable: true},
        {key: 'teacher', label: "Teacher", sortable: true},
        {key: 'createdAt', label: "Placed", sortable: true},
        {key: 'total', label: "$ Total", sortable: true},
        {key: 'items', label: "# Items", sortable: true}
      ],
      fields              : [
        // {key: "action", label: "Action", sortable: false},
        {key: "active", label: "Active", sortable: false},
        {key: "title", label: "Item Name", sortable: true},
        {key: "activeDate", label: "Show Begin", sortable: true},
        {key: "calendarStartDate", label: "Start Date", sortable: true},
        {key: "calendarEndDate", label: "End Date", sortable: true},
        {key: "createdAt", label: "Last Update", sortable: true},
        {key: "lastUpdateBy", label: "By", sortable: true}
      ],
      eventCategories     : [
        {
          id             : 1,
          title          : "No Items",
          textColor      : "white",
          backgroundColor: "#39a6c2"
        },
        {
          id             : 2,
          title          : "One Item",
          textColor      : "white",
          backgroundColor: "#a0e14f"
        },
        {
          id             : 3,
          title          : 'Two Items',
          textColor      : 'white',
          backgroundColor: '#7fb847'
        },
        {
          id             : 4,
          title          : 'More Than 2',
          textColor      : 'white',
          backgroundColor: '#ff813a'
        },

        {
          id             : 5,
          title          : 'Unassigned',
          textColor      : 'white',
          backgroundColor: '#555555'
        }
      ],
      events              : [
        // {
        //   title     : 'Week 1',
        //   start     : '2019-08-05 00:00 cst',
        //   end       : '2019-08-09 00:00 cst',
        //   repeat    : 'never',
        //   categoryId: 4
        // }
      ]

    }
  },
  async created() {
    // this.getPendingAccounts();
    let t = this;
    await Promise.all([
                        this.getOrderCalendars(""),
                        this.getTransactionConfig(""),
                        this.getDeliveryLocations()
                      ]);

    try {
      t.printerAddress = 'https://' + t.orderPrinterIp + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=60000';
      t.epos           = new epson.ePOSPrint(t.printerAddress);

    } catch(error) {
      console.log(error.message);
      await t.$root.alert(error.message);
    }

    t.epos.onreceive = async function(res) {

      if(!res.success) {
        t.printError = true;
        t.isPrinting = false;
        await t.$root.alert("Printer Error " + res.code + "\nDevice Name:" + t.orderPrinterIp + "\nTarget Address:" + t.printerAddress);
      } else {
        if(t.isPrinting) {
          if(!t.printAll || t.currentOrder >= t.orders.length) {
            t.isPrinting = false;
            await t.$root.alert("Printing Complete");
          } else {
            t.printOrder(t.currentOrder, t.orders[ t.currentOrder ]);
            t.currentOrder++;
          }
        }
      }
    }

    this.loadOptions();

  },
  computed: {},
  methods : {
    moment(d) {
      return Moment(d);
    },
    currency(c) {
      return Currency(c);
    },
    saveOptions() {
      localStorage.setItem('portal-showDescription', JSON.stringify(this.isShowDescription));
      localStorage.setItem('portal-showOrderSheet', JSON.stringify(this.isShowOrderSheet));
      localStorage.setItem('portal-showAllTeachers', JSON.stringify(this.isShowAllTeachers));
    },
    loadOptions() {
      this.isShowDescription = JSON.parse(localStorage.getItem('portal-showDescription'));
      this.isShowOrderSheet  = JSON.parse(localStorage.getItem('portal-showOrderSheet'));
      this.isShowAllTeachers = JSON.parse(localStorage.getItem('portal-showAllTeachers'));
    },
    isShowTeacher(t) {
      if(this.isShowAllTeachers) {
        return true;
      }
      if(t.orders) {
        return t.orders.length > 0;
      }
      return false;
    },
    async verifyPrinter(printerIp) {
      let url = "https://epson:epson@" + printerIp;
      // let url = "http://google.com";
      await this.$root.confirm("verify printer " + printerIp);

      try {
        let printer = axios.create({
                                     responseType: 'json',
                                     headers     : {
                                       'Content-Type': 'application/json',
                                       'Accept'      : 'application/json',
                                     }
                                   });
        let result  = await printer.get(url);
        // console.log( result );
      } catch(error) {
        await this.$root.confirm(error.message, 'warning');
      }

    },
    async printList() {

      window.print();

      // let html = document.getElementById("teacher-order-list").outerHTML;
      // console.log( JSON.stringify( html , null ,2 ));
      //
      //
      // let pdf = new jsPDF();
      //
      // pdf.html( html , { callback: function( doc ) {
      //     pdf.save( "wolfieTest" );
      // }});
      //

    },
    showDialog() {
      this.$refs.orderDialog.show();
    },
    hideDialog() {
      this.$refs.orderDialog.hide();
    },
    showOrder(order) {
      // console.log( "showOrder() " , order );
      this.orderDialogTitle = "Order For: " + order.alias;
      this.orderDialogData  = order;
      this.showDialog();
    },
    async emailTeacherSummary() {

      if(!await this.$root.confirm("Send this list to the teachers?")) {
        return;
      }

      if(!this.dailyRoster || this.dailyRoster.length === 0) {
        await this.$root.confirm("No orders or activity to send", "warning");
        return;
      }

      let envelopes   = [];
      let noEmail     = [];
      let subject     = "Meal Summary: " + Moment(this.dailyRoster[ 0 ].orderDate).format("Do [of] MMMM");
      let noEmailFlag = false;

      let emailHeader = "<html><body>";

      // console.log( {subject});
      this.dailyRoster.forEach((r) => {
        if(r.email && document.getElementById(r.id)) {
          let html     = document.getElementById(r.id).outerHTML;
          let envelope = {
            to     : `${r.teacher}<${r.email}>`,
            from   : `${this.$root.user.tenantName} Teacher Summary<noreply@mySchool.cafe>`,
            subject: subject,
            html   : emailHeader + html + "</body></html>"
          }
          envelopes.push(envelope);
        } else {
          if(!r.email) {
            noEmail.push(`\n(${r.homeRoom}) ${r.teacher} - No Email Address`);
            noEmailFlag = true;
          } else {
            noEmail.push(`\n(${r.homeRoom}) ${r.teacher} - Skipped`);
          }

        }
      });

      // console.log( "Envelops to be sent:" ) ;
      // console.log( {envelopes} );
      if(envelopes.length > 0) {
        let response = await GeneralService.sendEnvelope(envelopes);
        // console.log( "envelope sent" ,  response.data );
        if(noEmail.length > 0) {
          await this.$root.alert("Ok, done!\n" + noEmail.join(","), (noEmailFlag) ? "warning" : "success");
        } else {
          await this.$root.alert("Ok, I emailed the sheets to all the teachers");
        }
      } else {
        await this.$root.alert("No available sheets to send", "warning");
      }

    },
    async askPrintOrderSheets() {

      let t                  = this;
      t.isPrintTeacherSheets = true;
      t.isLoading            = true;

      await this.getPortalConfig();
      await Promise.all([
                          this.getStudents(),
                          this.getMenuItems("myschool-extra")
                        ]);

      this.dailyRoster = [];
      // console.log( "classrooms" , this.classrooms );
      // console.log( "orders" , this.orders );

      let idCounter = 0;
      this.classrooms.forEach((classroom) => {

        if(classroom) {
          let teacher              = classroom.alert.title || "-";
          let email                = classroom.alert.email || "";
          let homeRoom             = classroom.siteId;
          let classroomDescription = classroom.description;

          let orders = this.orders.filter((o) => { return o.homeRoom === homeRoom});
          orders     = _.orderBy(orders, [ 'alias' ], [ 'asc' ]);

          if(!this.isActiveOrdersOnly || orders.length > 0) {
            let students = this.students.filter((s) => { return s.homeRoom === homeRoom });

            if(students.length > 0 || orders.length > 0) {
              let o = {id: "order-" + idCounter.toString(), teacher: teacher, email: email, homeRoom: homeRoom, classroomDescription: classroomDescription, orders: orders, students: students};
              idCounter++;
              this.dailyRoster.push(o);
            }
          }
        }

      })

      // var u = {}, a = [];
      // for(var i = 0, l = this.orders.length; i < l; ++i){
      //   if(!u.hasOwnProperty(this.orders[i].teacher)) {
      //     a.push(this.orders[i].teacher);
      //     u[this.orders[i].teacher] = 1;
      //   }
      // }
      //
      // a.forEach( (t) => {
      //   let orders = this.orders.filter( (o) => { return o.teacher === t} );
      //   orders = _.orderBy( orders , ['alias'], ['asc'])
      //   let homeRoom = orders[0].homeRoom || "";
      //   let description = this.getClassDescription( homeRoom );
      //   let students = this.students.filter( (s) => { return  s.teacher === t } );
      //   this.dailyRoster.push( {teacher: t, homeRoom: homeRoom,  classroomDescription: description, orders: orders, students: students} );
      // });

      t.isLoading = false;
      // console.log( "dailyRoster" , this.dailyRoster );
    },
    async emailOrderSummary() {
      if(!await this.$root.confirm("Do you want this Order Summary emailed to you?")) {
        return;
      }

      let subject = "Order Summary For: " + this.productionDateTitle;
      let result  = `<style type="text/css"> </style>` + this.$refs.ordersummary.innerHTML;

      result += `<br><br><br><br><hr><small>Sent by request from iCare Cafe<br>Newcare International (an IOIHAN Company)<br>Visit us online at www.newcare.international</small>`

      await GeneralService.sendMail(null, subject, result);
      await this.$root.alert("Ok, I sent it to you")
    },
    async cancelPrint() {
      if(await this.$root.confirm("Cancel Printing?")) {
        this.isPrinting = false;
        //alert("Print Canceled");
      }
    },
    async askPrintOrder(row, ticketNumber) {
      //console.log("askPrintOrder() ticket, row", ticketNumber, JSON.stringify(row, null, 2))
      if(await this.$root.confirm("Print Ticket: " + (ticketNumber).toString() + " for " + row.item.alias + "?")) {
        if(await this.$root.confirm("Continue And Print Remaining Tickets?")) {
          this.printAll = true;
        } else {
          this.printAll = false;
        }
        this.printError   = false;
        this.isPrinting   = true;
        this.currentOrder = ticketNumber - 1;
        this.printOrder(this.currentOrder, this.orders[ this.currentOrder ]);
        this.currentOrder++;
      }
    },
    getClassDescription(homeRoom) {
      let description = "****";
      if(homeRoom) {
        let classroom = _.find(this.classrooms, {siteId: homeRoom});
        if(classroom) {
          description = classroom.description || "****";
        }
      }
      return description;
    },
    async printTeacherOrders() {

      let t = this;

      if(this.orders.length <= 0) {
        t.isPrinting = false;
        await t.$root.alert("No Active Orders");
        return;
      }

      t.activeTeachers = new Set();
      let noSummary    = this.noSummary.split(",");

      t.orders.forEach(function(order) {
        if(!noSummary.includes(order.grade)) {
          t.activeTeachers.add(order.teacher);
        }
      })

      t.activeTeachers.forEach(async function(teacher) {

        let orders = t.orders.filter(function(order) {
          return order.teacher === teacher;
        })

        if(orders.length > 0) {
          let builder = t.startTicket();

          builder.addTextSize(2, 2);
          builder.addText("Teacher Summary\n");

          builder.addTextSize(1, 1);
          builder.addText(Moment(orders[ 0 ].orderDate).format("dddd, DD-MMM") + "\n");
          builder.addText("-----------------------------------\n\n");

          t.ticketAddTeacher(builder, teacher);

          if(orders[ 0 ].grade.length > 0) {
            builder.addTextSize(1, 1);
            builder.addText("Grade: " + orders[ 0 ].grade + "\n");
          }

          if(orders[ 0 ].homeRoom) {
            builder.addText("Home Room: " + orders[ 0 ].homeRoom + "\n");
            builder.addTextSize(1, 2);
            builder.addText(t.getClassDescription(orders[ 0 ].homeRoom) + "\n");
            builder.addText(t.rotationLocation(orders[ 0 ].homeRoom) + "\n\n");
          }

          builder.addTextSize(1, 1);
          builder.addText("-----------------------------------\n\n\n");

          orders.forEach(function(order) {
            builder.addTextSize(2, 2);
            builder.addText("Ticket: " + (order.ticketNumber).toString() + "\n");
            builder.addTextSize(1, 1);
            builder.addText(order.alias + "\n");
            builder.addText("\n");
            t.ticketAddOrderItems(builder, order);
            t.ticketAddOrderBarcode(builder, order);
            builder.addText("============================\n\n\n\n");
          })

          let totalItems = _.sumBy(orders, (o) => { return _.sumBy(o.items, "qty") });

          builder.addTextSize(2, 1);
          builder.addText(orders.length.toString() + ` Ticket${(orders.length === 1) ? '' : 's'}\n\n`);
          builder.addText(totalItems.toString() + ` Item${(totalItems === 1) ? '' : 's'} Total\n\n\n`);
          builder.addTextSize(1, 1);

          // builder.addText("Teacher Summary \n");
          builder.addText(t.$root.tenant.tenantName + "\n")
          builder.addText("By mySchool.cafe\n");
          builder.addText("\n");
          builder.addText("\n");

          builder.addCut(builder.CUT_FEED);
          //Acquire the print document
          var request = builder.toString();

          try {
            //Send the print document
            await t.epos.send(request);
          } catch(error) {
            console.log(error.message);
            await t.$root.alert(error.message);
          }
        }

      });

      this.isPrinting = false;
    },
    async askPrintTeacherOrders() {
      let t = this;

      if(this.orders.length <= 0) {
        this.isPrinting = false;
        return;
      }

      if(!await this.$root.confirm("Print Teacher Order Summary Tickets?")) {
        this.isPrinting = false;
        return;
      }

      this.printTeacherOrders();
    },
    itemCount(items) {
      let c = 0;
      for(var item of items){
        c += item.qty;
      }
      return c;
    },
    makeLineItem(qty, title, price) {
      let line = ("   " + qty.toString()).slice(-4) + " " + (title + "                             ").slice(0, 25) + ("     " + Currency(price)).slice(-10);
      return line;
    },
    async getPortalConfig() {
      try {
        let result        = await TenantService.getPortalConfig();
        this.portalConfig = result.data;
        this.importTags   = this.portalConfig.options.importTags;
        // console.log( "portalConfig" , this.portalConfig , importTags );
      } catch(error) {
        console.log(error.message);
      }
    },
    async getStudents() {
      try {
        let response  = await PortalRegistrationService.getPortalRegisteredAuthorizationsShortList("student", this.importTags);
        this.students = response.data;
        this.students = _.orderBy(this.students, [ "lastName", "firstName" ], [ "asc" ]);
        // console.log( "students" , this.students );
      } catch(error) {
        console.log(error.message);
      }
    },
    async getMenuItems(category) {
      var response;
      var t = this;

      if(this.currentCategory === category) {
        return;
      }

      this.currentCategory = category;

      try {
        response         = await MenuItemService.getMenuItems(category);
        this.menuItems   = response.data;
        this.currentItem = null; // this.menuItems[ 0 ];
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
    },
    async printOrders() {
      if(this.orders.length <= 0) {
        return;
      }
      if(!await this.$root.confirm("Ready to print all orders on [" + this.orderPrinterIp + "]?")) {
        return;
      }

      this.printError   = false;
      this.isPrinting   = true;
      this.printAll     = true;
      this.currentOrder = 0;
      this.printOrder(0, this.orders[ 0 ])
      this.currentOrder++;
    },
    rotationLocation(room) {
      let r = _.find(this.rotation.rotations, {classroom: room});
      if(r) {
        let d = _.find(this.deliveryLocations, {siteId: r.deliveryLocation});
        if(d) {
          return d.title;
        }
      }

      return "****";
    },
    startTicket() {
      //Create an ePOS-Print Builder object
      var builder = new epson.ePOSBuilder();

      //Create a print document
      builder.addTextLang('en')
      builder.addTextSmooth(true);
      builder.addTextFont(builder.FONT_A);
      builder.addTextAlign(builder.ALIGN_CENTER);

      return builder;
    },
    ticketAddTeacher(builder, teacher) {
      if(teacher.length > 0) {
        builder.addTextSize(1, 2);
        builder.addText(teacher + "\n");
        builder.addHLine(1, 501, builder.LINE_THIN_DOUBLE);
      }
    },
    ticketAddOrderHeader(builder, order) {

      builder.addTextSize(2, 1);
      builder.addText(order.alias + "\n");
      builder.addText("\n");

      builder.addTextSize(1, 1);
      if(order.grade.length > 0) {
        builder.addText("Grade: " + order.grade + "\n");
      }
      if(order.homeRoom) {
        builder.addText("Home Room: " + order.homeRoom + "\n");
        builder.addText(this.getClassDescription(order.homeRoom) + "\n");
        builder.addTextSize(2, 1);
        builder.addText(this.rotationLocation(order.homeRoom) + "\n\n");
        builder.addTextSize(1, 1);
      }
      builder.addText(Moment(order.orderDate).format("dddd, DD-MMM-YY") + "\n");

      builder.addText("\n");
      builder.addText("\n");

    },
    ticketAddOrderItems(builder, order) {
      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addTextSize(1, 1);
      builder.addHLine(1, 501, builder.LINE_THIN);
      for(var item of order.items){
        builder.addText(this.makeLineItem(item.qty, item.title, Currency(item.price).multiply(item.qty)) + "\n");
      }
      builder.addHLine(1, 501, builder.LINE_THIN);
    },
    ticketAddOrderBarcode(builder, order) {
      builder.addTextAlign(builder.ALIGN_CENTER);
      builder.addText("\n");
      builder.addBarcode(order.badgeNumber, builder.BARCODE_CODE39, builder.HRI_NONE, builder.FONT_A, 2, 64);
      builder.addText(order.badgeNumber + "\n");
      builder.addText("\n");
    },
    ticketAddItemCount(builder, order) {
      builder.addTextSize(2, 4);
      builder.addText(this.itemCount(order.items).toString() + " Items\n");
      builder.addTextSize(1, 1);
      builder.addText("\n");
      builder.addText("\n");
    },
    buildOrder(order, builder) {

      let ticketNumber = order.ticketNumber;
      builder.addText("Ticket: " + (ticketNumber).toString() + "\n");
      builder.addText("\n");

      //add logo here...

      this.ticketAddTeacher(builder, order.teacher);

      this.ticketAddOrderHeader(builder, order);
      this.ticketAddOrderItems(builder, order);
      this.ticketAddOrderBarcode(builder, order);
      this.ticketAddItemCount(builder, order);

      builder.addText("Daily Order Ticket\n");
      builder.addText(this.$root.tenant.tenantName + "\n")
      builder.addText("By mySchool.cafe\n");
      builder.addText("\n");
      builder.addText("\n");
      builder.addText("\n");
      builder.addText("\n");

      builder.addCut(builder.CUT_FEED);

      return builder;
    },
    async printOrder(slotNumber, order) {

      let builder = this.startTicket();

      // t.currentOrder >= t.orders.length

      // let ticketNumber = order.ticketNumber;
      // builder.addText("Ticket: " + (ticketNumber).toString() + "\n");
      // builder.addText("\n");
      //
      // //add logo here...
      //
      // this.ticketAddTeacher(builder, order.teacher);
      //
      // this.ticketAddOrderHeader(builder, order);
      // this.ticketAddOrderItems(builder, order);
      // this.ticketAddOrderBarcode(builder, order);
      // this.ticketAddItemCount(builder, order);
      //
      // builder.addText("Daily Order Ticket\n");
      // builder.addText(this.$root.tenant.tenantName + "\n")
      // builder.addText("By mySchool.cafe\n");
      // builder.addText("\n");
      // builder.addText("\n");
      // builder.addText("\n");
      // builder.addText("\n");
      //
      // builder.addCut(builder.CUT_FEED);

      // let slot = slotNumber;
      let batchSize = this.printBatchSize;
      while(slotNumber < this.orders.length && batchSize > 0){
        builder = this.buildOrder(this.orders[ slotNumber ], builder);
        slotNumber++;
        batchSize--;
      }

      this.currentOrder = slotNumber - 1;   // the last ticket we "actually" printed

      //Acquire the print document
      var request = builder.toString();

      try {
        //Send the print document
        await this.epos.send(request);
        // this.epos.send(request);
      } catch(error) {
        console.log(error.message);
        await this.$root.alert(error.message);
      }
    },
    async sendOrders() {
      if(!await this.$root.confirm("Do you want this list of orders emailed to you?")) {
        return;
      }
      this.getOrders(this.day, true);
    },
    selectDay(day) {

      let d                    = Moment(day).format("YYYY-MM-DD");
      this.selectedDay         = day;
      this.productionDateTitle = Moment(day).format("dddd, Do [of] MMMM");
      this.productionDate      = d;
      this.getOrders(d);
    },
    async getRotation(id) {
      try {
        if(!id) {
          return;
        }
        let response  = await RotationService.getRotation(id);
        this.rotation = response.data;
      } catch(error) {
        console.log(error.message);
      }
    },
    async getOrders(day, email = false) {
      var response;
      var t       = this;
      t.day       = day;
      t.isLoading = true;

      try {
        if(email) {
          response = await OrderService.sendOrdersList(this.calendar.calendarNumber, day);
        } else {
          response = await OrderService.getOrdersList(this.calendar.calendarNumber, day);
        }
        this.orders = response.data;

        let menu = _.find(this.calendar.menu, (m) => { return Moment(m.menuDate).tz(this.$root.TZ).format("YYYY-MM-DD") === day });

        // console.log("menu", menu);

        if(menu) {
          await this.getRotation(menu.rotation || "default");
        }

        for(var i = 0; i < this.orders.length; i++){
          this.orders[ i ][ "ticketNumber" ] = i + 1;
        }
        t.isLoading = false;
      } catch(err) {
        if(err) {
          console.error(err);
          if(err.response && err.response.data && err.response.data.message) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.isLoading = false;
        }
      }
      t.isLoading = false;
    },
    async getDeliveryLocations() {
      try {
        let response           = await TenantService.getSites('delivery,class');
        this.deliveryLocations = response.data.filter((s) => { return s.siteType === 'delivery' });
        this.classrooms        = response.data.filter((s) => { return s.siteType === 'class' });

        this.deliveryOptions = this.deliveryLocations.map((d) => { return {value: d.siteId, text: d.title} })

        // console.log( "locations" , this.deliveryLocations , this.classrooms , this.deliveryOptions );
      } catch(error) {
        console.log(error.message);
      }
    },
    async getTransactionConfig(id) {
      try {
        let response = await UserService.getTransactionConfig("");

        this.transactionConfig = response.data;
        this.orderPrinterIp    = this.transactionConfig.portal.ticketPrinter || "";
        this.noSummary         = this.transactionConfig.portal.noSummary || "";
      } catch(error) {
        console.log(error.message);
      }
    },
    buildEvents(calendar) {
      this.events = [];

      const NoItem  = 1;
      const OneItem = 2;
      const TwoItem = 3;
      const More    = 4;

      for(let m of calendar.menu){
        var cat;
        if(m.choices.length > 0) {      // complete?
          if(m.choices.length > 3) {
            cat = More;
          } else {
            cat = m.choices.length + 1;
          }
        } else {
          cat = 1;
        }

        let event = {
          title     : m.title,
          start     : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours"), //m.menuDate,
          end       : this.moment(m.menuDate).tz(this.$root.TZ).add(12, "hours"), //m.menuDate,
          repeat    : "never",
          categoryId: cat
        };
        this.events.push(event);
      }
    },
    hydrateOrderCalendar(calendar) {
      this.isCalendarChanged      = false;
      this.calendar               = calendar;
      this.enableCalendarControls = false;

      try {
        this.buildEvents(calendar);
        if(new Date(calendar.calendarStartDate).getMonth() !== new Date(calendar.calendarEndDate).getMonth()) {
          this.enableCalendarControls = true;
        }
      } catch(error) {
        // todo: tell the user something about this issue.
        console.error(error);
      }
    },
    async getOrderCalendars(all) {
      let t       = this;
      t.isLoading = true;

      try {
        let response     = await OrderService.getOrderCalendars(all);
        t.orderCalendars = response.data;

      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
      t.isLoading = false;
    },
    async getOrderCalendar(calendarRow) {

      let id = calendarRow._id;
      try {
        let response = await OrderService.getOrderCalendar(id);
        this.hydrateOrderCalendar(response.data);
      } catch(error) {
        console.error(error);
      }
    },
    async getMenuItemCategories() {
      var t = this;
      try {
        let response            = await MenuItemService.getMenuItemCategories();
        this.menuItemCategories = response.data;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
    },
  }
}

</script>
<style lang="scss">

.teacher-order {
  background: #eee;
  border-radius: 1em;
  margin-top: .5em;
  margin-bottom: .5em;
  padding-left: 1em;
}

@media print {

  @page {
    size: auto;
    width: 8.5in !important;
    height: 11in !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: .35in !important;
  }

  .noprint {
    display: none;
  }

  .printable {
    position: static;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    float: none;
  }

  .main-content {
    // height: auto;
    background-color: #fff;
    margin: 0;
    padding: 0;
  }

  nav-bar, footer, notifications, app-footer, content-footer, mobile-menu, side-bar {
    display: none;
  }

  body {
    margin: 0;
    color: #000;
    background-color: #fff;
    height: auto;
    font-size: 9.5pt;
    overflow: visible;
  }

  h1 {
    page-break-before: auto;
    break-before: auto;
  }

  h4 {
    page-break-after: inherit;
    break-after: inherit;
    margin: 0;
    padding: 0;
  }

  table {
    page-break-inside: auto;
    font-size: 12px;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }
  thead {
    background: black !important;
  }

  .teacher-order {
    page-break-after: always;
    page-break-before: auto;
    background: #fff
  }

  .class-order {
    page-break-before: auto;
    page-break-inside: avoid;
  }

}

//.student-table {
//  width:100%;
//  thead {
//    background:black;
//    text-align:center;
//    color:white;
//    padding:.25em;
//    font-weight:600;
//  }
//  table, th, td {
//    border: 1px solid black;
//    padding:.25em;
//  }
//  tr:nth-child(even) {
//    background:#ddd;
//  }
//
//  //col:nth-child(2n+3) {background: #ddd}
//
//}

.table-data {
  font-size: 12pt;
}

</style>
