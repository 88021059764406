<template>
  <div class="" :class="{disabled: disabled}">
    
    <h4>{{title}}</h4>
    <b-form class="mb-3" @change.stop="nop()" @input.stop="nop()">
      <b-button @click="add(data)" class="btn btn-sm btn-fill btn-success float-left mr-2" :disabled="!canAdd(data)">Add </b-button>
      <template v-if="options">
        <sf-input  cstyle="width:15em;" type="list" :options="options" :labelCols="0"  v-model="data" />
      </template>
      <template v-else>
        <sf-input  cstyle="width:15em;" type="text" :labelCols="0"  v-model="data" @keyup.enter.native="add(data)"  @focus="onFocus()" @blur="onBlur()"/>
      </template>
    </b-form>
    <div>
      <slot>
        <ol>
          <li v-for="(v,index) in valueArray" :key="index" >
            <i class="nc-icon nc-scissors show-hand float-left mr-2" @click="remove(index)"></i>
            {{showOption(v)}}
          </li>
        </ol>
      </slot>
    </div>

  </div>
</template>
<script>

  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";

  export default {
    inheritedAttrs: true,
    name          : 'ListArrayFromDropDown',
    components    : {
      SfInput
    },
    props   : {
      title   : { type: String, default: '' },
      unique  : { type: Boolean, default: false },
      numeric : { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      showId  : { type: Boolean, default: false },
      options : { type: Array, default: () => [] },
      value   : { type: Array, default: () => [] },
    },
    data() {
      return {
        isList      : false,   // do we have a list of options?
        optionsArray: [],      // inbound choices
        valueArray  : [],      // outbound choices
        data        : ""
      }
    },
    computed: {
    },
    mounted() {
      this.valueArray   = this.value,
      this.optionsArray = this.options
      if( this.options ) {
        this.isList = true;
      }
    },
    methods: {
      nop() {},
      canAdd( d ) {
        if( this.unique ) {
          if( !d ) {
            return false;
          }
          if( this.numeric ) {
            d = parseInt(d);
          }
          return (this.valueArray.indexOf(d)<0) ? true : false  ;
        }
        return true;
      },
      add( v ) {
        if( this.unique ) {
          if( this.valueArray.indexOf(v)>=0 ) {
            return;
          }
        }
        if( this.numeric ) {
          v = parseInt(v);
        }
        this.valueArray.push( v );
        this.$emit('change', this.valueArray );
      }, 
      remove(index) {
        this.valueArray.splice(index,1);
        this.$emit('change', this.valueArray );
      },
      showOption(v) {
        if(this.optionsArray) {
          if( this.numeric ) {
            v = parseInt(v);
          }
          let x = _.find( this.optionsArray, { value: v } );
          if( this.showId ) {
            return (x) ? x.value.toString() + " : " + x.text : "** " + v;
          } else {
            return (x) ? x.text : "** " + v;
          }

        }
        return v;
      },
      onInput(e) {
        // console.log( "ei oninput" , this.label);
        this.$emit('input', e )
      },
      onChagne(e) {
        // console.log( "ei onchange", this.label );
        this.$emit('change', e )
      },
      onBlur(e) {
        // console.log( "ei onblur" , this.label );
        this.$emit('blur', e )
      },
      onFocus(e) {
        // console.log( "ei onfocus" , this .label);
        this.$emit('focus', e )
      }

    },
  }
</script>
