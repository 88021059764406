///-------------------------------------------------------------------------------------------------
// summary:	Badge service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  addBadge(badgeId, badgeData) {
    const response = Api().post(`/api/1/badge/${encodeURI(badgeId)}`, badgeData);
    return response;
  },

  getAccountDirectoryInformation( accountClassId ) {
    const response = Api().get(`/api/1/accountDirectoryInformation/${encodeURI(accountClassId)}`);
    return response;
  },

  isAccountExist(accountNumber) {
    const response = Api().get(`/api/1/isAccountExist/${encodeURI(accountNumber)}`);
    return response;
  },

  isBadgeExist(badgeNumber) {
    const response = Api().get(`/api/1/isBadgeExist/${encodeURI(badgeNumber)}`);
    return response;
  },

  getAccountByBadge(badgeNumber) {
    const response = Api().get(`/api/1/accountByBadge/${encodeURI(badgeNumber)}`);
    return response;
  },

  getAccountsByJobCode(accountType, jobCode = "") {
    const response = Api().get(`/api/1/accountsByJobCode` , { params: {accountType: accountType, jobCode: jobCode} });
    return response;
  },

  getAccountsByRoom(roomNumber) {
    const response = Api().get(`/api/1/accountsByRoom/${roomNumber}` );
    return response;
  },

  getPendingAccounts() {
    const response = Api().get(`/api/1/pendingAccounts`);
    return response;
  },

  getAccountPInfo(accountNumber) {
    return Api().get(`/api/1/accountPInfo/${encodeURIComponent(accountNumber)}`);
  },
  saveAccountPInfo(accountNumber, pInfo) {
    return Api().patch(`/api/1/accountPInfo/${encodeURIComponent(accountNumber)}`, pInfo );
  },

  getAccount( id ) {
    const response = Api().get(`/api/1/account/${encodeURI(id)}`);
    return response;
  },

  getAccountByAccount(accountNumber) {
    const response = Api().get(`/api/1/accountByAccount/${encodeURIComponent(accountNumber)}`);
    return response;
  },

  getAccountsByType(accountType) {
    const response = Api().get(`/api/1/accounts/${encodeURIComponent(accountType)}`);
    return response;
  },

  getAccountBalances(accountNumber) {
    const response = Api().get(`/api/1/accountBalances/${encodeURIComponent(accountNumber)}`);
    return response;
  },

  accountFix(id) {
    const response = Api().patch(`/api/1/accountFix/${encodeURIComponent(id)}`);
    return response;
  },

  removeAccount(accountNumber) {
    const response = Api().delete(`/api/1/account/${encodeURIComponent(accountNumber)}`);
    return response;
  },

  getBadgeListByType(badgeType) {
    const response = Api().get(`/api/1/badges/${badgeType}`);
    return response;
  },

  addAccount( accountNumber , accountData ) {
    const response = Api().post(`/api/1/account/${encodeURIComponent(accountNumber)}`, accountData );
    return response;
  },

  searchAccount(searchString , limit = null, accountType = "") {
    const response = Api().get(`/api/1/accountList/${encodeURIComponent(searchString)}/${accountType}`, { params: { limit: limit }});
    return response;
  },

  searchBadge(searchString, accountType="") {
    if (accountType > "") {
      accountType = '/' + accountType;
    }
    const response = Api().get(`/api/1/badgeList/${encodeURIComponent(searchString)}${accountType}`);
    return response;
  },
  searchBadgeWithAccountClassId(searchString, accountClassId="", limit = 200 , all = false) {
    if (accountClassId > "") {
      accountClassId = '/' + accountClassId;
    }
    const response = Api().get(`/api/1/badgeListWithAccountClassId/${encodeURIComponent(searchString)}${accountClassId}`, { params: { limit: limit , all: all }});
    return response;
  },
  liveSearch(category = "resident" , searchString = "a", limit = 5) {
    const response = Api().get(`/api/1/accountSearch/${encodeURIComponent(category)}/${encodeURIComponent(searchString)}`, { params: { limit: limit , active:true } });
    return response;
  },
  liveSearch2(accountClasses = "resident" , searchString = "a", limit = 5) {
    const response = Api().get(`/api/2/accountSearch/${encodeURIComponent(accountClasses)}/${encodeURIComponent(searchString)}`, { params: { limit: limit , active:true } });
    return response;
  },
  getAccountTypes() {
    return Api().get(`/api/1/accountTypes`);
  },
  getAccountClassWithSetupInfo(accountClassId) {
    return Api().get(`/api/1/accountClassWithSetupInfo/${accountClassId}`);
  },
  getAccountClasses(includeSetup=false) {
    return Api().get(`/api/1/accountClasses`,{ params: { includeSetup: includeSetup }});
  },
  getAccountTypeCounts() {
    return Api().get(`/api/1/accountTypeCounts`);
  },
  getAccountTotals( badgeNumber = "", accountNumber = "", all = false ) {
    const response = Api().get(`/api/1/accountTotals/${encodeURIComponent(badgeNumber)}`, { params: { accountNumber: encodeURIComponent(accountNumber), all: all } });
    return response;
  },
  getAccountTotalClass( badgeNumber ) {
    const response = Api().get(`/api/1/accountTotalClass/${encodeURIComponent(badgeNumber)}`);
    return response;
  },
  getBadge(badgeNumber, accountNumber ) {
    const response = Api().get(`/api/1/badge/${encodeURI(badgeNumber)}`, { params: { accountNumber: accountNumber }});
    return response;
  },

  saveAccount(_id, accountData) {
    const response = Api().patch(`/api/1/account/${encodeURI(_id)}`, accountData);
    return response;
  },

  updateBadgeContactInfo(_id, badgeData ) {
    const response = Api().patch(`/api/1/badgeContactInfo/${encodeURI(_id)}`, badgeData);
    return response;
  },

  // updates the password for the FIRST object in the remoteAccess array only - ALWAYS THE FIRST.
  // which is likely not the desired thing when we have multiple portal accounts.
  // ** depreciated 14-Apr-24 ** use setPortalPassword instead.
  updatePortalPassword(_id, password) {
    const response = Api().patch(`/api/1/portalPassword/${encodeURI(_id)}`, {password: password});
    return response;
  },

  // this works like the updatePortalPassword, except that the _id is for the remoteAccess object and
  // not for the account.
  setPortalPassword(_id, password) {
    const response = Api().patch(`/api/1/setPortalPassword/${encodeURI(_id)}`, {password: password});
    return response;
  },

  getCertifications( accountNumber, badgeNumber , all = "all") {
    const response = Api().get(`/api/1/accountCertificationsList/${encodeURI(accountNumber)}/${encodeURI(badgeNumber)}` , { params: { all: all } });
    return response;
  },

  getCertification( id ) {
    const response = Api().get(`/api/1/accountCertification/${id}` );
    return response;
  },

  // actually it returns the certification if it has it.
  hasCertification( accountNumber, certificationNumber ) {
    const response = Api().get(`/api/1/accountCertification/${accountNumber}/${certificationNumber}` );
    return response;
  },

  addCertification( accountNumber, badgeNumber , certification ) {
    const response = Api().post(`/api/1/accountCertification/${encodeURI(accountNumber)}/${encodeURI(badgeNumber)}`, certification );
    return response;
  },

  updateCertification( certification ) {
    const response = Api().patch(`/api/1/accountCertification`, certification );
    return response;
  },

  removeCertification( id ) {
    const response = Api().delete(`/api/1/accountCertification/${id}` );
    return response;
  },

  holdersByCertification( certificationId ) {
    const response= Api().get( `/api/1/accountsHoldingCertification/${certificationId}` );
    return response;;
  },

  accountCertificationSummary() {
    const response= Api().get( `/api/1/accountCertificationSummary` );
    return response;;
  }



}
