<template>
  <div>
    <div>
      <i class="fa fa-plus-square show-hand float-left" style="margin-right:.5em;font-size:1.5em;" v-b-popover.hover.top='"Add Category"' @click="addNewCategory"></i>
      <h4 class="card-title mb-2">Item Categories Management</h4>
      <sf-input v-if="categoryTypes" label="Category Type Filter" :label-cols="2" cstyle="width:12em;" type="list" v-model="categoryFilter" :options="categoryTypes" helpText="When blank, lists all categories"></sf-input>
      <sf-input v-if="revenueCenters && ['pos','catering'].indexOf(categoryFilter)>=0" label="Revenue Center Filter" :label-cols="2" cstyle="width:12em;" type="list" v-model="revenueCenterFilter" :options="revenueCenters.map((r) => {return { text: r.title, value:r.revenueCenterId }})" helpText="When blank, lists all revenue centers"></sf-input>

    </div>
    <div>
      <hr>

      <div v-if="category !== null">
        <div class="mb-4">
          <h2 class="card-title">{{category.categoryType}} {{category.title}} <span v-if="isCategoryChanged" class="small"> changed </span></h2>
        </div>

        <b-container fluid @change="itemChanged()">
          <b-tabs class="tab-hover" v-model="activeTab" active-nav-item-class="selected-tab">
            <b-tab title="Setup" class=" card-title mt-3">
              <i class="fa fa-copy" style="float:right;font-size:1.5em;" v-b-popover.hover.top="'Clone this category'" @click="cloneCategory()"/>
              <sf-input cstyle="width:15em;" v-model="category.title" type="text" label="Category Name" placeholder="User visible" />
              <sf-input cstyle="width:15em;" v-model="category.reference" type="text" label="Category Reference" placeholder="User Defined" />
              <sf-input cstyle="width:15em;" v-model="category.sequence" type="number" label="Sequence" />
              <sf-input cstyle="width:15em;" v-model="category.category" type="text" label="Tag" helpText="CAUTION if changing - Used to search in edit screens" placeholder="All Lower" v-b-popover.hover.top="{variant:'info',content:'Be careful when changing this field as it is used to perform lookups and other operations that require the exact title', title:'NOTE'}" />
              <sf-input v-model="category.categoryType" id="categoryList" :value="category.categoryType" type="list" label="Category List" :options='categoryTypes' />
              <sf-input v-model="category.itemType" :value="category.itemType" type="list" label="Category Item Type" :options='itemTypes' />
              <sf-input cstyle="width:8em;" v-model="category.minItemNumber" type="number" label="Starting Item #" />
              <sf-input cstyle="width:8em;" v-model="category.maxItemNumber" type="number" label="Last # In Series" />
              <b-form-group horizontal class="mt-1 mb-0" style="margin-left:16em;">
                <b-checkbox id="taxable" v-model="category.itemOptions.taxable">Taxable</b-checkbox>
                <b-form-group v-if="category.itemOptions.taxable" horizontal class="ml-2 mt-1 mb-0">
                  <b-checkbox id="taxable1" v-model="category.itemOptions.taxableItemizers[0]">Enable Rate 1</b-checkbox>
                  <b-checkbox id="taxable2" v-model="category.itemOptions.taxableItemizers[1]">Enable Rate 2</b-checkbox>
                  <b-checkbox id="taxable3" v-model="category.itemOptions.taxableItemizers[2]">Enable Rate 3</b-checkbox>
                  <b-checkbox id="taxable4" v-model="category.itemOptions.taxableItemizers[3]">Enable Rate 4</b-checkbox>
                </b-form-group>
                <b-checkbox id="discountable" v-model="category.itemOptions.discountable">Discountable</b-checkbox>
              </b-form-group>
            </b-tab>
            <b-tab v-if="category.categoryType==='pos' || category.categoryType==='catering'" title="Revenue Centers">
                <div>
                  <h4>Allowed Revenue Centers</h4>
                  <div @input="itemChanged()" >
                    <div class=""  style="width:500px;margin-left:2em;display:grid;grid-column: auto;">
                      <b-form-checkbox-group style="" v-model="category.revenueCenters">
                        <template v-for="(rc,index) in revenueCenters" >
                          <b-form-checkbox style="width:24em;" v-if="rc.options.isAllowMenuMasterList"  :value="rc.revenueCenterId" :key="index">
                          <span style="text-transform: capitalize;">{{rc.title}}</span></b-form-checkbox>
                        </template>
                      </b-form-checkbox-group>
                    </div>
                  </div>
                </div>


            </b-tab>
            <b-tab v-if="category.categoryType==='pos'" title="POS Groups">
              <sf-input cstyle="width:8em;" v-model="category.posGroup.menuItemClass" type="number" label="Menu Item Class #" />
              <sf-input cstyle="width:8em;" v-model="category.posGroup.menuLevelClass" type="number" label="Menu Level Class #" />
              <sf-input cstyle="width:8em;" v-model="category.posGroup.menuItemGroup" type="number" label="Menu Item Group #" />
              <sf-input cstyle="width:8em;" v-model="category.posGroup.printClass" type="number" label="Print Class #" />
              <sf-input cstyle="width:8em;" v-model="category.posGroup.majorGroup" type="number" label="Major Group #" />
              <sf-input cstyle="width:8em;" v-model="category.posGroup.familyGroup" type="number" label="Family Group #" />
            </b-tab>
            <b-tab v-if="category.priceActive" title="Price Configuration" class="card-title">
              <div class="table-responsive-sm">
                <table class="table table-bordered table-success">
                  <thead class="table-dark">
                  <tr>
                    <th scope="col" style="text-align:center!important;">Enabled ?</th>
                    <th scope="col">Price Level Description</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="padding:0;margin:0;" v-for="(i,index1) in category.priceActive.length" scope="row" :key="index1" :class="priceRowClass( category.priceActive[i-1])">
                    <td style="padding:0;width:100px;text-align:center;">
                      <b-checkbox style="" type="checkbox" v-model="category.priceActive[i-1]">Level {{i}}</b-checkbox>
                    </td>
                    <td style="padding:5px;width:250px;">
                      <input cstyle="width:100%;" v-model="category.priceNames[i-1]" type="text" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab v-if="category.SLU !== undefined" title="SLU Definition">
              <h4>SLU Definitions</h4>
              <b-form>
              <template v-for="(slu,sluIndex) in 10">
                <sf-input v-model="category.SLU[sluIndex]" :labelCols="2" cstyle="width:10em;" :key="sluIndex" :label="'SLU #' + (sluIndex+1).toString()" />
              </template>
              </b-form>
            </b-tab>
            <b-tab title="Hot Filters">
              <simple-list-only
                @change="itemChanged()"
                title="Hot Filters"
                :list="category.hotFilters || []"
              />
            </b-tab>
          </b-tabs>

          <b-button size="sm" class="float-right btn-fill btn-sm" variant="success" @click="categoryEditDone( category )">
            <span v-if="!isCategoryChanged">Done</span><span v-if="isCategoryChanged">Save</span>
          </b-button>

        </b-container>

      </div>
      <!-----------------------------------------------------------------------------------[ END MODAL ]------------->
      <div v-if="category === null" >
        <b-table class="table" striped hover  head-variant="dark" sort-by="sequence" @row-clicked="editCategory" :items="categoryList" :fields="columns" >
          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand" v-b-popover.hover.top='"Remove Category"' @click.stop="deleteCategory(row)"></i>
          </template>
        </b-table>
        <hr>
        <b-button class="btn btn-success btn-fill float-right btn-sm" @click="$router.go(-1)">Done</b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuItemService from "/src/Services/MenuItemService";
  import RevenueCenterService from "/src/Services/RevenueCenterService";
  import TenantService   from "/src/Services/TenantService";
  import Card            from "/src/components/UIComponents/Cards/Card.vue";
  import SfInput         from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable          from "/src/components/UIComponents/Table.vue";
  import SimpleListOnly from "@/components/UIComponents/Lists/SimpleListOnly.vue";

  export default {
    components: {
      SimpleListOnly,
      LTable,
      Card,
      SfInput
    },
    data() {
      return {
        saveDisabled       : true,
        changeCount        : 0,
        activeTab          : 0,
        modalTitle         : "Adding New Category",
        isEditing          : false,
        small              : true,
        categoryFilter     : "",
        revenueCenterFilter: "",
        categories         : [],
        revenueCenters     : [],
        category           : null,
        isCategoryChanged  : false,
        categoryTypes      : null,
        itemTypes          : null,
        columns            : [
          "actions",
          {key: "categoryType", label: "Category Type", sortable: true},
          {key: "itemType", label: "Item Type", sortable: true},
          {key: "sequence", label: "Sequence", sortable: true},
          {key: "reference", label: "Reference", sortable: true},
          {key: "category", label: "Tag", sortable: true},
          {key: "title", label: "Title", sortable: true},
          {key: "itemOptions.taxable", label: "Taxable", sortable: true, formatter: (v) => {return v ? '√' : '' }},
          {key: "minItemNumber", label: "Start Item #", sortable: true},
          {key: "maxItemNumber", label: "End Item #", sortable: true}
          // {key: "priceNames[0]", label: "$1", sortable: true},
          // {key: "priceNames[1]", label: "$2", sortable: true},
          // {key: "priceNames[2]", label: "$3", sortable: true}
        ]
      };
    },
    watch     : {},
    async mounted() {
      await Promise.all( [
        this.getCategories(),
        this.getRevenueCenters(),
        this.getCategoryTypes(),
        this.getItemTypes()
      ]);

    },
    computed: {
      categoryList() {
        if( this.categoryFilter === "" && this.revenueCenterFilter === "" ) {
          return this.categories;
        }
        if( this.revenueCenterFilter === "" || ['pos','catering'].indexOf(this.categoryFilter) < 0 ) {
          return this.categories.filter( (cat) => { return cat.categoryType === this.categoryFilter } );
        }
        return this.categories.filter( (cat) => { return cat.categoryType === this.categoryFilter && cat.revenueCenters.indexOf( parseInt(this.revenueCenterFilter) ) >= 0 } );
      }
    },
    methods   : {
      showAlert() {
        alert("you hit an alert");
      },
      itemChanged() {
        this.isCategoryChanged = true;
      },
      categoryEditDone(category) {
        if(this.isCategoryChanged) {
          this.saveCategory(category);
          this.isCategoryChanged = false;
        }
        this.isEditing = false;
        this.category = null;
      },
      priceRowClass(isActive) {
        if(!isActive) {
          return "table-dark";
        }
      },
      showModal() {
        this.$refs.myModalRef.show()
      },
      hideModal() {
        this.$refs.myModalRef.hide()
      },
      editCategory(item) {
        this.isCategoryChanged = false;
        this.category          = item;
        this.isEditing         = true;
      },
      async getCategories() {
        try {
          let response           = await MenuItemService.getAllMenuItemCategories();
          this.categories        = response.data;
          this.isCategoryChanged = false;
        } catch(error) {
          console.log(error.message);
        }
      },
      async getCategoryTypes() {
        try {
          let response       = await TenantService.getCategoryTypes()
          this.categoryTypes = response.data;
        } catch(error) {
          console.log(error.message);
        }
      },
      async getItemTypes() {
        try {
          let response   = await TenantService.getItemTypes()
          this.itemTypes = response.data;
        } catch(error) {
          console.log(error.message);
        }
      },
      async saveCategory(category) {
        try {
          let response           = await MenuItemService.saveCategory(category);
          this.isCategoryChanged = false;
        } catch(error) {
          console.log(error.message);
        }
      },
      async deleteCategory(item ) {
        if( ! confirm( "Remove " + item.item.title + "?")) {
          return;
        }

        try {
          let response        = await MenuItemService.deleteCategory( item.item._id );
          this.categories = response.data;
        }catch( error ) {
          console.error( error );
        }
        this.isEditing    = false;
        this.editCategory = false;
      },
      async addNewCategory() {
        let t = this;

        if(!await this.$root.confirm("Add New Category?")) {
          return;
        }

        const title = "new category";
        let category = { title: title, categoryType: this.categoryFilter, revenueCenters: [] }

        if( category.categoryType === "pos" && this.revenueCenterFilter.length > 0 ) {
          category.revenueCenters.push( parseInt( this.revenueCenterFilter ));
        }

        try {

          let response    = await MenuItemService.addCategory( category );
          this.categories = response.data;

          category = _.find( this.categories , { title: title } );

          if( category ) {
            this.category = category;
            this.isEditing = true;
          } else {
            this.isEditing = false;
          }

        } catch(error) {
          console.log(error.message);
        }
      },
      async cloneCategory() {
        let t = this;
        if(!await this.$root.confirm("Clone this category?")) {
          return;
        }

        let category = this.category;

        try {

          let title = category.title + " (cloned)";
          category.title = title;
          let response    = await MenuItemService.addCategory( category );
          this.categories = response.data;

          category = _.find( this.categories , { title: title } );

          if( category ) {
            this.category = category;
            this.isEditing = true;
          } else {
            this.isEditing = false;
          }

        } catch(error) {
          console.log(error.message);
        }


      },
      async getRevenueCenters() {
        let t = this;
        let response;

        try {
          response            = await RevenueCenterService.getRevenueCenters( true );
          this.revenueCenters = response.data;
        } catch (err) {
          console.log("getRevenueCenters error: ", err);
          if (err.response) {
            if (err.response.data) {
              t.$root.errorMessage("Oops", err.response.data.message);
            }
          } else {
            t.$root.errorMessage("Oops", "Had an error getting the revenue centers");
          }
          return;
        }
      },
    }
  };
</script>

<style lang="scss">
</style>
