<template>
  <div class="content">

    <div v-if="!template">
      <list-card
        title="Templates"
        titleHelp="Select the desired template to edit"
        :items="_.sortBy(templates, ['title'])"
        :fields="fields"
        :enableUpload="false"
        :enableAdd="true"
        :enableDelete="true"
        :enableDone="true"
        :enableShowActive="true"
        @click="edit($event)"
        @add="add()"
        @delete="remove($event)"
        @done="$router.go(-1)"
      >
      </list-card>
    </div>

    <div v-if="template">
      <template-edit
        :template="template"
        :changed="isDataChanged"
        :editing="isEditing"
        @done="done($event)"
        @save="save($event)"
        @cancel="cancel($event)"
        @clone="clone($event)"
        @changed="dataChanged()"
      />
    </div>

  </div>
</template>

<script>
//https://www.npmjs.com/package/vue-prism-editor
// import LocationForm from "./LocationForm.vue";

import TemplateEdit    from "./TemplateEdit.vue";
import Moment          from "moment-timezone";
import TemplateService from "/src/Services/TemplateService";
import ListCard        from '/src/components/UIComponents/Cards/ListCard.vue';

export default {
  components: {
    ListCard,
    TemplateEdit
  },
  data() {
    return {
      isDataChanged: false,
      isEditing    : false,
      template     : null,
      templates    : [],
      fields       : [
          { key: "action", label: "", sortable: true },
          { key: "category", label: "Category", sortable: true },
          // { key: "templateType", label: "Type", sortable: true },
          { key: "title", label: "Title", sortable: true },
          { key: "templateName", label: "Name", sortable: true },
          { key: "updatedAt", label: "Last Update", sortable: true,  formatter:(value) => { return Moment(value).fromNow() } }
        ],
    };
  },
  created(){
    this.getTemplates();
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isDataChanged ) {
      return next();
    }
    if( await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    done() {
      this.cancel();
    },
    cancel() {
      this.template      = null;
      this.isDataChanged = false;
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    async getTemplates() {
      let response = await TemplateService.getList();
      this.templates = response.data;
    },
    async add( template) {
      if( ! await this.$root.confirmYesNo( "Add New Template?" )) {
        return;
      }

      let t          = this;
      let newTemplate = { title: "", templateName: "new-template-name", active: true };  

      t.isLoading = true;
      template = Object.assign( {} , newTemplate, template );  // merge a preset passed in with our defaults;

      try {
        let response = await TemplateService.add( template );  // get db defaults.

        this.template  = response.data;
        this.isEditing = true;

        t.dataChanged();

      } catch( error ) {
        console.log(error);
        if (error.response) {
          t.$root.errorMessage("Oops", error.response.data.message);
        }
      }
      t.isLoading = false;

    },
    async edit(item) {
      console.log( "edit" , item );
      let response = await TemplateService.get( item._id );
      this.template = response.data;
    },
    async save( data ) {
      let t        = this;
      let response = await TemplateService.update( data );

      let id = response.data._id;

      let found = false;
      for( var i=0; i<t.templates.length; i++ ) {
        if( t.templates[i]._id === id ) {
          t.templates[i] = response.data;
          found = true;
          break;
        }
      }

      if( ! found ) {
        t.templates.unshift( response.data );    // plug the new ticket at the top.
      }

      this.template      = null;
      this.isDataChanged = false;
    },
    async colon() {
    },
    async remove(row) {
      let item = row.item;
      console.log( item );
      if( ! await this.$root.confirmYesNo( `Delete Template?<br>[${item.title}]` )) {
        return;
      }
      let response = await TemplateService.delete(item._id);
      await this.getTemplates();
    },

  }
};
</script>

<style>
</style>
