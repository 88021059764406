<template>
  <div class="">
    <div class="">
      <i v-if="!title && enableAdd" class="fa fa-plus-square float-right show-hand" style="font-size:1.5em;" @click="addItem()" v-b-popover.hover.right="addHoverText"></i>
      <h4 v-if="title" class="mt-1 mb-0"><i v-if="enableAdd" class="fa fa-plus-square show-hand" style="margin-right:.5em;" @click="addItem()" v-b-popover.hover.right="addHoverText"></i>{{ title }}</h4>
      <p v-if="subTitle" class="help-text mt-0" >{{subTitle}}</p>
    </div>
    <div class="" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div>
      <div v-if="tableData.length>0">
        <b-table :small="true" borderless sticky-header head-variant="dark" stacked="md" ref="tableDataList" :items="tableData" :fields="tableDataFields">
          <template v-slot:table-colgroup="scope">
            <col
                v-for="(field,index) in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'value' ? '90px' : field.key=== 'actions' ? '30px' : '250px' }"
                :index="index"
            >
          </template>
          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand float-left" @click.stop="removeItem(row.index)"></i>
          </template>
          <template v-slot:cell(value)="row">
            <sf-input :id="makeId()" :readonly="row.item.text.length>0" :labelCols="0" v-model="row.item.value" @input="change()" @change="validateKey(row.item)"></sf-input>
          </template>
          <template v-slot:cell(text)="row">
            <template v-if="!isMultiLine">
              <sf-input :id="makeId()" :labelCols="0" v-model="row.item.text" @input="change()"></sf-input>
            </template>
            <template v-if="isMultiLine">
              <div style="width:100%;margin:0;padding:0;">
                <textarea :id="makeId()" style="width:500px;" v-model="row.item.text" :rows="rows" @input="change()"></textarea>
              </div>
            </template>
          </template>
        </b-table>
      </div>
    </div>
    <div class="" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>

import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";

let idCount = 0;

export default {
  name      : 'OptionManagementTable',
  components: {
    SfInput
  },
  props     : {
    title          : {type: String, default: ""},
    subTitle       : {type: String, default: null},
    tableData      : {type: Array, default: () => []},
    tableOptions   : {type: Object, default: () => {}},
    enableAdd      : {type: Boolean, default: true},
    addHoverText   : {type: String, default: "Add New"},
    keysLowercase  : {type: Boolean, default: true},
    keysNoSpaces   : {type: Boolean, default: false},
    isMultiLine    : {type: Boolean, default: false},
    rows           : {type: Number, default: 3},
    tableDataFields: {
      type: Array, default: () => [
        {key: "actions", label: "", sortable: false},
        {key: "value", label: "Key", sortable: true},
        {key: "text", label: "Description", sortable: true}
      ]
    }
  },
  data() {
    return {}
  },
  methods: {
    makeId() {
      idCount++;
      return "inputId-" + idCount.toString();
    },
    addItem() {
      this.tableData.push({value: "new", text: ""});
      this.$emit("add");
      this.change();
    },
    validateKey(item) {
      console.log("validate key", item, item.value);
      item.value = item.value.trim();
      if(this.keysLowercase) {
        item.value = item.value.toLowerCase();
      }
      if(this.keysNoSpaces) {
        item.value = item.value.replace(/\s/g, "-");
      }
      ;
    },
    change() {
      // console.log( "change item"  );
      this.$emit("change");
    },
    editItem(e) {
      // console.log( "edit item" );
      this.$emit("click", e);
      this.change();
    },
    removeItem(index) {
      // console.log( "remove Item" , index );
      this.$emit("delete", this.tableData[ index ]);
      this.tableData.splice(index, 1);
      this.change();
    }
  }
}
</script>
<style>


</style>
