<template>
  <div class="contact-card show-hand">
    <div v-if="!isExpand" @click="isExpand=true">
      <!-- <i class="fa fa-chevron-down mr-3 float-right" @click="isEdit=true"></i> -->
      <div style="font-weight:100;font-size:.8em;">({{ contact.contactType }}) <div style="padding-right:1em;float:right;font-weight:900;font-size:1em;"> ({{ moment().tz(contact.TZ||"").format( "hh:mm a") }})</div></div>
      <div style="font-weight:700;">{{ contact.name }} <span v-if="contact.alias">({{ contact.alias }})</span></div>
      <div style="font-weight:400;">{{ contact.title }}</div>
      <div style="font-weight:100;">{{ contact.address1 }}</div>
      <div style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.phone }}</div>
      <div v-if="contact.cell" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.cell }} <span style="font-size:.8em;">(cell)</span></div>
      <div style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.email }}</div>
    </div>
    <div v-else style="margin-bottom:1em;">
      <div v-if="!isEdit">
        <i v-if="isAllowDelete" class="fa fa-trash float-right" @click="addressRemove()" v-b-popover.hover.top="'Remove this address'"></i>
        <i v-if="isAllowEdit" class="fa fa-gear mr-2 float-right" @click="isEdit=true" v-b-popover.hover.bottom="'Edit this address'"></i>
        <!-- <div style="font-weight:100;font-size:.8em;">({{ contact.contactType }})</div> -->
        <div style="font-weight:100;font-size:.8em;">({{ contact.contactType }}) <div style="padding-right:1em;float:right;font-weight:900;font-size:1em;"> ({{ moment().tz(contact.TZ||"").format( "hh:mm a") }})</div></div>
        <div style="font-weight:800;" @click="isExpand=false">{{ contact.name }} <span v-if="contact.alias">({{ contact.alias }})</span></div>
        <div style="font-weight:400;">{{ contact.title }}</div>
        <hr/>
        <div style="font-weight:100;">{{ contact.address1 }}</div>
        <div v-if="contact.address2" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.address2 }}</div>
        <div v-if="contact.address3" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.address3 }}</div>
        <div v-if="contact.address4" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.address4 }}</div>
        <div v-if="contact.address5" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.address5 }}</div>
        <div v-if="contact.city || contact.state">
          <span style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.city }}</span>
          <span style="font-weight:100;margin-left:5px;font-size:.8em;">{{ contact.state }}</span>
        </div>
        <div style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.zip }}</div>
        <div v-if="contact.phone" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.phone }}</div>
        <div v-if="contact.cell" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.cell }} <span style="font-size:.8em;">(cell)</span></div>
        <div v-if="contact.email" style="font-weight:500;margin-left:1em;font-size:.8em;">{{ contact.email }}</div>
        <div v-if="contact.website" style="font-weight:100;margin-left:1em;font-size:.8em;">{{ contact.website }}</div>
        <div v-if="contact.notes" style="font-weight:100;margin-left:1em;font-size:.8em;white-space:pre;">{{ contact.notes }}</div>
      </div>
      <!-- <b-modal v-else style="width:400px;margin-bottom:1em;background:#ddd;padding:8px 5px 10px 5px;border:2px solid gray;border-radius:.5em;"> -->
      <b-modal v-else show style="" size="lg" hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close v-model="isExpand">
        <b-form class="input-group" style="width:100%" @input="dataChanged()">

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Type</span>
            </div>
            <select class="custom-select" id="inputGroupSelect01" v-model="contact.contactType">
              <option v-for="type in contactTypes" :value="type.value">{{ type.text }}</option>
            </select>
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Name</span>
            </div>
            <input type="text" class="form-control" v-model="contact.name" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Alias</span>
            </div>
            <input type="text" class="form-control" v-model="contact.alias" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Title</span>
            </div>
            <input type="text" class="form-control" v-model="contact.title" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Address</span>
            </div>
            <input type="text" class="form-control" v-model="contact.address1" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text"></span>
            </div>
            <input type="text" class="form-control" v-model="contact.address2" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text"></span>
            </div>
            <input type="text" class="form-control" v-model="contact.address3" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text"></span>
            </div>
            <input type="text" class="form-control" v-model="contact.address4" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text"></span>
            </div>
            <input type="text" class="form-control" v-model="contact.address5" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">City</span>
            </div>
            <input type="text" class="form-control" v-model="contact.city" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">State</span>
            </div>
            <input type="text" class="form-control" v-model="contact.state" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Zip</span>
            </div>
            <input type="text" class="form-control" v-model="contact.zip" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Phone</span>
            </div>
            <input type="text" class="form-control" v-model="contact.phone">
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Cell</span>
            </div>
            <input type="text" class="form-control" v-model="contact.cell" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Email</span>
            </div>
            <input type="text" class="form-control" v-model="contact.email" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Website</span>
            </div>
            <input type="text" class="form-control" v-model="contact.website" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Timezone</span>
            </div>
            <b-form-select type="list" :options="$root.timezones" class="form-control" v-model="contact.TZ" />
          </div>

          <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text">Notes</span>
            </div>
            <textarea class="form-control" rows="4" v-model="contact.notes" />
          </div>

        </b-form>
        <b-button @click="save()" class="btn btn-fill btn-sm btn-success" style="margin-left:1em;">Done</b-button>
      </b-modal>
    </div>
  </div>
</template>
<script>

import Currency from "currency.js";
import SfInput  from "/src/components/UIComponents/Inputs/SFormInput.vue";
import Moment   from "moment-timezone"; // find the docs here: https://momentjs.com/timezone/
import Numeral  from "numeral"; // find the docs here: http://numeraljs.com/
import LTable   from "/src/components/UIComponents/Table.vue";

export default {
  name      : 'ContactCard',
  props     : {
    contact      : {type: Object, default: () => { return {name: "", title: "", _id: "000000000000000000000000"}}},
    index        : {type: Number, default: 0},
    isAllowEdit  : {type: Boolean, default: true},
    isAllowDelete: {type: Boolean, default: true},
    noborder     : {type: Boolean, default: false},
    contactTypes: {type: Array, default: () => { return [
        {value: "primary", text: "Primary"},
        {value: "contact", text: "Contact"},
        {value: "customer", text: "Customer"},
        {value: "attendee", text: "Attendee"},
        {value: "employee", text: "Employee"},
        {value: "sales", text: "Sales"},
        {value: "technical", text: "Technical"},
        {value: "prospect", text: "Prospect"},
        {value: "proposal", text: "Proposal"},
        {value: "vendor", text: "Vendor"},
        {value: "relative", text: "Relative"},
        {value: "billing", text: "Billing"},
        {value: "shipping", text: "Shipping"},
        {value: "mailing", text: "Mailing"},
        {value: "newsletter", text: "Newsletter"},
        {value: "portal", text: "Portal"},
        {value: "other", text: "Other"}
      ] }},
  },
  components: {
    SfInput,
    LTable
  },
  data() {
    return {
      isDataChanged: false,
      isExpand     : false,
      isEdit       : false,

    }
  },
  beforeUpdate() {
    if(!this.contact || this.contact.name.toLowerCase() === 'new' || this.contact.name.length === 0) {    // force new contact into edit mode.
      this.isDataChanged = true;
      this.isExpand      = true;
      this.isEdit        = true;
    }
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    numeral(x) {
      return Numeral(x);
    },
    currency(c) {
      return Currency(c);
    },
    addressEdit() {
      this.isExpand = false;
    },
    addressRemove() {
      this.isExpand = false;
      if(!this.contact._id) {    // this requires a save first.
        return;
      }
      this.$emit('remove', this.contact._id);
    },
    save() {
      this.isEdit = false;
      if(this.isDataChanged) {
        this.$emit(`change`, {contact: this.contact, index: this.index});
        this.isDataChanged = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.contact-card {
  // width:190px;
  width: 100%;
  padding: .5em;
  line-height: 18px;
  // border: 1px solid gray;
  // box-shadow: 2px 2px 2px gray;
  border-radius: 1em;
  min-height: 100px;
}

.input-group-text {
  width: 80px;
}


.contact-input-edit {
  font-size: .8em;
  padding: 0;
  margin: 0;
  height: 1.25em;
  line-height: 1em;
}

.card-table {
  font-size: .8em;
  line-height: 70%;
  margin-top: 5px;
  background: #f0f0f0;
  border-radius: 1em;
}

</style>
