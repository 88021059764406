<template>
  <div>

    <!--      <div>-->
    <!--        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>-->
    <!--        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>-->
    <!--        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>-->
    <!--        <h4>Events Configuration</h4>-->
    <!--      </div>-->


    <page-edit-header-card
        :enableClone="false"
        :enableEditIcon="false"
        :enableAdd="true"

        :isChanged="isDataChanged"
        :isLoading="isLoading"
        title="Events Configuration"
        @cancel="cancel()"
        @done="done()"
        @save="save()"
        @add="addNew();selectedTab=1;"
    >
    </page-edit-header-card>


    <b-tabs v-if="eventsConfig" id="donationsConfig" v-model="selectedTab" active-nav-item-class="selected-tab" class="tab-hover mt-5" content-class="">

      <b-tab title="Overhead">
        <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
          <form style="margin-top:2em;" @input="dataChanged()">
            <sf-input label="Title" :labelCols="1" cstyle="width:25em;" type="text" v-model="eventsConfig.title"></sf-input>
            <sf-input label="Notes" :labelCols="1" cstyle="width:25em;" :rows="18" type="textarea" v-model="eventsConfig.notes"></sf-input>
          </form>
        </div>
      </b-tab>

      <b-tab title="Types Setup" style="margin-top:1em;padding: .5em .5em .5em 0; border: 5px solid gray;border-radius:1em;">

        <div v-if="eventsConfig.eventTypes.length===0" style="padding:2em;">
          <b-button class="btn btn-sm btn-fill btn-success" @click="addNew()">Let's begin. Yep, start configuration!</b-button>
        </div>

        <div v-if="eventsConfig.eventTypes.length>0" style="min-height:650px;">
          <b-tabs v-model="selectedSubTab" active-nav-item-class="selected-tab-left" lazy card class="tab-hover-left" content-class="mt-2 ml-3 mr-3" vertical>

            <b-tab v-for="(event,index) in eventsConfig.eventTypes" :key="index" :title="event.title.substring(0,18)" style="border-left:4px solid royalblue;">
              <div>
                <!--                <div class="row">-->
                <!--                  <div class="col-md-12 col-sm-12" style="min-height:500px;">-->
                <b-tabs v-model="selectedEventTab" class="tab-hover-normal" content-class="mt-3 ml-3 " active-nav-item-class="selected-tab">
                  <b-tab title="General">
                    <div style="position:absolute;right:10px;width:4em;font-size:1.4em;z-index:100;">
                      <!--                      <i v-b-popover.hover.top="'Add New'" class="fa fa-plus-square show-hand" @click="addNew()"></i>-->
                      <i v-b-popover.hover.top="'Remove this entry'" class="fa fa-trash show-hand" @click="remove(index)"></i>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">

                        <b-form @input="dataChanged()">
                          <sf-input :readonly="event.title.length>0 && event.title!='new'" label="Key" :labelCols="2" cstyle="width:10em;" type="text" v-model.trim="event.key"></sf-input>
                          <sf-input v-model="event.eventType" :labelCols="2" cstyle="width:15em;" type="list" :options="eventTypes" label="Type" />
                          <sf-input v-model="event.title" :labelCols="2" cstyle="width:25em;" type="text" label="Title" />
                          <sf-input v-model="event.sequence" :labelCols="2" cstyle="width:8em;" label="Sequence" type="number" />
                        </b-form>

                        <p style="font-size:.8em;color:darkgray;width:50%;margin-top:2em;margin-left:auto;margin-right:auto;">
                          The BASE entry is setup for the calendar entry. The additional options define -what- other features can be
                          attached to the calendar.
                        </p>

                        <h4>Prompts</h4>
                        <b-form @input="dataChanged()">
                          <sf-input v-model="event.prompts.title" :labelCols="2" cstyle="width:15em;" type="text" label="Title" />
                          <sf-input v-model="event.prompts.general" :labelCols="2" cstyle="width:15em;" type="text" label="General" />
                          <sf-input v-model="event.prompts.contact" :labelCols="2" cstyle="width:15em;" type="text" label="Contact" />
                          <sf-input v-model="event.prompts.attendee" :labelCols="2" cstyle="width:15em;" type="text" label="Attendee" />
                          <sf-input v-model="event.prompts.documents" :labelCols="2" cstyle="width:15em;" type="text" label="Documents" />
                        </b-form>


                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div>
                          <h4 class="sub-header">Features</h4>
                          <b-form @input="dataChanged()" style="padding-right:1em;" title="form title">
                            <b-checkbox style="" v-model="event.options.enableClub" @change="event.isClub=event.options.enableClub">Enable Club Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableEvents">Enable Event Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableContacts">Enable Contact Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableOrdering">Enable Order Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableMembers">Enable Attendee/Member Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableBilling">Enable Billing Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableNews">Enable News Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enablePortal">Enable Portal Management</b-checkbox>
                            <b-checkbox style="" v-model="event.options.enableDocuments">Enable Document Management</b-checkbox>
                          </b-form>
                        </div>
                      </div>
                    </div>

                  </b-tab>

                  <!--                  <b-tab title="Options">-->
                  <!--                    <div style="padding:1em;">-->
                  <!--                      <b-form @input="dataChanged()" style="padding-right:1em;" title="form title">-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableClub">Enable Club Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableEvents">Enable Event Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableContacts">Enable Contact Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableMembers">Enable Member Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableBilling">Enable Billing Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enableNews">Enable News Management</b-checkbox>-->
                  <!--                        <b-checkbox style="" v-model="event.options.enablePortal">Enable Portal Management</b-checkbox>-->
                  <!--                      </b-form>-->
                  <!--                    </div>-->
                  <!--                  </b-tab>-->

                  <b-tab title="Event Categories">
                    <option-management-table
                        :keysLowercase="true"
                        :keysNoSpaces="true"
                        :tableData="event.categories"
                        @change="dataChanged()"
                    >
                      <div slot="header">
                        <!--                        <h4>Categories</h4>-->
                        <p class="help-text">The KEY is the database value, the TEXT is human-readable part.</p>
                      </div>
                    </option-management-table>
                  </b-tab>

                  <b-tab v-if="event.options.enableEvents" title="Event">
                  </b-tab>

                  <b-tab v-if="event.options.enableMembers" title="Account Classes">
                    <h4 class="sub-header">Allowed Account Profiles</h4>
                    <b-form-checkbox-group id="accountclasses" name="accountprofiles" v-model="event.accountClasses" @input="dataChanged()">
                      <b-form-checkbox v-for="(a,index) in accountClasses" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'ac'+a.accountClassId" :value="a.accountClassId" :key="index">
                        {{ a.title }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-tab>

                  <b-tab v-if="event.options.enableClub" title="Club">
                  </b-tab>

                  <b-tab title="Assignable Job Codes">
                    <div>
                      <h4>Allowed Job Codes</h4>
                      <div @input="dataChanged()">
                        <div style="margin-left:2em;">
                          <b-form-checkbox-group style="margin-left:0;line-height:.8em;" v-model="event.authorizedJobCodes">
                            <template v-for="(jc,index) in jobCodes">
                              <b-form-checkbox v-if="jc.key" class="col-md-3 col-sm-6" :value="jc.key" :key="index">
                                <span style="text-transform: capitalize;">{{ jc.value }}</span>
                              </b-form-checkbox>
                            </template>
                          </b-form-checkbox-group>
                        </div>
                      </div>
                    </div>
                  </b-tab>


                  <b-tab v-if="event.options.enableOrdering" title="Ordering Categories" style="margin-top:2em;">
                    <b-row @input="dataChanged()">
                      <b-col class="col col-md-3">
                        <h4 style="margin-top:0;margin-bottom:.5em;border-bottom:1px solid black;">Category Selection</h4>
                        <sf-input type="list"  cstyle="width:12em;" :labelCols="0" v-model="categoryType" :options="categoryTypes()" />
                        <b-checkbox-group style="margin-top:1em;" v-model="event.ordering.categories">
                          <b-checkbox style="width:50%;" v-for="(f,catIndex) in categories.filter((c) => {  return c.categoryType === categoryType })" :key="'cat2-'+catIndex.toString()" :value="f.category">
                            {{ f.title }}
                          </b-checkbox>
                        </b-checkbox-group>

                      </b-col>
                      <b-col class="col col-md-8" style="background:#eee;border-radius:1em;padding:1em;">
                        <h4 style="margin-top:0;margin-bottom:1em;">Current Categories</h4>
                        <ul>
                          <li v-for="(c,index) in event.ordering.categories" :key="'cat-'+index.toString()">
                            {{ categoryName( c ) }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab v-if="event.options.enableBilling" title="Billing">

                    <b-row>
                      <b-col class="col col-6">
                        <h4 class="sub-header">Posting Values</h4>
                        <b-form @input="dataChanged()">
                          <sf-input v-model="event.posting.totalClassId" :labelCols="3" cstyle="width:10em;" type="number" label="Total Class" />
                          <sf-input v-model="event.posting.revenueCenter" :labelCols="3" cstyle="width:10em;" type="number" label="Revenue Center" />
                          <sf-input v-model="event.posting.tenderNumber" :labelCols="3" cstyle="width:10em;" type="number" label="Tender Number" />
                          <sf-input v-model="event.posting.glAccount" :labelCols="3" cstyle="width:10em;" type="text" label="GL Account" />
                          <sf-input v-model="event.posting.arType" :labelCols="3" cstyle="width:10em;" type="text" label="AR Type" />
                        </b-form>
                      </b-col>
                      <b-col class="col col-6">
                        <h4>Summary Fields</h4>
                        <b-form @change="dataChanged()">
                          <b-checkbox style="" v-model="event.posting.summaryFields.venue">Enable Venue</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.decorations">Enable Decorations</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.entertainment">Enable Entertainment</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.appetizers">Enable Appetizers</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.food">Enable Food</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.beverage">Enable Beverage</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.liquor">Enable Liquor</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.beer">Enable Beer</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.wine">Enable Wine</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.equipment">Enable Equipment</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.transportation">Enable Transportation</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.delivery">Enable Delivery</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.publicity">Enable Publicity</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.labor">Enable Labor</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.outsideLabor">Enable Outside Labor</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.supplies">Enable Supplies</b-checkbox>
                          <b-checkbox style="" v-model="event.posting.summaryFields.other">Enable Other</b-checkbox>
                        </b-form>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab v-if="event.options.enablePortal" title="Portal">
                  </b-tab>

                  <b-tab v-if="event.options.enableNews" title="News Locations">
                    <h4 class="sub-header">News Locations</h4>
                    <b-form-checkbox-group id="newsSites" name="newsSites" v-model="event.newsLocations" @input="dataChanged()">
                      <b-form-checkbox v-for="(s,sIndex) in newsSites" class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" :id="'ns'+s._id" :value="s.siteId" :key="sIndex">
                        {{ s.title }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-tab>

<!--                  <b-tab v-if="event.options.enableBilling" title="Posting">-->
<!--                    <b-row>-->
<!--                      <b-col class="col-md-12 col-lg-6 col-xl-4">-->
<!--                        <h4>Auto Posting </h4>-->
<!--                        <b-form style="margin-top:1em;" @input="dataChanged()">-->
<!--                          <sf-input type="text" label="Total Class Id" cstyle="width:5em;" v-model="event.posting.totalClassId"></sf-input>-->
<!--                          <sf-input type="number" label="Revenue Center" cstyle="width:5em;" v-model="event.posting.revenueCenter"></sf-input>-->
<!--                          <sf-input type="number" label="Tender Number" cstyle="width:5em;" v-model="event.posting.tenderNumber"></sf-input>-->
<!--                        </b-form>-->

<!--                        <b-form style="margin-top:1em;" @input="dataChanged()">-->
<!--                          <sf-input type="text" label="Reply-to Name" v-model="event.replyUser"></sf-input>-->
<!--                          <sf-input type="text" label="Reply-to Email" v-model="event.replyEmail"></sf-input>-->
<!--                          <sf-input type="text" label="Reply-to Phone" v-model="event.replyPhone"></sf-input>-->
<!--                          &lt;!&ndash;                          <sf-input type="text" label="Reply-to Public" v-model="ticket.replyPublic"></sf-input>&ndash;&gt;-->
<!--                        </b-form>-->
<!--                      </b-col>-->
<!--                      <b-col>-->
<!--                        <h4>Manual Posting </h4>-->
<!--                        <div @input="dataChanged()" >-->
<!--                          <div style="margin-left:2em;">-->
<!--                            <h4>Account Profiles</h4>-->
<!--                            <b-form-checkbox-group id="accountProfiles" name="accountProfiles" v-model="event.posting.allowedAccountClasses">-->
<!--                              <b-form-checkbox v-for="(t,index) in accountClasses" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'ap'+t.accountClassId" :value="t.accountClassId" :key="index">{{t.title}}</b-form-checkbox>-->
<!--                            </b-form-checkbox-group>-->
<!--                            <h4>Total Profiles</h4>-->
<!--                            <b-form-checkbox-group id="totalProfiles" name="totalProfiles" v-model="event.posting.allowedTotalClasses">-->
<!--                              <b-form-checkbox v-for="(t,index) in totals" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'ttl'+t.totalClassId" :value="t.totalClassId" :key="index">{{t.title}}</b-form-checkbox>-->
<!--                            </b-form-checkbox-group>-->
<!--                            <h4>Tender Keys</h4>-->
<!--                            <b-form-checkbox-group id="tenderKeys" name="tenderKeys" v-model="event.posting.allowedTenderNumbers">-->
<!--                              <b-form-checkbox v-for="(t,index) in tenders" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'tnd'+t.tenderNumber" :value="t.tenderNumber" :key="index">{{t.tenderNumber}}:{{t.title}}</b-form-checkbox>-->
<!--                            </b-form-checkbox-group>-->
<!--                            <h4>Revenue Centers</h4>-->
<!--                            <b-form-checkbox-group id="revenueCenter" name="revenueCenter" v-model="event.posting.allowedRevenueCenters">-->
<!--                              <b-form-checkbox v-for="(r,index) in revenueCenters" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'rev'+r.revenueCenterId" :value="r.revenueCenterId" :key="index">{{r.title}}</b-form-checkbox>-->
<!--                            </b-form-checkbox-group>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </b-col>-->
<!--                    </b-row>-->

<!--                  </b-tab>-->

                </b-tabs>
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </b-tab>

          </b-tabs>

        </div>
      </b-tab>



    </b-tabs>


  </div>
</template>

<script>
import AccountService        from "/src/Services/AccountService";
import TenantService         from "/src/Services/TenantService";
import QueryService          from "@/Services/QueryService";
import MenuItemService       from "@/Services/MenuItemService";
import RevenueCenterService  from "@/Services/RevenueCenterService";
import TenderService         from "@/Services/TenderService";
import TotalService          from "@/Services/TotalService";
// import QueryService   from "/src/Services/QueryService";
import Moment                from "moment-timezone";
import Card                  from "/src/components/UIComponents/Cards/Card.vue";
import OptionManagementTable from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
import SfInput               from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable                from "/src/components/UIComponents/Table.vue";
import PageEditHeaderCard    from "../../../components/UIComponents/Cards/PageEditHeaderCard.vue";

export default {
  components: {
    PageEditHeaderCard,
    LTable,
    Card,
    SfInput,
    OptionManagementTable
  },
  data() {
    return {
      saveDisabled    : true,
      isLoading       : false,
      isDataChanged   : false,
      isAdding        : false,
      isEditing       : false,
      small           : true,
      selectedTab     : 1,
      selectedSubTab  : 0,
      selectedEventTab: 0,
      newsSites       : null,
      accountClasses  : null,
      tenders       : [],
      revenueCenters: [],
      eventsConfig    : null,
      jobCodes        : [],
      categories      : [],
      categoryType    : "catering",

      eventTypes: [
        {value: "club", text: "Club"},
        {value: "event", text: "Event"},
        {value: "meeting", text: "Meeting"},
        {value: "transportation", text: "Transportation"},
        {value: "trip", text: "Trip"},
        {value: "catering", text: "Catering"}
      ]

    };
  },
  watch  : {},
  mounted: async function() {
    await Promise.all([
                        this.getNewsSites(),
                        // this.getAccountClasses(),
                        this.getEventsConfig(),
                        this.getJobCodes(),
                        this.getCategories(),
                        this.getSupportRecords(),
                      ]);
  },
  async beforeRouteLeave(to, from, next) {
    if(!this.isDataChanged) {
      return next();
    }
    if(await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async alert(e) {
      await this.$root.alert(e);
    },
    async save() {
      await this.saveEventsConfig();
      this.done();
    },
    async cancel() {
      if(this.isDataChanged) {
        if(!await this.$root.confirmYesNo("Data changed -- You sure?")) {
          return;
        }
      }
      this.$router.go(-1);
    },
    moment(d) {
      return Moment(d);
    },
    categoryName( c ) {

      let cat = _.find( this.categories , { category: c } );

      // console.log("Hunting" , c, cat );

      if( cat && cat.title ) {
        return cat.title;
      }
      return "**" + c;
    },
    dataChanged() {
      this.isDataChanged = true;
    },

    showModal() {
      this.$refs.myModalRef.show()
    },
    hideModal() {
      this.$refs.myModalRef.hide()
    },
    categoryTypes() {
      let cats = [ ...new Set(this.categories.map((c) => { return c.categoryType })) ]
      return cats;
    },
    async getCategories() {
      try {
        let response    = await MenuItemService.getAllMenuItemCategories();
        this.categories = response.data;
        // console.log( "categories" , this.categories );
      } catch(error) {
        console.log(error.message);
      }
    },
    async getJobCodes() {
      var t = this;
      var response;

      if(this.jobCodes.length > 0) {
        return;
      }

      try {
        response = await QueryService.getJobCodes('');

        if(response.data) {
          this.jobCodes = response.data.map(jc => { return {key: jc.jobCode, value: jc.jobCode + " (" + jc.count.toString() + ")"}});
          // console.log( "jobCodes" ,  this.jobCodes );
        } else {
          this.jobCodes = [];
        }

      } catch(err) {
        console.log("getJobCodes error: ", err);
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the Job Codes");
        }
      }
    },
    async addNew() {
      if(!await this.$root.confirmYesNo("Add New")) {
        return;
      }

      let sequence = Math.max(...this.eventsConfig.eventTypes.map((e) => e.sequence)) + 1;

      // console.log("The array", this.eventsConfig.eventTypes.map((e) => e.sequence), sequence);

      let newEvent = {
        title         : "new",
        key           : "",
        category      : "",
        sequence      : sequence,
        categories    : [],
        accountClasses: [],
        prompts       : {},
        options       : {
          enableClub    : false,
          enableEvents  : false,
          enableContacts: false,
          enableMembers : false,
          enableBilling : false,
          enableNews    : false,
          enablePortal  : false,
        }
      }
      this.eventsConfig.eventTypes.push(newEvent);
      this.dataChanged();
    },
    async remove(index) {
      if(!await this.$root.confirmYesNo("Remove " + this.eventsConfig.eventTypes[ index ].title + "?")) {
        return;
      }
      this.dataChanged();
      this.eventsConfig.eventTypes.splice(index, 1);
    },
    sortEventTypes() {
      this.eventsConfig.eventTypes = _.sortBy(this.eventsConfig.eventTypes, (a) => a.sequence);
    },
    async getNewsSites() {
      try {
        let response   = await TenantService.getSites('news');
        this.newsSites = response.data;
      } catch(e) {
        await this.$root.alert(e.message, "error");
      }
    },
    // async getAccountClasses() {
    //   let response        = await AccountService.getAccountClasses();
    //   this.accountClasses = response.data;
    // },
    async getSupportRecords () {
      let t = this;
      let response;
      try {
        response            = await TenderService.getTenders();
        this.tenders        = response.data;
        //console.log( this.tenders );

        response            = await RevenueCenterService.getRevenueCenters();
        this.revenueCenters = response.data;

        response    = await TotalService.getTotals();
        this.totals = response.data;

        response            = await TenantService.getAccountClasses();
        this.accountClasses = response.data;

      } catch( err ) {
        console.log( "getSupportTotals error: ", err );
        if( err.response ) {
          if( err.response.data ) {
            t.$root.errorMessage( "Oops", err.response.data.message );
          }
        } else {
          t.$root.errorMessage( "Oops", "Had an error getting the support totals" );
        }
      }
    },
    async getEventsConfig() {
      var t = this;
      var response;
      try {
        response          = await TenantService.getEventsConfig();
        this.eventsConfig = response.data;

        this.sortEventTypes();

      } catch(err) {
        console.log("getEventsConfig error: ", err);
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the events configuration");
        }
      }
    },

    async saveEventsConfig() {
      let t = this;
      try {
        t.sortEventTypes();
        let response                = await TenantService.saveEventsConfig(this.eventsConfig);
        t.$root.tenant.eventsConfig = response.data;
        this.$root.successMessage("SUCCESS", "Events Config Updated");
        t.isDataChanged = false;
      } catch(err) {
        if(err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if(err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Events Configuration Data");
      }
    }
  }

};
</script>

<style lang="scss">

</style>
