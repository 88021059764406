<template>
  <div>
    <h4 style="margin:0;padding:0;">{{ title }}</h4>
    <p v-if="subTitle" class="small">{{ subTitle }}</p>
    <b-form style="display:inline-flex;margin-bottom:.5em;">
      <b-button @click="addString()" class="btn btn-sm btn-fill btn-warning" style="padding:0 .75em 0 .75em;margin:0 1em 0 .25em;">{{ addPrompt }}</b-button>
      <b-input id="input-add-item" v-model="listInput" @keyup.enter.native="addString()"></b-input>
      <b-input style="display:none;" id="without-this-field-pressing-enter-causes-page-refresh"></b-input>
    </b-form>
    <ul class="no-bullets" :class="(isFixedFont) ? 'fixed-font' : '' ">
      <li v-for="(s,index) in list" :key="index" @click="select(index)">
        <div>
          <div v-if="!isAllowEdit">
            <i class="fa fa-trash show-hand float-left mr-2" style="position:relative;top:3px;" @click="listInput=s;removeString(index)"></i>
            {{ s }}
          </div>
          <div v-if="isAllowEdit" style="display:flex;">
            <i class="fa fa-trash show-hand mr-2" style="position:relative;top:10px;" @click="listInput=s;removeString(index)"></i>
            <b-input v-model="list[index]" :maxLength="maxLength" :id="'Simplelist-'+index" @change="dataChanged()"></b-input>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name      : "simple-list-only",
  components: {
    //bImg
  },
  props     : {
    title              : {type: String},
    subTitle           : {type: String},
    maxLength          : {type: Number, default: 64},
    addPrompt          : {type: String, default: "Add"},
    deletePrompt       : {type: String, default: "Remove"},
    list               : {type: Array, default: () => []},
    confirmDeletePrompt: {type: String, default: "Remove this item?"},
    confirmDelete      : {type: Boolean, default: true},
    noEvents           : {type: Boolean, default: true},
    clearAfterAdd      : {type: Boolean, default: true},
    saveBeforeDelete   : {type: Boolean, default: true},
    isUnique           : {type: Boolean, default: true},
    isAllowBlank       : {type: Boolean, default: false},
    isFixedFont        : {type: Boolean, default: false},
    isAllowEdit        : {type: Boolean, default: false}
  },
  data() {
    return {
      listInput: ""
    }
  },
  mounted() {
    this.listInput = "";
  },
  methods: {
    nop() {},
    dataChanged() {
      this.$emit("change");
    },
    select(i) {
      this.listInput = this.list[ i ];
      this.$emit("select", i);
    },
    async addString() {
      console.log("got", this.listInput);
      if(this.listInput.trim().length == 0) {
        if(!this.isAllowBlank) {
          return;
        }
      }
      if(this.isUnique) {
        if(this.list.indexOf(this.listInput) >= 0) {
          await this.$root.alert("Already in the list", "warning");
          return;
        }
      }
      if(!this.noEvents) {
        this.$emit('add', this.listInput);
      } else {
        this.list.push(this.listInput);
      }
      if(this.clearAfterAdd) {
        this.listInput = "";
      }
      this.dataChanged();
    },
    async removeString(i) {
      if(!await this.$root.confirmYesNo(this.confirmDeletePrompt)) {
        return;
      }
      this.listInput = this.list[ i ];
      if(!this.noEvents) {
        this.$emit('remove', i);
      } else {
        this.list[ i ] = "";
        this.list.splice(i, 1);
      }
      this.dataChanged();
    }
  }
};
</script>
<style scoped>

ul.no-bullets {
  list-style-type : none;
  padding         : 0;
  margin          : 0;
}

ul.fixed-font {
  font-family : monospace;
}

.is-inactive {
  color : indianred;
}

.is-active {
  color : green;
}
</style>
