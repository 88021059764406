<template>
  <div class="list-card-ex-page">
    <slot name="pre-header">
    </slot>
    <div>
      <div style="position:relative;float:right;top:.25em;" class="noprint">
        <span v-if="enableUpload" class="show-hand noprint" style="top:4px;font-size:1.5em;padding-right:.5em;" v-b-popover.hover.top="uploadHelpPrompt" @click="upload()"><i :class="uploadIcon"></i></span>
        <span v-if="enableExport" class="show-hand noprint" style="font-size:1.5em;padding-right:1em;"><i class="fa fa-file-excel-o " v-b-popover.hover.top="'Export this list as CSV'" @click="csvExport()"></i></span>
        <span v-if="enableFieldFilter" class="show-hand noprint" style="font-size:1.5em;padding-right:1em;"><i class="fa fa-gear " v-b-popover.hover.top="'Select Fields'" @click=""></i></span>
      </div>
      <!-- <b-input type="search" :style="(searchFilter) ? 'background:pink;' : ''" style="width:225px;float:right;margin-right:2em;margin-bottom:.25em;border:1px black solid; border-radius:1em;" v-model="searchFilter" :placeholder="searchFilterPrompt"></b-input> -->

      <b-input-group v-if="enableSearch"  class="noprint" style="width:225px;float:right;margin:0 2em .5em 0;">
        <b-form-input id="list-card-ex-input-search" type="text" class="noprint" :style="(itemSearchFilter) ? 'background:pink;' : ''" style="border-radius:1em 0 0 1em;" v-model="itemSearchFilter" :placeholder="searchFilterPrompt" />
        <b-input-group-append>
          <b-input-group-text @click="clearSearchFilter()" class="noprint" style="border-radius:0 1em 1em 0;">
            <i class="fa fa-times " />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>

      <b-button v-if="enableDone" class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="done()">{{ donePrompt }}</b-button>
      <span v-if="enableAdd" class="show-hand" @dragover.prevent="canDrop=true" @dragend="canDrop=false" @dragenter="canDrop=true" @dragleave="canDrop=false" style="position:relative;float:left;top:0;font-size:1.5em;padding-right:.5em;" v-b-popover.hover.top="addHelpPrompt" @click="add()" @drop.prevent="onDrop($event)"><i :class="addIcon"></i></span>

      <slot name="card-title">
        <h3 class="card-title" :class="(canDrop && enableDrop) ? 'can-drop' : ''">{{ title }}
          <span v-if="subTitle" style="margin-left:1em;font-size:.7em;font-weight:bold;color:gray;">{{ subTitle }}</span>
        </h3>
      </slot>

      <slot name="subtitle">
        <p class="card-subtitle">{{ titleHelp }}</p>
      </slot>

      <table :class="tableClass" style="width:100%;">
        <slot name="header" v-bind="columns">
          <thead v-if="enableHeader">
          <slot name="sub-header" v-bind="columns">
          </slot>
          <tr>
            <template v-for="(c,cIndex) in columns">
              <th :key="cIndex" :class="c.thClass" :style="headerStyle(c)" @click="headerItemClick(c)">{{ c.label }}</th>
            </template>
          </tr>
          </thead>
        </slot>
        <tbody>

        <template v-if="items && items.length>0">
          <tr v-for="(item,iIndex) in itemList" :key="iIndex" @click="rowClicked(item)" class="show-hand" :style="trStyle">
            <td v-for="(c,ciIndex) in columns" :key="ciIndex" :class="[c.tdClass, (c.dataType=='currency') ? 'currency-field' : '']" :style="c.tdStyle">
              <slot :name="c.key" v-bind="item">
                <template v-if="c.key=='action'" >
                  <div style="display:inline-flex;">
                    <i class="fa fa-trash show-hand" @click.stop="remove(item)" v-b-popover.hover.right="'Remove this item'"></i>
                    <template v-if="showActiveFlags">
                      <i v-if="item.active" class="ml-2 fa fa-check show-hand" style="color:green;"></i>
                      <i v-if="!item.active" class="ml-2 fa fa-times show-hand" style="color:red;"></i>
                    </template>
                  </div>
                </template>
                <template v-else>
                  {{ getFieldData(c, item, ciIndex, iIndex) }}
                </template>
              </slot>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <td colspan="99"><h4 v-if="noDataPrompt" style="margin:0;padding:0;width:100%;text-align:center;">{{ noDataPrompt }}</h4></td>
          </tr>
        </template>

        </tbody>

        <tfoot v-if="enableTotalRow">
        <tr>
          <td v-for="(field,ciIndex) in columns" :key="ciIndex"  :class="[field.tdFooterClass,(field.dataType=='currency') ? 'currency-field' : '']" :style="field.tdFooterStyle">
            <span v-if="field.isSum">{{ (field.dataType == 'currency') ? USD(totals[ ciIndex ] ) : totals[ ciIndex ] }}</span>
          </td>
        </tr>
        </tfoot>

      </table>

    </div>
    <slot name="post-header">
    </slot>
  </div>
</template>
<script>

import exportLib from "/src/Lib/dataExportLib.js";
// import ExportLib from "@/Lib/dataExportLib";
import Currency  from "currency.js";
// import gravatar  from "gravatar";
import Moment    from "moment-timezone";
import SfInput   from "/src/components/UIComponents/Inputs/SFormInput.vue";

export default {
  name      : 'list-card-ex',
  components: {SfInput},
  props     : {
    addHelpPrompt     : {type: String, default: "Add New"},
    addIcon           : {type: String, default: "fa fa-plus-square"},
    donePrompt        : {type: String, default: "Done"},
    enableAdd         : {type: Boolean, default: false},
    enableDelete      : {type: Boolean, default: false},
    enableDone        : {type: Boolean, default: false},
    enableDrop        : {type: Boolean, default: false},
    enableExport      : {type: Boolean, default: false},
    enableFooter      : {type: Boolean, default: true},
    enableHeader      : {type: Boolean, default: true},
    enableHeaderClick : {type: Boolean, default: false},
    enableSearch      : {type: Boolean, default: true},
    enableShowActive  : {type: Boolean, default: false},
    enableTotalRow    : {type: Boolean, default: true},
    enableUpload      : {type: Boolean, default: true},
    enableFieldFilter : {type: Boolean, default: false},
    fields            : {type: Array, default: () => []},
    itemTotals        : {type: Array, default: () => []},
    items             : {type: Array, default: () => []},
    noDataPrompt      : {type: String, default: "No Data to Display"},
    searchFilterPrompt: {type: String, default: "Filter list"},
    searchHelpPrompt  : {type: String, default: "Filter list"},
    showActiveFlags   : {type: Boolean, default: true},
    subTitle          : {type: String, default: ""},
    tableClass        : {type: String, default: "steelBlueCols"},
    tableStyle        : {type: String, default: "margin-top:.5em;min-height:700px;overflow-x:auto;"},
    trStyle           : {type: String, default: ""},
    thClass           : {type: String, default: ""},
    title             : {type: String, default: "List"},
    titleHelp         : {type: String, default: ""},
    uploadHelpPrompt  : {type: String, default: "Upload List"},
    uploadIcon        : {type: String, default: "fa fa-upload"},
  },
  data() {
    return {
      Currency,
      placeHolderVariable: "",
      canDrop            : false,
      itemSearchFilter   : "",
      itemSearchString   : "",      // wjs: this is temp --
      filteredItemList   : null,
      columns            : [],
      totals             : [],
      sortedField        : "",
      sortDirection      : "asc",
    }
  },
  mounted() {
    this.columns = this.fields.filter((f) => f.visible !== false )
    this.sumList(this.items);
    this.getPerPage();
  },
  updated() {
    // console.log( "listView Updated", this.title);   // well, hooking this "could" be ok but it runs several times AND no indication that the props have been updated.
  },
  computed: {
    itemList() {
      if(this.items.length === 0) {
        return this.items;
      }
      let list;
      if(this.enableSearch && this.items && this.items[0].title || "" ) {
        let search = this.itemSearchFilter.toLowerCase();
        list       = this.items.filter((i) => i.title.toLowerCase().indexOf(search) >= 0);
      } else {
        list = this.items;
      }
      this.sumList(list);
      return list;
    }
  },
  methods : {
    hasFooter() {
      return false;
    },
    USD(value, precision=2, symbol = ' ') {
      if( !value ) {
        value = 0;
      }
      return Currency(value, {precision: precision, symbol: symbol}).format();
    },
    currency(c, precision = 2, symbol = ' ') {
      return Currency(c, {precision: precision, symbol: symbol});
    },
    moment(d) {
      return Moment(d);
    },
    monthName(m) {
      return months[ parseInt(m) - 1 ] || "-";
    },
    savePerPage() {
      localStorage.setItem( "perPage" , this.perPage );
    },
    getPerPage() {
      if( localStorage.getItem( "perPage" )) {
        this.perPage = localStorage.getItem( 'perPage' );
        if( this.perPage <= 0 ) {
          this.perPage = 10;
        }
      }
    },
    headerStyle( item ) {
      let style = "";
      if( item.style ) {
        style = item.style;
      }
      if( item.sortable && style.indexOf("cursor") <  0 ) {
        style = style + " cursor:pointer;";
      }
      return style;
    },
    headerItemClick(item) {
      if(this.enableHeaderClick) {
        this.$emit('header-click', item);
      } else {
        // console.log( "header Item" , item );
        // let's sort
        if( item.sortable ) {
          if(this.sortedField === item.key) {
            this.sortDirection = (this.sortDirection === "asc") ? "desc" : "asc";
          } else {
            this.sortDirection = "desc";
            this.sortedField   = item.key;
          }

          this.items = _.orderBy(this.items, item.key, [this.sortDirection]);
        }
      }
    },
    clearSearchFilter() {
      if(this.itemSearchFilter.length > 0) {  // only if we have something to do.
        // let t = ""; // wjs: 11-Nov-22 - Just setting to "" does NOT clear the visible input field; the data gets gone, but the visible text remains for some reason.
        // this.searchFilter = Object.assign(t);
        this.itemSearchFilter = ""; // this is supposed to work!!
        this.sumList(this.items);
      }
    },
    rowClicked(data) {
      this.$emit("click", data);
    },

    processFormula(formula, data, fieldIndex, itemIndex) {
      if(!formula) {
        return "**";
      }
      let f = formula.replaceAll("v3.", "data.");
      let result = eval(f);
      return result;
    },

    getFieldData(field, data, fieldIndex, itemIndex) {
      let dt = field.dataType;
      if(field.formula && field.formula.length > 0) {
        dt = "formula";
      }
      switch(dt){
        case "currency":
          return this.USD(_.get(data, field.key));
          break;
        case "date":
          return Moment(_.get(data, field.key)).format("MM/DD/YYYY");
          break;
        case "datetime":
          return Moment(_.get(data, field.key)).format("MM/DD/YYYY hh:mm a");
          break;
        case "time":
          return Moment(_.get(data, field.key)).format("hh:mm a");
          break;
        case "formula":
          let result = this.processFormula(field.formula, data, fieldIndex, itemIndex);
          return result;
        case "number":
          return _.get(data, field.key);
          break;
        case "boolean":
          return _.get(data, field.key) ? "Yes" : "No";
          break;
        case "array":
        case "string-array":
          return  _.get(data, field.key).join("\n");
          break;
        case "string":
        case "text":
        default:
          if(field.formatter) {
            return field.formatter(_.get(data, field.key), field, data, fieldIndex, itemIndex);
          }
          return _.get(data, field.key);
      }

    },
    done() {
      this.$emit("done");
    },
    add() {
      this.$emit("add");
    },
    sumList(items) {
      let totals = Array(this.columns.length + 1).fill(0);     // if we don't have a footer, this part isn't required - save the time.
      if(!this.enableFooter) {
        return;
      }
      // let items = this.itemList();
      if(items && this.columns) {
        items.map((item) => {
          this.columns.map((c, ci) => {
            if(c.isSum) {

              let t;
              if(c.formula) {
                t = this.processFormula(c.formula, item, ci, 0).replaceAll(",", "");
              } else {
                t = _.get(item, c.key);
              }

              totals[ ci ] = Currency(totals[ ci ]).add(t).value;
            }
          })
        })
      }
      this.totals = totals;
    },
    sum(field) {
      return USD(0.00).format();
    },
    onDrop(event) {
      if(this.enableDrop) {
        this.$emit("drop", event);
      }
    },
    remove(data) {
      this.$emit("delete", data);
    },
    upload() {
      this.$emit("upload");
    },
    makeCamelCase(s) {
      return s;
    },
    csvExport() {
      exportLib.csvExportAskFilename(this.itemList, this.fields);
    }

  }
}
</script>
<style lang="scss" scoped>

@media print {

  .list-card-ex-page {
    width: 100%;
    overflow:visible;
    color: #000;
    background-color: #fff;
  }

  table {
    //page-break-inside: auto;
    width: 100%;
    overflow: visible;
  }

  .noprint {
    display: none;
  }

}

.can-drop {
  background: black;
  color: white;
}

table.currency {
  text-align:right;
}
.currency-field {
  text-align:right;
}

table.steelBlueCols {
  border: 4px solid #555555;
  background-color: #EEEEEE;
  width: 400px;
  text-align: center;
  border-collapse: collapse;
}

table.steelBlueCols tbody tr:nth-child(even) {
  background-color: #ddd;
}

table.steelBlueCols td, table.steelBlueCols th {
  padding: 5px 10px;
}

table.steelBlueCols tbody td {
  // font-size: 12px;
  // font-weight: bold;
  color: #000000;
}

table.steelBlueCols tbody tr:hover {
  background: #BBB;
}

table.steelBlueCols thead {
  background: #27556a;
  // border-bottom: 10px solid #398AA4;
}

table.steelBlueCols thead th {
  // font-size: 15px;
  font-size: 1.1em;
  // font-weight: bold;
  font-weight: 800;
  color: #FFFFFF;
  // border-left: 2px solid #398AA4;
}

table.steelBlueCols thead th:first-child {
  border-left: none;
}

table.steelBlueCols tfoot {
  // font-size: 13px;
  font-size: 1.1em;
  font-weight: bold;
  color: #FFFFFF;
  background: #27556a;
}

// table.steelBlueCols tfoot td {
//   font-size: 15px;
// }


</style>
