<template>
  <div class="content">
    <div class="container-fluid">
      <div id="overlay"  @click="hideScreenOverlay()"><img class="overlay-image" :src="screenOverlay"></div>
      <div class="row">
        <div class="col-md-7 shadow p-3 mt-1 mb-5 bg-white rounded" v-if="asset">
          <div>
            <div class="float-left">
              <div v-if="isValid( 'status' ) && (!asset.status.isOwnerUpdateOnly || (asset.owner === $root.user.tenantUser ))" style="display:flex;">
                <div v-if="isChanged">
                  <b-button class="btn btn-sm btn-success btn-fill mr-1" @click="saveDone()">
                    Save
                  </b-button>
                  <b-button class="btn btn-sm btn-warning btn-fill mr-2" @click="done()">
                    Cancel
                  </b-button>
                </div>
                <div v-else>
                  <b-button class="btn btn-sm btn-success btn-fill mr-2" @click="done()">
                    Done
                  </b-button>
                </div>
                <span v-if="!isClone && !isChanged" class="show-hand" style="position:relative;float:right;font-size:20px;top:3px;margin-right:.5em;" v-b-popover.hover.top="'Clone this asset'" @click="cloneAsset()"><i  class="fa fa-clone" ></i></span>
              </div>
              <div v-else>
                <b-button class="btn btn-sm btn-success btn-fill mr-2" @click="done()">
                  Done
                  <small>(Owner Locked)</small>
                </b-button>
              </div>
            </div>
            <h4 slot="header">Asset: <strong>{{ asset.title }}</strong>
              <small><span v-if="!isClone"># ({{ asset.assetNumber }})</span><span v-if="isClone" class="shadow" style="font-size:.9em;color:white;background:red;padding:.4em .5em .4em .5em;border-radius:.75em;margin-right:.5em;">CLONE</span> <span v-if="asset && readonly" style="color:red;"> (locked by {{ asset.ownerName || "private" }})</span></small>
            </h4>

          </div>
          <div v-if="isShowImageUpload" style="height:70vh;">
            <image-upload
              :title="imageUploadTitle"
              :folder="uploadFolder"
              prefix=""
              :width="300"
              :height="300"
              @cancel="isShowImageUpload=false"
              @save="imageUploadComplete"
            />
          </div>

          <div v-if="!isShowImageUpload">
            <b-tabs style="margin-top:2em;min-height:700px;" class="tab-hover" active-nav-item-class="selected-tab">
              <b-tab title="General" style="margin-top:2em;">
                <b-form @input="changed()">
                  <!--<sf-input id="assetNumber" :readonly="isLockCategories" type="text" label="Asset Number" v-model="asset.assetNumber" required placeholder="Asset Number" />-->
                  <sf-input id="title" type="text" label="Title" :readonly="readonly" v-model="asset.title" required placeholder="Title"></sf-input>
                  <!-- Make / Model -->
                  <div class="" v-if="hasOption('enableMakeModel')" style="background:#f7f7f7;border-radius:1em;padding:.5em;">
                    <div v-if="!isChanged" class="show-hand" style="position:relative;float:right;" v-b-popover.hover.top="'MAKE/MODEL Image Upload for ALL MODELS'" @click="uploadMakeModelImage">
                      <i class="fa fa-camera" style="line-height:60px;font-size:16px;color:#bbb;"></i>
                    </div>

                    <sf-input id="make1" v-model="asset.make" type="text" cstyle="width:15em;" label="Make" :readonly="readonly" @change="getMakeModelFiles()" />
                    <sf-input id="model1" v-model="asset.model" type="text" cstyle="width:15em;" label="Model" :readonly="readonly" @change="getMakeModelFiles()" />

                  </div>
                  <sf-input v-if="hasOption('enableVehicle')" id="vin" type="text" :readonly="readonly" label="VIN #" v-model="asset.serialNumber" placeholder="Vehicle VIN Number"></sf-input>
                  <sf-input v-else type="text" id="serialNumber" :readonly="readonly" label="Serial #" v-model="asset.serialNumber" placeholder="Serial Number"></sf-input>

                  <sf-input v-if="hasOption('enableBookable') && asset.vehicle.isBookable" v-model="asset.vehicle.maxPassenger" type="number" cstyle="width:15em;" label="# Passenger" />
                  <sf-input type="textarea" :readonly="readonly" id="description" label="Description" :rows=6 wrap="soft" v-model="asset.description" placeholder="Description"></sf-input>
                  <!--                <b-button class="btn btn-sm btn-fill" style="display:flex;margin-top:1em;border-radius:1em;">Upload Image</b-button>-->
                </b-form>
                <hr>
                <span style="cursor:pointer;border:0 solid black;color:white;background:black;border-radius:1em;padding:.5em .75em .5em .75em;" @click="uploadImage">
                  <i class="fa fa-camera" style="" v-b-popover.hover.top="'Upload an image for THIS asset only'" ></i> Upload Image
                </span>
<!--                <div v-if="isShowLargeImage" class="show-hand" style="position:relative;height:300px;overflow:auto;" @click="isShowLargeImage=false">-->
<!--                  <img style="border:1px solid #eee; border-radius:2em;" :src="largeImageUrl" />-->
<!--                </div>-->

                <b-row class="show-hand" style="width:100%;">

                  <template v-for="(image,index) in makeModelFiles" >
                    <b-col class="col-sm-6 col-lg-4" v-if="isImage(image.name)" :key="'model-'+index" style="display:inline-block;padding:5px;" @click="showScreenOverlay(image.uri)">
                      <div v-if="$root.isAdmin()" style="position:relative;top:20px;left:10px;border-radius:50%;background:gray;height:32px;width:32px;" v-b-popover.hover.top="'Remove this MODEL image'" @click="removeImage(image.name , 'model' )">
                        <i class="fa fa-trash" style="position:relative;left:6px;top:4px;color:white;font-size:18px;"></i>
                      </div>
                      <b-img-lazy thumbnail  style="border:3px solid green; border-radius:2em;" :src="image.uri"  />
                    </b-col>
                  </template>

                  <template v-for="(image,index) in assetFiles">
                    <b-col class="col-sm-6 col-lg-4" v-if="isImage(image.name)" :key="'asset-'+index" style="display:inline-block;padding:5px;" @click="showScreenOverlay(image.uri)">
                      <div style="position:relative;top:20px;left:10px;border-radius:50%;background:gray;height:32px;width:32px;" v-b-popover.hover.top="'Remove this image'" @click="removeImage(image.name, 'asset' )">
                        <i class="fa fa-trash" style="position:relative;left:6px;top:4px;color:white;font-size:18px;"></i>
                      </div>
                      <b-img-lazy thumbnail style="border:1px solid #eee; border-radius:2em;" :src="image.uri" />
                    </b-col>
                  </template>

                </b-row>

              </b-tab>

              <!----------------------------- -->

              <b-tab title="Purchase Info" v-if="hasOption('enablePurchasing')" style="margin-top:2em;">
                <b-form @input="changed()">
                  <sf-input v-if="hasOption('enableVehicle')" id="vin" type="text" :readonly="readonly" label="VIN #" v-model="asset.serialNumber" placeholder="Vehicle VIN Number"></sf-input>
                  <sf-input v-else type="text" id="serialNumber" :readonly="readonly" label="Serial #" v-model="asset.serialNumber" placeholder="Serial Number"></sf-input>


                  <sf-input type="text" id="vendor" label="Dealer/Vendor" :readonly="readonly" v-model="asset.purchase.vendor" placeholder="Dealer/Vendor Name"></sf-input>
                  <sf-input type="comma" :isCurrency="true" id="price" label="Price" cstyle="width:10em;" :readonly="readonly" v-model="asset.purchase.price" />
                  
                  <!-- Depreciation Salvage Value -->
                  <sf-input v-if="hasOption('enableDepreciation')" type="comma" :isCurrency="true" id="salvageValue" label="Salvage Value" cstyle="width:10em;" :readonly="readonly" v-model="asset.purchase.salvageValue" />

                  <sf-input type="date" id="purchaseDate" v-model="asset.purchase.purchaseDate" cstyle="width:12em;" label="Date Bought" :readonly="readonly" />
                  <sf-input type="number" id="lifespan" cstyle="width:8em;" :helpText="moment(asset.purchase.purchaseDate).add( asset.purchase.estimatedLife,'month').fromNow()" label="Estimated Life" v-b-popover.hover.top="'Time in Months'" :readonly="readonly" v-model="asset.purchase.estimatedLife" />

                  <sf-input type="date" id="expireDate" v-model="asset.warranty.expireDate" :helpText="moment(asset.warranty.expireDate).fromNow()" cstyle="width:12em;" label="Warranty Expire" :readonly="readonly" />

                  <sf-input v-model="asset.purchase.approvedBy" id="approvedby" type="text" cstyle="width:12em;" label="Purchase Approved By" :readonly="readonly" />

                  <div>
                    <b-button class="btn btn-sm btn-fill btn-success" style="float:right;position:relative;top:5px;" @click="go(asset.purchase.url)">GO</b-button>
                    <sf-input id="url" :readonly="readonly" label="URL" type="url" v-model="asset.purchase.url" placeholder="URL Link" />
                  </div>

                </b-form>
                <sf-input id="purchaseCondition" v-model="asset.purchase.purchaseCondition" type="number"  :max="10" :min="0" v-b-popover.hover.top="'10 = New, 0 = Unusable'" cstyle="width:5em;" label="Purchase Condition" :readonly="readonly" @change="changed()"></sf-input>
                <sf-input id="currentCondition" v-model="asset.purchase.currentCondition" type="number"  :max="10" :min="0" v-b-popover.hover.top="'10 = New, 0 = Unusable; Typically set this after physical inventory'" cstyle="width:5em;" label="Current Condition" :readonly="readonly" @change="changed()"></sf-input>

              </b-tab>


              <b-tab title="Subscription Info" v-if="hasOption('enableSubscription')" style="margin-top:2em;">
                <b-form label-size="lg" label-class="font-weight-bold pt-0" @input="changed()">

                  <b-form-group label-cols-lg="3" label-class="text-sm-right" label="" label-for="autoRenew">
                    <b-form-checkbox id="autoRenew" :readonly="readonly" v-model="asset.subscription.isAutoRenew">
                      Auto Renew
                    </b-form-checkbox>
                  </b-form-group>

                  <sf-input v-model="asset.subscription.renewMonths" type="number" label="Renew Months" cstyle="width:5em;" :readonly="readonly" class="currency"></sf-input>
                  <sf-input v-model="asset.subscription.cost" type="comma" :isCurrency="true" cstyle="width:12em;" label="Cost" :readonly="!canChangeMaintenancePlan" class="currency" />
                  <sf-input v-model="asset.subscription.beginDate" type="date" cstyle="width:12em;" label="Date Started" :readonly="!canChangeMaintenancePlan" />
                  <sf-input v-model="asset.subscription.expireDate" type="date" cstyle="width:12em;" label="Expire Date" :readonly="!canChangeMaintenancePlan" />


                  <!--                <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Date Started" label-for="subBeginDate">-->
                  <!--                  <b-form-input id="subBeginDate" :readonly="readonly" type="date" v-model="asset.subscription.beginDate"></b-form-input>-->
                  <!--                </b-form-group>-->
                  <!--                <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Date Expire" label-for="subExpireDate">-->
                  <!--                  <b-form-input id="subExpireDate" :readonly="readonly" type="date" v-model="asset.subscription.expireDate"></b-form-input>-->
                  <!--                </b-form-group>-->

                  <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Admin Email" label-for="adminEmail">
                    <b-form-input id="adminEmail" :readonly="readonly" type="text" v-model="asset.subscription.adminEmail" placeholder="Admim/Account eMail Address"></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Admin URL" label-for="adminUrl">
                    <b-form-input id="adminUrl" :readonly="readonly" type="text" v-model="asset.subscription.adminUrl" placeholder="Admim/Account URL "></b-form-input>
                    <b-button class="btn btn-sm btn-info btn-fill" @click="go(asset.subscription.adminUrl)">GO</b-button>
                  </b-form-group>

                  <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Notes" label-for="subNotes">
                    <b-form-textarea class="textarea" :readonly="readonly" id="subNotes" :rows="4" wrap="soft" type="text" v-model="asset.subscription.notes" placeholder="Subscription Notes & Details"></b-form-textarea>
                  </b-form-group>

                </b-form>
              </b-tab>

              <!-- ---------------------------------------------------------------------------[ Support Contract ]------------- -->
              <b-tab title="Support Info" v-if="asset.support.active" style="margin-top:2em;">
                <b-form @input="changed()">
                  <sf-input v-model="asset.support.title" type="text" cstyle="" label="Contract Title" helpText="e.g., Standard Plan, Extended Plan, etc" :readonly="readonly" />
                  <sf-input v-model="asset.support.vendor" type="text" cstyle="" label="Vendor" :readonly="readonly" />
                  <sf-input v-model="asset.support.contractNumber" type="text" cstyle="" label="Contract #" :readonly="readonly" />
                  <sf-input v-model="asset.support.contact" type="text" cstyle="" label="Contact/eMail" :readonly="readonly" />
                  <sf-input v-model="asset.support.notes" type="textarea" cstyle="" label="Contract Notes" :readonly="readonly" />
                  <hr />
                  <sf-input v-model="asset.support.effectiveDate" type="date" cstyle="width:12em;" label="Effective" :readonly="readonly" />
                  <sf-input v-model="asset.support.expireDate" type="date" cstyle="width:12em;" label="Expire" :readonly="readonly" />
                  <sf-input v-model="asset.support.renewalDate" type="date" cstyle="width:12em;" label="Renewal" :readonly="readonly" />
                  <sf-input v-model="asset.support.termMonths" type="number" cstyle="width:12em;" label="# Months" :readonly="readonly" />
                  <sf-input v-model="asset.support.cost" type="comma" :isCurrency="true" cstyle="width:12em;" label="Total Cost" :readonly="readonly" />

                </b-form>
              </b-tab>
              <!-- ----------------------------------------------------------------------[ Maintenance ]--------------- -->

              <b-tab v-if="hasOption('enableFiles')" title="Documents" style="margin-top:.5em;">

                <b-checkbox v-model="isShowImages">Show Images</b-checkbox>

                <file-List
                  :title="asset.title"
                  :folder="assetFolder"
                  :files="assetDataFileList"
                  :enableDelete="true"
                  :enableMultipleFileUpload="true"
                  :readonly="readonly"
                  @getFiles="getFiles"
                />

                <file-List v-if="hasOption('enableMakeModel')"
                  title="MODEL Files"
                  :folder="makeModelFolder"
                  :files="makeModelDataFileList"
                  :enableDelete="true"
                  :enableMultipleFileUpload="true"
                  :readonly="readonly"
                  @getFiles="getFiles"
                />

              </b-tab>

              <b-tab title="Maintenance" v-if="hasOption('enableMaintenance')" style="margin-top:.5em;">
                <p style="font-size:.8em;font-style:italic;padding-left:12em;">These maintenance plans are for tasks and service WE manage and control</p>
                <div v-if="!hasMaintenanceRecords" class="show-hand mt-0" @click="addMaintenancePlan">
                  <i class="fa fa-plus-square" v-b-popover.hover.top="'Add Maintenance Plan'"></i> Add Maintenance Plan
                </div>
                <b-tabs v-if="hasMaintenanceRecords" card vertical style="overflow:auto;">
                  <b-tab v-for="(p,index) in asset.maintenance" :title="p.title" :key="index">

                    <b-form-group @input="changed()">
                      <span class="" style="position:relative;float:right;top:5px;">
                        <i class="fa fa-trash show-hand" @click="removeMaintenancePlan(index)" v-b-popover.hover.top="'Remove This Maintenance Plan'"></i>
                        <i class="fa fa-plus-square show-hand" @click="addMaintenancePlan" v-b-popover.hover.top="'Add Maintenance Plan'"></i>
                      </span>
                      <sf-input v-model="p.title" type="text" label="Title" :labelCols=2 placeholder="Maintenance Plan" :readonly="!canChangeMaintenancePlan" />
                      <!--                    <i class="fa fa-plus show-hand" style="position:relative;"></i>-->
                      <sf-input v-model="p.category" type="text" label="Category" :labelCols=2 cstyle="width:12em;" placeholder="Category" :readonly="!canChangeMaintenancePlan" />
                      <sfInput type="textarea" label="Notes" :labelCols=2 :readonly="!canChangeMaintenancePlan" id="maintNotes" :rows=8 wrap="soft" v-model="p.notes" placeholder="Maintenance Plan Notes">
                      </sfInput>
                    </b-form-group>

                    <hr />

                    <sf-input v-model="p.nextAction" type="date" label="Next Action" :labelCols=3 cstyle="width:12em;" :readonly="!canChangeMaintenancePlan" @change="changed()"/>
                    <sf-input v-model="p.recurNumber" type="number" label="Recurring #" :labelCols=3 cstyle="width:12em;" :readonly="!canChangeMaintenancePlan" @change="changed()"/>
                    <sf-input id="assetRecurType" type="list" label="Recur Type" :labelCols=3 cstyle="width:12em;" :disabled="!canChangeMaintenancePlan" v-model="p.recurType" :key="p.recurType" :options="recurTypes" class="" @change="changed()"></sf-input>

                    <b-form-checkbox type="checkbox" v-model="p.options.billable" @change="changed()">Billable</b-form-checkbox>
                    <sf-input v-if="hasOption('enableBilling')" v-model="p.billingCode" type="text" label="Billing Reference" placeholder="Billing Code/GL" :readonly="!canChangeMaintenancePlan" @change="changed()" />

                  </b-tab>
                </b-tabs>

              </b-tab>


              <!--               &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;[ Maintenance ]-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
              <!--                          <b-tab title="Maintenance">-->
              <!--                            <br>-->
              <!--                            &lt;!&ndash;              <sf-input v-model="b.firstName" type="text" label="First Name" placeholder="First Name" />&ndash;&gt;-->
              <!--                            &lt;!&ndash;              <b-form-group label-size="lg" label-class="font-weight-bold pt-0">&ndash;&gt;-->
              <!--                            &lt;!&ndash;                <sf-input v-model="asset.maintenance.title" type="text" label="Description" placeholder="Maintenance Description"/>&ndash;&gt;-->
              <!--                            &lt;!&ndash;              </b-form-group>&ndash;&gt;-->
              <!--                            <div>-->
              <!--                              <div v-if="!hasMaintenanceRecords" style="text-align: center"><br><br>No Maintenance Plans<br>-->
              <!--                                <div class="btn btn-success" @click="addMaintenancePlan()">Add Maintenance Plan</div>-->
              <!--                              </div>-->
              <!--                              <div v-if="hasMaintenanceRecords">-->
              <!--                                <b-table :items="asset.maintenance" :fields="columns" striped hover :config="config" :current-page="currentPage" :per-page="perPage">-->
              <!--                                  &lt;!&ndash;                    <template slot="HEAD_selected" slot-scope="data">&ndash;&gt;-->
              <!--                                  &lt;!&ndash;                      <input type="checkbox" @click.stop v-model="selectAll" @change="toggleSelectAll" />&ndash;&gt;-->
              <!--                                  &lt;!&ndash;                    </template>&ndash;&gt;-->
              <!--                                  &lt;!&ndash;                    <template slot="selected" slot-scope="data">&ndash;&gt;-->
              <!--                                  &lt;!&ndash;                      <input type="checkbox" v-model="data.item.selected" @change="selectRow(data.item)" />Selected {{ data.item._rowVariant }}&ndash;&gt;-->
              <!--                                  &lt;!&ndash;                    </template>&ndash;&gt;-->
              <!--                                </b-table>-->
              <!--                                <b-row>-->
              <!--                                  <b-col md="6" class="my-1">-->
              <!--                                    <b-pagination :total-rows="totalMaintenanceRows" :per-page="perPage" v-model="currentPage" class="my-0"/>-->
              <!--                                  </b-col>-->
              <!--                                  <b-col md="6" class="my-1">-->
              <!--                                    <b-form-group horizontal label="Per page" class="mb-0">-->
              <!--                                      <b-form-select :options="pageOptions" v-model="perPage"/>-->
              <!--                                    </b-form-group>-->
              <!--                                  </b-col>-->
              <!--                                </b-row>-->
              <!--                              </div>-->
              <!--                            </div>-->
              <!--                          </b-tab>-->
              <!--              -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; -->

              <b-tab v-if="hasOption('enableNotes')" title="Notes" style="margin-top:2em;">
                <b-form @input="changed()">
                  <sf-input type="textarea" label="" :labelCols=0 :readonly="readonly" id="notesTextarea" :rows=18 v-model="asset.notes" placeholder="Notes about this item" />
                </b-form>
              </b-tab>

              <b-tab v-if="$root.user.isAdmin" title="Private Notes" style="margin-top:2em;">
                <b-form @input="changed()">
                  <sf-input type="textarea" label="" cstyle="background:#ee99cc;" :labelCols=0 :readonly="readonly" id="privateNotesTextarea" :rows=18 v-model="asset.private.notes" placeholder="Private notes about this item" />
                </b-form>
              </b-tab>
              <br />
            </b-tabs>


            <!--            <div class="text-center">-->
            <!--              <div v-if="isValid( 'status' ) && (!asset.status.isOwnerUpdateOnly || (asset.owner === $root.user.tenantUser ))">-->
            <!--                <b-button class="btn btn-sm btn-success btn-fill float-right" @click="saveDone()">-->
            <!--                  Update-->
            <!--                </b-button>-->
            <!--                <b-button class="btn btn-sm btn-danger btn-fill float-right mr-2" @click="$router.go(-1)">-->
            <!--                  Cancel-->
            <!--                </b-button>-->
            <!--              </div>-->
            <!--              <div v-else>-->
            <!--                <b-button class="btn btn-sm btn-success btn-fill float-right" @click="$router.go(-1)">-->
            <!--                  Done-->
            <!--                  <small>(Owner Locked)</small>-->
            <!--                </b-button>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="clearfix"></div>
          </div>

        </div>
        <div class="col-md-5 shadow p-3 mt-1 mb-5 bg-white rounded" v-if="asset">
          <h4 v-if="!isLockCategories" >
            <span v-b-popover.hover.top="asset._id">Details</span><i v-if="$root.user.isAdmin" class="fa fa-chain-broken show-hand float-right" @click="isLockCategories=!isLockCategories"></i>
          </h4>
          <h4 v-if="isLockCategories">
            Details<i v-if="$root.user.isAdmin" class="fa fa-chain        show-hand float-right" @click="isLockCategories=!isLockCategories"></i>
          </h4>
          <form @input="changed()">
          <sf-input id="assetType" type="list" :labelCols="3" :disabled="isLockCategories" :readonly="readonly" label="Asset Type" v-model="asset.assetType" :options="assetTypeList()"></sf-input>
          <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Category" label-for="assetCategory">
            <b-form-select v-if="isLockCategories" id="assetCategory" :disabled="readonly" v-model="asset.category" :key="asset.category" :options="assetCategories" class=""></b-form-select>
            <b-form-input v-else id=assetCategory1 :readonly="readonly" v-model="asset.category"></b-form-input>
          </b-form-group>
          <b-form-checkbox style="margin-left:8em;" v-model="asset.deprecation.section179" v-b-popover.hover.top="'Does this asset qualify for Section 179 depreciation?'">Section
            179
          </b-form-checkbox>
          <hr />
          <div>
            <!--            <sf-input id="ownerName" type="text" :readonly="readonly" label="Stakeholder" v-model="asset.ownerName" placeholder="Who input this thing?"></sf-input>-->
            <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Assigned To" label-for="assignedTo">
              <b-form-input id="assignedTo" type="text" :readonly="readonly" v-model="asset.assignedTo" placeholder="Who has this thing?"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Location" label-for="location">
              <b-form-input id="location" type="text" :readonly="readonly" v-model="asset.location" placeholder="Where is this thing?"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-class="text-sm-right" label="Department" label-for="department">
              <b-form-input id="department" type="text" :readonly="readonly" v-model="asset.department" placeholder="Who is responsible for this thing?"></b-form-input>
            </b-form-group>
            <sf-input v-if="hasOption('enableInventoryTag')" type="text" :labelCols="3" id="inventory-tag" :readonly="readonly" label="Inventory Tag #"
                      v-b-popover.hover.left="{variant:'primary',content:'The Inventory Tag is NOT the Asset Number, although it defaults to it when created.  Use this field when applying pre-printed asset labels/shields or barcode labels, for example.', title: 'INVENTORY TAG'}"
                      v-model="asset.inventoryTag" placeholder="Inventory Tag Number"></sf-input>
<!--            <b-popover target="inventory-tag" triggers="hover focus">-->
<!--              <template v-slot:title>Content via Slots</template>-->
<!--              Embedding content <span class="text-danger">using slots</span> affords you-->
<!--              <em>greater <strong>control.</strong></em> and basic HTML support.-->
<!--            </b-popover>-->
          </div>
          <hr>
          <div>
          </div>
          <div>
            <b-form-checkbox :disabled="readonly" v-if="asset.owner === $root.user.tenantUser" v-model="asset.status.isOwnerUpdateOnly">Edit Locked (only by me)</b-form-checkbox>
            <b-form-checkbox :disabled="readonly" v-if="hasOption('enableVehicle')" v-model="asset.vehicle.isBookable">Can Book Vehicle</b-form-checkbox>
            <b-form-checkbox :disabled="readonly" v-model="asset.support.active">Has Support Contract</b-form-checkbox>
            <b-form-checkbox v-if="hasOption('enableRoomAsset') || asset.roomId.length>0" :disabled="readonly" v-model="asset.isRoomAsset">Room Asset</b-form-checkbox>
          </div>
          <div>
            <div v-if="asset.isRoomAsset" style="background:lightgray">
              Assigned to Room Number:
              <b-form-input id="roomNumber" :readonly="readonly" v-model="asset.roomId"></b-form-input>
            </div>
          </div>
          <div v-if="hasOption('enableLoanAndBorrow')">
            <b-form-checkbox :disabled="readonly" v-model="asset.status.isOnLoan">On Loan</b-form-checkbox>
            <div v-if="asset.status.isOnLoan" style="background:#eee;padding-left:2em;">
              <span style="font-decoration:italic;color:#888;">To:</span>
              <b-form-input id="isOnLoan" :readonly="readonly" v-model="asset.loanedTo"></b-form-input>
            </div>

          </div>
          <div v-if="hasOption('enableLoanAndBorrow')">
            <b-form-checkbox :disabled="readonly" v-model="asset.status.isBorrowed">Borrowed</b-form-checkbox>
            <div v-if="asset.status.isBorrowed" style="background:#eee;padding-left:2em;">
              From:
              <b-form-input id="isBorrowed" :readonly="readonly" v-model="asset.borrowedFrom"></b-form-input>
            </div>
          </div>
          </form>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssetService from "/src/Services/AssetService";
import FileService  from "/src/Services/FileService";
import Currency     from "currency.js/dist/currency.js";
import Moment       from "moment/moment";
import numeral      from "numeral"; // find the docs here: http://numeraljs.com/
import Card         from "/src/components/UIComponents/Cards/Card.vue";
import ImageUpload  from "/src/components/UIComponents/Cards/ImageUpload.vue";
import SfInput      from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LIB          from "/src/Lib/rootLib.js";
import VueFileUpload   from "vue-upload-component";
import FileUpload from "/src/components/UIComponents/Cards/FileUpload.vue";
import FileList from "/src/components/UIComponents/Cards/FileList.vue";

var assetId = null;

export default {

  Name      : "AssetEdit",
  props     : {
    assetId
  },
  components: {
    //LTable,
    Card,
    SfInput,
    ImageUpload,
    VueFileUpload,
    FileUpload,
    FileList
  },
  data() {
    return {
      LIB,
      isChanged: false,
      isClone: false,
      isShowImages: false,
      canChangeMaintenancePlan: true,
      textRows                : 6,
      isLockCategories        : true,
      screenOverlay: null,
      assetLoaded             : false,
      title                   : "undefined",
      enableUpload            : false,
      saveDisabled            : false,
      assetConfig             : null,
      assetCategories         : [],
      assetTypes              : [],
      makeModelFiles          : [],
      // files                   : [],
      assetFiles              : null,
      fileColumns             : [
        "actions",
        // { key: "contentSettings.contentType", label: "Type", sortable: true },
        {key: "name", label: "Filename", sortable: true},
        {key: "contentLength", label: "Size", sortable: true},
        {key: "lastModified", label: "Date Added", sortable: true}
      ],
      multiple                : true,
      directory               : false,
      drop                    : true,
      dropDirectory           : false,
      postAction              : '/file',
      selectAll               : false,
      fileRefresh             : false,
      imageUploadTitle        : "",
      uploadFolder             : "",
      isShowImageUpload            : false,
      isShowFileUpload: false,
      isShowLargeImage        : false,
      largeImageUrl           : "",
      recurTypes              : [
        "day",
        "week",
        "month",
        "quarter",
        "year"
      ],
      asset                   : null

    };
  },
  computed  : {
    totalSize() {
      if(this.assetFiles) {
        let size = this.assetFiles.reduce((a, b) => { return parseInt(a) + parseInt(b[ "contentLength" ]) }, 0)
        return size;
      }
      return 0;
    },
    assetFolder() {
      return 'assets/' + this.asset._id.toString();
    },
    makeModelFolder() {
      if( !this.asset.make || !this.asset.model) {
        return 'make-model/undefined';
      }
      return 'make-model/' + LIB.cleanName(this.asset.make.toLowerCase()) + "/" + LIB.cleanName(this.asset.model.toLowerCase());
    },
    hasMaintenanceRecords() {
      if(this.totalMaintenanceRows > 0) {
        return this.asset.maintenance.length > 0;
      } else {
        return false;
      }
    },
    totalMaintenanceRows() {
      if(!this.asset.maintenance) {
        return 0;
      }

      return this.asset.maintenance.length;
    },
    assetDataFileList() {
      if(!this.assetFiles) {
        return null;
      }
      if( this.isShowImages ) {
        return this.assetFiles;
      }
      return this.assetFiles.filter((f) => {return !this.isImage(f.name)});
    },
    makeModelDataFileList() {
      if(!this.makeModelFiles) {
        return null;
      }
      if( this.isShowImages ) {
        return this.makeModelFiles;
      }
      return this.makeModelFiles.filter((f) => {return !this.isImage(f.name)});
    },
    readonly() {
      let t = this;
      if(t.asset.assetNumber) {
        return (t.asset.status.isOwnerUpdateOnly && (t.asset.owner !== t.$root.user.tenantUser));
      } else {
        return false;
      }
    }
  }
  ,
  async created() {
    let i;

    try {
      await this.getAsset(this.assetId),
        await Promise.all([
                            // this.getCategories(),
                            this.getAssetConfig()
                          ]);
      this.getCategories();
    } catch(err) {
      console.error("AssetEdit:mounted() ", err);
      if(err.response) {
        if(err.response.data) {
          this.$root.errorMessage("Oops", err.response.data.message);
        }
      } else {
        this.$root.errorMessage("Oops", "Had an error getting initial settings");
      }
      return;
    }

  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods   : {
    changed()  {
      this.isChanged = true;
    },
    async cloneAsset() {
      if( this.isClone ) {    // already cloned
        return;
      }

      if( ! await this.$root.confirm( "Clone this asset?" )) {
        return;
      }

      this.isClone = true;
      this.changed();
      this.asset.serialNumber = "";
      this.asset.inventoryTag = "";
      this.asset.assetNumber = "";
      delete this.asset._id;
    },
    formatMoney(value, event) {
      return this.currency(value);
    },
    showScreenOverlay( image ) {
      this.screenOverlay = image;
      document.getElementById("overlay").style.display = "block";
    },
    hideScreenOverlay() {
      document.getElementById("overlay").style.display = "none";
    },
    isImage(name) {
      if(name) {
        let ext = name.substring(name.lastIndexOf(".") + 1, 256).toLowerCase();
        // jpeg|jpe|jpg|gif|png|webp
        if([ "jpg", "jpeg", "png", "bmp", "gif", "webp" ].indexOf(ext) >= 0) {
          return true;
        }
      }
      return false;
    },
    selectRow(item) {
      if(item.selected) {
        item._rowVariant = "info";
      } else {
        item._rowVariant = "default";
        if(this.selectAll) {
          this.selectAll = false;
        }
      }
    },
    hasOption(optionName) {
      if(this.assetConfig) {
        let a = _.find(this.assetConfig.assetTypes, {value: this.asset.assetType});
        if(a) {
          if(a.options.hasOwnProperty(optionName)) {
            return a.options[ optionName ];
          }
        }
      }
      return false;
    },
    assetTypeList() {
      return this.assetTypes.map((a) => { return {text: a.text, value: a.value}})
    },
    showLargeImage(url) {
      if(this.isShowLargeImage && url === this.largeImageUrl) {
        this.isShowLargeImage = false;
        return;
      }
      this.largeImageUrl    = url;
      this.isShowLargeImage = true;
    },
    uploadImage() {
      // alert( "Images Not Enabled" );
      this.imageUploadTitle = "ASSET Image Upload";
      this.uploadFolder      = 'assets/' + this.asset._id;
      this.isShowImageUpload     = true;
    },
    // enableFileUpload() {
    //   this.imageUploadTitle = "ASSET File Upload";
    //   this.enableUpload = true;
    //   this.uploadFolder = "assets/" + this.asset._id;
    // },
    uploadFileDone() {
      this.getFiles();
      this.enableUpload = false;
    },
    async uploadMakeModelImage() {
      if(!this.asset.make || this.asset.make.length === 0) {
        await this.$root.alert("MAKE cannot be blank");
        return
      }

      if(!this.asset.model || this.asset.model.length === 0) {
        await this.$root.alert("MODEL cannot be blank");
        return
      }

      this.imageUploadTitle = `${this.asset.make}/${this.asset.model} MODEL Image Upload`;
      this.uploadFolder      = this.makeModelFolder; // 'make-model/' + LIB.cleanName(this.asset.make.toLowerCase()) + "/" + LIB.cleanName(this.asset.model.toLowerCase());
      this.isShowImageUpload= true;
    },
    // async uploadMakeModelFile() {
    //   if(!this.asset.make || this.asset.make.length === 0) {
    //     await this.$root.alert("MAKE cannot be blank");
    //     return
    //   }
    //
    //   if(!this.asset.model || this.asset.model.length === 0) {
    //     await this.$root.alert("MODEL cannot be blank");
    //     return
    //   }
    //
    //   this.imageUploadTitle = `${this.asset.make}/${this.asset.model} File Upload`;
    //   this.uploadFolder      = this.makeModelFolder; // 'make-model/' + LIB.cleanName(this.asset.make.toLowerCase()) + "/" + LIB.cleanName(this.asset.model.toLowerCase());
    //   this.isShowFileUpload = true;
    // },
    uploadMakeModelFileDone() {
      this.getFiles();
      this.isShowFileUpload = false;
    },
    toggleSelectAll() {
      if(this.selectAll) {
        for(var i = 0; i < this.totalMaintenaceRows; i++){
          var updatingItem         = this.asset.maintenance[ i ];
          updatingItem.selected    = true;
          updatingItem._rowVariant = "info";
          this.$set(this.asset.maintenance, i, updatingItem);
        }
      } else {
        for(var i = 0; i < this.totalMaintenaceRows; i++){
          var updatingItem         = this.asset.maintenance[ i ];
          updatingItem.selected    = false;
          updatingItem._rowVariant = "default";
          this.$set(this.asset.maintenance, i, updatingItem);
        }
      }
    },
    async removeMaintenancePlan(i) {
      let t = this;
      if(await t.$root.confirm("Remove " + t.asset.maintenance[ i ].title + "?")) {
        t.asset.maintenance.splice(i, 1);
        await t.updateAsset();
        await t.getAsset(t.asset._id);
      }
    },
    async addMaintenancePlan() {
      let t = this;
      if(!await t.$root.confirm("Add New Maintenance Plan?")) {
        return;
      }
      this.asset.maintenance.push({title: "new plan", category: "", notes: "", tag: "", recurNumber: 1, recurType: "year", "options.billable": false, "billingCode": ""})
      await t.updateAsset();
      await t.getAsset(t.asset._id);
    },
    currency(c) {
      return Currency(c);
    },
    numeral(x) {
      return numeral(x);
    },
    done() {
      this.$router.go(-1); // FIXME: update this to ensure we are *NOT* moving back to login and acording to the webs, this is NOT implmented
      // h.router.history.current.name !== 'Login' ? this.$router.go(-1) : this.$router.push('/app/overview');
    },
    isValid(e) {
      return _.has(this.asset, e);
    },
    moment(v) {
      return Moment(v);
    },
    asMoney(v, event) {
      v.srcElement.value = Currency(v.srcElement.value).format();
    },
    go(url) {
      if(url) {
        if(!url.toLowerCase().startsWith("http")) {
          url = "http://" + url;
        }
        window.open(url);
      }
    },
    async getAsset(id) {
      let t = this;
      let response;
      try {

        t.assetLoaded = false;
        response      = await AssetService.getAsset(id);
        t.asset       = response.data;

        t.getFiles();

        t.assetLoaded = true;
      } catch(err) {
        if(err) {
          console.error(err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
    },
    async getFiles() {
      let t = this;
      Promise.all([
                    t.getMakeModelFiles(),
                    t.getAssetFiles()
                  ]);
    },
    async getAssetFiles() {
      try {
        this.assetFiles     = null;
        let response        = await FileService.getFiles("assets/" + this.asset._id.toString());
        this.assetFiles     = response.data.entries;
        this.downloadLink   = response.data.downloadLink;
        // this.assetFileNames = this.assetFiles.map((f) => { return f.name.split('/')[ 1 ] });

        // if(this.assetFiles) {
        //   this.asset.images = this.assetFiles.map((i) => { return {url: i.uri, name: i.name}});
        // }

        this.fileRefresh = false;
      } catch(error) {
        console.log("getAssetFiles() Error", error);
        await this.$root.alert(error.message + " Your Files May Not Be Present - Please Call Support");
        this.assetFiles     = [];
        this.fileRefresh    = true;
        this.$root.errorMessage("Oops", error.response.data.message || error.message);
      }
    },
    async getMakeModelFiles() {
      let make = this.asset.make;
      let model = this.asset.model;

      if(!make) {
        this.makeModelFiles = [];
        return;
      }

      if(!model) {
        this.makeModelFiles = [];
        return;
      }

      try {
        let folder          = this.makeModelFolder; // 'make-model/' + LIB.cleanName(make.toLowerCase()) + "/" + LIB.cleanName(model.toLowerCase());
        let response        = await FileService.getFiles(folder);
        this.makeModelFiles = response.data.entries; // .data.entries.map((i) => { return {url: i.uri, name: i.name}});
      } catch( error ) {
        this.makeModelFiles = [];
        console.log( error.message );
      }

    },
    async removeAssetFile(filename, assetType ) {
      this.isShowLargeImage = false;
      let n                 = filename.substring(filename.lastIndexOf('/') + 1, 255);
      if(!await this.$root.confirm(`Remove ${n}?`)) {
        return;
      }
      if( assetType === 'global' ) {
        if( ! await this.$root.confirm( "This is a GLOBAL file, are you sure?" )) {
          return;
        }
      }
      try {
        await FileService.deleteFile(null, filename);
        this.$root.successMessage("Success", "Deleted Successfully");
        this.getFiles();
      } catch(error) {
        console.log(error);
        this.$root.errorMessage("Oops", error.message);
      }
    },
    async openLinkInNewWindow(file) {
      let uri;

      if( typeof file === 'object' ) {
        if( file.hasOwnProperty("uri" )) {
          uri = file.uri;
        } else if(  file.hasOwnProperty( "url" )) {
          uri = file.url;
        }
      } else {
        uri = file;     // we can pass n a url too.
      }

      if( ! uri ) {   // didn't find one in the passed object.
        return;
      }

      let win = window.open(uri, "_blank" );
      win.focus();

    },
    async removeImage(filename, imageType) {
      this.isShowLargeImage = false;
      let fName             = filename.substring(filename.lastIndexOf("/") + 1, 256);
      if(!await this.$root.confirm("Remove " + fName + "?")) {
        return
      }
      if(imageType === "model") {
        if(!await this.$root.confirm("This is a GLOBAL MODEL Image, you sure?")) {
          return
        }
      }
      try {

        await FileService.deleteFile(null, filename);
        this.getFiles();
      } catch(error) {
        console.log(error.message);
        await this.$root.confirm(error.message, "danger");
      }
    },
    imageUploadComplete() {
      // this.getFiles( this.asset._id );
      // this.getMakeModelFiles( this.asset.make , this.asset.model );
      this.getFiles();
      // Promise.all([
      //               this.getMakeModelFiles(),
      //               this.getAssetFiles()
      //             ]);
      this.isShowImageUpload = false;
    },
    async getCategories() {
      let t = this;
      if(!t.assetConfig) {
        return null;
      }
      let a = _.find(t.assetConfig.assetTypes, {value: t.asset.assetType});
      if(a) {
        t.assetCategories = a.categories;
      } else {
        t.assetCategories = [ 'undefined' ];
      }
    },
    async getAssetConfig() {
      try {
        if(this.$root.tenant.assetTypes) {
          this.assetConfig = this.$root.tenant.assetConfig;
          this.assetTypes  = this.$root.tenant.assetConfig.assetTypes;
          return;
        }
        let response     = await AssetService.getConfig();
        this.assetConfig = response.data;
        this.assetTypes  = this.assetConfig.assetTypes;
        // this.$root.tenant.assetTypes = this.assetTypes;
      } catch(error) {
        console.log(error);
      }
    },

    async saveDone() {
      let t        = this;
      let response = await this.updateAsset();
      if(response) {
        t.$root.successMessage("success", "Asset Updated");
        if(!t.isLockCategories) {
          delete t.$root.tenant.assetCategories;
        }
        t.$router.go(-1);
      } else {
        await t.$root.confirm("OOPS - UPDATE FAILED - Please try again", "warning" );
      }
    },
    async updateAsset() {
      let t        = this;
      let response = true;
      try {

        if( t.isClone ) {
          response = await AssetService.addAsset("", t.asset);
          t.asset = response.data;
          t.isChanged = false;
          t.isClone = false
          t.$root.tenant.assets.push( t.asset );
        } else {
          await AssetService.updateAsset(t.asset._id, t.asset);
          t.isChanged = false;
          let x = _.findIndex(t.$root.tenant.assets, {_id: t.asset._id}); // (a) => {return a._id === t.asset._id} );
          if(x >= 0) {
            t.$root.tenant.assets[ x ] = t.asset;
          }
        }

      } catch(err) {
        response = false;
        if(err.response) { // note:  05-Dec-18 wjs I went through some digging and this seems to be a good method to pickup MongoDB thrown errors --
          console.log(err.response);
          if(_.has(err.response, "data.message")) {
            await t.$root.confirm( err.response.data.message, "warning");
          } else {
            await t.$root.confirm(err.response.data.errmsg, "warning");
          }
        } else {
          await t.$root.confrim( "Unable to update the record", "warning");
        }
      }
      return response;
    }
  }
};

</script>
<style lang="scss">

input.currency {
  text-align: right;
  padding-right: 15px;
}

.textarea {
  height: 100%;
}


  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.95); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    align:middle;
    v-align:middle;
    text-align:center;

    transition: 2s ease-in-out;

    .overlay-image {
      max-width: 100%;
      max-height: 100%;
      min-width:600px;
      height: auto;
      width: auto;

      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding:1em;
      background:white;
      border-radius:1em;

      webkit-box-shadow: 2px 5px 16px 0px #fefefe, 5px 5px 15px 5px rgba(252,252,252,0);
      box-shadow: 2px 5px 16px 0px #fefefe, 5px 5px 15px 5px rgba(252,252,252,0);

    }
  }

</style>
