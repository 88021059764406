<template>
    <div>
      <i class="fa fa-plus-square show-hand float-left" style="font-size:1.5em;margin-right:.5em;" v-b-popover.hover.right="'Add New'" @click="makeNewTender()"></i>
      <h4>Tendering Keys</h4>
      <div>
        <hr>

        <b-table striped hover head-variant="dark" stacked="md" sort-by="tenderNumber" :items="tenders" :fields="columns" @row-clicked="editTender($event)" >

          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand" v-b-popover.hover.right="'Remove Record'" @click="removeTender(row.item._id)"></i>
          </template>
          <template v-slot:cell(tenderNumber)="row">
            <div class="show-hand" >{{row.item.tenderNumber}}</div>
          </template>
          <template v-slot:cell(title)="row">
            <div class="show-hand" >{{row.item.title}}</div>
          </template>

        </b-table>
          <b-modal v-model="isAdding"
              :title="modalTitle"
              size="lg"
              header-bg-variant="info"
              header-text-variant="light"
              footer-bg-variant="info"
              footer-text-variant="light">
            <b-container fluid>
              <b-form-group horizontal breakpoint="lg" label="Tenders" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Number" label-for="tenderNumber">
                    <b-form-input id="tenderNumber" :readonly="isEditing" type="text" style="width:8em;" v-model="tender.tenderNumber" required placeholder="Tender Number"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Title" label-for="title">
                    <b-form-input id="title" type="text" v-model="tender.title" required placeholder="Title"></b-form-input>
                  </b-form-group>
                </b-form-group>
            </b-container>
            <div slot="modal-footer" class="w-100 mt-3">
              <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addTender( tender );">
                Add Tender
              </b-btn>
              <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="updateTender( tender );">
                Update Tender
              </b-btn>
              <b-btn size="sm" class="float-right btn-fill mr-2" variant="danger" @click="isAdding=false">
                Cancel
              </b-btn>
          </div>
        </b-modal>

        <hr>
        <b-button class = "btn btn-success btn-fill float-right" @click="$router.go(-1)">
          done
        </b-button>
        <!-- <b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
          Cancel
        </b-button> -->
      </div>
    </div>
</template>

<script>
import LTable from "/src/components/UIComponents/Table.vue";
import TenderService from "/src/Services/TenderService";
// import moment from "moment-timezone";
import Card from "/src/components/UIComponents/Cards/Card.vue";

export default {
  components: {
    LTable,
    Card
  },
  data() {
    return {
      saveDisabled  : true,
      changeCount   : 0,
      modalTitle    : "Adding New Interface",
      isAdding      : false,
      isEditing     : false,
      tender : {tenderNumber: 0 ,title: ""},
      tenders: [],
      columns       : [
        "actions",
        { key: "tenderNumber" , sortable: true},
        { key: "title", sortable: true }
      ]
    };
  },
  watch: {
    tenders: {
      handler(old) {
        this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
        if (this.changeCount < 2) {
          return;
        }
        this.saveDisabled = false;
      }
    }
  },
  methods: {
    makeNewTender() {

      let tender = {}

      tender.tenderNumber= this.tender.tenderNumber;
      tender.title           = this.tender.title;
      this.tender            = tender;

      this.isAdding   = true;
      this.isEditing  = false;
      this.modalTitle = "Adding Tender";

    },
    async addTender() {
      let t = this;
      let response;

      this.isEditing = false;
      this.isAdding  = false;

      try {
        response = await TenderService.addTender( this.tender );
        this.getTenders();
      }catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Tender");
      }
    },
    editTender( tender ) {

      let t = this;
      t.tender = tender;

      t.modalTitle = "Editing Tender";
      t.isAdding   = true;
      t.isEditing  = true;
    },
    async getTenders() {
      let t = this;
      let response;

      try {
        response            = await TenderService.getTenders();
        this.tenders = response.data;
      } catch (err) {
        console.log("getTenders error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tender");
        }
        return;
      }
    },
    async updateTender( tender ) {
      let t = this;
      let response;

      try {
        response = await TenderService.updateTender( tender );
        this.getTenders();
        this.isEditing = false;
        this.isAdding  = false;
      }catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Tender");
      }
    },
    async removeTender( id ) {
      let t            = this;
      let tenderName   = "";
      let tenderNumber = 0;
      let response;

      t.tenders.forEach(function(item, index) {
        if (item._id.toString() === id.toString()) {
            tenderName   = item.title;
            tenderNumber = item.tenderNumber;
        }
      });

      if( ! confirm( "Removing " + tenderNumber.toString() + " :: " + tenderName +" ?")){
        return;
      };

      try {
        response = await TenderService.removeTender( id );
      } catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Remove Tender");
      }
      t.getTenders();
    }
  },
  mounted: function() {
    this.getTenders();
  }
};
</script>

<style lang="scss">
</style>
