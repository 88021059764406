<template>
  <b-card class="card shadow page-body">
    <div class="row" v-if="postingAccount && postingTotal">
      <div class="col">
        <h4>
          <b-button class="btn btn-sm btn-fill btn-warning" style="float:left;position:relative;font-size:.5em;bottom:2px;margin-right:1em;" @click="cancel">Cancel</b-button>
          Manual Transaction
        </h4>
        <b-form v-if="revenueCenters">
          <sf-input v-model="transaction.totalClassId" type="list" :options="totalOptions" label="Transaction Profile" />
          <sf-input v-model="transaction.isPayment" type="list" :options="logicalOptions()" label="Transaction Type" />
          <sf-input v-model="transaction.inputDate" type="date" label="Date" />
          <sf-input v-model="transaction.inputTime" type="time" label="Time" />
          <sf-input v-model="transaction.revenueCenterId" type="list" :options="revenueCenterOptions" label="Revenue Center" />
          <sf-input v-model="transaction.tenderNumber" type="list" :options="tenderOptions" label="Tender Key" />
          <sf-input v-model="transaction.title" type="text" label="Legend" />
          <sf-input v-model="transaction.total" type="currency" label="Total" />
          <sf-input v-model="transaction.reference" type="textarea" :lines=4 label="Reference" />
        </b-form>
      </div>
      <div class="col">
        <div style="font-family:'Lucida Console', Monaco, monospace;white-space:pre;padding-left:4em;font-size:1vw">
          {{makeReceipt()}}
        </div>
        <span style="color:red;font-weight:700;">{{errorMessage}}</span>
        <b-button class="btn btn-sm btn-fill btn-success" @click="postTransaction()" :disabled="!isOkToPost()">Post Transaction</b-button>
      </div>
    </div>
  </b-card>
</template>
<script>

  import PosService           from "/src/Services/PosService";
  import RevenueCenterService from "/src/Services/RevenueCenterService";
  import TenderService        from "/src/Services/TenderService";
  import Currency             from "currency.js";
  import Moment               from "moment";
  import SfInput              from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import Card                 from './Card.vue';

  export default {
    name      : 'manual-transaction-form',
    props     : {
      postingAccount: {type: Object, default: () => {}},
      postingTotal  : {type: Object, default: () => {}}
    },
    components: {
      Card,
      SfInput
    },
    computed  : {
      revenueCenterOptions() {
        let r = this.revenueCenters.map(r => { return {text: r.revenueCenterId.toString() + " : " + r.title, value: r.revenueCenterId}});
        // console.log("options", r);
        return r;
      },
      tenderOptions() {
        let t = this.tenders.map(r => { return {text: r.tenderNumber.toString() + " : " + r.title, value: r.tenderNumber}});
        // console.log("options", t);
        return t;
      },
      totalOptions() {
        let t = this.postingAccount.totalClass.map(r => { return {text: r.totalClassId.toString() + " : " + r.title, value: r.totalClassId}});
        // console.log("totals", t);
        return t;
      }
    },
    data() {
      return {
        errorMessage   : "",
        revenueCenters : [],
        tenders        : [],
        transaction    : {
          accountNumber    : this.postingAccount.accountNumber,
          badgeNumber      : this.postingAccount.badge[ 0 ].badgeNumber,
          totalClassId     : this.postingTotal.totalClassId,
          isPayment        : 'false',
          title            : "Transaction",
          revenueCenterId  : 0,
          tenderNumber     : 0,
          inputDate        : this.moment().format("YYYY-MM-DD"),
          inputTime        : this.moment().format("HH:mm").toString(),
          transactionDate  : null,
          checkNumber      : this.moment().format("YYMMDDHHmmss").toString(),
          transactionNumber: this.moment().format("YYMMDDHHmmss").toString(),
          employeeNumber   : 0,
          employeeName     : this.$root.user.alias,
          workstationNumber: this.$root.user.currentIP.split(".")[ 3 ],
          cashierNumber    : 0,
          reference        : "",
          total            : 0.00,
          tax              : [ 0, 0, 0, 0 ],
          previousPay      : 0.00,
          taxable          : 0,
          nonTaxable       : 0,
          discount         : 0,
          serviceCharge    : 0
        },
        postingTemplate: {        // posting is for the API to manage a posting message
          accountNumber: "",
          badgeNumber  : "",
          revenueCenter: 0,
          tenderNumber : 0,
          tenderAmount : 0,
          tenderQty    : 1,
          totalClassId : 0,
          title        : 'sale',
          isPayment    : false,
          isMealPlan   : false,
          transactionDate: null,

          servingPeriod      : 0,    // Not used currently
          transactionNumber  : "",   // Our "next" transaction number
          checkNumber        : "",
          coverCount         : 1,    // Default to 1 since "most" transactions are for one person.
          transactionEmployee: 0,    // Who is ringing this transaction
          checkEmployee      : 0,    // for now, same as transactionEmployee
          terminalNumber     : 0,
          source             : "iCare Manual",

          comment         : "",
          transactionTotal: 0,   // The AMOUNT of this transactions; all discounts/service charges applied.  transPost will ensure this balances with all the other totals

          salesItemizer: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],   // 16 sales itemizers
          taxItemizer  : [ 0, 0, 0, 0 ],
          discount     : 0,       // discounts are represented as a negative value
          serviceCharge: 0,       // service charges are represented as positive values

          previousPay: 0,         // when split tender, this will be the amount of "all previous" tenders.

          items  : [ // include items with the LAST payment that closes the transaction
            //{
            //  itemType    : { type: String, default: "item", enum: ["item", "tender", "discount", "service", "tax", "reference"] },
            //  itemNumber  : Number,   // the user-readable number
            //  title       : String,   // menu item name
            //  qty         : Number,
            //  price       : Number,
            //  taxable     : Boolean,
            //  sequence    : Number,
            //  reference   : String,
            //}
          ],
          receipt: []

        },
      }
    },
    async created() {
      await Promise.all([
                          this.getTenders(),
                          this.getRevenueCenters()
                        ])
    },
    methods   : {
      gs(x) {
        return JSON.stringify(x, null, 2);
      },
      cancel() {
        this.$emit("cancel");
      },
      moment(d) {
        return Moment(d);
      },
      showErrorMessage(message) {
        this.errorMessage = message;
      },
      makeReceipt() {
        let paymentMessage
        if(this.transaction.isPayment === 'true') {
          paymentMessage = '(payment)';
        } else {
          paymentMessage = '(charge)';
        }
        // console.log("isPayment", paymentMessage, this.transaction.isPayment, typeof this.transaction.isPayment);
        let r = `
${this.$root.tenant.tenantName}
==================================================
  Emp: ${this.transaction.employeeName}
  Chk: ${this.transaction.checkNumber}
 Date: ${this.moment(this.transaction.inputDate + " " + this.transaction.inputTime).tz(this.$root.TZ).format("LLLL").toString()}
==================================================

   Revenue Center
     ${this.revenueCenter(this.transaction.revenueCenterId)}

   Tender
     ${this.tender(this.transaction.tenderNumber)}

    ${this.transaction.title} ${Currency(this.transaction.total)}
    ${paymentMessage}

==================================================
${this.transaction.reference}
        `;

        return r;
      },
      isOkToPost() {
        if( this.transaction.revenueCenterId === 0 || this.transaction.tenderNumber === 0 ) {
          return false;
        }
        return true;
      },
      buildPostingItem() {

        let trans = this.transaction       // the FULL transaction record

        let item = Object.assign({}, this.postingTemplate)    // let's make a NEW object that we can POST to the api

        item.accountNumber       = trans.accountNumber
        item.badgeNumber         = trans.badgeNumber
        item.revenueCenter       = trans.revenueCenterId
        item.tenderNumber        = trans.tenderNumber
        item.tenderAmount        = trans.total
        item.tenderQty           = 1
        item.totalClassId        = trans.totalClassId
        item.title               = trans.title
        item.transactionNumber   = trans.transactionNumber
        item.checkNumber         = trans.checkNumber
        item.transactionEmployee = trans.employeeNumber
        // item.checkEmployee       = trans.employeeNumber
        // item.servingEmployee     = trans.employeeName

        item.transactionDate = Moment( trans.inputDate + " " + trans.inputTime ).tz( this.$root.TZ ).format( "YYYY-MM-DD HH:mm" ).toString()

        item.workstationNumber   = trans.workstationNumber
        item.wksId               = trans.workstationNumber
        item.cashierNumber       = trans.cashierNumber
        item.isPayment           = (trans.isPayment === 'true') ? true : false

        item.transactionTotal = trans.total
        item.salesItemizer    = [ trans.taxable + trans.nonTaxable, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]   // 16 sales itemizers
        item.taxItemizer      = trans.tax
        item.discount         = trans.discount
        item.serviceCharge    = trans.serviceCharge
        item.previousPay      = trans.previousPay

        item.receipt.push(this.makeReceipt());

        if(item.tenderAmount >= trans.total) {
          item.items = Object.assign({}, trans.items)
        }

        this.postingItem = item;
        return this.postingItem;

      },
      async postTransaction() {

        if(!confirm("Are you ready to post this transaction?")) {
          return;
        }

        if( this.transaction.total ===  0) {
          if(!confirm("Post a 0.00 transaction?")) {
            return;
          }
        }

        if( this.transaction.revenueCenterId === 0 || this.transaction.tenderNumber === 0 ) {
          alert( "Please choose a valid Revenue Center and Tendering Number" );
          return;
        }


        this.loading = true;
        let posting  = this.buildPostingItem()
        let auth
        let that     = this

        // console.log("Posting This Record:", JSON.stringify(posting, null, 2));

        try {
          let response = await PosService.transactionPost(posting)
          auth         = response.data;
          if(response.status !== 200) {
            that.loading = false;
            return this.showErrorMessage(auth.message);
          }

          this.$emit('posted', posting);

        } catch(error) {
          that.loading = false;
          console.log(error.message)
          return this.showErrorMessage(error.message)
        }

        // let tenderItem       = this.tenderItem
        // tenderItem.reference = []
        // tenderItem.reference.push("Approved: " + auth.transactionDetail._id.slice(auth.transactionDetail._id.length - 8))
        // tenderItem.reference.push(this.account.badge.badgeNumber)
        // tenderItem.reference.push(this.account.badge.alias)
        // tenderItem.reference.push("Bal: " + this.currency(auth.total.total.chargeTotal)
        //                                         .subtract(auth.total.total.paymentTotal)
        //                                         .multiply((auth.total.total.totalType) === 'debit' ? -1 : 1))
        //
        // this.$emit('post', tenderItem)    // now, post this payment to OUR tranaction since it was approved

      },
      revenueCenter(id) {
        var t = this;
        var i;

        if(t.revenueCenters.length > 0) {
          for(i = 0; i < t.revenueCenters.length; i++){
            if(t.revenueCenters[ i ].revenueCenterId == id) {  // needs to be a loose equality check
              return "" + id + " : " + t.revenueCenters[ i ].title;
            }
          }
        }

        return "" + id
      },
      tender(id) {
        var t = this;
        var i;

        if(t.tenders.length > 0) {
          for(i = 0; i < t.tenders.length; i++){
            if(t.tenders[ i ].tenderNumber == id) {  // needs to e a loose equality check
              return "" + id + " : " + t.tenders[ i ].title;
            }
          }
        }

        return "" + id
      },
      total(id) {
        var t = this;
        var i;

        if(t.postingTotals.length > 0) {
          for(i = 0; i < t.postingTotals.length; i++){
            if(t.postingTotals[ i ].totalClassId == id) {    // needs to be a loose equality check
              return "" + id + " : " + t.postingTotals[ i ].title;
            }
          }
        }

        return "" + id
      },
      logicalOptions() {
        return [ {text: "Payment", value: true}, {text: "Charge", value: false} ];
      },
      async getRevenueCenters() {
        var response;
        var t = this;
        try {
          response         = await RevenueCenterService.getRevenueCenters();
          t.revenueCenters = response.data;

        } catch(err) {
          if(err) {
            alert(err.message);
            t.$root.errorMessage("Oops", err.message);
          }
        }

      },
      async getTenders() {
        var response;
        var t = this;
        try {
          response  = await TenderService.getTenders();
          t.tenders = response.data;

        } catch(err) {
          if(err) {
            alert(
              "oops hit error in getTenders",
              err
            );
            t.$root.errorMessage("Oops", "Had a problem getting the tender listing");
          }
        }
      }
    }
  }

</script>
<style>

  .page-body {
    border-radius : 1em;

  }

</style>
