<template>
  <b-card class="badge-card shadow" :style="(isActive) ? '' : 'background:lightgray;'">
    <div>
      <span class="card-category show-hand" @click="showDetail = !showDetail">{{t.totalClassId}} :: {{t.totalType}}</span><span v-if="!isActive" style="font-weight:600;font-size:.8em;padding-left:2em;">(Inactive)</span>
      <i class='fa fa-info show-hand' @click="showDetail = !showDetail" style="float:right;" v-b-popover.hover.top="'Show Activity Details'"></i>
      <i v-if="isShowTransactionButton && isActive" style="margin-right:1em;" class="fa fa-cart-plus float-right show-hand" @click.prevent.default="manualTransaction()" v-b-popover.hover.top="'Manual Transaction'"></i>
    </div>
    <h4 class="card-title show-hand" @click="showDetail = !showDetail">{{t.title}}<span style="float:right;"> {{numeral( signIt( t.total.chargeTotal - t.total.paymentTotal )).format( "0,0.00")}}</span>
    </h4>
    <div v-if="t && showDetail">
      <table class="table table-striped card-table" >
        <thead>
        <tr>
          <th>TOTALS</th>
          <th>Today</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody>
        <tr class="align-right">
          <td>Balance</td>
          <td>{{numeral( signIt(todayOrZero( t.lastChargeDate , t.daily.chargeTotal) - todayOrZero( t.lastPaymentDate , t.daily.paymentTotal)) ).format("0,0.00")}}</td>
          <td>{{numeral( signIt( t.total.chargeTotal - t.total.paymentTotal )).format( "0,0.00")}}</td>
        </tr>
        <tr class="align-right">
          <td># Charges</td>
          <td>{{numeral( todayOrZero( t.lastChargeDate , t.daily.chargeQty) ).format( "0,0")}}</td>
          <td>{{numeral( t.total.chargeQty ).format( "0,0")}}</td>
        </tr>
        <tr class="align-right">
          <td>Charge Total</td>
          <td>{{numeral( todayOrZero( t.lastChargeDate , t.daily.chargeTotal) ).format( "0,0.00")}}</td>
          <td>{{numeral( t.total.chargeTotal ).format( "0,0.00")}}</td>
        </tr>
        <tr class="align-right">
          <td># Payments</td>
          <td>{{numeral( todayOrZero( t.lastPaymentDate , t.daily.paymentQty)).format( "0,0")}}</td>
          <td>{{numeral( t.total.paymentQty).format( "0,0")}}</td>
        </tr>
        <tr class="align-right">
          <td>PaymentTotal</td>
          <td>{{numeral( todayOrZero( t.lastPaymentDate , t.daily.paymentTotal )).format( "0,0.00")}}</td>
          <td>{{numeral( t.total.paymentTotal ).format( "0,0.00")}}</td>
        </tr>
        </tbody>
      </table>
      <div style="font-size:.7em;font-color:gray;text-align:center;padding:0;margin:0;">Last Post: {{moment(t.lastPostDate).format("LLL")}}</div>
    </div>
  </b-card>
</template>
<script>

  import Currency from "currency.js";
  import moment  from "moment-timezone"; // find the docs here: https://momentjs.com/timezone/
  import Numeral from "numeral"; // find the docs here: http://numeraljs.com/
  import LTable  from "/src/components/UIComponents/Table.vue";

  export default {
    name: 'BadgeBalanceCard',
    props: {
      t: { type: Object, default: () => { } },
      isShowTransactionButton: { type: Boolean, default: false },
      isActive: { type: Boolean, default: true}
    },
    components: {
      LTable
    },
    data() {
      return {
        showDetail: false,
        totalHeaders   : [
          "Tracking Item",
          "Total"
        ],
        totalColumns: [
          "title",
          "trackingTotal:money"
        ]
      }
    },
    methods: {
      numeral( x ) {
        return Numeral(x);
      },
      moment(d) {
        return moment(d);
      },
      currency(c) {
        return Currency(c);
      },
      signIt( total ) {
        if( this.t.totalType == "debit" || this.t.totalType == "fund" ) {
          return Currency(total).multiply(-1).value;
        }
        return Currency(total).value;
      },
      todayOrZero( d , total ) {        // manage the "daily totals" - if the passed day is NOT today, then it's 0.
        if( d === undefined ) {         // todo: Move this logic into the BadgeService so we don't have to deal with it out here...
          return 0;
        }
        var d1 = moment(d).format("MM/DD/YYYY");
        var d2 = moment( new Date()).format("MM/DD/YYYY");
        if( d1 === d2) {
          return Currency(total).value;
        }
        return Currency(0.00).value;
      },
      manualTransaction() {
        // console.log("BadgeBalanceCard->ManualTransaction()", JSON.stringify(this.t,null,2));
        this.$emit( "manualTransaction", this.t )
      }
    }
  }
</script>
<style language="scss" scoped >

  .badge-card {
    border-radius:1em;
  }

  .card-table {
    font-size: .8em;
    line-height:70%;
    margin-top:5px;
    background:#f0f0f0;
    border-radius:1em;
  }

</style>
