///-------------------------------------------------------------------------------------------------
// summary:	Device service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {
  
  getDevices() {
    const response = Api().get(`/api/1/devices`);
    return response;
  },
  getDevice( id ) {
    const response = Api().get(`/api/1/device/${id}`);
    return response;
  },
  saveDevice(device) {
    const response = Api().patch(`/api/1/device`, device );
    return response;
  },
  addDevice(device) {
    const response = Api().post(`/api/1/device`, device );
    return response;
  },
  removeDevice(id) {
    return Api().delete(`/api/1/device/${id}`);
  }


}
