<template>
<div class="container">

    <div class="module container my-5 py-5">
        <div style="text-align:center;margin-bottom:2em;">
          <h1>Terms of Use</h1>
        </div>

        <div style="width:100%;font-weight:800;font-size:1.5em;margin-bottom:2em;">
          <p>
            You agree that this agreement complies with the terms of any applicable electronic documents legislation, including the requirement that this agreement is in writing, 
            and is capable of being retrieved, reviewed, printed and stored for further use.
          </p>
        </div>

        <h6 style="color:moccasin;margin-left:1em;">Effective as of: <b>April 8th, 2020</b></h6>

        <div class="container ">
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Welcome to the {{productName}} website, an online service designed and offered by {{companyName}}. and its affiliates ("{{companyShortName}}") especially 
                      for small businesses around the globe.
                       These Terms of Use are intended to explain our obligations as a service provider and your obligations as a user and subscriber. 
                       IT IS IMPORTANT THAT YOU READ ALL THE TERMS AND CONDITIONS CAREFULLY.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Please read this stuff or you won’t know the rules under which we will engage with you.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">If you use this website, you are agreeing to be bound by these Terms of Use without any modification or qualification. 
                      IF YOU ARE DISSATISFIED WITH THE TERMS, CONDITIONS, RULES, POLICIES, GUIDELINES OR PRACTICES OF OPERATING OUR SERVICE,
                        UNLESS EXPRESSLY SET OUT IN THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE. 
                        If for any reason you are unable to meet all the conditions set forth in these Terms of Use, or if you breach any
                        of the Terms of Use contained herein, your permission to use {{productName}} or access any of {{companyShortName}}’s Services (defined below) immediately lapses and you must destroy
                         any materials downloaded or printed from the {{productName}} website.</p>
                    <p class="small">{{companyShortName}} offers a number of Additional Services each with their own Terms of Service (“Specific Additional Service Terms”) applicable in addition to 
                      these Terms of Use. When you use an Additional Service, you will be subject to the
                        Specific Additional Service Terms. Note that if these Terms of Use are inconsistent with the Specific Additional Service Terms, those Specific Additional 
                        Service Terms will control. See Section X below.</p>
                    <p class="small">{{companyShortName}} reserves the right to modify or change these Terms of Use at any time without giving prior notice. 
                      We shall, however, notify you of significant changes by posting an announcement on the Site. Your use of {{productName}} is subject to
                        the most current Terms of Use posted on the Site at such time. The most current version can be reviewed by clicking “Terms of Use” hyperlink at the bottom of our Site pages.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>This is important. By using anything from {{companyShortName}}, you’re agreeing to be bound by everything in lawyer-approved sections. 
                      If you won’t or can’t agree to these terms, we can’t let you use {{productName}}. The terms may change in the future. If
                        the change is more than nominal, we’ll let you know and give you a chance to change your mind.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>A. Definitions</h2>
                    <p class="small">The following terms shall have the following meaning throughout the Terms of Use.</p>
                    <ol type="a">
                        <li class="small">“Agreement” or “this agreement” refers to these Terms of Use.</li>
                        <li class="small">“Applicable law” refers to the laws currently in force in the {{contractOrigin}} which shall govern this agreement.</li>
                        <li class="small">“Site” means this website, all related webpages, and all related websites operated by affiliates or divisions of {{companyShortName}}, 
                          but does not include any third party websites which are linked to or may link from this website whether or
                            not such third party websites are used in connection with the Services.</li>
                        <li class="small">“Service” and “Services” refer to the online financial services and other small business–related services and Support offered through {{productName}} from time to time.</li>
                        <li class="small">“Subscriber” refers to any person who maintains an account with us and utilizes our Services.</li>
                        <li class="small">“Support” means technical support and assistance provided to users by {{companyShortName}}.</li>
                        <li class="small">“{{companyShortName}}”, “we”, “us” and “our” shall refer to {{companyName}}  and all its affiliates and subsidiaries.</li>
                        <li class="small">“{{productName}}” means the Site and all mobile applications and other applications which provide access to the Services offered 
                          by {{companyShortName}} and its affiliates from time to time.</li>
                        <li class="small">“{{productName}} Parties” includes {{productName}}, its affiliates, subsidiaries, partners, service providers, suppliers and contractors 
                          and each of their respective officers, directors, agents, and employees.</li>
                        <li class="small">“{{productName}} Pros” means the persons and entities who have voluntarily registered with {{productName}} to be listed on the Site as 
                          members of the {{productName}} Pro Network in order to offer, for their own account, various technical support, financial
                            consulting, accounting and bookkeeping services to {{productName}} users.</li>
                        <li class="small">“{{productName}} Dealers Network” means the online community of {{productName}} Dealers listed on the Site from time to time.</li>
                        <li class="small">“You” and “user” shall refer to any person who visits the Site or uses {{productName}} and includes a subscriber, and its employees and agents.</li>
                    </ol>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Now we’re defining stuff. Most of this doesn’t need a translation.</p>
                    <p>We’re talking about {{companyShortName}} here, not about third parties.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>B. General</h2>
                    <p class="small">{{companyShortName}} grants you a non-exclusive, non-transferable limited license to use {{productName}} and related resources in 
                      accordance with these Terms of Use. You agree not to infringe our intellectual property and the intellectual property of any
                        third parties with whom we have partnerships, and you will comply with the terms of any applicable license agreements.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>We’d love for you to use {{productName}}, but you’re just using it. We still own it.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">You agree that any notices, disclosures, agreements and other communications that we may deliver or communicate to you from time 
                      to time comply with the terms of any applicable electronic documents legislation, including the requirement
                        that such documents be in writing, are capable of being retrieved, reviewed, printed and stored for further use by you.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You may print this document if you want to keep it, of course.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h3>Electronic Delivery</h3>
                    <p class="small">By accepting this Agreement, you agree and consent to receive electronically all communications, agreements, documents, 
                      notices and disclosures (collectively, “Communications”) that we provide in connection with your Account and your
                        use of the Service. Communications include but are not limited to:</p>
                    <ol type="a">
                        <li class="small">agreements and policies, such as this Agreement and our Privacy Policy, including updates thereto;</li>
                        <li class="small">annual disclosures;</li>
                        <li class="small">transaction receipts or confirmations;</li>
                        <li class="small">communication in relation to delinquent accounts (which may also be by phone, and may be made by {{companyShortName}} or by anyone on its behalf, 
                          including a third party collection agent);</li>
                        <li class="small">Account statements and history; and</li>
                        <li class="small">federal and state tax statements.</li>
                    </ol>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>We’re going to communicate electronically with you. Take a look in the other section for some examples of what might be included.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">We will provide these Communications to you by emailing them to you at the primary email address listed in your Account registration, 
                      by emailing you a link or instructions how to access them on a website, or (if permitted by law)
                        by posting them on the Website. Communications are considered received by you within 24 hours of the time they are emailed to you or posted to our Website. 
                        You further agree that your electronic signature has the same effect as
                        your manual, physical signature.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>I hope this doesn’t come as too much of a surprise, but when we say communicate electronically, we mean email.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">By giving your consent, you are confirming that you are able to receive, open, and print or download a copy of any Communications for your records. 
                      It is important for you to retain copies of Communications because they may not be
                        accessible in your Account at a later date.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>It’s your responsibility to ensure you can communicate by email.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h3>How to Withdraw Your Consent</h3>
                    <p class="small">You may withdraw your consent to receive Communications electronically by contacting us through Customer Support or as outlined in our Privacy Policy. 
                      If you withdraw your consent to receive Communications electronically, {{companyShortName}} may
                        deny your registration for an Account, restrict or close your Account, or charge you additional fees for paper copies.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you decide that we can no longer communicate by email, let us know and we’ll stop. If that happens, we might not be able to work together anymore.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h3>Collaboration</h3>
                    <p class="small">You may provide access to certain apps to authorized users (called Guest Collaborators). An authorized user is someone designated by you via the Guest Collaborator 
                      functionality available in {{productName}}. Such authorized users may have access
                        to your account information and perform various tasks as instructed by you which include but are not limited to creating invoices, categorizing transactions, and any other lawful 
                        tasks that you may prescribe. If you administer
                        your {{productName}} account on behalf of a business or corporation, you represent and warrant that you have the right to provide access to your Guest Collaborators.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You can invite a Guest Collaborator to work with you in {{productName}}. If you do, they can see your data and sometimes do things with it. 
                      Please only grant Guest Collaborator access if you have permission to do so.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">{{companyShortName}} may, without notice or liability, add, discontinue or revise any aspect, mode or design of the Services which 
                      include but not limited to the scope of service, time of service, or to the software/hardware required for access
                        to the Services. {{companyShortName}} may also limit the geographic locations or jurisdictions where certain Services may be available.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Sometimes things change, including {{productName}}.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Without prejudice to the foregoing, should there be a failure of or error, omission, defect, deficiency, delay causing downtime, 
                      or inability of a subscriber to access the Services for any length of time, including as a result of the
                        permanent termination of service, the subscriber acknowledges and agrees that, except as otherwise set out herein, its only remedy for any error,
                         omission, defect, deficiency, delay or other failure of {{productName}} or the Services whatsoever
                        is to discontinue using {{productName}} and the Services.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If the service we provide is less than awesome, your recourse is to stop using {{productName}}.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">You assume sole responsibility for and shall ensure that any information, data, documents or materials used, disclosed, entered into or created 
                      using {{productName}} or in connection with the Services is accurate, reliable and complete and does
                        not violate our content guidelines. You authorize us and our service providers and their vendors and subcontractors to monitor your website and your use of 
                        the Services. You represent and warrant that you have obtained all required
                        consents and comply with all applicable legislation, including without limitation privacy legislation, in connection with any use and disclosure of information 
                        relating to the use of {{productName}} and the Services. {{companyShortName}} accepts no responsibility
                        for the accuracy of any information, data, documents or materials entered into or created using {{productName}} or the Services except as otherwise set out herein. 
                        The input, posting or uploading of any information and the storage of any
                        information, data, documents or materials on the Service by us does not constitute our endorsement nor warranty as to the compliance of such information or materials 
                        with applicable legislation, nor to the accuracy, timeliness,
                        materiality, completeness, or reliability of such information or materials.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>This is software that captures and crunches numbers. If you put the wrong numbers in, {{companyShortName}} can not be responsible for the wrong numbers coming out. 
                      We will not be checking your work. Even if your numbers are correct, we aren’t going to certify
                        that you’ve done what the government or anyone else needed you to do.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h3>Other Information You Provide Us</h3>
                    <p class="small">While using the Services, we may also collect information from you about someone else. If you provide us with personal information 
                      about someone else, you are obligated to ensure that you are authorized to disclose that information
                        to us, and that we, without further action, may collect, use, and disclose that information for the purposes described in this policy.</p>

                    <p class="small">{{companyShortName}} may require you to assist with any requests by the individual to access or update their information, and you agree to do so.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You might give us some information about employees or customers while using {{productName}}. We’ll treat that information the same way we treat yours, 
                      as outlined in this policy. You’re stating you’ve got the necessary consent to do so.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>C. Conditions of Usage.</h2>
                    <p class="small">You agree to use {{productName}} and the Services for the purposes that they were intended and only in accordance with the terms of this agreement.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>{{productName}} is what it is. Don’t use it for something it’s not.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">If you register an account, you agree, represent and warrant that you will provide us with true, current, complete and accurate information requested 
                      in the registration form and from time to time in connection with your use of {{productName}}.
                        Your registration for any of {{productName}} constitutes consent to use any personal information which you provide us in such registration for the purposes 
                        set out in our Privacy Policy and for all uses ancillary thereto for which your consent
                        may reasonably be implied. You also will select a username and password. You are responsible for keeping your user name, password, account details, and all 
                        information required in connection with your use of {{productName}} (<em>e.g.</em> account information if you use {{productName}} Cashless) 
                        confidential and up to date. If you are not the business owner and are registering an account on behalf of a business, you represent and warrant that you have been authorized and directed
                        by your business's principals to open the account and that you have shared all user names, passwords and access credentials with other authorized representatives 
                        in your business and that you have provided us with the business
                        contact information of at least one other person. If you leave the business and your business’s principal contacts us we may be required to provide access to 
                        the {{productName}} account. You agree to hold harmless and release the {{productName}} Parties
                        from any liability if we do so.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>When you register, don’t lie about who you are. By registering, you’re also agreeing to our Privacy Policy. Keep track of your login details, and keep them safe. 
                      If you’re creating an account on behalf of a business which you do not
                        own, you must a) have permission, and b) share the login credentials with the owner.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Furthermore, you are entirely responsible for any activities that occur under your account. You agree to notify us immediately of any unauthorized 
                      access to or use of your account. You agree to hold harmless and release the {{productName}} Parties
                        from any loss or liability whatsoever that you may incur as a result of someone other than you using your username, password or account, either with or 
                        without your knowledge. You agree to indemnify the {{productName}} Parties for any damages,
                        third party claims or liabilities whatsoever that we may incur as a result of activities that occur on or through your account, 
                        whether or not you were directly or personally responsible.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You’re responsible for what happens in your account.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">We are responsible for the security of Card/Account holder Data that is collected, transmitted, stored, or processed by Us on Your behalf. 
                      “<strong>Card/Account holder Data</strong>” is defined as being the card/account holder’s primary account number, and where
                        a full unmasked card number is present, any of the card/account holder name, expiration date, and/or service code. {{companyShortName}} has developed 
                        strict security features to protect Card/Account holder Data, and as such it is required that this data only
                        be used in anticipated ways and stored in appropriate places.</p>
                    <p class="small">YOU ACKNOWLEDGE AND AGREE THAT YOU ARE PROHIBITED FROM ENTERING CARD/ACCOUNT HOLDER DATA INTO ANY FORM OR DATA ENTRY FIELDS IN {{productName}}, 
                      EXCEPT INTO THOSE FIELDS INTENDED SOLELY FOR THAT PURPOSE (<em>i.e.</em> where {{productName}} explicitly requests such
                        data be entered into such fields).</p>
                    <p class="small">Appropriate fields are clearly marked with labels such as ‘Card number’, 'Account number', 'Badge number', or by having a credit card icon 
                      precede them. For clarity, it is not permissible to store card/account holder data in fields with labels such as ‘memo’ or ‘note’. Similarly,
                        excluding payment forms, you must never enter CVV2 data into any fields in {{productName}}. 
                        You assume all responsibility for any card/account holder data entered into {{productName}} in violation of this Agreement.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>There are a bunch of rules for handling credit card information known as the <a href="https://www.pcisecuritystandards.org/security_standards/" target="_blank">Payment Card 
                    Industry Data Security Standard</a> (PCI-DSS). To help us follow these rules
                        and to protect your and your customers’ data, we need you to use the apps as they were intended. You agree not to enter your cardholder 
                        data in any form where such data isn’t explicitly requested by {{productName}}. We’re not responsible
                        for people doing unexpected things with their data.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>D. Online Access to Financial Institutions, {{productName}} Dealers and Other Services.</h2>
                    <p class="small">Certain online services (including access to online banking, online payments, digitization, storage of receipts, certain payroll related services, 
                      the {{productName}} Dealer Network and other online services related to the use
                        of the Site) are provided by financial institutions and other third party service providers and not by us. You agree to hold harmless and release 
                        the {{productName}} Parties from any liability relating to your use of such online services,
                        including the {{productName}} Dealer Network and the {{productName}} Dealer. Your access to and conditions of use of such services may be limited 
                        in accordance with the terms of use published by such third party provider. You represent and warrant that
                        you have the right to provide us with usernames, passwords, personal information and other access credentials which we may require 
                        to access any third party services or accounts in connection with the Services.</p>
                    <p class="small">{{companyShortName}} does not provide professional accounting services. If you use the {{productName}} Dealer Network, you agree that you do so at 
                      your own risk. Unless expressly stated to the contrary, {{companyShortName}} does not pre-qualify, review, or endorse {{productName}}
                        Dealers and we provide no representation or warranty as to the background, reputation, character, qualifications, skills, insurance, work product, 
                        services, advice, recommendations or quality of the {{productName}} Dealer. If you use a {{productName}} Dealer,
                        you acknowledge that you are doing so at your own risk and hereby agree to release and hold {{companyShortName}} harmless from any or Claims arising 
                        from your use of the {{productName}} Dealer, as further set out in section I below. {{companyShortName}} does not purport
                        to provide you with any financial or accounting advice and you agree to release and hold {{companyShortName}} harmless for and from any Claims 
                        (as defined below) arising from your use of a {{productName}} Dealer to provide you with technical support in
                        relation to {{productName}}, as further set out in section I below.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Sometimes you can use {{productName}} in conjunction with services from other companies. What you do with those other companies is up to you and them to work out. 
                      That includes fees they may charge you. If you need to provide {{productName}} with login
                        credentials for that other company, in order to make things work, you will only do so if you have permission.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>E. Software Notice.</h2>
                    <p class="small">In the event that a user is required to download or use software in connection with the Services, he or she shall be unable to download 
                      and access such software unless he or she first agrees to the license agreement relating to such
                        software. Use of any such software is governed by these Terms of Use and any such license agreement.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>For installable software, you’ll have to agree to a license agreement before you can use it.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>F. Copyrights, Trademarks and Intellectual Property.</h2>
                    <p class="small">{{companyShortName}} and its licensors and suppliers own both the proprietary rights as well as the intellectual property rights to all 
                      URLs, materials, products, web content, web page designs, web page layouts, images, text, tools, utilities and
                        software that make up the Services, but excluding your data and information which you provide to us or input using {{productName}} and 
                        the Services. The technical procedures, processes, concepts and methods of operation that are inherent
                        within {{productName}} constitute trade secrets. The usage of our Services does not constitute a sale or transfer of any intellectual 
                        property rights to the users. Without any prejudice to the foregoing, any information or data entered using
                        {{productName}} by a user or otherwise provided for accessing {{productName}} on the user’s behalf shall at all material times remain the 
                        property of the user. The user hereby grants to {{companyShortName}} a worldwide, perpetual, royalty free, nonexclusive right
                        and license to use all content provided by the user in connection with {{productName}} and the Services for uses related to the delivery of the Services.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You own all your data. We own everything we’ve created. You give us permission to do things like collect and crunch numbers for you.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Materials on and relating to {{productName}}, including the content of {{productName}} and any software downloaded from {{productName}}, 
                      are protected by copyright, trade-mark and other intellectual property laws. Subject to your ownership of your user content
                        and data, {{companyShortName}} reserves all rights in and to such materials. The subscriber will not make, store, download, transfer, sell, 
                        reproduce, redistribute, transfer to any other server, modify, reverse engineer or copy the Services
                        or any of the materials or software or any part of {{productName}} or any content therefrom without {{companyShortName}}’s express written consent. 
                        You will also take all reasonable steps to forestall any unauthorized use, copying or transfer of materials
                        on or relating to {{productName}}.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Another way of saying that you own all your data, and {{companyShortName}} owns everything we’ve created. 
                      You agree not to try to duplicate, copy or steal anything we’ve created.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Notwithstanding the above, you may, subject to the following conditions, use individual screen displays (screenshots) 
                      which you generate as a subscriber using the Services. Your use of screenshots is subject to the following:</p>
                    <ol type="a">
                        <li class="small">No screenshot may be used from any beta version of {{productName}} unless it has been commercially released to the public;</li>
                        <li class="small">the use is for illustrative purposes;</li>
                        <li class="small">the use may not imply any endorsement or affiliation by or with {{companyShortName}};</li>
                        <li class="small">the screenshot does not contain any commentary which may appear to have been attributable to us;</li>
                        <li class="small">the screenshot does not contain any third party content; and</li>
                        <li class="small">the use does not infringe on any of these terms of use.</li>
                    </ol>
                    <p class="small">{{companyShortName}} has rights to several trade-marks which it uses in connection with the operation of {{productName}}. 
                      {{companyShortName}} does not grant the users any right or license to use the {{companyShortName}} trade-marks or any logo, trade-name or other intellectual property
                        other than as expressly set out herein and in other licenses between you and us.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You can take screen shots as long as:</p>
                    <ol type="a">
                        <li>It’s not a beta feature or product;</li>
                        <li>you’re just trying to illustrate something;</li>
                        <li>the screenshot doesn’t suggest a relationship that doesn’t exist;</li>
                        <li>the screenshot doesn’t suggest we said or meant something that we didn’t say or mean;</li>
                        <li>the screenshot doesn’t contain something that {{companyShortName}} can’t give permission for; and</li>
                        <li>it doesn’t break any other rules.</li>
                    </ol>
                    <p>Permission to use {{productName}} is not equal to permission to use our trademarks.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>G. Prohibited Use.</h2>
                    <p class="small">The users shall access the information stored using the Services for lawful purposes only and may not use such information 
                      for the purpose of committing or furthering fraudulent acts or for committing any acts that would give rise
                        to both or either civil and criminal liability.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You’re not allowed to use {{productName}} to do or help anything illegal or bad.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">The users agree not to publish, post, upload, distribute, provide or enter any material or information that is illegal, 
                      unlawful or can be regarded as fraudulent, libelous, malicious, threatening, offensive, profane, obscene, fanning
                        ethnic or racial tensions, immoral or any such information which any reasonable person would consider objectionable on grounds of good conscience.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Don’t use {{productName}} to spread bad stuff.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">No user shall use any means to restrict or prevent another user from accessing or enjoying {{productName}}.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Don’t do anything that gets in the way of other {{productName}} customers.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">No user shall be permitted to upload material into {{productName}} that he or she ought to know infringes on the intellectual 
                      property of others, or upload material that places unnecessary load as to affect the performances of our websites,
                        systems and equipment. You may not use {{productName}} and the Services in a manner which could block access to, 
                        impair, damage or otherwise disable {{productName}} or any of our servers. You may not attempt to gain unauthorized 
                        access to {{productName}} or to any other user’s accounts, computer systems or networks through password mining, 
                        keystroke logging, hacking or any other means. You will not upload any files that contain viruses, Trojans,
                         malware, spyware, worms, corrupted files or any other material or software that may damage the operation of our, or another 
                         computer. Any and all materials uploaded are subject to applicable laws.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Don’t upload things that don’t belong to you. Don’t try to break anything. Don’t try to get into {{productName}}’s secure machines and systems, 
                      or anyone else’s account. Don't run with sissors.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">No user shall lease, sell, pledge, sublicense, assign or otherwise deal with the software belonging to {{productName}} in a manner that 
                      is inconsistent with our intellectual property rights over the software.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>{{productName}} belongs to {{companyShortName}}; don’t try to sell it or anything like that.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">No user shall promote any commercial interest, falsify or delete any information on {{productName}}, collect personal 
                      information without express authority to do so, violate any applicable laws, create a false identity or utilize {{productName}} under
                        false pretences.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>More bad things you’re not allowed to do, like pretending to be someone you’re not, or breaking laws.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>I. Limitation of Liability.</h2>
                    <p class="small">THE SUBSCRIBERS AND THE USERS HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE THE {{productName.toUpperCase()}} PARTIES FROM ANY AND ALL 
                      MANNER OF RIGHTS, LOSSES, COSTS, CLAIMS, COMPLAINTS, DEMANDS, DEBTS, DAMAGES, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES,
                        OBLIGATIONS, LEGAL FEES, COSTS AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, INCLUDING WITHOUT LIMITATION ANY LOST PROFIT, 
                        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES (COLLECTIVELY, A "CLAIM") WHETHER
                        KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH (I) THIS AGREEMENT; 
                        (II) THE USE OF {{productName.toUpperCase()}} AND ITS SERVICES AND ANY RELATED APPLICATIONS INCLUDING THE DEALER NETWORK AND THIRD PARTY SERVICES; 
                        (III) THE USE OF ANY SOFTWARE RELATED TO {{productName.toUpperCase()}}; (IV) VIRUSES, SPYWARE, SERVICE PROVIDER FAILURES OR INTERNET ACCESS INTERRUPTIONS; 
                        (V) LOSS OF USE, LOSS OF DATA, ERROR, INACCURACY OF DATA, PAYMENT FAILURE, PAYMENT DEFECT, INACCURATE
                        CALCULATIONS (EXCEPT AS OTHERWISE SET OUT HEREIN), DOWNTIME, IDENTITY THEFT, FRAUD OR UNAUTHORIZED ACCESS; 
                        OR (VI) ANY INFORMATION, DOCUMENT, MATERIALS, TOOLS, UTILITIES, PRODUCT OR DATA THAT RELATING TO THE USE OF {{productName.toUpperCase()}} AND THE
                        SERVICES; WHETHER SUCH CLAIM IS BASED IN CONTRACT OR TORT OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH CLAIM, 
                        OR SUCH CLAIM WAS REASONABLY FORESEEABLE AND NOTWITHSTANDING THE SUFFICIENCY OR INSUFFICIENCY
                        OF ANY REMEDY PROVIDED FOR HEREIN OR IN ANY LICENSE.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>The lawyers are in full swing, capital letters and all. Essentially, you agree that the only parts where {{companyShortName}} accepts 
                      liability are those places below where we explicitly say so.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">FOR GREATER CERTAINTY, IN NO EVENT SHALL WE (AND OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, 
                      CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO
                        THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SITE OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
                        ACCESS TO, AND USE OF, {{productName.toUpperCase()}} AND SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL
                        BE SOLELY RESPONSIBLE FOR ANY DAMAGE, MISUSE, ERROR, OR LOSS OF DATA ARISING FROM SUCH ACCESS AND USE.</p>
                    <p class="small">EXCEPT IN THE MANNER PROVIDED FOR IN THESE TERMS OF USE, {{companyShortName.toUpperCase()}}, ITS LICENSEES, AFFILIATES, AND THIRD PARTY 
                      SERVICE PROVIDERS, DISCLAIM, AND EXPRESSLY DO NOT PROVIDE ANY DIRECT OR INDIRECT, EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY
                        AS TO TITLE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY, OR MERCHANTABILITY OR MERCHANTABLE QUALITY; OR THAT {{productName.toUpperCase()}}, 
                        THE SERVICES, ANY THIRD PARTY SERVICES AND ONLINE SERVICES, AND ANY CONTENT, MATERIALS, TOOLS, UTILITIES, SOFTWARE
                        AND FUNCTIONALITY WILL: (I) BE FIT OR SUITABLE FOR ANY SPECIFIC PURPOSE OR INTENDED USE; (II) MEET THE USERS' REQUIREMENTS AND EXPECTATIONS; 
                        (III) BE UNINTERRUPTED, FLAWLESS, TIMELY, ACCURATE, RELIABLE, SECURE OR ERROR FREE, OR;
                        (IV) BE FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT {{productName.toUpperCase()}} AND THE SERVICES ARE PROVIDED 
                        ON AN "AS IS" BASIS AND YOU AGREE TO USE {{productName.toUpperCase()}}, THE SERVICES, ANY THIRD PARTY SERVICES AND ONLINE SERVICES,
                        AND ANY CONTENT, MATERIALS, TOOLS, UTILITIES, SOFTWARE AND FUNCTIONALITY RELATED TO {{productName.toUpperCase()}} AT YOUR OWN RISK. YOU AGREE 
                        TO DETERMINE THE SUITABILITY OF {{productName.toUpperCase()}} AND SERVICES FOR YOUR INTENDED USE AND AGREE TO VERIFY ALL RESULTS OBTAINED
                        USING {{productName.toUpperCase()}} AND THE SERVICES.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>More capital letters saying that {{companyShortName}}’s liability is limited to the areas we explicitly say so.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE 1542 IN CONNECTION WITH ANY RELEASE PROVIDED 
                      BY YOU IN THESE TERMS OF USE, INCLUDING THE FOREGOING, WHICH SECTION STATES: "A GENERAL RELEASE DOES NOT EXTEND TO
                        CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE 
                        WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you’re lucky enough to live in California, you waive Civil Code 1542.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>J. Termination of Service.</h2>
                    <p class="small">{{companyShortName}} may terminate this agreement and your use of {{productName}} at any time and for any reason 
                      with reasonable notice to you and without notice if you breach any of these terms or we suspect you of fraud or suspect that your Account has
                        been compromised in any way. Subscribers may use {{productName}} on an at-will basis until the agreement has been terminated by either party 
                        pursuant to the terms of this agreement or any related license agreement. You may also terminate
                        this agreement by following the “Close your {{productName}} Account” instructions found on your Account page. 
                        In the case of a company, upon an order or a resolution being passed for the purposes of winding up the business activities other
                        than for the purposes of amalgamation, merger or reconstruction or upon a composition agreement being made with the creditors.</p>
                    <p class="small">Upon such termination, you must immediately cease using {{productName}} indefinitely. We may at our option immediately 
                      block your access to {{productName}}.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you break the rules, we can close your {{productName}} account(s). You can also unsubscribe easily anytime you want.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>K. Ownership and Disclosure of Information.</h2>
                    <p class="small">You own all of your business’s private data, content and all information which you enter and use in connection with the Services. 
                      We do not claim any rights, proprietary or otherwise over any data or information which you may use or
                        disclose in connection with the Service and {{productName}}. Notwithstanding the above, there may be 
                        circumstances in which we may be required to disclose data, such as the following:</p>
                    <ol type="a">
                        <li class="small">For the purposes of fraud prevention and law enforcement;</li>
                        <li class="small">To comply with any legal, governmental or regulatory requirement;</li>
                        <li class="small">To our lawyers in connection with any legal proceedings; and</li>
                        <li class="small">To comply with a court order.</li>
                    </ol>
                    <p class="small">If we are required to disclose your data or information, we will use our best efforts to provide you with reasonable notice in the 
                      circumstances and if appropriate the right to challenge any such request. {{companyShortName}}’s Privacy Policy sets
                        out in greater detail how we strive to protect your privacy and limit disclosure of your personal information.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>We want you to be in control of all your data. You own it. We do not. We won’t disclose personally identifiable info except in cases like those below:</p>
                    <ol type="a">
                        <li>To uphold our obligation to law enforcement;</li>
                        <li>if we’re required by law;</li>
                        <li>when we talk to our own lawyers; and</li>
                    </ol>
                    <p>If we’re required by law to divulge your info, or in any cases outlined above, we’ll do our best to let you know ASAP and allow you to have your say about it.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>L. Third Party Links, APIs and Content.</h2>
                    <div>
                        <p class="small">You may when using our Site and Services be directed to websites maintained by other third party service providers.</p>
                        <p class="small">Some of the functionality of the Services and {{productName}} interoperate with, and are highly dependent upon, 
                          application programming interfaces (APIs) from third parties, such as Google and Microsoft. If at any point such third party services
                            cease to make themselves or their APIs available to us on reasonable terms, we may cease to provide those third party services without
                             entitling you to a refund, credit or other compensation. Your only recourse shall be to
                            stop using {{productName}} and the Services. In addition, if you authorize us to do so, we may grant third parties access to some or 
                            all (depending on the permission you give) of your private data, content and information through our
                            own API for use in connection with their services.</p>
                        <p class="small">You acknowledge that such sites and services are completely independent of {{productName}} and as we have no control 
                          over them, we accept no liability in respect of your use, ability or inability to use them or any of the content of such
                            sites. In addition, we may at any time in our discretion and without notice to you, discontinue providing our API to such 
                            third party services. You acknowledge that any use of the products and services offered by such third
                            party services providers (<em>e.g.</em> for the purposes of payment processing, direct deposit services, payroll tax return preparation, 
                            filing and government remittances) will be at your sole risk. You acknowledge that use
                            of such third party service providers and their websites and services is, except where prohibited or modified by applicable law, 
                            subject to the terms, conditions and policies established by the third party service providers.
                            You expressly hold us harmless and hereby release us from any liability whatsoever whether arising out of contract, tort or 
                            otherwise for and from any Claims (defined below) arising out of your use of, or inability to use,
                            the products and services of third party service providers whether or not such use is ancillary to your use of {{productName}}. 
                            The availability of such third party services in connection with {{productName}} does not constitute an endorsement,
                            warranty, or representation as to the fitness, suitability, merchantability, title, non-infringement, quality, 
                            or accuracy of the third party provider or its products or services.</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you find a link to something in {{productName}}, or use a service that relies on an API, including advertising links, we can’t be responsible 
                      for what other people or companies are saying or doing.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>M. Authorization to Export Data.</h2>
                    <p class="small">The subscriber and the user acknowledge that we may retain a copy of his/her/its transactional information gathered through 
                      the use of {{productName}} and other information uploaded on the subscriber’s account.  We shall not share personal information with anyone except in the 
                        manner provided in our <a href="https://www.newcare.international/privacy/" target="_blank">Privacy Policy</a>.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>To run our services, sometimes we need to make a copy of data and data-files.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>N. Force Majeure.</h2>
                    <p class="small">Neither party should be held liable for a delay or failure in performance of the agreement for services caused by reason of any 
                      occurrence of unforeseen event beyond its reasonable control, including but not limited to, acts of God,
                        natural disasters, power failures, server failures, third party service provider failures or service interruptions, embargo, 
                        labour disputes, lockouts and strikes, riots, war, floods, insurrections, legislative changes, and governmental
                        actions. The party so affected by the unforeseen event shall be so excused on a day-to-day basis for the period 
                        of time equal to that of the underlying cause of delay.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If things outside of {{companyShortName}}’s control interrupt {{productName}}, neither you nor {{companyShortName}} is liable.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>O. Severance.</h2>
                    <p class="small">All provisions of these Terms of Use are, notwithstanding the manner in which they have been grouped together or linked grammatically, 
                      are severable from each other. If any of these Terms of Use should be determined to be unenforceable
                        the remaining Terms of Use shall survive and remain in full force and effect and continue to be binding and enforceable.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>One bad apple doesn’t spoil the whole bunch. If you find an error in these terms, the rest of the terms are still in effect.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>P. Governing Law and Dispute Resolution.</h2>
                    <p class="small">Except as otherwise set out herein, this Agreement shall in all respects be governed by and interpreted, 
                      construed and enforced in accordance with the laws of the {{contractOrigin}} and the laws of {{contractCountry}} applicable therein as applied
                        to agreements entered into and to be performed entirely within the {{contractOrigin}}, without regard to its choice of law or conflicts of law principles 
                        that would require application of law of a different jurisdiction. This agreement and any
                        actions whatsoever taken by you in connection herewith and with any Service, software, tool, application or functionality, will be deemed to 
                        have been performed in the {{contractOrigin}}. The parties hereto irrevocably submit and attorn
                        to the exclusive jurisdiction and venue of the courts of the {{contractOrigin}}.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>The laws of the {{contractOrigin}} govern this agreement and anything that comes out of it.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">You waive all rights to a trial by jury in connection with any legal proceeding or dispute against us. 
                      You further agree that any dispute or proceeding which you may bring against us shall be conducted on an individual basis and not
                        a class-wide basis and that any such proceeding or dispute shall not be consolidated with any other dispute or proceeding which 
                        might arise between us and any other user.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If we get in a dispute, we’ll resolve it without juries or class action suits.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>Q. Legal Status of {{companyName}}</h2>
                    <p class="small">The websites {{productWebsites}}, and the names {{productNames}} are the property of {{companyName}}.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Our official name is {{companyName}}., and all of {{companyShortName}}’s stuff belongs to that company. We’re a USA based company, and we like good coffee, and model railroading.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>R. Language.</h2>
                    <p class="small">You and we expressly acknowledge and agree that this agreement and all related agreements, schedules, materials, licenses and 
                      policies be drafted in the English language only. Es wird vereinbart, dass dieser Vertrag und alle damit zusammenhängenden Anhänge, Dokumente, 
                      Lizenzen und Richtlinien in englischer Sprache abgefasst werden.  And also,  Imekubaliwa kuwa Mkataba huu na viambatisho vyote, nyaraka, leseni 
                      na sera zinazohusiana na hayo zitungwe kwa lugha ya Kiingereza.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>We do business in English, just easier.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>S. Privacy Policy.</h2>
                    <p class="small">{{companyShortName}}’s <a href="https://www.newcare.international.com/privacy/" target="_blank">Privacy Policy</a> is hereby incorporated 
                    into this Agreement and applies to the collection, use, disclosure, retention, protection and accuracy of your personal information and
                        your business’ financial information (the “Information”) collected for the purposes of the Services offered through our website. 
                        The {{companyShortName}} Privacy Policy is based on applicable legislation as well as 10 fair information principles,
                        and may be updated from time to time.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>We also have a <a href="https://www.newcare.international/privacy/" target="_blank">Privacy Policy</a>, and that’s part of the agreement we make with you. Please have a read.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>T. Information Received from Third Party Services</h2>
                    <p class="small">If you elect to have {{productName}} access information from a third party account by providing the access information to such third 
                      party account, our API's and Servers, or services with which we contract, will access such third party accounts
                        for the purposes of performing the Services. This can include aggregation of bank transaction data as well as account verification. 
                        By providing the requisite access information to {{productName}}, you grant us and our Bank and Service Data Providers
                        permission and authority to access the relevant third party accounts, on your behalf or on behalf from the principal account holder, 
                        and retrieve the relevant information to make it available as part of the Services and agree to
                        be bound by our Bank Data Provider's Terms of Use in addition to these Terms of Use. You are not permitted to provide {{productName}} with any 
                        access information for any third party accounts for which
                        you are not the principal account holder unless you have the authority to act on behalf of the principal account holder.</p>
                    <p class="small">{{companyShortName}} is not obligated to review the information for any purpose, including but not limited to accuracy. 
                      It is entirely your responsibility to ensure the accuracy, completeness and timeliness of any information provided to us or
                        any third party service providers in connection with any of the Services.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you wish to connect {{productName}} with your bank or srvice, you are giving us (and our appropriate partner) the authority to get your data from your bank or service.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>U. Registration Information.</h2>
                    <p class="small">You and your users are responsible for maintaining the confidentiality and security of usernames, passwords and other access credentials 
                      which allow the use of {{productName}} and access to the Services. You understand and agree that the you
                        are liable for unauthorized use of your account.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You’re the only one who knows your password, so keep it a secret! If someone gets hold of your password and does something to your account, I’m afraid that’s your fault.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>V. Consumer Issues.</h2>
                    <p class="small">You acknowledge and agree that you will only use {{productName}} and the Services for business purposes and not for personal, family
                        or household purposes.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>{{productName}} is made for businesses.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>W. No Waiver</h2>
                    <p class="small">No delay or omission to exercise any right or remedy accruing upon any breach or default hereunder shall impair any such right or 
                      remedy nor be construed as a waiver of any such breach or default or of any similar breach or default
                        thereafter occurring, nor shall any single or partial exercise thereof preclude any other or further 
                        exercise thereof or the exercise of any other right or remedy. No waiver (or consecutive waivers) of any single breach or default
                        shall operate or be construed as a waiver of any subsequent breach or default.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>From time to time, {{companyShortName}} may pay penalties or waive some of our rights, as defined in these Terms. This doesn’t 
                      mean {{companyShortName}} waives its rights in any other circumstances, and shouldn’t be interpreted as an indicator of future behaviour.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2 id="additional-terms">X. Terms for Additional Services</h2>
                    <p class="small">The use of certain Additional Services are subject to and governed by additional terms of service (<em>i.e.</em> the Specific Additional Service Terms, 
                    noted in the recitals at the beginning of this agreement). 
                        </p>
                    <h3>All Businesses</h3>
                    <p class="small">
                      Certain modules, systems, and interfaces many have Terms of Use and you should check with connected subsystems for those details.
                      In the event additional or specific terms are inconsistent with the Specific Additional Service Terms, those Specific Additional Service
                        Terms will control.
                    </p>
                    
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>Rather than making these Terms even larger, we’ve opted to put some app-specific terms into place. These are the rules that govern the use of those 
                      specific apps, modules, and services linked to in the legal section.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <h2>Y. Digital Millennium Copyright Act.</h2>
                    <p class="small">Some of the content provided on {{productName}} from time to time may be contributed by users. You represent and warrant that any content or material 
                      provided to us does not breach the rights, including copyright, of others.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>You agree not to infringe on other people’s copyrights.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Procedure for Reporting Copyright Infringement Claims: If you believe your copyright in any work has been infringed and such work is accessible 
                      through {{productName}}, you may notify {{companyShortName}}’s copyright agent pursuant to the Digital Millennium
                        Copyright Act of 1998 (DMCA) for notice of claims of copyright infringement here, Attention: Copyright Agent. 
                        For your complaint to be valid under the DMCA, you must provide the following information when providing notice of the
                        claimed copyright infringement, which {{companyShortName}} may then forward to the alleged infringer:</p>
                    <ol type="a">
                        <li class="small">identification of the copyrighted material that you believe has been infringed;</li>
                        <li class="small">identification of the allegedly infringing material on {{productName}}, with sufficient detail to enable us to locate it on {{productName}};</li>
                        <li class="small">your address, telephone number and email address;</li>
                        <li class="small">a statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
                        <li class="small">a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized 
                          to act on behalf of the owner of an exclusive right that is allegedly infringed; and</li>
                        <li class="small">provide your or your agent’s physical or electronic signature.</li>
                    </ol>
                    <p class="small">You may provide this information in writing or by email notification to the following Designated Agent:</p>
                    <p class="small ml-5">
                      Attention: DMCA Office Agent<br>
                      {{companyName}}<br>
                      Post Office Box 5984<br>
                      Brandon, MS  39047<br>
                      United States of America<br>
                      dmcAgent@newcare.io<br>
                    </p>
                    <p class="small">Upon receiving and reviewing your complaint, {{companyShortName}} may remove content that you believe infringes your copyright.</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If someone is infringing on your copyright, follow these instructions to let us know.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">Procedure for Counter-Notification: If material that you have posted to {{productName}} has been taken down, you may file a 
                      counter-notification by contacting {{companyShortName}}’s copyright agent, who can be reached here, Attention: copyrightAgent@newcare.io, which
                        counter-notification must contain the following details:</p>
                    <ol type="a">
                        <li class="small">Identification of the material that has been removed or to which access has been disabled and the 
                          location at which the material appeared before it was removed or disabled;</li>
                        <li class="small">A statement, under penalty of perjury, that you have a good faith belief that the material was 
                          removed or disabled as a result of mistake or misidentification of the material in question;</li>
                        <li class="small">Your name, address and telephone number;</li>
                        <li class="small">A statement that, notwithstanding the choice of laws and venue otherwise set out herein, you consent 
                          for the purposes of this counter-notification and other proceedings under the DMCA to the jurisdiction of the Federal District
                            Court for judicial district in which your address is located or, if your address is outside of the USA, for the jurisdiction of the 
                            Courts of the {{contractOrigin}}, and that you will accept
                            service of process from the person who submitted a notice in compliance with the section (c)(1)(C) of the DMCA, as generally described above; and</li>
                        <li class="small">Your physical or electronic signature.</li>
                    </ol>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If, following a complaint, we took something down that belongs to you, and you want it back up, follow these instructions.</p>
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-sm-12 col-md-8">
                    <p class="small">NOTE THAT YOU MAY BE HELD LIABLE FOR DAMAGES, COURT COSTS AND ATTORNEY'S FEES INCURRED BY US, BY A COPYRIGHT OWNER, 
                      OR BY A COPYRIGHT OWNER'S LICENSEE IF YOU PROVIDE ANY FALSE INFORMATION, OR MISREPRESENT YOURSELF AS THE RIGHTFUL OWNER
                        OF COPYRIGHTED MATERIAL. YOU MAY ALSO BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY.</p>
                    <p class="small">This information should not be construed as legal advice. For further details on the information required 
                      for valid DMCA notifications, see 17 U.S.C. 512(c).</p>
                </div>
                <div class="col-sm-12 col-md-4 pt-5 pt-md-0">
                    <h4 class="header-gradient">IN SIMPLE TERMS<br></h4>
                    <p>If you file a false complaint, there will be consequences.</p>
                </div>
            </div>
        </div>


    </div>


</div>
</template>
<script>

  export default {
    props: {
      productName     : { type: String, default: "Product-Name" },
      productNames    : { type: String, default: "product1, product2, and product3" },
      productWebsites : { type: String, default: "www.website1.com and www.webiste2.com" },
      companyName     : { type: String, default: "Company Name, LLC" },
      companyShortName: { type: String, default: "CompanyShort" },
      contractOrigin  : { type: String, default: "State of Confusion" },
      contractCountry : { type: String, default: "United States of America" }
    },
    data() {
      return {

      }
    }
  }

</script>
<style scoped lang="scss">

.small {
  font-size:12pt;
}

.container {
    text-align: left;
}

p {
    max-width: 80%;
    display: inline-block;
    color: gray;
}

ol {
    max-width: 80%;
    color: gray;
}

h1 {
  color: moccasin;
}

h2 {
    max-width: 80%;
    color: moccasin;
}

h3 {
    max-width: 80%;
    color: moccasin;
}

h4 {
    max-width: 80%;
    color: mediumseagreen;
    font-weight: 800;
}

h5 {
    max-width: 80%;
    color: lightgoldenrodyellow;
}


.noteworthy {
    background: lightgray;
    text-align:left;
    padding: 2em;
    margin-bottom: 3em;
    border-radius: 2em;
    color: moccasin;
    p {
      min-width:100%;
      padding:1em 8em 0 1em;
      text-align:left;
      font-size:1.25em;
      margin-left:2em;
      font-weight:200;
      text-decoration:italic;
    }
}

.ist {
    color: gray;
    float: right;
    max-width: 30%;
    font-size: 1.5em;
    font-weight: 400;
    margin-bottom: 2em;
}
</style>

