 <template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 shadow p-3 mt-1 ml-3  mb-5 bg-white rounded">
          <card>
            <template slot="header">
              <span class="show-hand" v-b-popover.hover.top="'Select Account'" @click="selectAccount(transaction.badgeNumber,transaction.accountNumber)">
                <i class="fa fa-user" ></i>
                <h4 class="card-title">Badge # {{transaction.badgeNumber}} ({{transaction.alias}}) <span style="float:right;"> Posted: {{numeral( transaction.tenderAmount ).format( "0,0.00")}}</span> </h4>
              </span>
              <h5 class="card-title">Receipt</h5>
            </template>
            <div class="rounded" style="background-color:#f0f0f0;height:620px;overflow-y:auto;">
              <table>
                <tbody>
                <tr v-for="(line,index) in receipt" :key="index">
                  <td class="receipt-fixed">{{line}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 shadow p-3 mt-1 ml-3  mb-5 bg-white rounded">
          <card>
<!--            <template slot="header">-->
<!--              <span>-->
<!--                <i class="fa fa-user show-hand" v-b-popover.hover.top="'Select Account'" @click="selectAccount(transaction.badgeNumber,transaction.accountNumber)"></i>-->
<!--                <h4 @click="alert('clicked me')" class="card-title show-hand">Badge # {{transaction.badgeNumber}} ({{transaction.alias}}) for {{numeral( transaction.tenderAmount ).format( "0,0.00")}} </h4>-->
<!--              </span>-->
<!--              &lt;!&ndash; <p class="card-category">{{badge.badgeType}}<b-button class="pull-right btn  btn-sm btn-fill" @click="done" variant="success" >Done</b-button></p> &ndash;&gt;-->
<!--            </template>-->
            <div >
              <table class="table-center-align">
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                   <!-- <tr>
                    <td>Badge Number</td><td>{{transaction.badgeNumber}}</td>
                  </tr> -->
                  <tr><td>Account Number</td><td>{{transaction.accountNumber}}</td></tr>
                  <tr>
                    <td><i v-if="$root.isInRole('transactions-delete')" @click="deleteTransaction" class="fa fa-trash show-hand mr-2" v-b-popover.hover.top="'Remove this transaction'"></i>Posting Total</td><td>{{numeral( transaction.tenderAmount ).format( "0,0.00")}}</td>
                  </tr>
                  <tr><td>Revenue Center</td><td>{{numeral( transaction.revenueCenter ).format( "0")}}</td></tr>
                  <tr><td>Transaction Date</td><td>{{tzDate(transaction.transactionDate )}}</td></tr>
                  <tr><td>Posting Date</td><td>{{tzDate(transaction.postDate)}}</td></tr>
                  <tr><td>Transaction #</td><td>{{transaction.transactionNumber}}</td></tr>
                  <tr><td>Trans Type</td><td>{{ transaction.isPayment ? "Payment" : "Charge"}}</td></tr>
                  <tr>
                    <td><i v-if="$root.isInRole('transactions')" @click="editTotalClass" class="fa fa-pencil show-hand mr-2" v-b-popover.hover.top="'Assign this transaction to a different Total Profile'"></i>Total Profile</td><td>{{ transaction.totalClassId }}</td>
                  </tr>
                  <tr><td >Description</td><td>{{transaction.title}}</td></tr>
                  <tr>
                    <td><i v-if="$root.isInRole('transactions')" @click="editTenderNumber" class="fa fa-pencil show-hand mr-2" v-b-popover.hover.top="'Assign this transaction to a different Tendering Key'"></i>Tender Number</td><td>{{tenderName(transaction.tenderNumber)}}</td>
                  </tr>
                  <tr><td>Terminal Number</td><td>{{transaction.terminalNumber}}</td></tr>
                  <tr><td>Check #</td><td>{{transaction.checkNumber}}</td></tr>
                  <tr><td>Cover Count</td><td>{{transaction.coverCount}}</td></tr>
                  <tr><td>Check Employee</td><td>{{transaction.checkEmployee}}</td></tr>
                  <tr><td>Transaction Employee</td><td>{{transaction.transactionEmployee}}</td></tr>
                  <tr v-if="transaction.companyCode"><td>Company Code</td><td>{{transaction.companyCode}}</td></tr>
                  <tr v-if="transaction.payrollId"><td>Payroll ID</td><td>{{transaction.payrollId}}</td></tr>
                  <tr v-if="transaction.arType"><td>AR Type</td><td>{{transaction.arType}}</td></tr>
                  <tr v-if="transaction.comment"><td>Comment</td><td>{{transaction.comment}}</td></tr>
                  <tr><td>Source</td><td>{{transaction.transactionSource}}</td></tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>

      </div>

      <b-button @click="done" class="btn btn-sm btn-fill" variant="success" >Done</b-button>
      <b-modal ref="modalChangeTotalClass" hide-footer title="Change Posting Total Profile">
        <div class="d-block text-center">
          <h3>Change Posting Profile</h3>
            <b-form-select v-model="totalClass" :options="totalClassList" />
        </div>
        <b-button class="mt-3 btn btn-sm btn-fill btn-danger"  @click="$refs.modalChangeTotalClass.hide()">Cancel</b-button>
        <b-button v-if="totalClass!=transaction.totalClass" class="mt-3 btn btn-sm btn-fill btn-success"  @click="saveChangeTotalClass">Change It</b-button>
      </b-modal>
      <b-modal ref="modalChangeTenderNumber" hide-footer title="Change Tender Key Number">
        <div class="d-block text-center">
          <h3>Change Tendering Key</h3>
            <b-form-select v-model="tenderNumber" :options="tenderList" />
        </div>
        <b-button class="mt-3 btn btn-sm btn-fill btn-danger"  @click="$refs.modalChangeTenderNumber.hide()">Cancel</b-button>
        <b-button v-if="tenderNumber!=transaction.tenderNumber" class="mt-3 btn btn-sm btn-fill btn-success"  @click="saveChangeTenderNumber">Change It</b-button>
      </b-modal>


    </div>
  </div>
</template>
<script>

import moment from "moment-timezone";
import numeral from "numeral";          // find the docs here: http://numeraljs.com/
import _ from "lodash";

import AccountService from "/src/Services/AccountService";
import TenderService from "/src/Services/TenderService";
import TransactionService from "/src/Services/TransactionService";
// import TotalService from "/src/Services/TotalService";
import LTable from "/src/components/UIComponents/Table.vue";
import Card from "/src/components/UIComponents/Cards/Card.vue";

var t = this;
var transactionId = "";

  // "totalClassId": 10,
  // "accountNumber": "26",
  // "badgeNumber": "26",
  // "title": "Sale",
  // "tenderNumber": 10,
  // "tenderAmount": 0,
  // "tenderQty": 1,
  // "trackingId": -1,
  // "isPayment": false,
  // "isMealPlan": false,
  // "revenueCenter": 0,
  // "postDate": "2018-05-18T04:54:41.508Z",
  // "transactionDate": "2018-05-18T04:54:41.508Z",
  // "businessDate": "2018-05-18T04:54:41.508Z",
  // "transactionNumber": "",
  // "checkNumber": "",
  // "coverCount": 1,
  // "transactionTotal": 1.25,
  // "salesItemizer": [],
  // "taxItemizer": [],
  // "previousPay": 0,
  // "receipt": [],
  // "transactionSource": "interface",
  // "_id": "5afe5c94f226662fc80a180a",
  // "badge": {
  //   "badgeNumber": "26",
  //   "badgeType": "user",
  //   "alias": "Manual Badge",
  //   "_id": "5af5f9a8908a2a455889587c",
  //   "firstName": "first"
  // },
  // "totalClass": "5afe4d193c788506f06b2736",
  // "createdAt": "2018-05-18T04:54:44.077Z",
  // "updatedAt": "2018-05-18T04:54:44.077Z",
  // "lastUpdateBy": "unknown",
  // "__v": 0

// Get a single transaction record and display details

export default {
  props: {
    transactionId
  },
  components: {
    LTable,
    Card
  },
  mounted() {
    this.getTenders();
    this.getTransaction(this.transactionId);
  },
  data() {
    return {
      title         : "undefined",
      transaction   : {},
      totalClass    : "",
      tenderNumber  : null,
      totalClasses  : null,
      totalClassList: [],
      tenderList    : null
    };
  },
  computed: {
    receipt() {
      return this.transaction.receipt;
    }
  },
  methods: {
    done() {
      let t=this;
      if( t.$root.lastRouteName() === "Login" ) {       // fixme: this is a patch using localstorage.
        this.$router.push( "/app/accounts");
      } else {
        this.$router.back(-1);
      }
    },
    numeral( x ) {
      return numeral(x);
    },
    tzDate( d ) {
      return moment.tz(d , this.$root.TZ).format('ddd DD-MMM-YY hh:mm a');
    },
    selectAccount( badge , account ) {
      this.$router.push(`/app/account/${badge}/${account}`);
    },
    tenderName( t ) {
      try {
        let tender = _.find( this.tenderList , { value: t });
        return tender.text;
      } catch( error ) {
        return `${t} : Undefined`;
      }
    },
    async saveChangeTotalClass() {
      this.$refs.modalChangeTotalClass.hide();
      var result;
      try {
        result = await TransactionService.transactionChangeTotalClass( this.transaction._id, this.totalClass );
        if( result.status === 200 ) {
          this.$root.successMessage( "Notice" , "Updated Successfully");
        }
        this.getTransaction( this.transaction._id );
      } catch ( err ) {
         if (err) {
          if( err ) {
            console.error( err );
            if( err.response.data ) {
              if( err.response.data.message ) {
                this.$root.errorMessage("Oops", err.response.data.message);
              }
            }
          }
        }
        return;
      }
    },
    async saveChangeTenderNumber() {
      this.$refs.modalChangeTenderNumber.hide();
      var result;
      try {
        result = await TransactionService.transactionChangeTenderNumber( this.transaction._id, this.tenderNumber );
        if( result.status === 200 ) {
          this.$root.successMessage( "Notice" , "Updated Successfully");
        }
        this.getTransaction( this.transaction._id );
      } catch ( err ) {
         if (err) {
          if( err ) {
            console.error( err );
            if( err.response.data ) {
              if( err.response.data.message ) {
                this.$root.errorMessage("Oops", err.response.data.message);
              }
            }
          }
        }
        return;
      }
    },
    async deleteTransaction() {
      if(! await this.$root.confirm(`Delete check # ${this.transaction.checkNumber} for ${numeral( this.transaction.tenderAmount ).format( "0,0.00")}`))  {
        return;
      }
      try {
        let response = await TransactionService.transactionDelete( this.transaction._id );
        if( this.$root.transactionList && this.$root.transactionList.length > 0 ) {
          let index = _.findIndex( this.$root.transactionList , { _id: this.transaction._id });
          if( index >= 0 ) {
            this.$root.transactionList.splice(index,1);
          }
        }
        this.done();
      } catch( error ) {
        await this.$root.alert( error.message , 'warning' );
        console.log( error );
      }
    },
    async editTotalClass( id ) {
      await this.getTotalClasses( this.transaction.badgeNumber );
      if( this.totalClasses.length <= 1 ) {
        alert( "No Available Total Classes To Move To");
        return;
      }
      this.$refs.modalChangeTotalClass.show()
    },
    async editTenderNumber( id ) {
      await this.getTenders();
      if( this.tenderList.length <= 1 ) {
        alert( "No Available Tender Keys to Change To");
        return;
      }
      this.tenderNumber = this.transaction.tenderNumber;
      this.$refs.modalChangeTenderNumber.show()
    },
    async getTotalClasses( badgeNumber ) {
      let t = this;
      try{

        let result  = await AccountService.getAccountTotalClass( badgeNumber );
        t.totalClasses = result.data;
        t.totalClassList = [];
        var x;
        for( x in t.totalClasses.totalClass ) {
          let doc = { value: t.totalClasses.totalClass[x]._id , text: t.totalClasses.totalClass[x].title } ;
          t.totalClassList.push( doc );
        }
      } catch( err ) {
        if( err.message ) {
          console.error( err.message );
          t.$root.errorMessage( "Oops", err.message );
        } else {
          console.error( err );
          t.$root.errorMessage( "Oops" , "Cannot Get Account Total Classes");
        }
      }
    },
     async getTenders() {
      let t = this;
      try{

        if( this.tenderList ) {
          return;
        }

        let result  = await TenderService.getTenders();
        t.tenderList = result.data.map((t) => {
          return {value: t.tenderNumber, text: t.tenderNumber.toString() + ": " + t.title}
        })

      } catch( err ) {
        if( err.message ) {
          console.error( err.message );
          t.$root.errorMessage( "Oops", err.message );
        } else {
          console.error( err );
          t.$root.errorMessage( "Oops" , "Cannot Get Tendering Key list");
        }
      }
    },
    async getTransaction(transactionId ){
      try {
        let [rTrans] = await Promise.all([
          TransactionService.getTransaction(transactionId)
        ]);
        this.transaction = rTrans.data;
        this.totalClass  = this.transaction.totalClass;
      } catch (err) {
        if (err) {
          if( err ) {
            console.error( err );
            if( err.response.data ) {
              if( err.response.data.message ) {
                t.$root.errorMessage("Oops", err.response.data.message);
              }
            }
          }
        }
        return;
      }
    }
  }
};
</script>
<style language="scss">

.table-center-align td:first-child {
  text-align:right;
  font-weight:600;
  background-color: #f0f0f0;
  padding-right:.5em;
  padding-left: 1em;
}

.table-center-align td:last-of-type {
  text-align:left;
  padding-left: .5em;
  padding-right: 1em;
}

.receipt-fixed {
    white-space: pre-wrap;
    font-family: monospace;
    overflow:auto;
}

</style>
