<template>
  <div>


    <!--      <div v-if="isEdit" class="modal-background" @click="this.save()"></div>-->
    <div class="modal-background" @click="save()"></div>
    <!--    <b-modal show style="" size="lg"  hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close v-model="isEdit">-->
    <b-modal show style="" size="lg" @hidden="save()" hide-header hide-footer hide-header-close v-model="isEdit">
      <h4 style="margin:0;padding:0;">{{ title }}</h4>
      <b-form class="input-group" style="width:100%" @input="dataChanged()">

        <div class="input-group input-group-sm mb-1" style="width:15em;">
          <div class="input-group-prepend">
            <span class="input-group-text">Type</span>
          </div>
          <select class="custom-select" id="inputGroupSelect01" v-model="contact.contactType">
            <option v-for="type in contactTypes" :value="type.value">{{ type.text }}</option>
          </select>
        </div>

        <div v-if="isLinked">
          <span style="position:absolute;right:0;font-size:.8em;padding:0 5px 0 5px;color:white;border-radius:.5em;background:firebrick;">Linked Account: <b>{{contact.accountNumber}}</b></span>
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Title</span>
          </div>
          <input type="text" class="form-control" v-model="contact.title" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Name</span>
          </div>
          <input type="text" :readonly="isLinked" class="form-control" v-model="contact.name" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Alias</span>
          </div>
          <input type="text" class="form-control" v-model="contact.alias" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Address</span>
          </div>
          <input type="text" class="form-control" v-model="contact.address1" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text"></span>
          </div>
          <input  type="text" class="form-control" v-model="contact.address2" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text"></span>
          </div>
          <input type="text" class="form-control" v-model="contact.address3" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text"></span>
          </div>
          <input type="text" class="form-control" v-model="contact.address4" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text"></span>
          </div>
          <input type="text" class="form-control" v-model="contact.address5" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">City</span>
          </div>
          <input type="text" class="form-control" v-model="contact.city" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">State</span>
          </div>
          <input type="text" class="form-control" v-model="contact.state" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Zip</span>
          </div>
          <input type="text" class="form-control" v-model="contact.zip" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Phone</span>
          </div>
          <input type="text" class="form-control" v-model="contact.phone">
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Cell</span>
          </div>
          <input type="text" class="form-control" v-model="contact.cell" />
        </div>

<!--        <div class="input-group input-group-sm mb-1">-->
<!--          <div class="input-group-prepend">-->
<!--            <span class="input-group-text">Email</span>-->
<!--          </div>-->
<!--          <input type="email" class="form-control" v-model="contact.email" />-->
<!--        </div>-->

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Email</span>
          </div>
          <input type="email" class="form-control" :style="!isEmailValid ? 'background:rgba(255, 0, 0, 0.1);' : ''" v-model="contact.email" @blur="validateEmail()"/>
          <div class="input-group-append">
            <i v-if="isEmailValid" class="fa fa-check input-group-text" style="width:2em;color:green;"></i>
            <i v-if="!isEmailValid" class="fa fa-times input-group-text" style="width:2em;color:red;"></i>
            <a href="mailto:" class="input-group-text" style="width:2.5em;" v-bind:href="'mailto:' + contact.email">
              <i class="fa fa-envelope"></i>
            </a>
          </div>
        </div>

<!--        <div class="input-group input-group-sm mb-1">-->
<!--          <div class="input-group-prepend">-->
<!--            <span class="input-group-text">Website</span>-->
<!--          </div>-->
<!--          <input type="text" class="form-control" v-model="contact.website" />-->
<!--        </div>-->

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Website</span>
          </div>
          <input type="text" class="form-control" v-model="contact.website" />
          <div class="input-group-append">
            <a :href="contact.website" target="_blank" class="input-group-text" style="width:2.5em;">
              <i class="fas fa-globe"></i>
            </a>
          </div>
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Timezone</span>
          </div>
          <b-form-select type="list" :options="$root.timezones" class="form-control" v-model="contact.TZ" />
        </div>

        <div class="input-group input-group-sm mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Notes</span>
          </div>
          <textarea class="form-control" rows="4" v-model="contact.notes" />
        </div>

      </b-form>
      <b-button @click="save()" class="btn btn-fill btn-sm btn-success" style="margin-left:1em;">Done</b-button>
    </b-modal>

  </div>
</template>
<script>

import iLib     from "/src/Lib/ioihanLib.js";
import Currency from "currency.js";
import Moment   from "moment";
import gravatar from "gravatar";
import Numeral  from "numeral";

export default {
  name      : "comment-card",
  components: {},
  props     : {
    title          : {type: String, default: "Edit Contact"},
    contact        : {type: Object, default: () => { }},
    index          : {type: Number, default: 0},
    iconPlaceholder: {type: String, default: "wavatar"},
    isSelected     : {type: Boolean, default: false},
    isShowTime     : {type: Boolean, default: true},
    isAllowEdit    : {type: Boolean, default: true},
    isAllowDelete  : {type: Boolean, default: true},
    isAllowMore    : {type: Boolean, default: true},
    isInlineNotes  : {type: Boolean, default: false},
    noborder       : {type: Boolean, default: false},
    contactTypes   : {
      type: Array, default: () => {
        return [
          {value: "primary", text: "Primary"},
          {value: "contact", text: "Contact"},
          {value: "customer", text: "Customer"},
          {value: "attendee", text: "Attendee"},
          {value: "employee", text: "Employee"},
          {value: "sales", text: "Sales"},
          {value: "technical", text: "Technical"},
          {value: "prospect", text: "Prospect"},
          {value: "proposal", text: "Proposal"},
          {value: "vendor", text: "Vendor"},
          {value: "relative", text: "Relative"},
          {value: "billing", text: "Billing"},
          {value: "shipping", text: "Shipping"},
          {value: "mailing", text: "Mailing"},
          {value: "newsletter", text: "Newsletter"},
          {value: "portal", text: "Portal"},
          {value: "other", text: "Other"}
        ]
      }
    },
  },
  data() {
    return {
      isEdit       : true,
      isMore       : false,
      isEmailValid : true,
      isLinked     : false,     // linked records block certain fields from modification.
      isDataChanged: false,
    };
  },
  mounted() {
    this.validateEmail();
    if( this.contact && this.contact.contactId ) {
      this.isLinked = true;
    }
  },
  computed: {
    hasAddress() {
      let allAddress = this.contact.address1 + this.contact.city + this.contact.state + this.contact.zip;
      return (allAddress.length > 0);
    },
    hasAnyInfo() {
      if(this.hasAddress) {
        return true;
      }
      let allInfo = this.contact.phone + this.contact.cell + this.contact.email + this.contact.website;
      return (allInfo.length > 0);
    },
    leftTabStyle() {
      let ct = this.contact.contactType;
      switch(ct){
        case 'primary': return 'background:#ff7b00;'; break;
        case 'contact': return 'background:#007bff;'; break;    // the breaks are not required, but if we have other styles happening we may be adding.
        case 'customer': return 'background:#880011;'; break;
        case 'attendee': return 'background:#7b7700;'; break;
        case 'sales': return 'background:#33007b;'; break;
        case 'prospect': return 'background:salmon;'; break;
        case 'proposal': return 'background:firebrick;'; break;
        case 'relative': return 'background:#33cc44;'; break;
        case 'technical': return 'background:black;'; break;
        case 'shipping': return 'background:orange;'; break;
        case 'billing': return 'background:green;'; break;
        case 'mailing': return 'background:blue;'; break;
        case 'newsletter': return 'background:grey;'; break;
        case 'portal': return 'background:teal;'; break;
        case 'other': return 'background:maroon;'; break;
        case 'vendor': return 'background:purple;'; break;
        case 'employee': return 'background:#337733;'; break;
        default: return 'background:#007bff;'; break;
      }
      return 'lime'; // last ditch effort.
    },
  },
  methods : {
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    validateEmail() {
      if( !this.contact.email ) {
        this.isEmailValid = true;
        return;
      }
      this.isEmailValid = iLib.validateEmail(this.contact.email);
    },
    numeral(x) {
      return Numeral(x);
    },
    currency(c) {
      return Currency(c);
    },
    more() {
      this.isMore = !this.isMore;
    },
    enableEdit() {
      if(this.enableEdit) {   // if we are allowed to edit, then we can edit.
        this.isEdit = true;
      }
    },
    myGravatar(email = this.email) {
      // d=identicon
      // d=monsterid
      // d=mm
      // d=retro
      // d=robohash
      // d=wavatar
      // d=blank
      // d=mp
      // d=404
      //
      // return gravatar.url( email, {s: '80', r: 'pg', d: 'mm'}, true);
      return gravatar.url(email, {s: '80', r: 'pg', d: this.iconPlaceholder}, true);
    },
    formatWebLink(url) {
      if(!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      return url;
    },
    leftSelect() {
      this.$emit('left-select', this.index);
    },
    rightSelect() {
      this.$emit('right-select', this.index);
    },
    remove(index) {
      this.isEdit = false;        // let's make sure we're not editing.
      if(!this.contact._id) {     // this requires a save first.
        this.$root.warningMessage('Remove Contact Warning', `OOPS : No ID found for this record. Please SAVE your changes first and try again. Seems this is a new record.`);
        return;
      }
      this.$emit('remove', this.contact._id);
    },
    save() {
      if(this.isDataChanged) {
        this.$emit(`change`, this.contact);
        this.isDataChanged = false;
      } else {
        this.$emit('done', this.contact);
      }
    }
  }
};
</script>
<style lang="scss" scoped>


.input-group-prepend {
  .input-group-text {
    justify-content: flex-end;
    font-weight: 600;
  }
}

/* CSS for Contact Card */
.contact-card {
  line-height: .8;
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  background: #f1f1f1;
  overflow: hidden;
  min-width: 500px; // total size is fixed; look at left and right section size
  width: 500px;
  min-height: 200px;
  margin: .25em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.contact-card .selected {
  background: rgba(0, 128, 0, 0.1);
}

.contact-card .unselected {
  //background: white;
}

.contact-card .left-section {
  flex: 0 0 100px; // works with card and right section size
  background-color: #007bff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: 10%;
}

.contact-card .left-section img {
  position: relative;
  left: 25%;
  border-radius: 50%;
  //padding: 10px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  //background: white;
  border: 6px solid white; /* Add this line to create a white border */
}

.contact-card .right-section {
  flex: 0 0 400px; // look at left section size
  padding: 1rem 1rem 1rem 2rem;
}

.contact-card .right-section .tool-section {
  float: right;
  //position:relative;
  //right:10px;
  //top:10px;
}

.tool-section i:hover {
  color: black;
}

.tool-section .tool-section-info {
  color: black;
  margin-top: .75em;
  font-weight: 100;
  font-size: .8em;
}

.tool-section .tool-section-info:hover {
  font-weight: 900;
  //font-size: 1.5rem;
}

.contact-card .right-section .data-title {
  font-weight: bold;
  //font-size: 1.5rem;
  //margin-bottom: 10px;
  div:first-child {
    //font-weight: bold;
    font-size: 1.1rem;
  }

  div:nth-child(2) {
    margin-top: .35rem;
    font-weight: 100;
    font-size: .8rem;
    text-transform: capitalize;
  }
}

.contact-card .data-section {
  margin: 1em 0 0 1em;
  font-weight: 500;
}

.data-notes {
  margin: 0;
  padding: 0;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: .9rem;
  white-space: pre-line;
  //max-height: calc(90vh - 100px);
  //max-height: calc(90vh);
  width: 100%;
  max-height: 120px;
  overflow: auto;
  //background:lime;
}

.data-notes-inline {
  margin: 0;
  padding: 0;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: .9rem;
  white-space: pre-line;
  //max-height: calc(90vh - 100px);
  //max-height: calc(90vh);
  width: 100%;
  //background:lime;
}

.contact-card .info-item {
  i {
    font-size: .8rem;
    width: .8rem;
  }
}

.contact-card .right-section .info-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-card .right-section .info-item i {
  margin-right: 10px;
}

.contact-card .right-section .active {
  color: #28a745;
  font-weight: bold;
}

.contact-card .right-section .inactive {
  color: #dc3545;
  font-weight: bold;
}

.input-group-text {
  width: 80px;
}

.contact-input-edit {
  font-size: .8em;
  padding: 0;
  margin: 0;
  height: 1.25em;
  line-height: 1em;
}

.card-table {
  font-size: .8em;
  line-height: 70%;
  margin-top: 5px;
  background: #f0f0f0;
  border-radius: 1em;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  backdrop-filter: blur(3px); /* Adjust the blur effect as needed */
  z-index: 999; /* Ensure it's above other content */
}

/** =====================
 * Responsive
 ========================*/
@media only screen and (max-width: 766px) {

}

</style>

