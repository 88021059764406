///-------------------------------------------------------------------------------------------------
// summary:	Menu Item service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getAllMenuItemCategories() {
    const response = Api().get(`/api/1/itemCategories`);
    return response;
  },
  getMenuItemCategories() {
    const response = Api().get(`/api/1/itemCategories/pos?itemType=item`);
    return response;
  },
  addCategory( category ) {
    const response =Api().post(`/api/1/itemCategory`, category );
    return response;
  },
  saveCategory(category) {
    const response =Api().patch(`/api/1/itemCategory/${category._id}`, category );
    return response;
  },
  deleteCategory( id ) {
    const response =Api().delete(`/api/1/itemCategory/${id}`);
    return response;
  },
  getMenuItems( category ) {
    const response = Api().get(`/api/1/items/${encodeURI(category)}`);
    return response;
  },
  findMenuItemsByUpc( upc ) {
    const response = Api().get(`/api/1/itemsByUpc/${upc}`);
    return response;
  },
  getMenuItem( id ) {
    const response = Api().get(`/api/1/item/${id}`);
    return response;
  },
  saveMenuItem( menuItem ) {
    const response = Api().patch(`/api/1/item/${menuItem._id}` , menuItem );
    return response;
  },
  addMenuItem( menuItem ) {
    const response = Api().post(`/api/1/item` , menuItem );
    return response;
  },
  deleteMenuItem( id ) {
    const response = Api().delete(`/api/1/item/${id}`  );
    return response;
  }

}
