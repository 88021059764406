<template>
  <!--<div class="content shadow round" style="border-radius:1em;"> -->
  <div class="col-md-12" style="min-height:750px;padding:1em;">
    <div v-show="!workstationConfig" style="margin-bottom:10px;">
      <i class="fa fa-plus-square show-hand " style="position:absolute;right:1em;font-size:1.5em;" v-b-popover.hover.top='"Add workstation config"' @click="addWorkstationConfig()"></i>
      <b-button class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="$router.go(-1)">Done</b-button>
      <h4 slot="header" class="card-title">Workstation Configurations</h4>
    </div>
    <div v-if="!workstationConfig">
      <b-table striped hover head-variant="dark" class="show-hand" stacked="md" sort-by="sequence" :items="workstationConfigs" :fields="columns" @row-clicked="rowClicked($event)">
        <template v-slot:cell(active)="data">
          <i class="fa fa-trash show-hand" @click.stop="removeWorkstationConfig(data)"></i>
          <i v-if="!data.item.active" class="fa fa-times" style="color:red;"></i>
          <i v-if="data.item.active" class="fa fa-check" style="color:green;"></i>
        </template>
      </b-table>
    </div>
    <div v-else>
      <i class="fa fa-clone" @click="cloneWorkstationConfig" style="float:right;" v-b-popover.hover.top="'Clone this configuratoin'"></i>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="border-radius:.75em;float:left;margin-right:1em;" @click="updateWorkstationConfig( workstationConfig )">
        Save
      </b-button>
      <b-button v-if="workstationConfig" class="btn btn-success btn-fill btn-sm float-left mr-2" style="border-radius:.75em;float:left;" @click="cancelEdit()">Cancel</b-button>
      <h4 style="margin-top:0;">{{ workstationConfig.title }}<span style="margin-left:1em;font-size:.8em;font-weight:300;">({{ workstationConfig.configurationId }})</span></h4>
      <hr />

      <b-tabs v-if="workstationConfig" class="tab-hover" active-nav-item-class="selected-tab">

        <b-tab title="General" style="margin-top:2em;">
          <b-form @input="hasChanged" style="">
            <b-row class="row">
              <b-col class="col col-md-8">
                <b-form-group horizontal>
                  <!-- <b-checkbox v-model="reportFormat.active" style="position:relative;float:right;">Active</b-checkbox> -->
                  <sf-input type="text" cstyle="width:25em;" label="Title" v-model="workstationConfig.title" />
                  <sf-input type="list" cstyle="width:12em;" label="Type" v-model="workstationConfig.workstationType" :options="['3700','ipos']" />
                  <sf-input v-if="workstationConfig.workstationType==='ipos'" type="text" cstyle="width:12em;" label="Configuration ID" v-model="workstationConfig.configurationId" />
                  <hr>
                  <sf-input type="text" label="Primary Printer Address" cstyle="width:12em;" v-model="workstationConfig.primaryPrinter" />
                  <sf-input type="text" label="Primary Printer Name" cstyle="width:12em;" v-model="workstationConfig.primaryPrinterName" />
                  <!-- <b-checkbox v-model="reportFormat.enableExport" style="position:relative;margin-left:14em;">Enable Export</b-checkbox> -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab title="Property">
          <b-row>
            <b-col class="col-12 col-lg-6 ">
              <div style="margin-left:3em;">
                <h4>General</h4>
                <b-form @input="hasChanged()">
                  <!-- propertyLogo : String, -->
                  <sf-input type="text" cstyle="width:20em;" :labelCols="2" label="Name" v-model="workstationConfig.prpertyName" />
                  <sf-input type="text" cstyle="width:20em;" :labelCols="2" label="Email" v-model="workstationConfig.prpertyEmail" />
                  <sf-input type="text" cstyle="width:15em;" :labelCols="2" label="Phone" v-model="workstationConfig.prpertyPhone" />

                </b-form>
              </div>
            </b-col>
            <b-col class="col-12 col-lg-6">
              <div style="margin-left:3em;">
                <h4>Other</h4>
                <b-form @change="hasChanged()">
                  <!-- <b-checkbox-group stacked v-model="reportFormat.filter.totalClass">
                    <b-form-checkbox v-for="(t,index) in activeTotalProfiles" :key="t.totalClassId" :value="t.totalClassId">{{t.title}}</b-form-checkbox>
                  </b-checkbox-group> -->
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Categories" style="margin-top:2em;">
          <b-row @input="hasChanged">
            <b-col class="col col-md-3">
              <h4 style="margin-top:0;margin-bottom:.5em;border-bottom:1px solid black;">Category Selection</h4>
              <sf-input type="list" @change="workstationConfig.categories=[]" cstyle="width:12em;" label="Category Type" v-model="workstationConfig.categoryType" :options="categoryTypes()" />
              <b-checkbox-group v-model="workstationConfig.categories">
                <b-checkbox style="width:50%;" v-for="(f,catIndex) in categories.filter((c) => {  return c.categoryType === workstationConfig.categoryType })" :key="'cat2-'+catIndex.toString()" :value="f.category">
                  {{ f.title }}
                </b-checkbox>
              </b-checkbox-group>

              <!--
                            <ul class="field-list" style="">
                              <li class="show-hand" v-for="(f,index) in availableFields" :key="f.key" @click="addFieldToReport( f )">{{f.label}}</li>
                            </ul> -->
            </b-col>
            <b-col class="col col-md-8" style="background:#eee;border-radius:1em;padding:1em;">
              <h4 style="margin-top:0;margin-bottom:1em;">Current Categories</h4>
              <ul>
                <li v-for="(c,index) in categories.filter((cat) => {  return cat.categoryType===workstationConfig.categoryType &&  workstationConfig.categories.indexOf(cat.category) >= 0 })" :key="'cat-'+index.toString()">
                  {{ c.title }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-tab>


        <b-tab title="Options">
          <b-row>
            <b-col class="col col-md-7">
              <b-form @change="hasChanged()">


                <b-form-group horizontal style="margin-top:2em;padding-left:5em;">
                  <b-form-checkbox v-model="workstationConfig.options.hasCamera">Has Camera</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.hasRfid">Has RFID</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.customerDisplayActive">Customer Display</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.receiptPrinterActive">Receipt Printer</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.signOutAfterSale">Signout After Sale</b-form-checkbox>

                  <sf-input type="number" cstyle="width:10em;" :labelCols="2" label="Timeout" v-model="workstationConfig.options.signOutTimeout" />
                  <b-form-checkbox v-model="workstationConfig.options.canReports">Reports</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.canFunctions">Functions</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.canSignOut">Can Signout</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.canSetup">Can Setup</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.canVoid">Can Void</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.canDiscount">Can Discount</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.consolidateItems">Consolidate Items</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.consolidateTax">Consolidate Tax</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.sortReceipt">Sort Receipt</b-form-checkbox>
                  <b-form-checkbox v-model="workstationConfig.options.transactionStayDown">Trans "Stay Down"</b-form-checkbox>
                  <!-- <sf-input type="textarea" :rows=5 label="CSS CARD Style" v-model="reportFormat.cardStyle" placeHolder="Use Valid CSS Formatting Including Trailing ;" /> -->
                </b-form-group>
                <b-form-group horizontal>
                  <!-- <sf-input type="textarea" :rows=5 label="CSS Table Style" v-model="reportFormat.tableStyle" placeHolder="Use Valid CSS Formatting Including Trailing ;" /> -->
                </b-form-group>
                <p style="font-size:.8em;font-style:italic;color:#444;">Options take effect after terminal signout</p>
              </b-form>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Printers">
          <p class="help-text">Printer setup information </p>
          <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
            <div class="row">
              <div class="col">
                <simple-list
                  title="Receipt Header"
                  :list="workstationConfig.receiptPrinter.header"
                  :isUnique="false"
                  :isAllowBlank="true"
                  :isFixedFont="true"
                  :isAllowEdit="true"
                  :maxLength="40"
                  @change="hasChanged()"
                />
              </div>
              <div class="col">
                <simple-list
                  title="Receipt Footer"
                  :list="workstationConfig.receiptPrinter.footer"
                  :isUnique="false"
                  :isAllowBlank="true"
                  :isFixedFont="true"
                  :isAllowEdit="true"
                  :maxLength="40"
                  @change="hasChanged()"
                />
              </div>

            </div>

          </div>
        </b-tab>


        <b-tab title="Settings">
          <p class="help-text">These settings are a quick way to add configuration items dynamically</p>
          <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">
            <option-management-table
              title="Settings"
              :tableData="workstationConfig.configItems"
              @change="hasChanged()"
            />
          </div>
        </b-tab>


        <b-tab title="Payment Styles">
          <i v-if="workstationConfig.paymentType.length===0" class="fa fa-plus-square" @click="addPaymentType()" />
          <div v-for="(pt,ptIndex) in workstationConfig.paymentType" :key="'wks-'+ptIndex.toString()">
            <h4>Payment Type: {{ pt.title }}</h4>
            <p style="font-size:.8em;color:gray;">Payment Styles provide the styling and the "how" the tendering keys will function. This prevents having to
              style each tendering key separately. </p>
            <b-form @input="hasChanged()">
              <!-- propertyLogo : String, -->
              <b-form-checkbox v-model="pt.active">Active</b-form-checkbox>
              <div style="display:flex;margin:.5em 0 .5em 0;">
                <b-form-checkbox style="padding-right:2em;" v-model="pt.ask.cardNumber">Ask Card Number</b-form-checkbox>
                <b-form-checkbox style="padding-right:2em;" v-model="pt.ask.zipCode">Ask Zipcode</b-form-checkbox>
                <b-form-checkbox style="padding-right:2em;" v-model="pt.ask.pin">Ask Pin Confirmation</b-form-checkbox>
                <b-form-checkbox style="padding-right:2em;" v-model="pt.ask.authorization">Ask Authorization</b-form-checkbox>
              </div>
              <sf-input type="text" cstyle="width:25em;" :labelCols="2" label="Style Name" v-model="pt.title" />
              <sf-input type="text" cstyle="width:25em;" :labelCols="2" label="ID" v-model="pt.id" />
              <sf-input type="list" cstyle="width:10em;" :labelCols="2" label="Type" v-model="pt.paymentType" :options="['icare','shift4','cash']" />

              <sf-input v-if="pt.ask.cardNumber" type="text" cstyle="width:25em;" :labelCols="2" label="Card #" v-model="pt.prompt.cardNumber" />
              <sf-input v-if="pt.ask.zipCode" type="text" cstyle="width:25em;" :labelCols="2" label="Zipcode" v-model="pt.prompt.zipCode" />
              <sf-input v-if="pt.ask.pin" type="text" cstyle="width:25em;" :labelCols="2" label="Pin" v-model="pt.prompt.pin" />
              <sf-input v-if="pt.ask.authorization" type="text" cstyle="width:25em;" :labelCols="2" label="Auth" v-model="pt.prompt.authorization" />

            </b-form>
          </div>
        </b-tab>

        <b-tab title="Payment Keys">
          <i class="ml-2 fa fa-plus-square" style="font-size:1.5em;float:right;margin-right:1em;" v-b-popover.hover.top="'Add a new tender key'" @click="addTenderMedia()" />
          <!-- <h4>Tender Keys</h4> -->
          <b-tabs class="tab-hover" active-nav-item-class="selected-tab" style="margin-top:1em;">
            <template v-for="(tm,ptIndex) in workstationConfig.tenderMedia">
              <b-tab :title="tm.title" :key="'tender-'+ptIndex.toString()">
                <b-form style="margin-top:1em;" @input="hasChanged()">
                  <!-- <b-form-checkbox v-model="pt.active">Active</b-form-checkbox> -->


                  <b-row>
                    <b-col col-sm-12 col-md-12 col-lg-6 col-xl-4 style="margin:5px;padding-top:1em;border:1px solid gray;border-radius:1em;">
                      <i class="ml-2 fa fa-trash-o show-hand" style="position:relative;right:20px;font-size:1.5em;" v-b-popover.hover.top="'Remove this tender'" @click="removeTenderMedia(ptIndex)" />
                      <i class="ml-2 fa fa-clone" style="font-size:1.5em;float:right;margin-right:1em;" v-b-popover.hover.top="'Clone this tender key'" @click="cloneTenderMedia(ptIndex)" />
                      <br>
                      <sf-input type="text" cstyle="width:15em;" :labelCols="3" label="Name" v-model="tm.title" />


                      <sf-input type="list" @change="tenderChanged(tm)" cstyle="width:15em;" :labelCols="3" label="Tender #" v-model="tm.itemNumber" :options="tenders.map((t) => { return {value: t.tenderNumber, text:t.title}})" />
                      <sf-input type="list" cstyle="width:15em;" :labelCols="3" label="Payment Style" v-model="tm.id" :options="workstationConfig.paymentType.map((p) => {return { value:p.id ,text:p.title}})    " />
                      <!-- <sf-input type="list" cstyle="width:10em;" :labelCols="2" label="Type" v-model="tm.paymentType" :options="['credit','debit']"/> -->
                      <sf-input type="number" cstyle="width:15em;" :labelCols="3" label="Halo" v-model="tm.halo" />
                      <sf-input type="text" cstyle="width:15em;" :labelCols="3" label="Icon" v-model="tm.icon" />
                    </b-col>
                    <b-col col-sm-12 col-md-12 col-lg-6 col-xl-4 style="margin:5px;padding:0 0 2em .5em;border:1px solid gray;border-radius:1em;">
                      <h4 class="section-header">Options</h4>
                      <div style="display:block;margin-left:1em;">
                        <sf-input type="text" cstyle="width:15em;" :labelCols="3" label="Display Class" helpText="CSS 'class' for tender button" v-model="tm.class" />
                        <sf-input type="number" cstyle="width:15em;" :labelCols="3" label="Sequence" v-model="tm.sequence" />
                        <div style="margin-left:25%;margin-top:2em;">
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.creditAllowed">Credit Allowed</b-form-checkbox>
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.autoDiscount">Auto Discount</b-form-checkbox>
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.taxExempt">Tax Exempt</b-form-checkbox>
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.openDrawer">Open Drawer</b-form-checkbox>
                          <hr>
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.options.isVerifyPosting">Verify Before Posting</b-form-checkbox>
                          <b-form-checkbox style="margin-right:2em;" v-model="tm.options.isExitSalesScreen">Exit Sales Screen After Posting</b-form-checkbox>
                        </div>
                      </div>
                    </b-col>
                    <b-col col-sm-12 col-md-12 col-lg-6 col-xl-4 style="margin:5px;padding-top:1em;border:1px solid gray;border-radius:1em;">
                      <h4 class="section-header">Presets</h4>
                      <sf-input type="text" cstyle="width:15em;" :labelCols="3" label="Badge Number" v-model="tm.presets.badgeNumber" />
                      <sf-input type="number" cstyle="width:15em;" :labelCols="3" label="Amount" v-model="tm.presets.amount" />
                      <p style="font-size:.7em;color:gray;font-style:italic;padding:0 2em;">NOTE: Defining presets will override the cashiers ability to enter that field for this
                        tendering key</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col col-sm-12 col-md-6 style="margin:5px;padding:0 15px 2em 15px;border:1px solid gray;border-radius:1em;">
                      <h4 class="section-header">Allowed Account Classes</h4>
                      <b-checkbox-group v-model="tm.accountClass" style="margin-left:3em;padding:0;line-height:.8em;">
                        <b-form-checkbox v-for="(ac,index) in accountClasses" style="" :id="'ac'+ptIndex+ac.accountClassId" :value="ac.accountClassId" :key="'account-class-'+index.toString()">
                          <span style="text-transform: capitalize;">{{ ac.title }}</span></b-form-checkbox>
                      </b-checkbox-group>
                    </b-col>
                    <b-col col-sm-12 col-md-6 style="margin:5px;padding:0 15px 2em 15px;border:1px solid gray;border-radius:1em;">
                      <h4 class="section-header">Allowed Total Classes</h4>
                      <b-checkbox-group v-model="tm.totalClass" style="margin-left:3em;padding:0;line-height:.8em;">
                        <b-form-checkbox v-for="(tc,index) in totals" style="" :id="'tc'+ptIndex+tc.totalClassId" :value="tc.totalClassId" :key="'allowed-ac-'+index.toString()">
                          <span style="text-transform: capitalize;">{{ tc.title }}</span></b-form-checkbox>
                      </b-checkbox-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

            </template>
          </b-tabs>
        </b-tab>


      </b-tabs>

    </div>
    <div class="clearfix"></div>
    <br />
  </div>
  <!--</div>-->
</template>

<script>

// import TenantService  from "/src/Services/TenantService";
import Moment                from "moment-timezone";
import AccountService        from "/src/Services/AccountService";
import TotalService          from "/src/Services/TotalService";
import TenderService         from "/src/Services/TenderService";
import KioskService          from "/src/Services/KioskService";
import MenuItemService       from "/src/Services/MenuItemService";
import Card                  from "/src/components/UIComponents/Cards/Card.vue";
import SfInput               from "/src/components/UIComponents/Inputs/SFormInput.vue";
import draggable             from 'vuedraggable'
import OptionManagementTable from "/src/components/UIComponents/Cards/OptionManagementTableKeyValue.vue";
import SimpleList            from "/src/components/UIComponents/Lists/SimpleListOnly.vue";

export default {
  components: {
    Card,
    SfInput,
    draggable,
    SimpleList,
    OptionManagementTable
  },
  data() {
    return {
      saveDisabled      : true,
      changeCount       : 0,
      isChanged         : false,
      isAdding          : false,
      isEditing         : false,
      small             : true,
      totals            : null,
      accountClasses    : null,
      categories        : null,
      tenders           : null,
      workstationConfig : null,
      workstationConfigs: null,
      columns           : [
        {key: "active", Label: "", sortable: true},
        {key: "workstationType", Label: "Type", sortable: true},
        {key: "configurationId", Label: "Config ID", sortable: true},
        {key: "title", Label: "Title", sortable: true},
        // {key: "categories", Label: "Categories", dataType:"string-array", sortable: true},
        {key: "createdAt", Label: "Created", sortable: true, formatter: (value, key, item) => {return Moment(value).fromNow()}},
        {key: "updatedAt", Label: "Last Update", sortable: true, formatter: (value, key, item) => {return Moment(value).fromNow()}}
      ],

    };
  },
  computed: {},
  methods : {
    hasChanged() {
      this.isChanged = true;
    },
    async removeWorkstationConfig(e) {
      if(!confirm("Remove " + e.item.title + "?")) {
        return;
      }
      if(!confirm("Really sure to remove " + e.item.title + "?")) {
        return;
      }
      try {
        let results = await KioskService.deleteWorkstationConfig(e.item._id);
        this.workstationConfigs.splice(e.index, 1);
      } catch(error) {
        console.log(error);
      }
    },
    categoryTypes() {
      let cats = [ ...new Set(this.categories.map((c) => { return c.categoryType })) ]
      return cats;
    },
    async rowClicked(item) {
      //console.log(JSON.stringify(item, null, 2));
      await this.getWorkstationConfigById(item._id);
    },
    cancelEdit() {
      this.workstationConfig = null;
      this.isChanged         = false;
    },
    async cloneWorkstationConfig() {
      if(!confirm("Clone this workstation config?")) {
        return;
      }
      var t = this;
      try {
        let results             = await KioskService.addWorkstationConfig({title: "New Workstation Configuration"});
        t.workstationConfig._id = results.data._id;
        t.workstationConfig.title += "(clone)"
        this.hasChanged();
      } catch(error) {
        console.log(error);
      }
    },
    async addWorkstationConfig() {
      if(!confirm("Add new workstation config?")) {
        return;
      }
      var t = this;
      try {
        let results         = await KioskService.addWorkstationConfig({title: "New"});
        t.workstationConfig = results.data;
      } catch(error) {
        console.log(error);
      }
    },
    addPaymentType() {
      // let nextId = this.workstationConfig.paymentType.reduce((p,v=0) => {return max(v,p.id)});
      let nextId = 10;
      let pt     = {
        "prompt"     : {
          "cardNumber"   : "Please Enter Badge Information",
          "zipCode"      : "Please Enter Zip Code",
          "pin"          : "Please Enter Pin",
          "authorization": "Please Enter Auth Code"
        },
        "ask"        : {
          "cardNumber"   : true,
          "zipCode"      : false,
          "pin"          : false,
          "authorization": false
        },
        "id"         : nextId,
        "active"     : true,
        "title"      : "New",
        "paymentType": "icare",
        "icon"       : "fa fa-user-circle",
        "class"      : "",
      }

      this.workstationConfig.paymentType.push(pt);
    },
    addTenderMedia() {
      let t = {
        "id"           : 0,
        "itemNumber"   : 0,
        "sequence"     : 100,
        "paymentType"  : "icare",
        "title"        : "New",
        "icon"         : "fa fa-user",
        "class"        : "",
        "halo"         : 100,
        "presets"      : {
          "badgeNumber" : "",
          "badgeName"   : "",
          "amount"      : 0,
          "totalClassId": ""
        },
        "creditAllowed": true,
        "openDrawer"   : false,
        "autoDiscount" : 0,
        "taxExempt"    : false,
        "accountClass" : [],
        "totalClass"   : []
      }
      this.workstationConfig.tenderMedia.push(t);
      this.hasChanged();
    },
    cloneTenderMedia(i) {
      let t = Object.assign({}, this.workstationConfig.tenderMedia[ i ]);
      t.title += " (clone)";
      this.workstationConfig.tenderMedia.push(t);
      this.hasChanged();
    },
    async removeTenderMedia(index) {
      if(!await this.$root.confirm(`Remove tender media [${this.workstationConfig.tenderMedia[ index ].title}]?`)) {
        return;
      }
      this.workstationConfig.tenderMedia.splice(index, 1);
      this.hasChanged();
    },
    async getWorkstationConfigById(id) {
      try {
        let results            = await KioskService.getWorkstationConfigById(id);
        this.workstationConfig = results.data;
      } catch(error) {
        console.log(error)
      }
    },
    async getWorkstationConfigs() {
      try {
        let results             = await KioskService.getWorkstationConfigs();
        this.workstationConfigs = results.data;
        this.workstationConfig  = null;
        this.isChanged          = false;
      } catch(error) {
        console.log(error)
      }
    },
    async updateWorkstationConfig(config) {
      try {
        await KioskService.updateWorkstationConfig(config);
        this.getWorkstationConfigs();
      } catch(error) {
        console.log(error)
      }
    },
    async tenderChanged(t) {

      let tender = _.find(this.tenders, {tenderNumber: parseInt(t.itemNumber)});
      // console.log( t , tender );
      if(!tender) {
        return await this.$root.alert("Cannot find tender record");
      }
      // t.id = t.itemNumber;
      // t.paymentType = tender.paymentType;
      // alert( "tender changed " );
    },
    async getCategories() {
      try {
        let response    = await MenuItemService.getAllMenuItemCategories();
        this.categories = response.data;
      } catch(error) {
        console.log(error.message);
      }
    },
    async getAccountClasses() {
      try {
        let response        = await AccountService.getAccountClasses();
        this.accountClasses = response.data;
      } catch(error) {
        console.log(error)
      }
    },
    async getTenders() {
      try {
        let response = await TenderService.getTenders();
        this.tenders = response.data;
      } catch(error) {
        console.log(error.message);
      }
    },
    async getTotals() {
      let t = this;
      let response;

      try {
        response    = await TotalService.getTotals();
        this.totals = response.data;
      } catch(err) {
        console.log("getTotals error: ", err);
        if(err.response) {
          if(err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the total");
        }
      }
    }

  },
  async created() {
    let t = this;
    try {
      await Promise.all([
                          t.getWorkstationConfigs(),
                          t.getCategories(),
                          t.getTotals(),
                          t.getAccountClasses(),
                          t.getTenders()
                        ]);
    } catch(err) {
      if(err.response) {
        if(err.response.data) {
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      } else {
        t.$root.errorMessage("Oops", "Had an error getting initial settings");
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.section-header {
  margin:.5 0 1em 0;

}

.report-field-header:hover {
  background: #aaa;
  color: white;
  cursor: pointer;
}

.field-list {
  list-style-type: square;
}

</style>
