<template>
  <!--   <div class="menu-item-detail shadow p-3 mb-5 bg-white rounded">-->
  <div class="" @change="setChanged">
    <h2>{{ currentItem.title }}</h2>

    <b-form-group horizontal breakpoint="lg" label-size="lg" label-class="font-weight-bold pt-0">
      <b-row>
        <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="border-right:3px solid royalblue;">
          <sf-input v-model="currentItem.itemNumber" cstyle="width:6em;" type="number" required label="Item Number" :readonly="!$root.isSA()"></sf-input>
          <sf-input v-model="currentItem.sequence" cstyle="width:6em;" type="number" required label="Sequence"></sf-input>
          <sf-input cstyle="width:15em;" v-model="currentItem.itemStyle" type="list" :options="menuItemClasses" label="Item Class" />
          <sf-input cstyle="width:15em;" v-model="currentItem.itemPostingGroup" type="list" :options="itemPostingGroups" label="Item Posting Group" />
          <sf-input v-model="currentItem.title" type="text" required label="Item Name" placeholder="Item Name"></sf-input>

          <sf-input label="Detailed Description" type="textarea" v-model="currentItem.description" />
        </b-col>
        <b-col>
          <div style="padding-left:2em;">
            <b-form-checkbox v-model="currentItem.options.active">Active</b-form-checkbox>
            <b-form-checkbox v-model="currentItem.options.openItem">Open Item</b-form-checkbox>
            <b-form-checkbox v-model="currentItem.options.askDescription">Ask Description</b-form-checkbox>
            <hr>
            <sf-input v-model="currentItem.servings" cstyle="width:6em;" type="number" label="# Servings"></sf-input>
            <sf-input v-model="currentItem.cost" cstyle="width:6em;" type="currency" label="Cost"></sf-input>
            <sf-input v-model="currentItem.maxChoice" cstyle="width:6em;" type="number" label="Max Per Order"></sf-input>

          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="border-right:3px solid green;">
          <sf-input v-model="currentItem.POSName" type="text" required :maxlength=16 label="POS Name" cstyle="width:12em;" placeholder="POS Name"></sf-input>
          <sf-input v-model="currentItem.POSName2" type="text" :maxlength=16 label="POS Name2" cstyle="width:12em;" placeholder="POS Name 2"></sf-input>
          <hr>
          <template v-for="(p,priceIndex) in currentItem.price">
            <sf-input v-if="category.priceActive[priceIndex]===true" :key="priceIndex" cstyle="width:10em;" v-model="currentItem.price[priceIndex]" type="currency" :label="category.priceNames[priceIndex]" />
          </template>
        </b-col>
        <b-col>
          <simple-list-only style="margin:0;padding:0;"
                            title="UPC Codes"
                            confirmDeletePrompt="Remove this UPC code?"
                            @change="setChanged()"
                            :list="currentItem.UPCS"
          ></simple-list-only>
        </b-col>
      </b-row>

    </b-form-group>

  </div>
</template>

<script>

import Card           from './Card.vue';
import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
import SimpleListOnly from "/src/components/UIComponents/Lists/SimpleListOnly.vue";
import Currency       from "currency.js";

export default {

  props     : {
    "currentItem": {type: Object, default: () => {}},
    "category"   : {type: Object, default: () => {}}
  },
  components: {
    Card,
    SfInput,
    SimpleListOnly,
    Currency
  },
  mounted() {
    // console.log("MenuItemDetail mounted");
  },
  beforeDestory() {
    // console.log("menuitemDetail before destroy");
  },
  activated() {
    // console.log("mid Activated");
  },
  deactivated() {
    // console.log("mid deactivated");
    if(this.itemChanged) {
      // console.log("sparking the emit");
      this.$emit("change", currentItem);
    }
  },
  data() {
    return {
      itemChanged      : false,
      itemTitle        : "",
      menuItemClasses  : [
        {text: "Default", value: ""},
        {text: "Aqua", value: "aqua"},
        {text: "Blue", value: "blue"},
        {text: "Coral", value: "coral"},
        {text: "Gold", value: "gold"},
        {text: "Gray", value: "gray"},
        {text: "Green", value: "green"},
        {text: "Light Green", value: "light-green"},
        {text: "Purple", value: "purple"},
        {text: "Steel Blue", value: "steelblue"},
      ],
      itemPostingGroups: [
        {text: "Food", value: 0},
        {text: "Beverage", value: 1},
        {text: "Liquor", value: 2},
        {text: "Beer", value: 3},
        {text: "Wine", value: 4},
        {text: "Appetizers", value: 5},
        {text: "Desert", value: 6},
        {text: "Venue", value: 7},
        {text: "Decorations", value: 8},
        {text: "Entertainment", value: 9},
        {text: "Equipment", value: 10},
        {text: "Transportation", value: 11},
        {text: "Delivery", value: 12},
        {text: "Publicity", value: 13},
        {text: "Labor", value: 14},
        {text: "Outside Labor", value: 15},
        {text: "Other", value: 16},

      ]

    };
  },
  computed: {},
  methods : {
    currency(c) {
      return Currency(c);
    },
    formatMoney(value, event) {
      return this.currency(value);
    },
    setChanged() {
      // console.log("Setting item changed");
      this.itemChanged = true;
      this.$emit("change", this.currentItem);
    }
  }
};
</script>

<style lang="scss" scoped>

/*******
  #TENANT-CARD
 */

.menu-item-detail {
  width: 300px;
  max-height: 256px;
  float: left;
  padding: .5em;

  .card {
    padding: .25em;
    border: none;
    height: 175px;
    width: auto;

    img {
      display: block;
      max-height: 128px;
      max-width: 256px;
    }

    div {
      span {
        font-size: 1.25em;
      }
    }
  }
}


</style>
