/**
 * Created by wscott on 7/5/2020.  Machine: {}
 *
 *  Revision History for PieChart.js ::  iCareAdmin
 *
 *
 *  Module Overview:
 *
 *
 *
 *
 *
 *      Initial Writing: 7/5/2020        wjscott
 *      ------------------------------------------------------------------------
 *
 *       1.
 *
 */

import { Pie, mixins } from 'vue-chartjs';

export default {
  name   : 'pie-chart',
  mixins : [ Pie, mixins.reactiveProp ],
  props  : {
    chartData: {type: Object, default: () => null},
    options  : {type: Object, default: () => null}
  },
  data   : () => ({}),
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {},
}

