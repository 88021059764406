<template>
    <div>

      <div>
        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>
        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>
        <h4>Asset Configuration</h4>
      </div>
      <hr>

      <b-tabs v-if="assetConfig" id="assetConfig" v-model="selectedTab" class="tab-hover" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab" >

        <b-tab title="Overhead">
          <div style="height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;">
<!--            <h4>Asset Configuration</h4>-->
            <form style="margin-top:2em;" @input="dataChanged()">
              <sf-input label="Notes" :labelCols="1" :rows="18" type="textarea" v-model="assetConfig.notes"></sf-input>
            </form>
<!--            <p style="font-size:.8em;color:gray;font-style:italic;text-align:right;">When configuring the Key/Description fields, the Key is for the Database, while the description is the public visible entry</p>-->
          </div>
        </b-tab>

        <b-tab title="Asset Types" >

          <div v-if="assetConfig.assetTypes.length===0" style="padding:2em;">
            <b-button class="btn btn-sm btn-fill btn-success" @click="addNewAssetType()">Let's begin.  Yep, start asset configuration!</b-button>
          </div>

          <div v-if="assetConfig.assetTypes.length>0" style="min-height:650px;border-left:4px solid gray;" >
            <b-tabs vertical card class="tab-hover-left" v-model="selectedSubTab" content-class="mt-2 ml-3 mr-3" active-nav-item-class="selected-tab-left" >

              <!----------------------------------------------------------------------------[ Asset Types ]-->
<!--              <b-tab :title="asset.title" v-for="(asset,index) in assetConfig.assetTypes" :key="index">-->
<!--                <div title="Asset Types">-->
<!--                  <div style="width:80%;height:650px;padding-right:1em;overflow-y:auto;overflow-x:hidden;margin-left:auto;margin-right:auto;">-->
<!--                    <option-management-table-->
<!--                      title="Asset Types"-->
<!--                      :tableData="assetConfig.assetTypes"-->
<!--                      @change="dataChanged()"-->
<!--                    ></option-management-table>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-tab>-->
              <b-tab :title="asset.text" v-for="(asset,index) in assetConfig.assetTypes" :key="index">
                <div>
<!--                  <h4 style="position:absolute;top:-100px;border-radius:.5em;border:.25em gray solid;background:lightgray;margin-left:1em;padding:.25em 2em .25em 2em;">{{asset.text}}</h4>-->
                  <div class="float-right" style="margin-top:1em;font-size:1.4em;">
                    <i class="fa fa-plus-square show-hand ml-3 mr-3"  v-b-popover.hover.top="'Add New Asset Type'" @click="addNewAssetType()"></i>
                    <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove this Asset Type'" @click="removeAssetType(index)"></i>
                  </div>

                  <div class="row">
                    <div class="col-md-12 col-sm-12" style="min-height:500px;border-left:4px solid gray;">
                      <b-tabs  class="tab-hover" v-model="selectedAssetTab" content-class="mt-3 ml-3 mr-3" active-nav-item-class="selected-tab" >
                        <b-tab title="General">
                          <h4>General Information</h4>
                          <b-form @input="dataChanged()">
                            <sf-input v-model.trim="asset.value" :readonly="!(isAdding || asset.text.length===0)" type="text" cstyle="width:12em;" label="Database Key" helpText="This is the value used internally in the system" @change="fixDbValue(asset)"/>
                            <sf-input v-model="asset.text" label="Title" helpText="What we show on the screen" />
                            <sf-input v-model="asset.sequence" type="number" cstyle="width:12em;"  @change="sortAssetTypes()" label="Display Sequence" helpText="The Order in the list"/>
                            <sf-input v-if="asset.options.enableDepreciation" v-model="asset.depreciation.depreciationMethod" type="list" :options="depreciationMethods" label="Depreciation Method"></sf-input>
                          </b-form>
                        </b-tab>

                        <b-tab title="Categories">
                          <h4>Asset Categories</h4>
                          <!-- <i class="nc-icon nc-simple-add show-hand float-left" @click="addSkill"></i>  -->
                          <div class="mb-3">
                            <b-button @click="addCategory(asset)" class="btn btn-sm btn-fill btn-success float-left mr-2">Add </b-button>
                            <sf-input  cstyle="width:15em;" :labelCols="0"  v-model="category" @keyup.enter.native="addCategory(asset)"/>
                          </div>
                          <ol>
                            <li v-for="(c,index) in asset.categories" :key="index" >
                              <i class="nc-icon nc-scissors show-hand float-left mr-2" @click="removeCategory(asset,c)"></i>
                              {{c}}
                            </li>
                          </ol>
                        </b-tab>


                        <b-tab title="Options">
                          <h4>Asset Options</h4>
                          <b-form style="margin-left:10em;line-height:.8em;"  @input="dataChanged()">

                            <b-checkbox v-model="asset.options.enableInventoryTag" v-b-popover.hover.top="'Enable the Inventory Tag field typically used for pre-printed inventory tags and barcode tracking'">Enable Inventory Tag Field</b-checkbox>
                            <b-checkbox v-model="asset.options.enableMakeModel" v-b-popover.hover.top="'Enable the Make/Model and related fields'">Enable Make/Model Related Fields</b-checkbox>
<!--                            <b-checkbox v-model="asset.options.enableComments" v-b-popover.hover.top="'Enable comment tracking'">Enable Comments</b-checkbox>-->
<!--                            <b-checkbox v-model="asset.options.enableParts">Enable Parts</b-checkbox>-->
                            <b-checkbox v-model="asset.options.enablePurchasing" v-b-popover.hover.top="'Enable the purchasing related fields, like cost, purchase price, etc'">Enable Purchasing Related Fields</b-checkbox>
                            <b-checkbox v-model="asset.options.enableSubscription" v-b-popover.hover.top="'Enable subscription related fields'">Enable Subscription Related Fields</b-checkbox>
                            <b-checkbox v-model="asset.options.enableVehicle" v-b-popover.hover.top="'These fields include VIN, Mileage, etc.'">Enable Vehicle Related Fields</b-checkbox>
                            <b-checkbox v-model="asset.options.enableBookable" v-b-popover.hover.top="'When enabled, this asset can be BOOKED for events'">Enable Bookable Options</b-checkbox>
                            <b-checkbox v-model="asset.options.enableBilling" v-b-popover.hover.top="'When enabled, Billing related fields are enabled '">Enable Billing Fields</b-checkbox>
                            <b-checkbox v-model="asset.options.enableNotes">Enable Notes Section</b-checkbox>
                            <b-checkbox v-model="asset.options.enableLoanAndBorrow">Enable The Loan & Borrow Fields</b-checkbox>
                            <b-checkbox v-model="asset.options.enableRoomAsset">Enable Room Asset Option</b-checkbox>
                            <b-checkbox v-model="asset.options.enableFiles">Enable Files</b-checkbox>
                            <b-checkbox v-model="asset.options.enableDepreciation">Enable Depreciation</b-checkbox>

                          </b-form>
                        </b-tab>


                      </b-tabs>
                    </div>
                  </div>
                </div>
              </b-tab>

            </b-tabs>

          </div>
        </b-tab>

      </b-tabs>


    </div>
</template>

<script>
  // import AccountService from "/src/Services/AccountService";
  import TenantService  from "/src/Services/TenantService";
  // import QueryService   from "/src/Services/QueryService";
  import Moment         from "moment-timezone";
  import Card           from "/src/components/UIComponents/Cards/Card.vue";
  import OptionManagementTable from "/src/components/UIComponents/Cards/OptionManagementTable.vue";
  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LTable         from "/src/components/UIComponents/Table.vue";

  export default {
  components: {
    LTable,
    Card,
    SfInput,
    OptionManagementTable
  },
  data() {
    return {
      saveDisabled       : true,
      isChanged          : false,
      isAdding           : false,
      isEditing          : false,
      small              : true,
      selectedTab        : 0,
      selectedSubTab     : 0,
      selectedAssetTab   : 0,
      assetConfig        : null,
      category           : "",
      depreciationMethods: [
        {value: "straight", text: "Straight Line" } ,
        {value: "decline", text: "Declining Balance" },
        {value: "sum", text: "Sum of Years' Digits" }
      ]
    };
  },
  watch: {
  },
  mounted: async function() {
    await Promise.all([
      this.getAssetConfig()
    ]);
  },
  async beforeRouteLeave( to , from, next ) {
    if( !this.isChanged ) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async alert(e) {
      // console.log( "assetConfig-" , this.assetConfig );
      await this.$root.alert( e );
    },
    async save() {
      await this.saveAssetConfig();
      this.done();
    },
    async cancel() {
      if( this.isChanged ) {
        if( ! await this.$root.confirm( "Data changed -- You sure?" )) {
          return;
        }
      }
      this.$router.go(-1);
    },
    moment(d) {
      return Moment(d);
    },
    fixDbValue( v ) {
      v.value = v.value.toLowerCase().replaceAll(" " , "-");
    },
    dataChanged() {
      this.isChanged = true;
    },

    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },
    async addNewAssetType() {
      if( ! await this.$root.confirm( "Add New Asset Type" )) {
        return;
      }
      this.assetConfig.assetTypes.push( {
                                           text: "" ,
                                           value: "database-key" ,
                                           categories: [],
                                           options: {}
                                         });
      this.dataChanged();
    },
    async removeAssetType( index ) {
      if( ! await this.$root.confirm( "Remove " + this.assetConfig.assetTypes[ index ].text + "?" )) {
        return;
      }
      this.dataChanged();
      this.assetConfig.assetTypes.splice(index,1);
    },
    addCategory( asset ) {
      var t = this;
      if (t.category.length > 0) {
        if (asset.categories.indexOf(t.category) < 0) {
          asset.categories.push(t.category);
          asset.categories = asset.categories.sort();
          this.dataChanged();
        }
      }
      t.category = "";
    },
    removeCategory(asset,c) {
      var i = asset.categories.indexOf(c);
      if (i >= 0) {
        this.category=c;
        asset.categories.splice(i, 1);
        this.dataChanged();
      }
    },
    sortAssetTypes() {
      this.assetConfig.assetTypes = _.sortBy( this.assetConfig.assetTypes, (a) => parseInt( a.sequence));
    },
    async getAssetConfig() {
      var t = this;
      var response;
      try {
        response = await TenantService.getAssetConfig( );
        this.assetConfig = response.data;

        this.sortAssetTypes();

        // console.log( "config=" , this.assetConfig );


      } catch (err) {
        console.log("getAssetConfig error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the asset configuration");
        }
      }
    },

    async saveAssetConfig() {
      var t = this;
      var response;
      try {
        this.sortAssetTypes();
        response = await TenantService.saveAssetConfig(this.assetConfig);
        this.$root.tenant.assetTypes = this.assetConfig.assetTypes;
        t.$root.successMessage("SUCCESS", "Asset Config Updated");
        t.isChanged = false;
      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Asset Configuration Data");
      }
    }
  }

};
</script>

<style lang="scss">

</style>
