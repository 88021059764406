<template>
  <div style="background:black;">
    <terms 
      productName="iCare"
      productWebsites="icare.cafe, icare.team, ipos.cafe, api.icare.cafe, and display.dog"
      productNames="iCare, iPOS, iCare Teams, and Display Dog"
      companyName="Newcare International, LLC"
      companyShortName="Newcare"
      contractOrigin="state of Mississippi"
      contractCountry="United States of America"
    />
  </div>
</template>
<script>

  // import terms from "../components/tos/TermsOfUse.vue";
  import terms from "./TermsOfUse.vue";

  export default {
    name: "tos-page",
    components: {
      terms
    },
    data() {
      return {

      }
    }
  }

</script>
<style>
</style>