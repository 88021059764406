<template>
  <div class="content" v-if="data">
    <template slot="header" slot-scope="data" >
      <h4 style="margin-top:0;" >Change Order <span style="font-size:.85em;">: {{data.orderNumber}}</span></h4>
    </template>

    <form @change="dataChanged()">
      <table class="form-group" style="width:100%;">

        <tr><td>
          <!-- <sf-input type="list" cstyle="width:15em;" label="Type" :options=" [ 'change']" v-model="changeOrder.orderType" @input="ticketSetChanged()" /> -->

          <sf-input type="date-local" label="Order Date" cstyle="width:15em;"  v-model="data.orderDate" />
          <sf-input type="text" label="Order #" cstyle="width:15em;" v-model="data.orderNumber" :readonly="true" />
          <sf-input v-if="data.step && data.step.id" type="text" :disabled="true" label="For Step" cstyle="" placeHolder="For Step ?" v-model="data.step.title" />
          <sf-input v-if="data.step && data.step.id && data.step.budgetYear" type="text" :disabled="true" label="Budget Year" cstyle="width:12em;"  v-model="data.step.budgetYear" />
          <sf-input type="text" label="Title" cstyle="" placeHolder="Summary of this change order" v-model="data.title" />
        </td></tr>

        <tr><td>
          <sf-input type="textarea" label="Description" :rows="5" id="change-order-description" placeHolder="Brief description of this change order. (optional)" v-model="data.description"></sf-input>
          <sf-input type="textarea" label="Reason" :rows="2" id="change-order-reason" placeHolder="Why are you issuing this change order? (optional)" v-model="data.reason"></sf-input>
        </td></tr>

        <tr><td>&nbsp;</td></tr>
        
        <tr><td>
          <sf-input type="number" label="Hours"    cstyle="width:12em;"  v-model="data.hours" />
          <sf-input type="currency" label="Fixed Labor $"    cstyle="width:12em;"  v-model="data.labor" />
          <sf-input type="currency" label="Parts"    cstyle="width:12em;"  v-model="data.parts" />
          <sf-input type="currency" label="Supplies" cstyle="width:12em;"  v-model="data.supplies" />
          <sf-input type="currency" label="Expenses" cstyle="width:12em;"  v-model="data.expenses" />
          <sf-input type="currency" label="Outside Expenses"  cstyle="width:12em;"  v-model="data.outside" />
        </td></tr>

        <tr><td>&nbsp;</td></tr>

        <tr><td>
          <sf-input type="text" label="Requested By"  cstyle="width:15em;"  v-model="data.requestedBy" />
          <sf-input type="text" label="Approved By"   cstyle="width:15em;"  v-model="data.approvedBy" />
          <sf-input type="date-local" label="Approval Date" cstyle="width:15em;"  v-model="data.approvedDate" />
        </td></tr>
      </table>
    </form>

    <template slot="footer" slot-scope="{ data }">
      <b-button class="btn-fill" size="sm" variant="success" @click="save()">
        DONE
      </b-button>
    </template>
  </div>
</template>

<script>

  import SfInput         from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LiveSearch      from "/src/components/UIComponents/Inputs/LiveSearch.vue";

  export default {
    props: {
      changeOrder: { type: Object, default: () => {}}
    },
    components: {
      SfInput,
      LiveSearch,
    },
    data() {
      return {
        data: null
      }
    },
    created() {
      this.data = this.changeOrder
    },

    methods: {
      save() {
        this.$emit('save', this.data)
      },
      done() {
        this.$emit('done', this.data)
      },
      dataChanged() {
        this.$emit('changed')
      },
      selectStep( step ) {
        this.data.stepId    = step._id;
        this.data.stepTitle = step.title;
        this.dataChanged()
      },

    }
  };
</script>



<style lang="scss">

</style>
