<template>
  <div class="ml-5" >
    <vue-avatar
      :width='width'
      :height='height'
      :rotation="parseFloat(rotation)"
      :borderRadius="borderRadius"
      :border='border'
      :color='color'
      :scale="parseFloat(scale)"
      ref="vueavatar"
      @vue-avatar-editor:image-ready="onImageReady"
      @select="onSelectFile($event)"
      >
    </vue-avatar>
    <br>
    <label v-if="hasScale">
      Zoom : {{scale}}x
      <br>
      <input
        type="range"
        min=.2
        max=3
        step=0.02
        v-model='scale'
      />
    </label>
    <br>
    <label v-if="hasRotation">
      Rotation : {{rotation}}°
      <br>
      <input
        type="range"
        min=0
        max=360
        step=1
        v-model='rotation'
      />
    </label>
    <br>
    <b-button class="btn btn-sm btn-fill btn-danger mr-2" @click="cancel" >Cancel</b-button>
    <b-button class="btn btn-sm btn-fill btn-success" @click="finished">{{ finishText }}</b-button>
  </div>
</template>

<script>
import VueAvatar from './VueAvatar.vue';

export default {
    props: {
        finishText: {
            type: String,
            default: 'Save'
        },
        hasRotation: {
            type: Boolean,
            default: true
        },
        hasScale: {
            type: Boolean,
            default: true
        },
        image: {
            type: String,
            default: ''
        },
        border: {
            type: Number,
            default: 25
        },
        borderRadius: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 200
        },
        color: {
            type: Array,
            default: () => [0, 0, 0, 0.5]
        }
    },
    data () {
        return {
            rotation: 0,
            scale: 1.0
        };
    },
    components: {
        VueAvatar
    },
    methods: {
      onSelectFile (files) {
        this.$emit('select', files);
      },
      onImageReady () {
          this.scale = 1.0;
          this.rotation = 0;
      },
      cancel() {
          this.$emit("cancel" );
      },
      finished () {
          return this.$emit('finished', this.$refs.vueavatar );
      }
    }
};

</script>
