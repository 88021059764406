<template>
  <div class="full-screen main-background">
    <center>
      <div class="shadow" style="width:400px;margin:7em 15% 2em 15%;text-align:center;padding:15px;background:#7ab63f;border:4px solid #9ad65f;border-radius:3em;">
        <img   src="@/assets/img/icare-cafe-logo-225.png" alt="iCare Cafe by Newcare International"  />
      </div> 
    </center>

    <div class="wrapper container">
      <div class="row">
        <div class="col-md-6 shadow mt-5 ml-5 p-3 mr-auto ml-auto rounded">
          <b-form>
            <h3>{{title}}</h3>
                <b-form-group>
                  <b-form-group label-for="email" label="Please Enter Your Email Address">
                    <b-form-input id="email" v-model="emailAddress" ></b-form-input>
                    <span style="font-size:.75em;color:gray;font-style:italic;">The "Send" button will appear after you enter your email address</span>
                  </b-form-group>
                </b-form-group>
                <b-form-group v-if="isHaveCode" >
                  <b-form-group v-if="!isCodeValid" label-for="resetCode" label="Reset Code">
                    <b-form-input id="resetCode" :readonly="isCodeValid" v-model="passwordResetCode"></b-form-input>
                    <p><small>An email was sent with the <b>7 character</b> reset code. Please check your email and enter the supplied reset code in the field above</small></p>
                  </b-form-group>
                  <div v-if="isCodeValid">

                    <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="New Password" label-class="text-sm-right" label-for="newPassword">
                      <b-form-input id="newPassword" type="password" :state="newPasswordOk()" v-model="newPassword"></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback2">
                        <span v-if="commonPW">{{commonPW}}</span>
                        <span v-else>Enter at least 6 letters and NOT the same as your old password</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label="Confirm Password" label-class="text-sm-right" label-for="confirmPassword">
                      <b-form-input id="confirmPassword" type="password" :state="newPassword===confirmPassword" v-model="confirmPassword"></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback3">
                        Passwords do not match
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-form-group>

                <div>
                  <div v-if="!isHaveCode" class="float-left">
                    <b-btn  class="btn-info btn-fill btn-sm" variant="info"  @click="isHaveCode=true;">I Have a Code</b-btn>
                  </div>
                  <div v-if="!isHaveCode" class="float-right">
                    <b-btn class="btn btn-danger btn-fill btn-sm" variant="outline-danger"  @click="home()">Cancel</b-btn>
                    <b-btn v-if="emailAddress.length > 0 && ! passwordResetCode" class="btn-success btn-fill btn-sm" variant="success"  @click="sendResetLink()">Send Reset Link <span v-show="loading" :class="isLoading"></span></b-btn>
                    <b-btn v-if="emailAddress.length > 0 && passwordResetCode" class="btn-success btn-fill btn-sm" variant="success"  @click="verifyResetCode()">Verify Reset Code <span v-show="loading" :class="isLoading"></span></b-btn>
                  </div>
                  <div v-if="isHaveCode" class="float-right">
                    <b-btn class="btn btn-danger btn-fill btn-sm mr-3" variant="outline-danger"  @click="home()">Cancel</b-btn>
                    <b-btn v-if="!isCodeValid" :disabled="passwordResetCode.length!==7" class="btn-success btn-fill btn-sm" variant="success"  @click="verifyResetCode()">Verify Reset Code</b-btn>
                    <b-btn v-if="isCodeValid && newPassword===confirmPassword && isPasswordOk" class="btn-success btn-fill btn-sm" variant="success"  @click="changePassword()">Change Password <span v-show="loading" :class="isLoading"></span></b-btn>
                  </div>
                </div>
          </b-form>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="copyright pull-right">
          &copy; Copyright 2015-{{ new Date().getFullYear() }}, Newcare International, (an IOIHAN Company)
        </div>
      </div>
    </footer>

  </div>
</template>

<script>

// import AuthenticationService from "/src/Services/AuthenticationService";
import UserService from "/src/Services/UserService";
import TenantCard from "/src/components/UIComponents/Cards/TenantCard";
import rootLib from "./../Lib/rootLib";

var t = this;

export default {
  components: {
    "tenant-card": TenantCard
  },
  props: {
    resetCode: {
      default: ""
    }
  },
  data() {
    return {
      title            : "Request Password Reset",
      message          : "Please enter your email address",
      loginMessage     : "Log In",
      loading          : false,
      isLoading        : "fa fa-spinner  fa-refresh-animate",
      errors           : null,
      newPassword      : "",
      confirmPassword  : "",
      emailAddress     : "",
      passwordResetCode: "",
      isHaveCode       : false,
      isCodeValid: false,
      isPasswordOk: false,
      badPasswords: [
        '000000',
        '0000000',
        '00000000',
        '01234567',
        '111111',
        '1111111',
        '121212',
        '123123',
        '123456',
        '1234567',
        '12345678',
        '123456789',
        '1234567890',
        '1234pass',
        '1q2w3e4r5t',
        '1qaz2wsx',
        '222222',
        '2222222',
        '333333',
        '3rjs1la7qe',
        '444444',
        '555555',
        '654321',
        '666666',
        '696969',
        '777777',
        '888888',
        '987654321',
        '999999',
        'abc123',
        'abcdef',
        'abcdefg',
        'abcdefgh',
        'access',
        'access1',
        'admin1',
        'adminadmin',
        'adobe123',
        'ashley',
        'bailey',
        'baseball',
        'dragon',
        'football',
        'fuck123',
        'fuck1234',
        'fuckabc',
        'fuckme',
        'fuckyou',
        'google',
        'iloveyou',
        'jesus0',
        'jesus1',
        'jesus2',
        'jesuschrist',
        'letmein',
        'master',
        'monkey',
        'mustang',
        'mypass',
        'nothing',
        'p@$$word',
        'p@$$word1',
        'p@ssword',
        'pass1234',
        'passw0rd',
        'password',
        'password0',
        'password1',
        'password2',
        'password3',
        'password4',
        'password5',
        'password6',
        'password7',
        'password8',
        'password9',
        'photoshop',
        'princess',
        'qazwsx',
        'qwerty',
        'qwerty123',
        'qwertyuiop',
        'shadow',
        'starwars',
        'sunshine',
        'superman',
        'superwoman',
        'trustno1',
        'welcome',
        'welcome1',
        'zxcvbnm'
      ]
    };
  },
  mixins: [rootLib],
  mounted() {
    if( this.resetCode ) {
      this.passwordResetCode = this.resetCode;
      this.isHaveCode = true;
      this.title = "Verify Reset Code";
      //this.verifyResetCode();
    }
  },
  methods: {

    onReset(e) {
      e.preventDefault();
      this.username = "";
      this.password = "";
      this.$router.push("/");     //  async, so "this" works just fine (instead of using the cached "t")
    },
    newPasswordOk() {
      this.commonPW = "";
      if (this.newPassword.length > 5) {
        if (this.newPassword === this.oldPassword) {
          this.isPasswordOk = false;
          return false;
        }
        if (this.badPasswords.indexOf(this.newPassword.toLowerCase()) >= 0) {
          this.commonPW = "Your choice is to common";
          this.isPasswordOk = false;
          return false;
        }
        this.isPasswordOk = true;
        return true;
      }
      this.isPasswordOk = false;
      return false;
    },
    validateBeforeSubmit() {},
    async sendResetLink() {
      let t = this;
      let response;

      if( ! t.emailAddress ) {
        await this.$root.alert( "Valid email address required");
        return;
      }

      if( t.passwordResetCode ) {   // came in on url
        this.isHaveCode = true;
        return;
      }
      this.loading = true;
      try {
        response = await UserService.sendPasswordReset( t.emailAddress );
        t.$root.successMessage( "Password Reset" , "Code sent, check your inbox");
        this.isHaveCode = true;
      } catch( err ) {
        console.log( JSON.stringify(err ));
        if( err.response ) {
          if( err.response.data ) {
            t.$root.errorMessage( "Oops" , err.response.data.message );
          }
        } else {
          t.$root.errorMessage( "Oops" , err.message );
        }
      }
      this.loading = false;
    },
    async verifyResetCode() {
      let t = this;

      if( ! this.emailAddress ) {
        this.isHaveCode = false;
        await this.$root.alert("Invalid Email Address");
        return;
      }

      if( ! this.passwordResetCode ) {
        this.isHaveCode = false;
        await this.$root.alert("Invalid Reset Code");
        return;
      }

      this.loading = true;
      try {
        let response = await UserService.verifyResetCode( t.emailAddress , t.passwordResetCode );
        t.$root.successMessage( "Code Valid" , "Enter your new password");
        this.isCodeValid = true;
      } catch( err ) {
        console.log( JSON.stringify(err ));
        if( err.response ) {
          if( err.response.data ) {
            t.$root.errorMessage( "Oops" , err.response.data.message );
          }
        } else {
          t.$root.errorMessage( "Oops" , err.message );
        }
      }
      this.loading = false;
    },
    async changePassword() {
      let t = this;

      if( ! this.newPassword ) {
        await this.$root.alert("Password Required");
        return;
      }

      if( this.newPassword !== this.confirmPassword ) {
        await this.$root.alert("Passwords do not match");
        return;
      }
      this.loading = true;
      try {
        let response = await UserService.changePasswordWithCode( t.emailAddress , t.passwordResetCode , t.newPassword );
        this.loading = false;
        await this.$root.alert( "Password Changed Successfully");
        this.home();
      } catch( err ) {
        console.log( JSON.stringify(err ));
        if( err.response ) {
          if( err.response.data ) {
            t.$root.errorMessage( "Oops" , err.response.data.message );
          }
        } else {
          t.$root.errorMessage( "Oops" , err.message );
        }
      }
      this.loading = false;
      
    },
    appHome() {
      this.$router.push( "/app");
    },
    home() {
      this.$router.push("/");       // jump to the APP root to start his show
    },
    clearErrors() {
      this.errors.clear();
    }
  }
};
</script>
<style language="scss" scoped>

 .login-footer {
    position:fixed;
    bottom:10px;
    width:100%;
    text-align:right;
    font-size:.8em;
    color:gray;
  }

  .login-container {

    margin-top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: 500px;

    .login-logo {
      text-align: center;
      margin-bottom: 40px;
    }

    .login-form {
      margin-bottom:50px;

      .login-credentials {
        margin-left:4em;
        margin-right:4em;
      }
    }

    .login-forgot-password {
      float:left;
      margin-left:4em;
    }
  }

  .fa-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
  }

  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }

    to {
      transform: scale(1) rotate(360deg);
    }
  }

.main-background {
  background-color:white;
}

.fa-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

</style>
