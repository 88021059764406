<template>
    <card>
      <h4 slot="header" class="card-title"><i class="nc-icon nc-simple-add show-hand float-left" @click="makeNewLocation()"></i>&nbsp;&nbsp;Edit Locations</h4>
      <div>
        <hr>

        <b-table striped hover head-variant="dark" stacked="md" sort-by="title" :items="tenant.interfaceLink" :fields="columns" >

          <template slot="actions" slot-scope="row">
            <i class="nc-icon nc-scissors show-hand float-left" @click="removeInterface(row.item._id)"></i>
          </template>

          <template slot="title" slot-scope="row">
            <div class="show-hand" @click="editInterface(row.item._id)">{{row.item.title}}</div>
          </template>

          <template slot="apiKeyLink" slot-scope="row">
            <div class="show-hand" @click="editInterface(row.item._id)">{{row.item.apiKeyLink}}</div>
          </template>
          

          <template slot="userId" slot-scope="row">
             <div class="show-hand" @click="editInterface(row.item._id)" v-b-tooltip.hover placement="righttop" :title="row.item.password">{{row.item.userId}}</div>
          </template>

        </b-table>

          <b-modal v-model="isAdding"
              :title="modalTitle"
              size="lg"
              header-bg-variant="info"
              header-text-variant="light"
              footer-bg-variant="info"
              footer-text-variant="light">
            <b-container fluid>
              <b-form-group horizontal breakpoint="lg" label="Interface Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-form-group horizontal label-class="text-sm-right" label="Title" label-for="title">
                    <b-form-input id="title" type="text" v-model="interfaceLink.title" required placeholder="Interface Title"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-class="text-sm-right" label="API Key" label-for="apiKeyLink">
                    <b-form-input id="apiKeyLink" type="text" v-model="interfaceLink.apiKeyLink" required placeholder="API Key"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-class="text-sm-right" label="IP Address" label-for="ipAddress">
                    <b-form-input id="ipAddress" type="text" v-model="interfaceLink.ipAddress" placeholder="All IP Addresses"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-class="text-sm-right" label="User ID" label-for="userId">
                    <b-form-input id="userId" required type="text" v-model="interfaceLink.userId" placeholder="User ID"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-class="text-sm-right" label="Password" label-for="userPassword">
                    <b-form-input id="userPassword" required type="password" v-model="interfaceLink.password" placeholder="User Password"></b-form-input>
                  </b-form-group>
                  <b-form-group horizontal label-class="text-sm-right" :rows="3" label="Notes" label-for="interfaceNote">
                    <b-form-textarea id="interfaceNote" v-model="interfaceLink.note" placeholder="Notes" :rows="3" :max-rows="3"></b-form-textarea>
                  </b-form-group>
                </b-form-group>
            </b-container>
            <div slot="modal-footer" class="w-100">
              <b-btn v-if="!isEditing" size="sm" class="float-left btn-fill" variant="info" @click="addInterface">
                Clear Form
              </b-btn>
              <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addInterface();">
                Add Interface
              </b-btn>
              <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addInterface();">
                Update Interface
              </b-btn>
              <b-btn size="sm" class="float-right btn-fill" variant="danger" @click="isAdding=false">
                Cancel
              </b-btn>
          </div>
        </b-modal>

        <hr>
        <b-button class = "btn btn-success btn-fill float-right" :disabled = saveDisabled @click = "saveInterfaces()">
          Update Interfaces
        </b-button>
        <b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
          Cancel
        </b-button>
      </div>
      <div class="clearfix"></div>
      <br/>
    </card>
</template>

<script>
import LTable from "/src/components/UIComponents/Table.vue";
import TenantService from "/src/Services/TenantService";
// import moment from "moment-timezone";
import Card from "/src/components/UIComponents/Cards/Card.vue";

export default {
  components: {
    LTable,
    Card
  },
  data() {
    return {
      saveDisabled : true,
      changeCount  : 0,
      modalTitle   : "Adding New Interface",
      isAdding     : false,
      isEditing    : false,
      small        : true,
      interfaceLink: { title: "", apiKeyLink: "", ipAddress: "", userId: "", password: "", note: "" },
      columns      : [
        "actions",
        { key: "title", sortable: true },
        { key: "apiKeyLink", sortable: true },
        { key: "ipAddress", sortable: true },
        { key: "userId", sortable: true },
        { key: "note", sortable: true }
      ],
      tenant: {
        interfaceLink: []
      }
    };
  },
  watch: {
    tenant: {
      deep: true,
      handler(old) {
        this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
        if (this.changeCount < 2) {
          return;
        }
        this.saveDisabled = false;
      }
    }
  },
  methods: {
    makeNewLocation() {

      // console.log( "Hit makeNewInterface()");

      var number = Math.random()                  // 0.9394456857981651
      var id = number.toString(36).substr(2, 8);  // 'xtis06h6'
      var interfaceLink = {}

      interfaceLink.apiKeyLink = "NEWCARE:" + id;
      interfaceLink.ipAddress  = "*";
      interfaceLink.title      = "New Interface";
      interfaceLink.userId     = "";
      interfaceLink.password   = "";
      interfaceLink.note       = "";

      this.interfaceLink = interfaceLink;

      this.isAdding   = true;
      this.isEditing  = false;
      this.modalTitle = "Adding New Interface";

    },
    addInterface() {
      if( ! this.isEditing ) {
        this.tenant.interfaceLink.push( this.interfaceLink );
      }

      this.isAdding  = false;
      this.isEditing = false;
    },
    editInterface( d ) {

      if( ! d ) {
        alert( "This Interface Has Not Yet Been Saved - Recommend To SAVE or Cancel First");
        return;
      }

      var t = this;
      this.tenant.interfaceLink.forEach(function(item, index) {
        if (item._id.toString() === d.toString()) {
          t.interfaceLink = item;
        }
      });

      this.modalTitle = "Editing Interface";
      this.isAdding   = true;
      this.isEditing  = true;

    },
    removeInterface(d) {
      let t = this;

      if( ! d ) {
        alert( "That Interface Has Not Yet Been Saved - Recommend To SAVE or Cancel First");
        return;
      }

      if( ! confirm( "Remove This Interface?")){
        return;
      };

      this.tenant.interfaceLink.forEach(function(item, index) {
        if (item._id.toString() === d.toString()) {
          t.tenant.interfaceLink.splice(index, 1);
        }
      });
    },
    async getTenant() {
      var t = this;
      var response;
      try {
        response = await TenantService.getTenant();
        this.tenant = response.data;
      } catch (err) {
        console.log("getTenant error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the tenant");
        }
        return;
      }
    },
    async saveInterfaces() {
      var t = this;
      var response;
      try {

        // fixme: build up an interface record
        const tenantInterfaceLink = { _id: this.tenant._id, interfaceLink: this.tenant.interfaceLink};

        response             = await TenantService.saveTenant(tenantInterfaceLink);
        t.$root.tenant.interfacLink = tenantInterfaceLink;

        t.$root.successMessage("SUCCESS", "Interfaces Updated");
        t.$router.go(-1);

      } catch (err) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Interface Data");
      }
    }
  },
  mounted: function() {
    this.getTenant();
  }
};
</script>

<style lang="scss">
</style>
