<template>
  <div class="card shadow page-body">
    <template v-if="badge" slot="header">
      <p class="card-category">{{badge.badgeType}} :: {{badge.badgeNumber}}
        <!-- <b-button class="pull-right" @click="done" variant="success">Done</b-button> -->
      </p>
      <h4 class="card-title">{{badge.alias}} ({{badge.firstName}} {{badge.lastName}})</h4>
      <div class="card-category">{{transactionList.length}} Transactions</div>
    </template>
    <!-- <template slot="body"> -->
    <div class=" card-table" v-if="transactionList.length > 0">
      <b-table outlined striped hover head-variant="dark" @row-clicked="rowClicked( $event )" :fields="transColumns" :items="transactionList">

        <template v-slot:cell(transactionDate)="row">
          <template v-if="moment(row.item.transactionDate).year() === moment().year()">
            {{tzDate(row.item.transactionDate)}}
          </template>
          <template v-else>
            <span style="font-weight:1000;color:red;">{{tzDate(row.item.transactionDate, true)}}</span>
          </template>
        </template>

        <template v-slot:cell(accountNumber)="row">
          {{row.item.accountNumber}}
          <!-- <span v-if="showDetails===true">/{{row.item.badgeNumber}}</span> -->
        </template>

        <template v-slot:cell(badgeNumber)="row">
          <span v-b-popover.hover.html.top="'<b>Badge # </b>' + row.item.badgeNumber">{{row.item.alias}}</span>
<!--          <span style="font-size:.8em;">/{{row.item.badgeNumber}}</span>-->
        </template>

        <template v-slot:cell(totalClassId)="row">
          {{total( row.item.totalClassId )}}
        </template>

        <template v-slot:cell(revenueCenter)="row">
          {{revenueCenter( row.item.revenueCenter)}}
        </template>

        <template v-slot:cell(tenderNumber)="row">
          {{tender(row.item.tenderNumber)}}<span v-if="showDetails===true"><br>{{row.item.title}}</span>
        </template>

        <template v-slot:cell(checkNumber)="row">
          {{row.item.checkNumber}}
        </template>

        <template v-slot:cell(tenderAmount)="row">
          <div class="float-right" style="font-weight:600;" :style="row.item.isPayment ? 'color:green;' : 'color:red;'">
              {{ makeValue( row.item ) }}
          </div>
        </template>

      </b-table>
    </div>
    <div class="table-responsive" style="padding:2em;" v-else>
      No Current Transactions
    </div>
    <!-- </template> -->
  </div>
</template>
<script>

  import RevenueCenterService from "/src/Services/RevenueCenterService";
  import TenderService        from "/src/Services/TenderService";
  import TotalService         from "/src/Services/TotalService";
  import Moment               from "moment";
  import MomentTZ             from "moment-timezone"; // find the docs here: https://momentjs.com/timezone/
  import numeral              from "numeral"; // find the docs here: http://numeraljs.com/
  import LTable               from "/src/components/UIComponents/Table.vue";

  // transaction list example record
  // "totalClassId": 10,
  // "accountNumber": "26",
  // "badgeNumber": "26",
  // "title": "Sale",
  // "tenderNumber": 10,
  // "tenderAmount": 0,
  // "tenderQty": 1,
  // "isPayment": false,
  // "isMealPlan": false,
  // "revenueCenter": 0,
  // "transactionNumber": "",
  // "checkNumber": "",
  // "transactionTotal": 1.25,
  // "transactionDate"
  // "_id": "5afe5c94f226662fc80a180a"

  export default {
    name: 'TransactionList',
    props: {
      transactionList: Array,
      badge          : Object
    },
    components: {
      LTable
    },
    data() {
      return {
        showDetails: false,
        revenueCenters: [],
        tenders: [],
        totals: [],
        transColumns: [{
          key: "transactionDate",
          label: "Date",
          sortable: true,
          sortDirection: "desc"
        }, {
          key: "accountNumber",
          label: "Account",
          sortable: true
        }, {
          key: "badgeNumber",
          label: "Badge",
          sortable: true
        }, {
          key: "totalClassId",
          label:"Total Profile",
          sortable: true
        }, {
          key: "revenueCenter",
          label: "Revenue Center",
          sortable: true
        }, {
          key: "tenderNumber",
          label: "Tender #",
          sortable: true
        }, {
          key: "checkNumber",
          label: "Chk #",
          sortable: true
        }, {
          key: "transactionNumber",
          label: "Trans #",
          sortable: true
        }, {
          key: "tenderAmount",
          label: "Total $",
          sortable: true
        }],

      };
    },
    async mounted() {
      var t=this;
      try {
        //var beginDate = this.tzDateOnly(new Date()) + " 00:00";
        //var endDate = this.tzDateOnly(new Date()) + " 23:59:59";
        await Promise.all([
          this.getRevenueCenters(),
          this.getTenders(),
          this.getTotals()
          //this.getTransactions(t.beginDate + " 00:00:00", t.endDate + " 23:59:00", 100000)
        ]);
      } catch (err) {
        console.log("Transactions Mounted error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting initial settings");
        }
        return;
      }
    },
    methods: {
      numeral(x) {
        return numeral(x);
      },
      moment(d) {
        return MomentTZ(d);
      },
      tzDate(d, showYear = false) {
        if( ! this.showDetails && !showYear) {
          return Moment.tz(d, this.$root.user.TZ).format('ddd DD-MMM hh:mm a');
        } else {
          return MomentTZ.tz(d, this.$root.user.TZ).format('dddd, DD-MMM-YY hh:mm a');
        }
      },
      tzDateOnly(d) {
        return MomentTZ.tz(d, this.$root.user.TZ).format('MM-DD-YYYY');
      },
      rowClicked( event ) {
        this.$emit( "transactionSelect" , event._id );
      },
      revenueCenter(id) {
      var t = this;
      var i;

      if (t.revenueCenters.length > 0) {
        for (i = 0; i < t.revenueCenters.length; i++) {
          if (t.revenueCenters[i].revenueCenterId == id) {
            return "" + id + " : " + t.revenueCenters[i].title;
          }
        }
      }

      return "" + id
    },
    tender(id) {
      var t = this;
      var i;

      if (t.tenders.length > 0) {
        for (i = 0; i < t.tenders.length; i++) {
          if (t.tenders[i].tenderNumber == id) {
            return "" + id + " : " + t.tenders[i].title;
          }
        }
      }

      return "" + id
    },
    totalType( classId ) {
      let x = _.find( this.totals, { totalClassId: classId } );
      // console.log( "searching for", classId ,x  );
      if( x ) {
        return x.totalType;
      } 
      return "credit";
    },
    makeValue( item ) {
      let x = (item.tenderAmount!==item.transactionTotal) ? "** " : "";
      let tt = this.totalType( item.totalClassId );
      if( tt == "debit" || tt == "fund" || tt == "giftcard" || tt=="points") {
        if( item.isPayment ) {
          return x + numeral( item.tenderAmount ).format("0,0.00")
        } 
        return x + numeral( -item.tenderAmount ).format("0,0.00")
      }
      if( item.isPayment ) {
        return x + numeral( -item.tenderAmount ).format("0,0.00")
      } 
      return x + numeral( item.tenderAmount ).format("0,0.00")
    },
    total(id) {
      var t = this;
      var i;

      if (t.totals.length > 0) {
        for (i = 0; i < t.totals.length; i++) {
          if (t.totals[i].totalClassId == id) {
            return "" + id + " : " + t.totals[i].title;
          }
        }
      }

      return "" + id
    },
    async getRevenueCenters() {
      var response;
      var t = this;
      try {
        response = await RevenueCenterService.getRevenueCenters();
        t.revenueCenters = response.data;

      } catch (err) {
        if (err) {
          alert(
            "oops hit error in getRevenueCenters",
            err
          );
          t.$root.errorMessage("Oops", "Had a problem getting the revenue center listing");
        }
        return;
      }

    },
    async getTenders() {
      var response;
      var t = this;
      try {
        response = await TenderService.getTenders();
        t.tenders = response.data;

      } catch (err) {
        if (err) {
          alert(
            "oops hit error in getTenders",
            err
          );
          t.$root.errorMessage("Oops", "Had a problem getting the tender listing");
        }
        return;
      }
    },
    async getTotals() {
      var response;
      var t = this;
      try {
        response = await TotalService.getTotals();
        t.totals = response.data;

      } catch (err) {
        if (err) {
          alert(
            "oops hit error in getTotals",
            err
          );
          t.$root.errorMessage("Oops", "Had a problem getting the totals listing");
        }
        return;
      }
    }

    }
  }
</script>
<style language="scss" scoped>

  .page-body {
    width:100%;
    border-radius:1em;
    background:red;
  }

  .card-table {
    /*line-height:90%;*/
    font-size:.9em;
  }

  /*.table-responsive {
    table {
      thead {
//        background-color: rgba(00,200,80,.4); //green;
        background-color:black;
      }
    }

  }*/

</style>
