<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 shadow p-3 mb-5 bg-white rounded">
          <reports-form>
          </reports-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsForm from "./ReportsForm.vue";
export default {
  components: {
    ReportsForm
  }
};
</script>

<style>
</style>
