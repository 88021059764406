<template>
  <div>

    <div v-if="!total">
      <i class="fa fa-plus-square show-hand float-left" style="font-size:1.5em;margin-right:.5em;" v-b-popover.hover.right="'Add New'" @click="makeNewTotal()"></i>
      <h4>Total Profiles</h4>
      <hr>

      <b-table striped hover head-variant="dark" stacked="md" sort-by="totalNumber" :items="totals" :fields="columns" @row-clicked="editTotal($event)">

        <template v-slot:cell(actions)="row">
          <i class="fa fa-trash show-hand" v-b-popover.hover.right="'Remove this profile'" @click.stop="removeTotal(row.item._id)"></i>
          <i class="fa fa-times mr-2" v-if="!row.item.active" style="color:red;"></i>
          <i class="fa fa-check mr-2" v-if="row.item.active" style="color:green;" ></i>
          <i class="fa fa-list"v-if="row.item.options.showOnTransactionList" style="color:purple;"></i>
        </template>
        <template v-slot:cell(totalClassId)="row">
          <div class="show-hand" >{{row.item.totalClassId}}</div>
        </template>
         <template v-slot:cell(totalType)="row">
          <div class="show-hand" >{{row.item.totalType}}</div>
        </template>
        <template v-slot:cell(title)="row">
          <div class="show-hand">{{row.item.title}}</div>
        </template>
        <template v-slot:cell(match)="row">
          <div class="show-hand">{{(row.item.match.length>0) ? "matches" : "" }}</div>
        </template>

        <template v-slot:cell(limits.daily)="row">
          <div class="show-hand" style="float:right;" >{{currency(row.item.limits.daily)}}</div>
        </template>

        <template v-slot:cell(limits.total)="row">
          <div class="show-hand" style="float:right;" :style="row.item.totalType=='debit' && row.item.limits.total>0 ? 'color:red;font-weight:1000;' : ''" >{{(row.item.limits.total>=999999) ? 'unlimited' : currency(row.item.limits.total)}}</div>
        </template>

      </b-table>

      <hr>
      <b-button class="btn btn-success btn-fill float-right" @click="$router.go(-1)">
        done
      </b-button>
      <!-- <b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
        Cancel
      </b-button> -->
    </div>
    <div v-if="total" class="p-2 col-md-12">
        <b-container fluid >
          <h4 class="mt-0 mb-0">Profile: <span style="font-size:.8em;">{{total.totalClassId}}</span> :: {{total.title}}</h4>
          <hr class="mb-3"/>
          <b-btn v-if="!isMatch" style="float:right;" class="btn btn-fill" size="sm" variant="primary" @click="addMatch()">Add Match</b-btn>
          <b-tabs class="tab-hover" active-nav-item-class="selected-tab">
            <br />
            <b-tab title="General" active>
              <!--<b-form-group horizontal breakpoint="lg" label="Totals" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">-->
              <b-form-checkbox style="margin-left:11em;" v-model="total.active">Active?</b-form-checkbox>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Number" label-for="totalNumber">
                <b-form-input id="totalNumber" v-if="!isEditing" style="width:8em;" type="text" v-model="total.totalClassId" required placeholder="Total Number"></b-form-input>
                <b-form-input id="totalNumber" v-else readonly style="width:8em;" type="text" v-model="total.totalClassId" required placeholder="Total Number"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Title" label-for="title">
                <b-form-input id="title" type="text" v-model="total.title" required placeholder="Title"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="POS Title" label-for="posTitle">
                <b-form-input id="posTitle" style="width:15em;" type="text" v-model="total.miniTitle" maxlength="8" required placeholder="POS Title"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Total Type" label-for="totalType">
                <b-form-select id="totalType" style="width:15em;" v-model="total.totalType" :key="total.totalType" :options="totalTypes" class=""></b-form-select>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Sequence" label-for="sequence">
                <b-form-input id="sequence" style="width:10em;" type="number" v-model="total.sequence" placeholder="Sequence"></b-form-input>
              </b-form-group>
              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Short Description" label-for="shortDescription">
                <textarea id="shortDescription"  style="width:600px;" :rows="3" v-model="total.shortDescription" placeholder="Short Description"></textarea>
                <p style="font-size:.8em;color:gray;">This description is used in the portal and other areas facing an end-user</p>
              </b-form-group>
            </b-tab>
            <b-tab title="Options" >
              <div v-if="total.options" style="margin:0 2em 0 2em;">
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.strictLimitEnforced">Strict Limits Enforced </b-form-checkbox>
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.allowFunds">Allow Funds </b-form-checkbox>
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.showOnTransactionList">Show On Transaction List </b-form-checkbox>
                <hr/>
                <h5>Processing Related</h5>
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.autoMonthlyReset">Auto Monthly Reset</b-form-checkbox>
                <hr/>
                <h5>POS Related</h5>
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.authorizeWithBalance">Authorize With Balance </b-form-checkbox>
                <b-form-checkbox style="margin-left:11em;" v-model="total.options.authorizeWithName">Authorize With Name</b-form-checkbox>
              </div>
              <div v-else>
                <h4>No Options Configured</h4>
              </div>
            </b-tab>
            <b-tab title="Limits">

              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Expire Days" label-for="expireDays">
                <b-form-input id="expireDays" type="text" style="width:10em;" v-model="total.expireDays" placeholder="Expire Days"></b-form-input>
              </b-form-group>

              <table v-if="total.limits" class="table">
                <col width="25%">
                <col width="30%">
                <col width="30%">
                <thead>
                  <tr>
                    <td></td>
                    <td>Qty</td>
                    <td>$ Total</td>
                  </tr>
                </thead>
                <tr>
                  <td style="float:right;">Daily</td>
                  <td>
                    <b-form-input class="col-md-5" v-model="total.limits.dailyQty"></b-form-input>
                  </td>
                  <td>
                    <b-form-input class="col-md-6" v-model="total.limits.daily"></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td style="float:right;">Overall Limit</td>
                  <td>
                    <b-form-input class="col-md-5" v-model="total.limits.totalQty"></b-form-input>
                  </td>
                  <td>
                    <b-form-input class="col-md-6"  v-model="total.limits.total"></b-form-input>
                  </td>
                </tr>
              </table>
              <p style="width:50%;margin-left:auto;margin-right:auto;font-size:.8em;color:gray;">NOTE that the daily limits do NOT affect any account balance and simply restrict the spend for a DAY so they can be used with debit or credit totals.</p>
              <p style="width:50%;margin-left:auto;margin-right:auto;font-size:.8em;color:gray;">Credit Limits should be set to 0 for debit style accounts unless you wish to allow "over spend".</p>
            </b-tab>

            <b-tab v-if="isMatch" v-for="(m,index) in total.match" :key="index" :title="m.title">

              <b-form-group horizontal label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Match Title" label-for="matchTitle">
                <b-form-input class="col-md-6" id="matchTitle" v-model="m.title"></b-form-input>
                <br>
                <b-form-checkbox v-model="m.isUseIfAvailable">Use If Available?</b-form-checkbox>
                <b-form-checkbox v-model="m.isIgnoreIfFull">Ignore When Full?</b-form-checkbox>
              </b-form-group>

              <h4>Tendering Keys</h4>
              <div class="row">
                <b-form-checkbox-group id="tenderKeys" name="tenderKeys" v-model="m.tenderNumber">
                  <b-form-checkbox v-for="(t,index) in tenders" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'tnd'+t.tenderNumber" :value="t.tenderNumber" :key="index">{{t.tenderNumber}}:{{t.title}}</b-form-checkbox>
                </b-form-checkbox-group>
              </div>
              <h4>Revenue Centers</h4>
              <div class="row">
                <b-form-checkbox-group id="revenueCenter" name="revenueCenter" v-model="m.revenueCenter">
                  <b-form-checkbox v-for="(r,index) in revenueCenters" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" :id="'rev'+r.revenueCenterId" :value="r.revenueCenterId" :key="index">{{r.title}}</b-form-checkbox>
                </b-form-checkbox-group>
              </div>

            </b-tab>
          </b-tabs>

        </b-container>
        <div slot="modal-footer" class="w-100 mt-3">
          <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addTotal( total );">
            Add Total
          </b-btn>
          <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="updateTotal( total );">
            Update Total
          </b-btn>
          <b-btn size="sm" class="float-right btn-fill mr-2" variant="danger" @click="total=null">
            Cancel
          </b-btn>
        </div>
      </div>

  </div>
</template>

<script>
  //import LTable from "/src/components/UIComponents/Table.vue";
  import TotalService from "/src/Services/TotalService";
  import TenderService from "/src/Services/TenderService";
  import RevenueCenterService from "/src/Services/RevenueCenterService";
  // import moment from "moment-timezone";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import Currency from "currency.js";

  export default {
    components : {
      // LTable,
      Card,
      Currency
    },
    data () {
      return {
        isDataChanged: false,
        totalTypes    : ["credit", "debit", "payroll", "house", "points", "meal", "timepay", "coupon", "reward", "fund" ],
        modalTitle    : "Adding New ",
        isAdding      : false,
        isEditing     : false,
        total         : null, // { totalNumber : 0, title : "", miniTitle : "", expireDays: 36500,  match : [] },
        totals        : [],
        revenueCenters: [],
        tenders       : [],
        columns       : [
          { key: "actions" },
          { key: "totalClassId", lable: "ID", sortable : true },
          { key: "sequence", sortable : true },
          { key: "totalType", label:"Type", sortable : true },
          { key: "title", sortable: true },
          { key: "match" },
          { key: "miniTitle", label: "POS Title", sortable: true },
          { key: "limits.daily", label:"Daily Limit" , sortable : true  },
          { key: "limits.total", label:"$ Limit" , sortable : true  }
        ]
      };
    },
    computed: {
      isMatch() {
        if( !this.total.match) {
          return false;
        }
        if( this.total.match.length === 0 ) {
          return false;
        }
        return true;
      }
    },
    watch      : {
    },
    methods: {
      dataChanged() {
        isDataChanged = true;
      },
      currency(c) {
        return Currency(c);
      },
      formatCurrency(value, key, item) {
        return "Value = " + Currency(value);
      },
      addMatch() {
        this.total.match = [
          {
            title            : "New Match",
            isUseIfAvailable : false,
            isIgnoreIfFull   : false,
            site             : [],
            revenueCenter    : [],
            tenderNumber     : [],
            servingPeriod    : []
          }
        ]
      },
      async makeNewTotal () {

        let total = {
          totalClassId: 0,
          title: "New Total",
          totalType:"credit"
        }

        this.total = total;

        // await this.addTotal();

        // this.isAdding          = true;
        // this.isEditing         = false;
        // this.modalTitle        = "Adding Total ";

      },
      async addTotal () {
        let t = this;
        let response;

        this.isEditing = false;
        this.isAdding  = false;

        try {
          response = await TotalService.addTotal( this.total );
          this.total = null;
          this.getTotals();
        } catch( err ) {
          if( err.response ) {
            console.log( "error response ", err.response );
            t.$root.errorMessage( "Oops", err.response.data.message );
          } else if( err.request ) {
            console.log( "Error Request ", err.request );
            t.$root.errorMessage( "Oops", "Unable to Save Total" );
          } else {
            console.log( "message only ", err.message );
            t.$root.errorMessage( "Oops", "Unable to Save Total" );
          }
          // t.$root.errorMessage("Oops", "Unable to Save Total");
        }
      },
      async editTotal ( total ) {

        let t = this;
        // let id = total.totalClassId;

        // this.totals.forEach( function( item, index ) {
        //   if( item.totalClassId === id ) {
        //     t.total = item;
        //   }
        // } );

        try {
          let response = await TotalService.getTotal( total.totalClassId );
          t.total = response.data;
        } catch( error ) {
          console.error( error );
          this.$root.alert( error.message );
        }

        if( !t.total.match ) {
          t.addMatch();
        }

        this.modalTitle = "Editing ";
        this.isAdding   = true;
        this.isEditing  = true;

      },
      async getSupportRecords () {
        let t = this;
        let response;
        try {
          response            = await TenderService.getTenders();
          this.tenders        = response.data;
//console.log( this.tenders );

          response            = await RevenueCenterService.getRevenueCenters();
          this.revenueCenters = response.data;
//console.log( this.revenueCenters );

        } catch( err ) {
          console.log( "getSupportTotals error: ", err );
          if( err.response ) {
            if( err.response.data ) {
              t.$root.errorMessage( "Oops", err.response.data.message );
            }
          } else {
            t.$root.errorMessage( "Oops", "Had an error getting the support totals" );
          }
        }
      },
      async getTotals () {
        let t = this;
        let response;

        try {
          response    = await TotalService.getTotals();
          this.totals = response.data;
          // if( !this.totals.match ) {
          //   this.totals.match = [];
          // }
//console.log( this.totals );
        } catch( err ) {
          console.log( "getTotals error: ", err );
          if( err.response ) {
            if( err.response.data ) {
              t.$root.errorMessage( "Oops", err.response.data.message );
            }
          } else {
            t.$root.errorMessage( "Oops", "Had an error getting the total" );
          }
        }
      }
      ,
      async updateTotal ( total ) {
        let t = this;
        let response;

//        console.log( "updating total ", total );

        try {
          response = await TotalService.updateTotal( total );
          this.getTotals();
          this.total = null;
          this.isEditing = false;
          this.isAdding  = false;
        } catch( err ) {
          if( err.response ) {
            console.log( "error response ", err.response );
            t.$root.errorMessage( "Oops", err.response.data.message );
          } else if( err.request ) {
            console.log( "Error Request ", err.request );
            t.$root.errorMessage( "Oops", "Unable to Save Total" );
          } else {
            console.log( "message only ", err.message );
            t.$root.errorMessage( "Oops", "Unable to Save Total" );
          }
          //t.$root.errorMessage("Oops", "Unable to Save Total");
        }
      }
      ,
      async removeTotal ( id ) {
        let t            = this;
        let totalName    = "";
        let totalClassId = 0;
        let response;

        console.warn( id );

        t.totals.forEach( function( item, index ) {
          if( item._id.toString() === id.toString() ) {
            totalName    = item.title;
            totalClassId = item.totalClassId;
          }
        } );

        if( !await this.$root.confirmYesNo( `Removing ${totalClassId} :: ${totalName} ?` ) ) {
          return;
        };

        try {
          response = await TotalService.removeTotal( id );
        } catch( err ) {
          if( err.response ) {
            console.log( "error response ", err.response );
            await t.$root.alert( err.response.data.message , "error" );
          } else if( err.request ) {
            console.log( "Error Request ", err.request );
            t.$root.errorMessage( "Oops", "Unable to Remove Total" );
          } else {
            console.log( "message only ", err.message );
            t.$root.errorMessage( "Oops", "Unable to Remove Total" );
          }
          // t.$root.errorMessage("Oops", "Unable to Remove Total");
        }
        t.getTotals();
      }
    },
    mounted    : function() {
      this.getTotals();
      this.getSupportRecords();
    }
  };
</script>

<style lang="scss">
</style>
