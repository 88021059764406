///-------------------------------------------------------------------------------------------------
// summary:	General service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  sendMail(to, subject, body) {
    let mail = {
      to     : to,
      subject: subject,
      body   : body
    };
    const response = Api().post('/api/1/sendMail', mail );
    return response;
  },

  sendEnvelope( envelope ) {
    const response = Api().post('/api/1/sendMail', envelope );
    return response;
  },

  activeTimezones() {
    let response = Api().get('/api/1/activeTimezones');
    return response;
  },

  getNewId() {
    let response = Api().get('/api/1/newId');
    return response;
  }

}
