<template>
  <div style="">
    <div class="">
      <i v-if="enableAdd" class="fa fa-plus-square float-right show-hand" style="font-size:1.5em;" @click="addItem()"></i>
      <h4 v-if="title" class="mt-1 mb-0">{{title}}</h4>
    </div>

    <div class="">
      <div v-if="tableData.length>0" >
        <b-table responsive small hover head-variant="dark"  stacked="md" ref="tableDataList" :items="tableData" :fields="tableDataFields" >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'value' ? '90px' : field.key=== 'actions' ? '30px' : '250px' }"
            >
          </template>
          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash show-hand float-left" @click.stop="removeItem(row.index)"></i>
          </template>
          <template v-slot:cell(value)="row" >
            <sf-input :id="makeId()" :readonly="row.item.text.length>0" :labelCols="0" v-model="row.item.value" @input="change()"></sf-input>
          </template>
          <template v-slot:cell(class)="row" >
            <sf-input :id="makeId()" type="list" :options="classList"  :labelCols="0" v-model="row.item.class" @input="change()"></sf-input>
          </template>
          <template v-slot:cell(text)="row">
            <sf-input :id="makeId()" :labelCols="0" v-model="row.item.text"  @input="change()"></sf-input>
          </template>
        </b-table>
      </div>
    </div>
    <div class="" v-if="$slots.footer">
      <hr>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>

  import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";

  let idCount = 0;

  export default {
    name: 'OptionManagementTable',
    components: {
      SfInput
    },
    props: {
      title: { type: String, default: "" },
      tableData: { type: Array, default: () => [] },
      classList: { type: Array, default: () => [] },
      tableOptions: { type: Object, default: () => {}},
      enableAdd: { type: Boolean, default: true },
      addHoverText: { type: String, default: "Add a new item" },
      classHeader: { type: String, default: "Class" }
    },
    computed: {
      tableDataEx() {
        if( this.tableData.length === 0 ) {
          return this.tableData;
        }
        return this.tableData;
      }
    },
    data() {
      return {
        tableDataFields:[
            {key: "actions", label: "", sortable: false },
            {key: "value"  , label: "Key", sortable: true, "thStyle":"min-width:140px;"},
            {key: "class"  , label: this.classHeader , sortable: true},
            {key: "text"   , label: "Description", sortable: true}
          ]
      }
    },
    mounted() {
      console.log( "TablewithClass() data " , this.tableData );
    },
    methods: {
      makeId() {
        idCount++;
        return "inputId-" + idCount.toString();
      },
      addItem() {
        this.tableData.push( { value: "new", text: "", class: "" } );
        this.$emit( "add" );
        this.change();
      },
      change( ) {
        console.log( "change item"  );
        this.$emit( "change" );
      },
      editItem( e ) {
        console.log( "edit item" );
        this.$emit( "click", e );
        this.change();
      },
      removeItem( index ) {
        console.log( "remove Item" , index );
        this.$emit( "delete" , this.tableData[index] );
        this.tableData.splice(index,1);
        this.change();
      }
    }
  }
</script>
<style>

</style>
