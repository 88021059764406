<template>
  <div class="show-hand" :class="chartClass" @click="cardClicked" >
    <div class="stats-card-shadow" :style="cCardStyle">
      <bar-chart :chart-data="makeDataset()" :options="chartOptions" :style="cChartStyle" />
      <h4 style="width:100%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;" class="ml-2 mt-1 pb-2">{{title}}</h4>
    </div>
  </div>

</template>

<script>

import LineChart from "/src/components/Charting/LineChart.js";
import BarChart  from "/src/components/Charting/BarChart.js";

export default {
  name: 'line-widget',
  components: {
    BarChart,
    LineChart
  },
  props: {
    title: { type: String, default: "" },
    fill: { type: Boolean, default: false },
    showLegend: {type: Boolean, default: false },
    chartClass: {type: String, default: "col-sm-6 col-md-4 col-xl-3" },
    chartColor: { type: String, default: "#ff9933" },
    chartLabels: { type: Array, default: () => null },
    chartData: { type: Array, default: () => null },
    chartDataBackgroundColor:{  default: "rgba(255, 255, 255, 0.8)" },
    datasets: { type: Array, default: () => null },
    cardStyle: { type: String, default: "margin: 0 0em 2em 0;padding:1em 1em 0em 1em;border:0px solid black;border-radius:1em;" },
    chartStyle: { type: String, default: "border-radius:1em;" }
  },
  data() {
    return {
      chartOptions: {
        responsive:true,
        maintainAspectRatio: true,
        legend: {
          display:this.showLegend,
          labels: {
            fontColor: 'black'
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              offsetGridLines: true
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 100
            }
          }]
        }
      }
    }
  },
  computed: {
    cChartStyle() {
      return `${this.chartStyle};background:${this.chartColor};`;
    },
    cCardStyle() {
      return `${this.cardStyle};background:${this.chartColor};`;
    }
  },
  methods: {
    cardClicked() {
      this.$emit( "click" , this.title + " " + this.subTitle );
    },
    makeDataset() {

      // let ticketData = this.ticketSummary;
      //
      // let labels   = ticketData.map( (t) => { return months[t.month-1] });
      // let datasets = [];
      //
      // datasets.push( { label: '# Closed', backgroundColor: colors[1], data: ticketData.map((t) => {return t.closedTickets } ) });
      // datasets.push( { label: '# Billable', backgroundColor: colors[3], data: ticketData.map((t) => {return t.billableTickets } ) });
      // datasets.push( { label: '# High', backgroundColor: colors[8], data: ticketData.map((t) => {return t.statusHighTotal } ) });
      // datasets.push( { label: '# Critical', backgroundColor: colors[2], data: ticketData.map((t) => {return t.statusCriticalTotal } ) });

      // this.ticketsSummaryChart = {labels: labels,datasets: datasets};

      // let labels = ["Samples","two"];

      if( this.datasets ) {
        if( this.chartLabels ) {
          this.chartOptions.legend.display = true;
          return { labels: this.chartLabels, datasets: this.datasets };
        }
        return this.datasets;
      }

      // let labels = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
      // datasets.push( {fill: this.fill, backgroundColor: "rgba(255, 255, 255, 0.8)",  data: [120,200,300,950,350,550,200,800,1100,2560,200,1200,3800]});

      let datasets = []
      let labels = this.chartLabels;
      datasets.push( {fill: this.fill, backgroundColor: this.chartDataBackgroundColor,  data: this.chartData });
      // datasets.push( {label: "# Open" , backgroundColor: "#ccc", data: [500,200,50]});
      return {   labels: labels, datasets: datasets };

    }
  }
};
</script>

