<template>
  <div class=''>
    <div>
      <h2>Wolfie Test Calendars</h2>
      <p>Building up a "true" calendar to display events instead of a list of items for a specifc has become quite a little
        challenge - especially when those events tend to bridge multiple months, could be out of order and may or may not
        follow a specifc format -- Got the basic content management under control so now to get on with some formatting.
      </p>
      <b-checkbox class="float-right" v-model="isShowSecond">Show Alternate</b-checkbox>
<hr>
    <div v-if="!isShowSecond" >
      <b-checkbox v-model="isShowTime">Show Time</b-checkbox>
      <b-checkbox v-model="isShowDescription">Show Descriptions</b-checkbox>
      <b-checkbox v-model="isShowValue">Show $$$</b-checkbox>
      <b-checkbox v-model="isShowItems">Show Items</b-checkbox>
      Timezone: {{ $root.TZ }} :: {{ $root.user.TZ }}
      <simple-calendar
                      title="Wilson's Model Tour Calendar"
                      :isCalendarMode="true"
                      :calendarDate="calendarDate"
                      :timeZone="$root.TZ"
                      :isShowTime="isShowTime"
                      :isShowValue="isShowValue"
                      :isShowItems="isShowItems"
                      :isShowDescription="isShowDescription"
                      :events="simpleCalendarEvents"
                      @click="selectDay"
                      @gotoToday="gotoToday"
                      @nextMonth="nextMonth"
                      @prevMonth="prevMonth"
                    />

    </div>

    </div>

    <div v-if="isShowSecond" class="demo-app" >
    <div class='demo-app-sidebar'>
      <div class='demo-app-sidebar-section'>
        <h2>Instructions</h2>
        <ul>
          <li>Select dates and you will be prompted to create a new event</li>
          <li>Drag, drop, and resize events</li>
          <li>Click an event to delete it</li>
        </ul>
      </div>
      <div class='demo-app-sidebar-section'>
        <label>
          <input
            type='checkbox'
            :checked='calendarOptions.weekends'
            @change='handleWeekendsToggle'
          />
          toggle weekends
        </label>
      </div>
      <div class='demo-app-sidebar-section'>
        <h2>All Events ({{ currentEvents.length }})</h2>
        <ul>
          <li v-for='(event,index) in currentEvents' :key="'sevent-'+index">
            <b>{{ event.startStr }}</b>
            <i>{{ event.title }}</i>
          </li>
        </ul>
      </div>
    </div>
    <div class='demo-app-main'>
      <FullCalendar
        class='demo-app-calendar'
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
    </div>
  </div>
</template>
<script>
  // import Card from "/src/components/UIComponents/Cards/Card.vue";
import SimpleCalendar from "/src/components/UIComponents/SimpleCalendar";
import Moment from "moment-timezone";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

  function createEventId() {
    return String(eventGuid++)
  }
const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  },
  {
    id: createEventId(),
    groupId: 'blueEvents', // recurrent events in this group move together
    daysOfWeek: [ '4' ],
    startTime: '10:45:00',
    endTime: '12:45:00'
  },
  {
    id: createEventId(),
    daysOfWeek: [ '3' ], // these recurrent events move separately
    startTime: '11:00:00',
    endTime: '11:30:00',
    color: 'red'
  },
  {
    id: createEventId(),
    title: 'Mall Trip - Big Bus',
    start: '2020-11-20T10:30:00',
    end: '2020-11-20T11:30:00',
    extendedProps: {
      department: 'BioChemistry'
    },
    description: 'Lecture'
  }
]
  export default {
    components: {
      // Card,
      FullCalendar,
      SimpleCalendar
    },
    data() {
      return {
        isShowSecond        : false,
        isShowTime          : true,
        isShowValue         : true,
        isShowDescription   : true,
        isShowItems         : true,
        calendarDate        : Moment().tz(this.$root.TZ),
        simpleCalendarEvents: [
          { title:"Mark Johnson", eventDate: '2023-11-20T21:48:54.811-06:00', description: "SandPiper II" },
          { title:"Sue Miller", eventDate: '2023-11-20T21:48:54.811-06:00', description: "SandPiper II" },
          { title:"Karl Smith", eventDate: '2023-11-09 11:00', description: "" , items: [ "Balcony","Jacuzzi","Close to the elevator"]},
          { title:"Salon Sales", eventDate: '2023-11-04',  value: 416.25, description: "", items: ["Sharon  129.50","Marcy 286.75"] },
          { title:"Office Closed", eventDate: '2023-12-04', allDay: true, description: "" },
          { title:"Sam Jones", eventDate: '2023-12-20 13:00', description: "SandPiper II" , value: 1809.00},
          { title:"Wendy Adams", eventDate: '2023-12-20 14:00', description: "SandPiper II", value:360.90 },
          { title:"Ann Hicks", eventDate: '2023-12-10 16:00', description: "SandPiper II" },
          { title:"Curt Redford", eventDate: '2023-12-11 18:00', description: "SandPiper II" },
          { title:"Burt Reynolds", eventDate: '2023-10-22 10:00', description: "SandPiper II" },
          { title:"Gene Hackman", eventDate: '2023-12-23 10:00', description: "Sandpiper II" , items: [ "Cannot drive","Likes Fish"]}
        ],
        currentEvents: [],
        calendarOptions: {
          themeSystem: 'bootstrap',
          dateClick  : this.wolfie,
          plugins    : [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin
          ],
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          },
          initialView  : 'dayGridMonth',
          initialEvents: INITIAL_EVENTS,
          editable     : true,
          selectable   : true,
          selectMirror : false,
          dayMaxEvents : true,
          weekends     : true,
          select       : this.handleDateSelect,
          eventClick   : this.handleEventClick,
          eventsSet    : this.handleEvents
            /* you can update a remote database when these fire:
            eventAdd:
            eventChange:
            eventRemove:
            */

        }
      }
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      async wolfie( d ) {
        console.log( "select date" ,  d );
       await this.$root.alert( d.dateStr );
      },

      async selectDay( d ) {
        console.log( "selectDay()" ,  d );
        await this.$root.alert( "you selected " + d.month.toString() + "-" + d.day.toString() + "-" + d.year.toString());
      },
      async monthChanged( n ) {
        console.log( "monthChanged() " , n );
        await this.$root.alert( "Month Changed " + n.month + "-" + n.year );
      },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    gotoToday() {
      this.calendarDate=Moment().tz(this.$root.TZ);
    },
    nextMonth() {
      console.log( "testPage -> nextMonth()" );
      this.calendarDate = Moment(this.calendarDate).tz(this.$root.TZ).add( 1, 'month' );
    },
    prevMonth() {
      console.log( "testPage -> prevMonth()" );
      this.calendarDate = Moment(this.calendarDate).tz(this.$root.TZ).subtract( 1, 'month' );
      // this.simpleCalendarEvents.push( { title:"Added THIS", eventDate: Moment(this.calendarDate).tz(this.$root.TZ).startOf('month'), allDay: true, description: "" })
      // this.simpleCalendarEvents.push( { title:"Added THIS", eventDate: Moment(this.calendarDate).tz(this.$root.TZ).startOf('month').add(6.5,'hours'), description: "" })
      // this.simpleCalendarEvents.push( { title:"Next Day", eventDate: Moment(this.calendarDate).tz(this.$root.TZ).startOf('month').add(1,'day'), description: Moment(this.calendarDate).tz(this.$root.TZ).startOf('month').add(1,'day').toString() })
    },
    handleDateSelect(selectInfo) {
      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar
      calendarApi.unselect() // clear date selection
      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },
    handleEventClick(clickInfo) {
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },
    handleEvents(events) {
      this.currentEvents = events
    }
    }
  }
</script>
<style language="scss" scoped>

h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b { /* used for event dates/times */
  margin-right: 3px;
}
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

</style>
