<template>
  <div class="content">
    <div class="container-fluid">
      <account-class-form @changed="dataChanged($event)" />
    </div>
  </div>
</template>

<script>
import AccountClassForm from "./AccountClassForm.vue";

export default {
  components: {
    AccountClassForm
  },
  data() {
    return {
      isDataChanged: false
    }
  },
  async beforeRouteLeave( to , from, next ) { // this event/routine needs to be defined on the element that the $ROUTER is pointing too - thus sub components won't trigger it
    if( !this.isDataChanged) {
      return next();
    }
    if( await this.$root.confirm('You have unsaved changes, continue leaving without saving?', "warning")) {
      return next();
    }
    next(false)
  },
  methods: {
    dataChanged( result = true ) {
      this.isDataChanged = result;
    },
  }
};
</script>

<style>
</style>
