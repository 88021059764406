<template>
  <card >
    <h4 v-if="assetTypes" slot="header" class="card-title" style="text-transform:capitalize;">{{(assetType) ? _.find( assetTypes , {value:assetType}).text : "Summary" }}</h4>
    <div>
      <b-table striped hover head-variant="dark" class="show-hand" stacked="md" sort-by="title" @row-clicked="rowClicked( $event )" :items="assetSummary" :fields="columns">
        <template v-slot:cell(totalValue)="data">
          {{ currency(data.item.totalValue ) }}
        </template>
      </b-table>
    </div>
    <div class="clearfix"></div>
    <br />
  </card>
</template>

<script>
  // import router from "/src/routes";
  import LTable from "/src/components/UIComponents/Table.vue";
  // import AssetService from "/src/Services/AssetService";
  import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";
  // import moment from "moment-timezone";
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import Currency from "currency.js/dist/currency.js";

  export default {
    components: {
      LTable,
      Card,
      SfInput
    },
    props: {
      assetType: { type: String, default: "" },
      assetTypes: { type: Array, default: () => [] },
      assetSummary: { type: Array, default: () =>[{ _id: "", count: 0, totalValue: "0.00" }]}
    },
    data() {
      return {
        columns: [
          { key: "_id", sortable: true, label: "Category" },
          { key: "count", sortable: true, label: "#" },
          { key: "totalValue", sortable: true, label: "Value" , class:"align-right"},
        ],
        assets: []
      }
    },
    mounted: async function () {
    },
    methods: {
      currency(v) {
        return Currency(v).format();
      },
      rowClicked(row) {
        let t = this;
        t.$emit("categoryClick", row._id);
      }
    }
  };
</script>

<style lang="scss">

  .align-right {
    text-align:right;
  }

</style>
