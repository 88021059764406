"use strict";

//
//  TicketsLib
//
//  author: wjscott 20-Nov-18
//
//  Some global functions
//
//    More of the global functions that are shared amoung ticket management.
//
//

import TenantService  from "/src/Services/TenantService";
import TicketService  from "/src/Services/TicketService";
import UserService    from "/src/Services/UserService";
import Currency       from "currency.js";

export default {

    about() {
      return "TicketsLib V1.00, Last Update 30-Oct-21";
    },

    async getTicketConfig() {
      let ticketConfig
      try {
        ticketConfig = sessionStorage.getItem('ticketConfig');
        if( ! ticketConfig ) {
          let response = await TenantService.getTicketConfig();
          ticketConfig = response.data;
          sessionStorage.setItem('ticketConfig', JSON.stringify(ticketConfig));
        } else {
          ticketConfig = JSON.parse(ticketConfig);
        }
      } catch( error ) {
        console.log( error.message );
      }
      return ticketConfig;
    },

    calculateChangeOrderTotals( ticket ) {

      ticket.adjustedBudget.hours    = ticket.estimatedHours      // the adjusted budget STARTS with the original, then we add/sub the change orders
      ticket.adjustedBudget.labor    = ticket.estimatedLabor
      ticket.adjustedBudget.parts    = ticket.estimatedParts
      ticket.adjustedBudget.supplies = ticket.estimatedSupplies
      ticket.adjustedBudget.expenses = ticket.estimatedExpenses
      ticket.adjustedBudget.outside  = ticket.estimatedOutsideTotal

      ticket.estimatedTotal = Currency(ticket.estimatedHours).multiply(ticket.hourlyRate).add(ticket.estimatedLabor).add(ticket.estimatedParts).add(ticket.estimatedSupplies).add(ticket.estimatedExpenses).add(ticket.estimatedOutsideTotal).value;

      if( ticket.changeOrders && ticket.changeOrders.length > 0 ) {

        ticket.changeOrders.map( (co) => {
          ticket.adjustedBudget.hours    = Currency(ticket.adjustedBudget.hours   ).add(co.hours).value;
          ticket.adjustedBudget.labor    = Currency(ticket.adjustedBudget.labor   ).add(co.labor).value;
          ticket.adjustedBudget.parts    = Currency(ticket.adjustedBudget.parts   ).add(co.parts).value;
          ticket.adjustedBudget.supplies = Currency(ticket.adjustedBudget.supplies).add(co.supplies).value;
          ticket.adjustedBudget.expenses = Currency(ticket.adjustedBudget.expenses).add(co.expenses).value;
          ticket.adjustedBudget.outside  = Currency(ticket.adjustedBudget.outside ).add(co.outside).value;
        });
      }

      ticket.adjustedBudget.total = Currency( ticket.adjustedBudget.hours).multiply(ticket.hourlyRate).add(ticket.adjustedBudget.labor).add(ticket.adjustedBudget.parts).add(ticket.adjustedBudget.supplies).add(ticket.adjustedBudget.expenses).add(ticket.adjustedBudget.outside).value;

    },

    /*

    hours       : { type: Number, default: 0 },
    laborTotal  : { type: Number, default: 0.00 },
    partsTotal  : { type: Number, default: 0.00 },
    supplyTotal : { type: Number, default: 0.00 },   // supply can be general expense or other
    expenseTotal: { type: Number, default: 0.00 },
    outsideTotal: { type: Number, default: 0.00 },   // when not our parts

    ticketTotal : { type: Number, default: 0.00 },   // this SHOULD be updated with each revision to this data; this is the ticket grand total and includes hours * hourly Rate

    couponTotal : { type: Number, default: 0.00 },
    couponNumber: { type: String, default: "" },
    hourlyRate  : { type: Number, default: 0.00 },

    glAccount: {
      hourly  : { type: String, default: "" },
      labor   : { type: String, default: "" },
      parts   : { type: String, default: "" },
      supplies: { type: String, default: "" },
      expenses: { type: String, default: "" },
      outside : { type: String, default: "" },
      coupon  : { type: String, default: "" }
    },

    estimatedHours       : { type: Number, default: 0 },    // these are the hours that were estimated, without a value attached
    estimatedLabor       : { type: Number, default: 0 },    // this is a $ value of the labor expected in ADDITION to the # of hours
    estimatedParts       : { type: Number, default: 0 },
    estimatedSupplies    : { type: Number, default: 0 },
    estimatedExpenses    : { type: Number, default: 0 },
    estimatedOutsideTotal: { type: Number, default: 0 },
    estimatedTotal       : { type: Number, default: 0 },   // this is a sum of the above totals; it is the app responsibility to add this up before saving.

    budget: {   // the ACTUAL budget is the actual estimated to perform the job, but the ADJUSTED is the values we will perform the math on
      hours   : { type: Number, default: 0 },
      labor   : { type: Number, default: 0 },
      parts   : { type: Number, default: 0 },
      supplies: { type: Number, default: 0 },
      expenses: { type: Number, default: 0 },
      outside : { type: Number, default: 0 },
      total   : { type: Number, default: 0 },
    },

    adjustedBudget: {   // the adjusted budget is the actual estimated and ADD the change order items from "changeOrders"
      hours   : { type: Number, default: 0 },
      labor   : { type: Number, default: 0 },
      parts   : { type: Number, default: 0 },
      supplies: { type: Number, default: 0 },
      expenses: { type: Number, default: 0 },
      outside : { type: Number, default: 0 },
      total   : { type: Number, default: 0 },
    },

    */

  clearProjectTotals( ticket ) {
      if(!ticket.projects || ticket.projects.length === 0) {
        return ticket;
      }
      // console.log( "clearProjectTotals" , ticket.projects );

      ticket.projects.forEach((project) => {
        project.actual.hours = 0;
        project.actual.labor = 0;
        project.actual.parts = 0;
        project.actual.supplies = 0;
        project.actual.expenses = 0;
        project.actual.outside = 0;
        project.actual.payments = 0;
        project.actual.deposits = 0;
        project.actual.total = 0;

        project.estimated.hours = 0;
        project.estimated.labor = 0;
        project.estimated.parts = 0;
        project.estimated.supplies = 0;
        project.estimated.expenses = 0;
        project.estimated.outside = 0;
        project.estimated.total = 0;

      });

      return ticket;
  },

  addToProjectTotal(ticket, step) {

    // console.log("addToProjectTotal", ticket, step);

    if(!ticket || !step || !ticket.projects || ticket.projects.length === 0 || !step.projectId) {
      return ticket;
    }

    let project = ticket.projects.find((p) => p.id === step.projectId);

    if(project) {

      // console.log( "found project" , project );

      if(step.stepType === 'task') {
        project.actual.hours    = Currency(project.actual.hours).add(step.hours).value;
        project.actual.labor    = Currency(project.actual.labor).add(step.labor).value;
        project.actual.parts    = Currency(project.actual.parts).add(step.parts).value;
        project.actual.supplies = Currency(project.actual.supplies).add(step.supplies).value;
        project.actual.expenses = Currency(project.actual.expenses).add(step.expenses).value;
        project.actual.outside  = Currency(project.actual.outside).add(step.outside).value;
        project.actual.total    = Currency(project.actual.total)
          .add(Currency(step.hours).multiply(ticket.hourlyRate))
          .add(step.labor)
          .add(step.parts)
          .add(step.supplies)
          .add(step.expenses)
          .add(step.outside).value;

        project.estimated.hours    = Currency(project.estimated.hours).add(step.estimatedHours).value;
        project.estimated.labor    = Currency(project.estimated.labor).add(step.estimatedLabor).value;
        project.estimated.parts    = Currency(project.estimated.parts).add(step.estimatedParts).value;
        project.estimated.supplies = Currency(project.estimated.supplies).add(step.estimatedSupplies).value;
        project.estimated.expenses = Currency(project.estimated.expenses).add(step.estimatedExpenses).value;
        project.estimated.outside  = Currency(project.estimated.outside).add(step.estimatedOutsideTotal).value;
        project.estimated.total    = Currency(project.estimated.total)
          .add(Currency(step.estimatedHours).multiply(ticket.hourlyRate))
          .add(step.estimatedLabor)
          .add(step.estimatedParts)
          .add(step.estimatedSupplies)
          .add(step.estimatedExpenses)
          .add(step.estimatedOutsideTotal).value;

      }

      if(step.stepType === 'transaction') {
        project.actual.payments = Currency(project.actual.payments).add(step.labor).add(step.parts).add(step.supplies).add(step.expenses).add(step.outside).value;
      }

    }

    // console.log( "leaving addToProjectTotal" , ticket );

    return ticket;
  },

 // ** NOTE ** Not used!
  calculateTaskTotals( ticket ) {   // case:936 Do something with this thing - it's simple

    let ttl = {
      hours   : 0,
      labor   : 0,
      parts   : 0,
      supplies: 0,
      expenses: 0,
      outside : 0,
      deposits: 0,
      payments: 0,
      total   : 0,
    };

  // console.log("hit - calculateTaskTotals", ticket);

    // ttl.hours=  0;
    // ttl.labor=  0;
    // ttl.parts=  0;
    // ttl.supplies= 0;
    // ttl.expenses= 0;
    // ttl.outside=  0;
    // ttl.total=  0;
    // ttl.payments = 0;
    // ttl.deposits = 0;

    ticket.steps.forEach((step) => {
      if( step.stepType === 'task' ) {
        ttl.hours    = Currency(ttl.hours).add(step.hours).value;
        ttl.labor    = Currency(ttl.labor).add(step.labor).value;
        ttl.parts    = Currency(ttl.parts).add(step.parts).value;
        ttl.supplies = Currency(ttl.supplies).add(step.supplies).value;
        ttl.expenses = Currency(ttl.expenses).add(step.expenses).value;
        ttl.outside  = Currency(ttl.outside).add(step.outside).value;
        ttl.total    = Currency(ttl.total)
          .add(Currency(step.hours).multiply(ticket.hourlyRate))
          .add(step.labor)
          .add(step.parts)
          .add(step.supplies)
          .add(step.expenses)
          .add(step.outside).value;
      }

      if( step.stepType === 'transaction' ) {
        ttl.payments = Currency(ttl.payments).add(step.labor).add(step.parts).add(step.supplies).add(step.expenses).add(step.outside).value;
      }

      // add to the budget year total here.(but still need to add to the task totals)
      // if( step.projectId ) {
      //   ticket = this.addToProjectTotal(ticket, step);
      // }

    });

    // ticket.taskTotals = ttl;

    return ttl;
  },

    // This routine assumes that all actions, and steps are part of the ticket when making this call
    // this is the only routine that should be called to update the ticket totals
    // and this is something that we should evaluate in the future to add these values when
    // the individual elements are added or removed because as this grows, passing all this data
    // all the time becomes a problem.
    calculateTicketTotals( ticket ) {

    // console.log( "hit calculateTicketTotals" , ticket );

      let hours      = 0,                  // our working total for the REAL/ACTUAL totals
          labor      = 0,
          parts      = 0,
          supplies   = 0,
          expenses   = 0,
          outside    = 0,
          payments   = 0,
          deposits   = 0,
          // total      = 0,
          hourlyRate = ticket.hourlyRate;

      ticket.actual.hours    = 0;     // reset the actuals
      ticket.actual.labor    = 0;
      ticket.actual.parts    = 0;
      ticket.actual.supplies = 0;
      ticket.actual.expenses = 0;
      ticket.actual.outside  = 0;
      ticket.actual.payments = 0;
      ticket.actual.deposits = 0;
      ticket.actual.total    = 0;   // there is ONE exception to this segmentation; the TOTAL field inside actual and at the root ticket are the SAME thing

      this.clearProjectTotals(ticket);     // if we have project-level totals, let's clear those too, since we're going to accumulate these below.

      try {
        // remember, we are building our local variables with all this code.
        // let's loop through comments and pickup hours (if any) this is an OLD feature
        if( ticket.actions ) {
          ticket.actions.map((a) => {
            if( a.hours ) {
              hours += parseFloat(a.hours);
            }
          })
        }

        // now, let's accumulate steps - they also have ALL total fields now
        // also, if we have steps, these base ticket estimates are accumulated
        // from the task steps.  And, steps now have budget values too.

        if( ticket && ticket.steps && ticket.steps.length > 0 ) {

          ticket.estimatedHours        = 0;
          ticket.estimatedLabor        = 0;
          ticket.estimatedParts        = 0;
          ticket.estimatedSupplies     = 0;
          ticket.estimatedExpenses     = 0;
          ticket.estimatedOutsideTotal = 0;
          ticket.estimatedTotal        = 0;

          ticket.steps.map((s) => {
            if( s.stepType === 'task' ) {
              hours    = Currency(hours).add(s.hours).value;
              labor    = Currency(labor).add(s.labor).value;
              parts    = Currency(parts).add(s.parts).value;
              supplies = Currency(supplies).add(s.supplies).value;
              expenses = Currency(expenses).add(s.expenses).value;
              outside  = Currency(outside).add(s.outside).value;
            }

            if( s.stepType === 'transaction' ) {
              // the "values" are negative for payments, so we ADD these to the total to reduce the value
              payments = Currency(payments).add(s.labor).add(s.parts).add(s.supplies).add(s.expenses).add(s.outside).value;
            }

            // total    = Currency(hours).multiply(hourlyRate).add(total).add(s.labor).add(s.parts).add(s.supplies).add(s.expenses).add(s.outsideTotal).value;

            ticket.estimatedHours        = Currency(ticket.estimatedHours).add(s.estimatedHours).value;
            ticket.estimatedLabor        = Currency(ticket.estimatedLabor).add(s.estimatedLabor).value;
            ticket.estimatedParts        = Currency(ticket.estimatedParts).add(s.estimatedParts).value;
            ticket.estimatedSupplies     = Currency(ticket.estimatedSupplies).add(s.estimatedSupplies).value;
            ticket.estimatedExpenses     = Currency(ticket.estimatedExpenses).add(s.estimatedExpenses).value;
            ticket.estimatedOutsideTotal = Currency(ticket.estimatedOutsideTotal).add(s.estimatedOutsideTotal).value;
            // ticket.estimatedTotal        = Currency(ticket.estimatedTotal).add(s.estimatedTotal).value;

            if( s.projectId ) {
              this.addToProjectTotal(ticket, s);
            }

          })
        }


        let taskTotals = {
          hours   : hours,
          labor   : labor,
          parts   : parts,
          supplies: supplies,
          expenses: expenses,
          outside : outside,
          payments: payments,
          deposits: deposits,
          total   : Currency(hourlyRate).multiply(hours).add(labor).add(supplies).add(parts).add(expenses).add(outside).subtract(ticket.couponTotal).value,
        };

        ticket.taskTotals = taskTotals;   // this is pre-manual adjustments and charge orders. (maybe change order should impact these, dunno yet.)

        // now let's add in any manually adjusted values
        hours    = Currency( hours ).add(ticket.hours).value;
        labor    = Currency( labor ).add(ticket.laborTotal).value;
        parts    = Currency( parts ).add(ticket.partsTotal).value;
        supplies = Currency( supplies ).add(ticket.supplyTotal).value;
        expenses = Currency( expenses ).add(ticket.expenseTotal).value;
        outside  = Currency( outside ).add(ticket.outsideTotal).value;
        payments  = Currency( payments ).add(ticket.paymentTotal).value;
        deposits  = Currency( deposits ).add(ticket.depositTotal).value;
        // total    = Currency( total).add(ticket.total).value;

        ticket.actual.hours    = hours;
        ticket.actual.labor    = labor;
        ticket.actual.parts    = parts;
        ticket.actual.supplies = supplies;
        ticket.actual.expenses = expenses;
        ticket.actual.outside  = outside;

        ticket.actual.payments = payments;
        ticket.actual.deposits = deposits;

        ticket.actual.total    = Currency(hourlyRate).multiply(hours).add(labor).add(supplies).add(parts).add(expenses).add(outside).subtract(ticket.couponTotal).value;
        ticket.estimatedTotal  = Currency(hourlyRate).multiply(ticket.estimatedHours).add(ticket.estimatedLabor).add(ticket.estimatedSupplies).add(ticket.estimatedParts).add(ticket.estimatedExpenses).add(ticket.estimatedOutsideTotal).value;
        ticket.ticketTotal     = ticket.actual.total


      } catch( error ) {
        console.log( error );
      }



      return ticket;
    },
};
