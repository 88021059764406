///-------------------------------------------------------------------------------------------------
// summary:	QUERY service class (used mostly for reporting and data analysis)
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getAccountBalance( totalClassId , query ) {
    const response = Api().get(`/api/1/query/accountBalance/${totalClassId}`, { params: query } );
    return response;
  },
  getTransactionSummary( query ) {
    const response = Api().get(`/api/1/query/transactionSummary/`, { params: query } );
    return response;
  },

  getDailySalesSummaryByRevenueCenter( revenueCenter ='all' , reportDate = '' ) {
    const response = Api().get(`/api/1/query/dailySalesSummaryByRevenueCenter/${revenueCenter}/${reportDate}` );
    return response;
  },

  getOpenTicketSummary( ticketTypes = []) {
    const response = Api().get(`/api/1/query/openTicketSummary`, {params: { ticketTypes: ticketTypes.join(",")}});
    return response;
  },
  getPeriodClosedTicketSummary( params = {}) {
    const response = Api().get(`/api/1/query/periodClosedTicketSummary`, {params: params});
    return response;
  },

  getOpenTicketSummaryByCategory(ticketTypes = []) {
    const response = Api().get(`/api/1/query/openTicketSummaryByCategory`, { params: { ticketTypes: ticketTypes.join(",") } });
    return response;
  },
  getTicketSummaryByUser( period , ticketTypes = [] ) {
    const response = Api().get(`/api/1/query/ticketSummaryByUser/${period}` , {params: { ticketTypes: ticketTypes.join(",")}});
    return response;
  },
  getJobCodes( accountType ) {
    const response = Api().get(`/api/1/query/jobCodes`, {params: { accountType: accountType }});
    return response;
  },
  getFundContributions( startDate, endDate, maxTrans ) {
    if( !maxTrans ) {
      maxTrans = 10000;
    }
    const response = Api().get(`/api/1/query/fundContributions`, {params: { startDate: startDate, endDate: endDate, limit: maxTrans }});
    return response;
  },
  getMasterCertificationList() {
    const response = Api().get(`/api/1/query/masterCertificationsList` );
    return response;
  },
  // we will get some parms in here shortly
  getMasterCertificationMatrix() {
    const response = Api().get(`/api/1/query/masterCertificationsMatrix` );
    return response;
  },
  // summaryFormat = what are we grouping by (day, week, month, year)
  // source: catering, orders; defaults to catering
  getItemSummary(startDate = null, endDate = null, summaryFormat='%Y-%m-%d', source ='catering', eventKey='') {
    const response = Api().get(`/api/1/query/itemSummary`, {params: { startDate: startDate, endDate: endDate, summaryFormat: summaryFormat, source: source, eventKey: eventKey }});
    return response;
  }


}
