///-------------------------------------------------------------------------------------------------
// summary:	Tender service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getTenders() {
    const response = Api().get(`/api/1/tenders`);
    return response;
  },
  addTender( tenderNumber) {
    const response = Api().post(`/api/1/tender`, tenderNumber );
    return response;
  },
  updateTender( tender ) {
    const response = Api().patch(`/api/1/tender/${tender.tenderNumber}`, tender );
    return response;
  },
  removeTender(tenderObjectId) {
    const response = Api().delete(`/api/1/tender/${tenderObjectId}`);
    return response;
  }
}
