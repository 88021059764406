import VTooltip from 'v-tooltip';
import notifications from 'vue-notifyjs'; // Notifications plugin
import GlobalComponents from './globalComponents'; // A plugin file where you could register global components used across the app
import GlobalDirectives from './globalDirectives'; // A plugin file where you could register global directives
import SideBar from './components/UIComponents/SidebarPlugin'; // Sidebar on the right. Used as a local plugin in AppLayout.vue
// asset imports
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-notifyjs/themes/default.scss'
import './assets/sass/light-bootstrap.scss'
import './assets/css/app.css'
// library auto imports
import 'es6-promise/auto'

/**
 * This is the main Light Bootstrap Application Vue plugin where App related plugins are registerd.
 */
export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(notifications)      // not sure how/where this gets tied in.
    Vue.use(VTooltip)
  }
}
