<template>
  <div class="content">
    <div class="container-fluid">
      <card>
        <template slot="header">
          <h3 class="card-title">In-Process/Incomplete Registrations <button class="float-left mr-3 btn btn-success btn-sm btn-fill" @click="$router.push('/app');"><span v-show="loading" :class="isLoading"></span>Done</button></h3>
          <hr>
        </template>
        <div class="row">
          <div class="col-md-8">
            <h4>Accounts</h4>
            <b-table  v-if="accounts.length > 0 " striped hover :items="accounts" :fields="fields">
              <template v-slot:cell(action)="data">
                <i class="fa fa-trash show-hand" v-b-popover.hover.top="'Remove This Registration'"  @click="removeAccount(data.item)" ></i>
              </template>
              <template v-slot:cell(createdAt)="data">
                {{moment(data.item.createdAt).fromNow()}}
              </template>
            </b-table>
            <div v-else>
              <h5>No Accounts Pending</h5>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import Card from "/src/components/UIComponents/Cards/Card.vue";
  import AccountService from "/src/Services/AccountService";
  import Moment from "moment";

  export default {
    components: {
      Card,
      Moment
    },
    data () {
      return {
        accounts: [],
        fields  : [
          "action",
          { key: 'remoteAccess[0].login.email' , label: "Email" , sortable: true },
          { key: 'accountNumber', sortable: true },
          { key: 'accountTitle', sortable: true},
          { key: 'createdAt', label: "Created", sortable: true }
          ],
        loading      : false,
        isLoading    : "fa fa-spinner  fa-refresh-animate mr-3",
        type         : ['', 'info', 'success', 'warning', 'danger'],
        notifications: {
          topCenter: false
        }
      }
    },
    async mounted() {
      this.getPendingAccounts();
    },
    methods: {
      moment(d){
        return Moment(d);
      },
      notifyVue (verticalAlign, horizontalAlign) {
        const notification = {
          template: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`
        }

        const color = Math.floor((Math.random() * 4) + 1)
        this.$notifications.notify(
          {
            component: notification,
            icon: 'nc-icon nc-app',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: this.type[color]
          })
      },
       async getPendingAccounts() {
        var response;
        var t = this;

        t.loading = true;

        try {

          response = await AccountService.getPendingAccounts();
          this.accounts = response.data;
          t.loading = false;
        } catch (err) {
          if (err) {
            console.error( err );
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      },
       async removeAccount( account ) {
        var response;
        var t = this;

        if(! confirm( "Remove " + account.accountNumber + "/" + account.accountTitle )) {
          return;
        }

        t.loading = true;

        try {

          response = await AccountService.removeAccount( account.accountNumber );
          await this.getPendingAccounts();
          t.loading = false;
        } catch (err) {
          if (err) {
            console.error( err );
            t.$root.errorMessage("Oops", err.response.data.message);
          }
          t.loading = false;
        }
      }
    }
  }

</script>
<style lang="scss">

</style>
