///-------------------------------------------------------------------------------------------------
// summary:	Report service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getActiveReportFormats() {
    const response = Api().get('/api/1/reportFormats');
    return response;
  },
  getAllReportFormats() {
    const response = Api().get('/api/1/reportFormats', { params: { all: true } });
    return response;
  },
  getReportFormat(id) {
    const response = Api().get(`/api/1/reportFormat/${id}`);
    return response;
  },
  saveReportFormat(report) {
    const response = Api().patch(`/api/1/reportFormat`, report);
    return response;
  },
  addReportFormat(report) {
    const response = Api().post(`/api/1/reportFormat`, report);
    return response;
  },
  deleteReportFromat(id) {
    const response = Api().delete(`/api/1/reportFormat/${id}`);
    return response;
  }

}
