/* eslint-disable no-console */

import { register } from 'register-service-worker'

// console.log( "Expecting this to exist: registerServiceWorker.js() service-worker.js location =" , `${process.env.BASE_URL}service-worker.js` );
// console.log( "resigterServiceWorker.js()-> process.env.NODE_ENV=" , process.env.NODE_ENV );


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For App Details, visit https://www.newcare.international'
      );
    },

    updated( registration ) {
      // this fires sometime after the other "startup" events - But does NOT fire if user exists the browser and re-opens; it's just the new version then.
      let appVersion = localStorage.getItem( "appVersion" );
      console.log("A new app version is available (updated localStorage too)", registration , appVersion, process.env.VUE_APP_VERSION)
      // do any updates for new version here...
      localStorage.setItem( "appVersion" , process.env.VUE_APP_VERSION );
      let updateEvent = new CustomEvent('swUpdated', { detail: registration });
      document.dispatchEvent(updateEvent);
    },
    registered( registration ) {
      // this fires second, regardless of new app available
      let appVersion = localStorage.getItem( "appVersion" );
      if(!appVersion || appVersion !== process.env.VUE_APP_VERSION ) {
        console.log( "registeredServiceWorker->registered() Updating appVersion" );
        localStorage.setItem( "appVersion" , process.env.VUE_APP_VERSION );
      }
      console.log( "App has been registered as version (env/localStorage)" , process.env.VUE_APP_VERSION , appVersion );
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    periodicSync () {
      console.log( "periodicSync()" );
    },
    updateAvailable(e){
      console.log( "Update Available" , e );
    },
    updatefound (e) {
      console.log('Update Found.', e);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  })
}
