<template>
  <div v-if="taskList.length>0" class="task-list">
    <table class="table table-bordered table-hover table-striped" style="table-layout:fixed;line-height:1em;" >
      <thead class="table-dark">
        <tr>
          <th class="task-center" style="width:120px;"></th>
          <th class="task-center" style="width:50px;">Comp</th>
          <th v-if="isShowBudgetYear" class="task-center" style="width:50px;">B/Year</th>
          <th v-if="isCategory" class="task-center" style="width:9%;">Category</th>
          <th>Title</th>
          <th v-if="isDetails" style="width:130px;text-align:center;">Scheduled</th>
          <th v-if="isDetails" style="width:60px;">A/Hrs</th>
          <th v-if="isDetails" style="width:90px;">$ Actual</th>
          <th v-if="isDetails && isBudget" style="width:60px;">E/Hrs</th>
          <th v-if="isDetails && isBudget" style="width:90px;">$ Estimated</th>
          <th v-if="isVendor">Vendor</th>
        </tr>
      </thead>
      <tbody>
<!--      <draggable v-model="taskList" style="" ghost-class="ghost" @end="taskDropped" >-->
        <tr v-for="(task,index) in taskList" :key="index" :style="taskBackgroundColor(task)">
          <template>
            <td class="task-center">{{task.sequence}}
              <i class="fa fa-trash ml-2" v-if="task.stepType==='task' || $root.isAdmin()" v-b-popover.hover.left="'Remove this item?'" @click="removeTask(task)"/>
              <i v-if="task.stepType==='task'" class="fa fa-clone ml-2" v-b-popover.hover.top="'Clone task'" @click="taskClone(task)"/>
            </td>
            <td class="task-center" >
              <template v-if="task.stepType==='task'">
                <span v-if="!task.complete" @click="markComplete(task)"><i class="fa fa-square-o"></i></span><span v-else @click="markIncomplete(task)"><i class="fa fa-check-square-o" style="color:green;"></i></span>
              </template>
            </td>
            <th v-if="isShowBudgetYear" style="font-size:.9em;width:50px;" :style="(task.budgetYear!=budgetYear?'font-weight:100;':'')">{{task.budgetYear}}</th>
            <td v-if="isCategory" ><div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{task.category}}</div></td>
            <td class="task-title" @click.stop="taskEdit(task)"><span style="font-weight:100;margin-right:.5em;font-size:.8em;" >({{task.stepId}})</span>{{task.title}}<div v-if="isDescription && task.description" class="task-description w-100">{{task.description}}</div></td>
            <td v-if="isDetails" class="text-center" style="border-right:1px solid black;">{{(task.scheduledDate) ? moment(task.scheduledDate).format("ddd DD-MMM-YY") : '-' }}</td>
            <td v-if="isDetails" class="text-right">{{task.hours}}</td>
            <td v-if="isDetails" class="text-right" style="border-right:1px solid black;">{{ currency( task.labor).add(task.parts).add(task.supplies).add(task.expenses).add(task.outside).add(task.hours * hourlyRate ) | comma2d }}</td>
            <td v-if="isDetails && isBudget" class="text-right" :style="(task.complete) ? 'background:#D5FFCC;' : 'background:#eee;'">{{task.estimatedHours}}</td>
            <td v-if="isDetails && isBudget" class="text-right" :style="(task.complete) ? 'background:#D5FFCC;' : 'background:#eee;'">{{ currency( task.estimatedLabor).add(task.estimatedParts).add(task.estimatedSupplies).add(task.estimatedExpenses).add(task.estimatedOutsideTotal).add(task.estimatedHours * hourlyRate ) | comma2d }}</td>
            <td v-if="isVendor" class="task-vendor" @click.stop="vendorEdit(task)"><div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{task.vendorName}}</div></td>
          </template>
        </tr>
<!--      </draggable>-->
      </tbody>
      <tfoot class="table-dark">
        <tr v-if="isDetails" style="text-align:right;">
          <td class="task-center" style="width:60px;">{{taskList.length}} Items</td>
          <td class="task-center" style="width:50px;"></td>
          <td v-if="isShowBudgetYear"></td>
          <td v-if="isCategory" class="task-center" ></td>
          <td></td>
          <td v-if="isDetails" style="font-weight:900;" v-b-popover.hover.top="'Totals based on visible tasks (to see, toggle Show Completed)'">Totals</td>
          <td v-if="isDetails" style="font-weight:900;">{{currency(taskList.reduce((r,a) => r+= parseFloat(a.hours), 0))}}</td>
          <td v-if="isDetails" style="font-weight:900;">{{currency(taskList.reduce((r,a) => r+= currency( a.labor).add(a.parts).add(a.supplies).add(a.expenses).add(a.outside).add(a.hours * hourlyRate ).value, 0)) | comma2d}}</td>
          <td v-if="isDetails && isBudget" style="font-weight:900;">{{currency(taskList.reduce((r,a) => r+= parseFloat(a.estimatedHours), 0))}}</td>
          <td v-if="isDetails && isBudget" style="font-weight:900;">{{currency(taskList.reduce((r,a) => r+= currency( a.estimatedLabor).add(a.estimatedParts).add(a.estimatedSupplies).add(a.estimatedExpenses).add(a.estimatedOutsideTotal).add(a.estimatedHours * hourlyRate ).value, 0)) | comma2d}}</td>
          <td v-if="isVendor"></td>
        </tr>
      </tfoot>
    </table>
    <p v-if="!taskList || taskList.length===0" style="text-align:center;margin-top:2em;">No Items</p>
  </div>
</template>

<script>

  import Moment from "moment-timezone";
  import Currency from "currency.js";
  import draggable from "vuedraggable";

  export default {
    props     : {
      tasks             : {type: Array, default: () => []},
      isDetails         : {type: Boolean, default: false},
      isDescription     : {type: Boolean, default: false},
      isCategory        : {type: Boolean, default: false},
      isVendor          : {type: Boolean, default: true },
      isProject         : {type: Boolean, default: false},
      isBudget          : {type: Boolean, default: false},
      isShowAllTasks    : {type: Boolean, default: true},
      isShowBudgetYear  : {type: Boolean, default: false},
      projectList       : {type: Array, default: () => []},
      hourlyRate        : {type: Number , default: 0},
      budgetYear        : {type: String, default: ""}
    },
    components: {
      draggable
    },
    computed: {
      taskList() {
        if( this.isShowAllTasks ) {
          return this.tasks;
        }
        return this.tasks.filter((t) => {return t.stepType==='task' });
      }
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      // showCategory() {
      //   let c;
      //   if( this.tasks ) {
      //     c = this.tasks.filter((t) => {return t.complete !== true });
      //   } else {
      //     c = this.tasks;
      //   }

      // },
      moment(d) {
        return Moment(d);
      },
      taskDropped(a,b,c) {
        console.log( "taskDropped", a,b,c );
        for(let i=0; i<this.taskList.length; i++) {
          this.taskList[i].sequence = (i+1)*100;
        }
        // this.dataChanged();
      },
      taskBackgroundColor( task ) {

        if( this.isProject ) {
          if( !task.budgetYear ){
          // if( !task.projectId || this.projectList.find((p) => {return p.id === task.projectId}) === undefined ) {
            // return 'background:#FFD5D5;';
            return "background:#ffd9b3;color:black;";
          }
        }

        return (task.complete) ? 'background:#EAFFE6;' : '';
      },
      removeTask( task ) {
        this.$emit( "remove" , task );
      },
      markComplete(task) {
        this.$emit("complete", task);
      },
      markIncomplete(task) {
        this.$emit("incomplete", task);
      },
      taskEdit( task ) {
        this.$emit( "edit" , task );
      },
      taskClone( task ) {
        this.$emit( "clone" , task );
      },
      vendorEdit(task) {
        this.$emit( "vendorEdit" , task );
      }
    }
  };
</script>
<style lang="scss" scoped>

  /* MUST reference the tr/th because of specificity in the override :( */
  thead.table-dark >tr>th {
    background:black;
    color: white;
  }

  tfoot.table-dark  {
    background:lightgray;
    color:black;
  }

  tbody.table-dark {
    border-color:black;
  }

  .task-list {
    overflow-x    : auto;
  }

  .task-center {
  }

  .task-title {
    cursor: pointer;
    text-align:left;
    //max-width:400px;
    //width:400px;
    text-overflow:ellipsis;
    overflow-x:hidden;
  }

  .task-show-details {
    position:absolute;
    right:25px;
    top:60px;
  }

  .task-description {
    font-weight:100;
    font-size:.8em;
    margin-left:2em;
    padding-top:5px;
    line-height:14px;
    white-space:pre-line;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  .task-vendor{
    cursor: pointer;
    text-align:left;
    max-width:250px;
    text-overflow:ellipsis;
    overflow-x:hidden;
  }

</style>
