<template>
  <div class="content">
      <tenant-profile-form/>
  </div>
</template>

<script>
import TenantProfileForm from "./TenantProfileForm.vue";
// import Sites from "./Sites.vue";

export default {
  components: {
    TenantProfileForm
  }
};
</script>

<style>
</style>
