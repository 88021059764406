///-------------------------------------------------------------------------------------------------
// summary:	Log service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getFiles( folder = null, duration = 10080 ) {
    let response;
    if (folder) {
      // duration is an available query param; defaults to 1 week
      response = Api().get('/api/1/files', { params: { path: folder, duration: duration } });
    } else {
      response = Api().get('/api/1/files',  { params: { duration: duration } });
    }
    return response;
  },
  getFile() {
    const response = Api().get('/api/1/file');
    return response;
  },
  makeFilenameWithToken( filename , duration = 10080 ) {
    const response = Api().get('/api/1/fileWithToken' , { params:{ filename: filename, duration: duration }} );
    return response;
  },
  getVideos() {
    const response = Api().get('/api/1/videos');
    return response;
  },
  loadTest() {
    return Api().get("/api/1/pdf/samplePage");
  },
  postFile(folder, form) {
    return Api().post("/api/1/file", form, { params: { folder: folder }, config: { 'content-type': 'multipart/form-data'} });
    //return Api().post("/api/1/file", form, { params: { filename: filename } });
  },
  deleteFile(folder, filename) {
    return Api().delete("/api/1/file", { params: { folder: folder, filename: filename } });
  },
  uploadImageFromText(data) {
    const response = Api().post("/api/1/uploadImageFromText", data)
    return response;
  },

}


