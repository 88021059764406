<template>
  <div class="content container-fluid">
    <b-row>
      <b-col class="col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 p-3 mb-4 noprint" style="background:#eee;border-radius:1em;min-height:335px;">
        <b-row>
          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
            <div style="display:block;">
              <b-button class="btn btn-sm btn-fill btn-success" style="margin-right:1em;margin-top:1.75em;border-radius:.75em;" @click="$router.push('/app');">Done</b-button>
              <h3 class="card-title" style="display:inline-block;vertical-align:top;">
                <span v-if="autoRefresh===false">Transaction Listing</span>
              </h3>
              <div style="margin-left:4em;margin-top:-1em;margin-bottom:1em;">
                <span v-if="autoRefresh">Live Preview (last 10 transactions)</span>
                <span v-else>{{ transactionList.length }} Transaction{{ transactionList.length === 1 ? "" : "s" }}</span>
              </div>
            </div>
            <div>
              <!--<b-checkbox class="float-right" v-model="autoRefresh">Live View</b-checkbox>-->
              <b-form class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <b-form-group horizontal label="Date Range">
                  <b-form-input type="date" style="border-radius:.75em;" v-model="beginDate" required></b-form-input>
                  <b-form-input type="date" style="border-radius:.75em;" v-model="endDate" required></b-form-input>
                  <div style="margin-top:1em;">
                    <!-- <b-button class="btn btn-fill btn-sm mt-2" style="border-radius:.75em;" @click="autoRefresh=false;getTransactions(beginDate+ ' 00:00:00',endDate+' 23:59:59',999999);"> -->
                    <b-button class="btn btn-fill btn-sm mt-2" :style="hasFilter(false) ? 'background:orange;border-radius:.75em;' : '' " @click="autoRefresh=false;getTransactions(beginDate  ,endDate  ,999999);">
                      <span v-show="loading" :class="isLoading" /> Get Range
                    </b-button>
                  </div>
                </b-form-group>
                <!-- <b-checkbox  v-if="transactionList.length>0" class="" v-model="showDetails">Show More Detail</b-checkbox> -->
              </b-form>
            </div>
          </b-col>
          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
            <div style="display:block;">
              <h4 style="text-align:center;margin-top:1em;margin-bottom:0;">Preset Dates</h4>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;today();getTransactions(beginDate ,endDate,999999);">Today: {{
                  showToday()
                }}
              </b-button>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;beginDate=emoment().subtract(1,'days').format('YYYY-MM-DD');endDate=emoment().subtract(1,'days').format('YYYY-MM-DD');getTransactions(beginDate,endDate,999999);">
                Yesterday: {{ emoment().subtract(1, 'days').format('dddd, MMM DD ') }}
              </b-button>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;beginDate=emoment().startOf('week').format('YYYY-MM-DD');endDate=emoment().endOf('week').format('YYYY-MM-DD');getTransactions(beginDate,endDate,999999);">
                This Week
              </b-button>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;beginDate=emoment().startOf('week').subtract(1,'week').format('YYYY-MM-DD');endDate=emoment(beginDate).endOf('week').format('YYYY-MM-DD');getTransactions(beginDate,endDate,999999);">
                Last Week
              </b-button>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;beginDate=emoment().startOf('month').format('YYYY-MM-DD');endDate=emoment().endOf('month').format('YYYY-MM-DD');getTransactions(beginDate,endDate,999999);">
                This Month
              </b-button>
              <b-button class="btn btn-fill btn-sm btn-date-select" @click="autoRefresh=false;beginDate=emoment().startOf('month').subtract(1,'month').format('YYYY-MM-DD');endDate=emoment().subtract(1,'month').endOf('month').format('YYYY-MM-DD');getTransactions(beginDate,endDate,999999);">
                Last Month
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col  class="col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5  p-3 mb-4 noprint" style="">
        <div class="main-title ">
          <i class="fa fa-eraser show-hand" v-if="hasFilter()" style="float:left;font-size:1.5em;margin-top:5px;margin-right:.5em;color:red;" @click="clearFilter()" v-b-popover.hover.right="'Clear this filter'"></i>
<!--          <b-checkbox v-if="transactionList.length<100000" style="float:right;" v-model="isLiveFilter" v-b-popover.hover.left="'Filter transactions real-time.  NOTE: If you have a large number of transactions, live filter may be slow and if there are more than 100,000 transactions this feature will be disabled'">-->
<!--            Live Filter-->
<!--          </b-checkbox>-->
          <h4 style="margin:0;padding:0;font-style:italic;">Filter Transactions</h4>
          <p style="font-size:.7em;color:gray;margin-top:0;margin-bottom:.5em;padding-left:1em;">Filter can be applied to existing transactions or used when fetching new ones.  You can filter by multiple fields at the same time.  To filter by multiple values in a single field, separate the values with a comma. <br><strong>Filters are CASE SENSITIVE.</strong></p>
          <b-form @input="(isLiveFilter) ? applyFilter() : updateFilter=true;">
            <b-row>
              <b-col>
                <sf-input v-model="filters.account" label="Account"  />
                <sf-input v-model="filters.badge" label="Badge" />
                <sf-input v-model="filters.alias" label="Alias" />
              </b-col>
              <b-col>
                <sf-input v-model="filters.totalClass" label="TTL Class" style="color:lightgray;"/>
                <sf-input v-model="filters.revenueCenter" label="Rev Cntr" />
                <sf-input v-model="filters.tenderNumber" label="Tender" />
                <sf-input v-model="filters.checkNumber" label="Check" />
                <sf-input v-model="filters.transactionLimit" label="Trans $" />
              </b-col>
            </b-row>
          </b-form>
          <b-button v-if="updateFilter==true && !isLiveFilter" class="btn btn-sm btn-fill center" style="margin-top:1em;width:50%;margin-left:15%;background:#1a8d60;color:white;font-weight:600;" @click="applyFilter()">
            Apply Filter Only
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div class="float-right show-hand noprint" style="position:relative;font-size:1.5em;top:-12px;">
      <i class="fa fa-file-excel-o show-hand" style="margin-right:.5em;" @click="csvExport()" v-b-popover.hover.top="'Export List'"></i>
      <i class="fa fa-print noprint" @click="print('transaction-list')"></i>
    </div>

    <b-tabs>
      <b-tab title="Transactions" active>
        <div class="row transaction-list" v-if="transactionList.length>0">
          <div class="col-12 mb-4 print-container" style="margin-top:1em;">

            <h4 class="card-title" style="margin-top:0;margin-bottom:0;">
              <span :style="(hasFilter()) ? 'color:orange;font-weight:1000;' : ''">Transactions</span>
              <small>&nbsp;&nbsp; Displaying: {{ transactionListCount }} / {{ numeral(transactionListTotal).format("0,0.00") }}</small>
              <span style="margin-left:2em;font-size:.8em;color:red;">{{ transactionsMessage }}</span>
            </h4>
            <p class="card-category" style="margin-left:2em;margin-bottom:0;color:orange;font-weight:900;" v-if="hasFilter()">FILTER ACTIVE
              <span v-if="listFilter.length>0" style="border-radius:.75em;padding:2px 8px 2px 8px;background:#ff9500;color:white;">{{ listFilter }}</span></p>

            <!-- <div v-if="transactions.length<20000"> -->
            <div>
              <div class="noprint">
                <div style="float:right;width:200px;position:relative;top:-.75em;">
                  <sf-input label="" v-model="perPage" type="list" cstyle="width:8em;" :options="[5,10,25,50,100,250,500,9999]" @change="savePerPage"></sf-input>
                </div>
                <p style="float:right;">Current Page: {{ currentPage }}/{{ Math.floor((transactions.length - 1) / perPage) + 1 }}</p>
                <b-pagination align="center"
                              size="sm"
                              class="mt-0 mb-1"
                              first-text="First"
                              prev-text="Prev"
                              next-text="Next"
                              last-text="Last"
                              v-model="currentPage"
                              :total-rows="transactions.length"
                              :per-page="perPage"
                              aria-controls="my-table">
                </b-pagination>
              </div>
              <div style="width:100%;overflow:auto;">
                <b-table outlined striped hover style="font-size: 10pt; line-height: 4px;" head-variant="dark" :per-page="perPage" :current-page="currentPage" :rows="transactions.length" class="show-hand" @row-clicked="rowClicked($event)" sort-by="transactionDate desc" :items="transactions" :fields="transColumns">

                  <template v-slot:head(accountNumber)="data">
                    <span v-if="showDetails">Account/Badge</span>
                    <span v-else>Account</span>
                  </template>

                  <template v-slot:cell(isPayment)="row">
                    <i v-if="row.item.isPayment" class="fa fa-product-hunt" style="color:red;" v-b-popover.hover.top="'Payment'"></i>
                    <i v-else class="fa fa-codiepie" style="color:#1a8d60;" v-b-popover.hover.top="'Charge'"></i>
                  </template>

                  <template v-slot:cell(transactionDate)="row">
                    {{ tzDate(row.item.transactionDate) }}
                  </template>

                  <template v-slot:cell(accountNumber)="row">
                    <div>
                      {{ row.item.accountNumber }}
                      <span v-if="showDetails===true" style="display:flex;line-height:.7em;"><br />{{ row.item.badgeNumber }}</span>
                    </div>
                  </template>

                  <!--              <template v-slot:cell(alias)="row">-->
                  <!--                <span v-b-popover.hover.top="{title:'Badge Information',content: row.item.alias + ' ' +  row.item.badgeNumber, delay:500}">{{ row.item.alias }}</span>-->
                  <!--              </template>-->

                  <template v-slot:cell(totalClassId)="row">
                    {{ total(row.item.totalClassId) }}
                  </template>

                  <template v-slot:cell(revenueCenter)="row">
                    {{ revenueCenter(row.item.revenueCenter) }}
                  </template>

                  <template v-slot:cell(tenderNumber)="row">
                    {{ tender(row.item.tenderNumber) }}
                  </template>

                  <template v-slot:cell(checkNumber)="row">
                    <div class="show-hand" @click="$router.push(`/app/transaction/${row.item._id}`)">{{ row.item.checkNumber || "-na-" }}</div>
                  </template>

                  <template v-slot:cell(tenderAmount)="row">
                    <div class="float-right">
                      <span><small v-b-popover.hover.top="'Split Transaction'">{{ (row.item.tenderAmount !== row.item.transactionTotal) ? "** " : "" }}</small></span>
                      <!-- {{numeral(row.item.tenderAmount * ((row.item.isPayment) ? -1 : 1)).format("0,0.00")}} -->
                      {{  makeValue( row.item ) }}
                    </div>
                  </template>
                </b-table>

              </div>
            </div>
            <!-- <div v-else>
              <table>
                <thead style="background:black;color:#eee;padding-right:1em;">
                  <tr>
                    <th>Date</th>
                    <th>Account</th>
                    <th>Alias</th>
                    <th>Total Class</th>
                    <th>Revenue Center</th>
                    <th>Tender</th>
                    <th>Check #</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody >
                  <tr style="border:1px solid black;" v-for="(item,index) in transactions" :key="index"  @click="$router.push(`/app/transaction/${item._id}`)">
                    <td style="width:12em;">{{tzDate(item.transactionDate)}}</td>
                    <td style="width:10em;">{{item.accountNumber}}</td>
                    <td style="width:10em;">{{item.alias}}</td>
                    <td style="width:13em;">{{total( item.totalClassId )}}</td>
                    <td style="width:13em;">{{revenueCenter(item.revenueCenter)}}</td>
                    <td style="width:13em;">{{tender(item.tenderNumber)}}</td>
                    <td style="width:5em;">{{item.checkNumber || "-na-"}}</td>
                    <td style="width:8em;" class="float-right"><span><small v-b-popover.hover.top="'Split Transaction'">{{(item.tenderAmount!==item.transactionTotal) ? "** " : ""}}</small></span>{{numeral( item.tenderAmount * ((item.isPayment) ? -1 : 1 )).format( "0,0.00")}}</td>
                  </tr>
                </tbody>
              </table>

            </div> -->

          </div>
        </div>
        <div class="table-responsive noprint" v-else>
          <div style="margin-top:2em;margin-left:2em;">No Transactions</div>
        </div>
      </b-tab>
      <b-tab title="Summary" >
        <h3>Summary Totals</h3>
        <b-row>
          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <h4>Revenue Center Totals</h4>
            <table class="table table-sm table-striped table-hover">
              <thead style="background:black;color:#eee;padding-right:1em;">
              <tr>
                <th>Revenue Center</th>
                <th colspan="2">Payments</th>
                <th colspan="2">Charges</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in revenueCenterSummary" :key="index">
                <td style="width:10em;border-right:1px solid gray;" @click="filters.revenueCenter+=','+item.id.toString();applyFilter();">{{ revenueCenter(parseInt(item.id)) }}
                </td>
                <td style="width:10em;text-align:right;">{{ item.pCount }}</td>
                <td style="width:13em;text-align:right;border-right:1px solid gray;">{{ currency(item.pTotal) }}</td>
                <td style="width:10em;text-align:right;">{{ item.tCount }}</td>
                <td style="width:13em;text-align:right;">{{ currency(item.tTotal) }}</td>
              </tr>

              </tbody>
            </table>
          </b-col>

          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <h4>Total Class</h4>
            <table class="table table-sm table-striped table-hover">
              <thead style="background:black;color:#eee;padding-right:1em;">
              <tr>
                <th>Total Class</th>
                <th colspan="2">Payments</th>
                <th colspan="2">Charges</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in totalClassSummary" :key="index">
                <td style="width:10em;border-right:1px solid gray;" @click="filters.totalClass+=','+item.id.toString();applyFilter();">{{ total(parseInt(item.id)) }}</td>
                <td style="width:10em;text-align:right;">{{ item.pCount }}</td>
                <td style="width:13em;text-align:right;border-right:1px solid gray;">{{ currency(item.pTotal) }}</td>
                <td style="width:10em;text-align:right;">{{ item.tCount }}</td>
                <td style="width:13em;text-align:right;">{{ currency(item.tTotal) }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>

          <b-col class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <h4>Tendering Key</h4>
            <table class="table table-sm table-striped table-hover">
              <thead style="background:black;color:#eee;padding-right:1em;">
              <tr>
                <th>Tendering Key</th>
                <th colspan="2">Payments</th>
                <th colspan="2">Charges</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in tenderSummary" :key="index">
                <td style="width:10em;border-right:1px solid gray;" @click="filters.tenderNumber+=','+item.id.toString();applyFilter();">{{ tender(parseInt(item.id)) }}</td>
                <td style="width:10em;text-align:right;">{{ item.pCount }}</td>
                <td style="width:13em;text-align:right;border-right:1px solid gray;">{{ currency(item.pTotal) }}</td>
                <td style="width:10em;text-align:right;">{{ item.tCount }}</td>
                <td style="width:13em;text-align:right;">{{ currency(item.tTotal) }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>


        </b-row>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// import router   from "/src/routes";
import LTable    from "/src/components/UIComponents/Table.vue";
import Moment    from "moment";
import MomentTZ  from "moment-timezone";
import numeral   from "numeral"; // find the docs here: http://numeraljs.com/
import Card      from "/src/components/UIComponents/Cards/Card.vue";
import SfInput   from "/src/components/UIComponents/Inputs/SFormInput.vue";
import Currency  from "currency.js";
import exportLib from "/src/Lib/dataExportLib.js";

import TransactionService   from "/src/Services/TransactionService";
import RevenueCenterService from "/src/Services/RevenueCenterService";
import TenderService        from "/src/Services/TenderService";
import TotalService         from "/src/Services/TotalService";

//  accountNumber: (...)
//alias: (...)
//badgeNumber: (...)
//checkNumber: (...)
//isMealPlan: (...)
//isPayment: (...)
//revenueCenter: (...)
//tenderAmount: (...)
//tenderNumber: (...)
//tenderQty: (...)
//title: (...)
//totalClassId: (...)
//transactionDate: (...)
//transactionNumber: (...)
//transactionTotal: (...)

export default {
  components: {
    LTable,
    Card,
    SfInput
    // Datepicker
  },
  data() {
    return {
      perPage        : 10,
      currentPage    : 1,
      dateFormat     : "mm-dd-yyyy",
      beginDateFormat: "M-D-YYYY 00:00:00 -06:00",
      endDateFormat  : "M-D-YYYY 23:59:59 -06:00",
      // filterField         : "alias",
      isLiveFilter        : false,
      updateFilter        : false,
      activeFilters       : {
        account         : "",
        badge           : "",
        accountClass    : "",
        totalClass      : "",
        revenueCenter   : "",
        tenderNumber    : "",
        alias           : "",
        checkNumber     : "",
        transactionLimit: ""
      },
      filters             : {
        account         : "",
        badge           : "",
        accountClass    : "",
        totalClass      : "",
        revenueCenter   : "",
        tenderNumber    : "",
        alias           : "",
        checkNumber     : "",
        transactionLimit: ""
      },
      transactionListTotal: 0,
      transactionListCount: 0,

      transactionsMessage: "",
      searchString       : "0",
      loading            : false,
      isLoading          : "fa fa-spinner  fa-refresh-animate",
      beginDate          : Date(), // MomentTZ.tz( Date(), this.$root.TZ).format('YYYY-MM-DD'),
      endDate            : Date(), // MomentTZ.tz( Date(), this.$root.TZ).format('YYYY-MM-DD'),
      showDetails        : false,
      autoRefresh        : false,
      listFilter         : "",
      totals             : [],
      revenueCenters     : [],
      tenders            : [],
      transactionList    : [],
      //totalClassId badgeNumber accountNumber tenderNumber tenderAmount tenderQty title alias isPayment isMealPlan revenueCenter transactionEmployee checkEmployee checkNumber transactionNumber transactionTotal transactionDate companyCode payrollId
      transColumns: [
        {key: "transactionDate", label: "Date", sortable: true, sortDirection: "desc", dataType: 'date'},
        {key: "isPayment", label: "Type", sortable: true, dataType: 'boolean'},
        {key: "accountNumber", label: "Account", sortable: true, dataType: 'string'},
        {key: "badgeNumber", label: "Badge", sortable: true, dataType: 'string'},
        {key: "alias", label: "Alias", sortable: true, dataType: 'string'},
        {key: "totalClassId", label: "Total Class", sortable: true, dataType: 'number'},
        {key: "revenueCenter", label: "Revenue Center", sortable: true, dataType: 'number'},
        {key: "tenderNumber", label: "Tender", sortable: true, dataType: 'number'},
        {key: "transactionEmployee", label: "Employee", sortable: true, dataType: 'number'},
        {key: "checkNumber", label: "Check #", sortable: true, dataType: 'string'},
        {key: "tenderAmount", label: "Total", sortable: true, dataType: 'currency'}
      ],
      eventIds    : [ {
        eventId    : 0,
        description: ""
      } ]
    };
  },
  computed: {
    revenueCenterSummary() {
      // console.log( "hit revenueCenterSummary()");

      let totals = [];
      this.transactions.map((trans) => {

        const key   = "T" + trans.revenueCenter.toString();
        const value = trans.tenderAmount;

        if(!totals[ key ]) {
          totals[ key ] = {
            id    : trans.revenueCenter,
            tCount: 0,
            tTotal: 0,
            pCount: 0,
            pTotal: 0
          };
        }
        ;

        if(trans.isPayment) {
          totals[ key ].pTotal += value;
          totals[ key ].pCount++;

        } else {
          totals[ key ].tTotal += value;
          totals[ key ].tCount++;
        }

      }, 0);

      // console.log("revenueCenter Totals" , totals );

      let results = _.sortBy(Object.keys(totals)
                                   .map((k) => { return {id: totals[ k ].id, tCount: totals[ k ].tCount, tTotal: totals[ k ].tTotal, pCount: totals[ k ].pCount, pTotal: totals[ k ].pTotal}}),
                             "id");
      return results;
    },
    tenderSummary() {
      // console.log( "hit tenderSummary()");

      let totals = [];
      this.transactions.map((trans) => {

        const key   = "T" + trans.tenderNumber.toString();
        const value = trans.tenderAmount;

        if(!totals[ key ]) {
          totals[ key ] = {
            id    : trans.tenderNumber,
            tCount: 0,
            tTotal: 0,
            pCount: 0,
            pTotal: 0
          };
        }
        ;

        if(trans.isPayment) {
          totals[ key ].pTotal += value;
          totals[ key ].pCount++;

        } else {
          totals[ key ].tTotal += value;
          totals[ key ].tCount++;
        }

      }, 0);

      let results = _.sortBy(Object.keys(totals)
                                   .map((k) => { return {id: totals[ k ].id, tCount: totals[ k ].tCount, tTotal: totals[ k ].tTotal, pCount: totals[ k ].pCount, pTotal: totals[ k ].pTotal}}),
                             "id");
      return results;
    },
    totalClassSummary() {
      // console.log( "hit totalClassSummary()");

      let totals = [];
      this.transactions.map((trans) => {

        const key   = "T" + trans.totalClassId.toString();
        const value = trans.tenderAmount;

        if(!totals[ key ]) {
          totals[ key ] = {
            id    : trans.totalClassId,
            tCount: 0,
            tTotal: 0,
            pCount: 0,
            pTotal: 0
          };
        }
        ;

        if(trans.isPayment) {
          totals[ key ].pTotal += value;
          totals[ key ].pCount++;

        } else {
          totals[ key ].tTotal += value;
          totals[ key ].tCount++;
        }

      }, 0);

      let results = _.sortBy(Object.keys(totals)
                                   .map((k) => { return {id: totals[ k ].id, tCount: totals[ k ].tCount, tTotal: totals[ k ].tTotal, pCount: totals[ k ].pCount, pTotal: totals[ k ].pTotal}}),
                             "id");
      return results;
    },
    transactions() {

      if(!this.hasFilter()) {
        return this.transactionList;
      }

      // console.log( "get Transactions" );

      this.transactionListTotal = 0;
      this.transactionListCount = 0;

      let revenueCenter;
      if(this.activeFilters.revenueCenter) {
        revenueCenter = this.activeFilters.revenueCenter.split(",");
        revenueCenter = revenueCenter.map((item) => { return parseInt(item); });
      }

      let tenderNumber;
      if(this.activeFilters.tenderNumber) {
        tenderNumber = this.activeFilters.tenderNumber.split(",");
        tenderNumber = tenderNumber.map((item) => { return parseInt(item);});
      }

      let transactionLimit;
      let transactionLimitType;
      if(this.activeFilters.transactionLimit.length > 0) {
        // console.log( "Got transaction limit [%s]" , this.activeFilters.transactionLimit );
        if(this.activeFilters.transactionLimit.substring(0, 1) == "<") {
          transactionLimit     = Currency(parseFloat(this.activeFilters.transactionLimit.substring(1) || 0)).value;
          transactionLimitType = "-1";
        } else if(this.activeFilters.transactionLimit.substring(0, 1) == ">") {
          transactionLimit     = Currency(parseFloat(this.activeFilters.transactionLimit.substring(1) || 0)).value;
          transactionLimitType = "1";
        } else if(this.activeFilters.transactionLimit.substring(0, 1) == "=") {
          transactionLimit     = Currency(parseFloat(this.activeFilters.transactionLimit.substring(1) || 0)).value;
          // console.log( "doing an equals" , transactionLimit );
          transactionLimitType = "0";
        } else {
          transactionLimit     = Currency(parseFloat(this.activeFilters.transactionLimit)).value;
          transactionLimitType = "0";
        }
      }

      return this.transactionList.filter((t) => {
        if(this.activeFilters.account) {
          if(  this.activeFilters.account.split(",").indexOf(t.accountNumber) <0  ) {
            return false;
          }
        }
        if(this.activeFilters.badge) {
          if( this.activeFilters.badge.split(",").indexOf(t.badgeNumber ) < 0) {
            return false;
          }
        }
        if(this.activeFilters.alias) {
          if(this.activeFilters.alias.split(",").indexOf(t.alias ) < 0) {
            return false;
          }
        }
        if(this.activeFilters.checkNumber) {
          if(t.checkNumber.trim().toLowerCase().indexOf(this.activeFilters.checkNumber.toLowerCase()) === -1) {
            return false;
          }
        }
        if(this.activeFilters.totalClass) {
          if(this.activeFilters.totalClass.toString().indexOf(t.totalClassId.toString()) === -1) {
            return false;
          }
        }
        if(transactionLimitType) {
          if(transactionLimitType == 0) {
            if(Currency(t.tenderAmount).value != transactionLimit) {
              return false;
            }
          } else if(transactionLimitType == -1) {
            if(t.tenderAmount >= transactionLimit) {
              return false;
            }
          } else if(transactionLimitType == 1) {
            if(t.tenderAmount <= transactionLimit) {
              return false;
            }
          }
        }
        if(revenueCenter) {
          if(revenueCenter.indexOf(t.revenueCenter) < 0) {
            return false;
          }
        }
        if(tenderNumber) {
          if(tenderNumber.indexOf(t.tenderNumber) === -1) {
            return false;
          }
        }
        this.transactionListTotal += t.tenderAmount;
        this.transactionListCount++;
        return true;
      });

      // console.log( "************************************ this never gets hit" );
      //
      // if(this.filterField === 'account') {
      //   return this.transactionList.filter((t) => {
      //     if(t.accountNumber.toLowerCase().indexOf(this.listFilter.toLowerCase()) >= 0) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'badge') {
      //   return this.transactionList.filter((t) => {
      //     if(t.badgeNumber.toLowerCase().indexOf(this.listFilter.toLowerCase()) >= 0) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'alias') {
      //   return this.transactionList.filter((t) => {
      //     if(t.alias.toLowerCase().indexOf(this.listFilter.toLowerCase()) >= 0) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'rc') {
      //   return this.transactionList.filter((t) => {
      //     if(t.revenueCenter == this.listFilter) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'tc') {
      //   return this.transactionList.filter((t) => {
      //     if(t.totalClassId == this.listFilter) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'tender') {
      //   return this.transactionList.filter((t) => {
      //     if(t.tenderNumber == this.listFilter) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'check') {
      //   return this.transactionList.filter((t) => {
      //     if(t.checkNumber.indexOf(this.listFilter) >= 0) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }
      //
      // if(this.filterField === 'transLimit') {
      //   let min = 0,
      //       max = 0;
      //   if(this.listFilter == '-') {
      //     min = -99999999;
      //     max = 0.01;
      //   } else {
      //     min = parseFloat(this.listFilter.split(' ')[ 0 ]);
      //     max = parseFloat(this.listFilter.split(' ')[ 1 ]);
      //     if(!max) {
      //       if(min <= 0) {
      //         max = 0
      //       } else {
      //         max = min;
      //         min = 0
      //       }
      //     }
      //     if(min > max) {
      //       let x = max;
      //       max   = min;
      //       min   = x;
      //     }
      //   }
      //
      //   return this.transactionList.filter((t) => {
      //     if(t.tenderAmount >= min && t.tenderAmount <= max) {
      //       this.transactionListTotal += t.tenderAmount;
      //       this.transactionListCount++;
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      // }

    }
  },
  mounted() {
    var t = this;
    try {
      //var beginDate = this.tzDateOnly(new Date()) + " 00:00";
      //var endDate = this.tzDateOnly(new Date()) + " 23:59:59";

      if(localStorage.getItem("perPage")) {
        this.perPage = localStorage.getItem('perPage');
      }

      let f = localStorage.getItem('transactionFilters');

      if(f) {
        this.activeFilters = JSON.parse(f);
        this.filters       = Object.assign({}, this.activeFilters);
      }

      t.today();

      Promise.all([
                    t.getRevenueCenters(),
                    t.getTenders(),
                    t.getTotals(),
                    t.loadTransactions()
                  ]);
    } catch(err) {
      console.log("Transactions Mounted error: ", err);
      if(err.response) {
        if(err.response.data) {
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      } else {
        t.$root.errorMessage("Oops", "Had an error getting initial settings");
      }
      return;
    }

  },
  created() {
    this.$options.interval = setInterval(this.refreshTransactionList, 10000);

    // if (this.$root.filterField) {
    //   this.listFilter = this.$root.listFilter;
    //   this.filterField = this.$root.filterField;
    // }
  },
  beforeDestroy() {
    clearInterval(this.$options.interval);
  },
  methods: {
    savePerPage() {
      localStorage.setItem("perPage", this.perPage);
    },
    currency(c) {
      return Currency(c);
    },
    momentTZ(d) {
      return MomentTZ(d);
    },
    print() {
      window.print();
    },
    hasFilter(activeFilter = true) {

      let t;

      if(activeFilter) {
        t = this.activeFilters.account +
            this.activeFilters.badge +
            this.activeFilters.totalClass +
            this.activeFilters.revenueCenter +
            this.activeFilters.tenderNumber +
            this.activeFilters.alias +
            this.activeFilters.checkNumber +
            this.activeFilters.transactionLimit;
      } else {
        t = this.filters.account +
            this.filters.badge +
            this.filters.totalClass +
            this.filters.revenueCenter +
            this.filters.tenderNumber +
            this.filters.alias +
            this.filters.checkNumber +
            this.filters.transactionLimit;
      }
      if(t.trim() > "") {
        return true;
      }
      return false;
    },
    clearFilter() {
      this.filters.account          = "";
      this.filters.badge            = "";
      this.filters.totalClass       = "";
      this.filters.revenueCenter    = "";
      this.filters.tenderNumber     = "";
      this.filters.alias            = "";
      this.filters.checkNumber      = "";
      this.filters.transactionLimit = "";
      this.applyFilter();
    },
    applyFilter() {

      this.updateFilter                   = false;
      this.activeFilters.account          = this.filters.account;
      this.activeFilters.badge            = this.filters.badge;
      this.activeFilters.totalClass       = this.filters.totalClass;
      this.activeFilters.revenueCenter    = this.filters.revenueCenter;
      this.activeFilters.tenderNumber     = this.filters.tenderNumber;
      this.activeFilters.alias            = this.filters.alias;
      this.activeFilters.checkNumber      = this.filters.checkNumber;
      this.activeFilters.transactionLimit = this.filters.transactionLimit;

      localStorage.setItem("transactionFilters", JSON.stringify(this.activeFilters));

    },
    changeListFilter() {
      // this.$root.listFilter = this.listFilter;
      // this.$root.filterField = this.filterField;
    },
    // testDate() {
    // },
    // ezShow( msg ) {
    //   alert(format( new Date(msg) , "M-D-YYYY"));
    // },
    showToday() {
      return Moment.tz(new Date(), this.$root.TZ).format('dddd, MMM DD ');
    },
    emoment(d) {
      return Moment(d);
    },
    listBalance() {
    },
    csvExport() {
      exportLib.csvExportAskFilename(this.transactions, this.transColumns);
    },
    // formatDates(dateOne, dateTwo) {
    //   let formattedDates = ''
    //   if (dateOne) {
    //     formattedDates = format(dateOne, this.dateFormat)
    //   }
    //   if (dateTwo) {
    //     formattedDates += ' - ' + format(dateTwo, this.dateFormat)
    //   }
    //   return formattedDates
    // },
    // dtFormat( v1, v2) {
    //   return format( v1 , v2 );
    // },
    numeral(x) {
      return numeral(x);
    },
    tzDate(d) {
      if(!this.showDetails) {
        return Moment.tz(d, this.$root.TZ).format('ddd DD-MMM hh:mm a');
      } else {
        return Moment.tz(d, this.$root.TZ).format('ddd, DD-MMM-YY hh:mm a');
      }
    },
    tzDateOnly(d) {
      return Moment.tz(d, this.$root.TZ).format('MM-DD-YYYY');
    },
    doFilter(item) {
      return true;
    },
    refreshTransactionList() {
      let t = this;

      if(t.autoRefresh) {
        //const beginDate = this.tzDateOnly(new Date()) + " 00:00";
        //const endDate = this.tzDateOnly(new Date()) + " 23:59:59";
        t.today();
        this.getTransactions(t.beginDate, t.endDate, 10);
      }
    },
    today() {
      let t       = this;
      t.beginDate = Moment().startOf('day').format('YYYY-MM-DD');
      t.endDate   = Moment().endOf('day').format('YYYY-MM-DD');
      // console.log( "begin/end " , t.beginDate , t.endDate );
    },
    doNothing() {},
    revenueCenter(id) {
      var t = this;
      var i;

      if(t.revenueCenters.length > 0) {
        for(i = 0; i < t.revenueCenters.length; i++){
          if(t.revenueCenters[ i ].revenueCenterId == id) {
            return "" + id + " : " + t.revenueCenters[ i ].title;
          }
        }
      }

      return "" + id
    },
    tender(id) {
      var t = this;
      var i;

      if(t.tenders.length > 0) {
        for(i = 0; i < t.tenders.length; i++){
          if(t.tenders[ i ].tenderNumber == id) {
            return "" + id + " : " + t.tenders[ i ].title;
          }
        }
      }

      return "" + id
    },
    total(id) {
      var t = this;
      var i;

      if(t.totals.length > 0) {
        for(i = 0; i < t.totals.length; i++){
          if(t.totals[ i ].totalClassId == id) {
            return "" + id + " : " + t.totals[ i ].title;
          }
        }
      }

      return "" + id
    },
    totalType( classId ) {
      let x = _.find( this.totals, { totalClassId: classId } );
      // console.log( "searching for", classId ,x  );
      if( x ) {
        return x.totalType;
      }
      return "credit";
    },
    makeValue( item ) {
      let x = (item.tenderAmount!==item.transactionTotal) ? "** " : "";
      let tt = this.totalType( item.totalClassId );
      if( tt == "debit" || tt == "fund" || tt == "giftcard" || tt=="points") {
        if( item.isPayment ) {
          return x + numeral( item.tenderAmount ).format("0,0.00")
        }
        return x + numeral( -item.tenderAmount ).format("0,0.00")
      }
      if( item.isPayment ) {
        return x + numeral( -item.tenderAmount ).format("0,0.00")
      }
      return x + numeral( item.tenderAmount ).format("0,0.00")
    },
    onReset(e) {
      e.preventDefault();
    },
    rowClicked(item) {
      this.$router.push(`/app/transaction/${item._id}`);
    },
    async getRevenueCenters() {
      var response;
      var t     = this;
      t.loading = true;

      try {
        response                      = await RevenueCenterService.getRevenueCenters();
        t.revenueCenters              = response.data;
        t.$root.tenant.revenueCenters = response.data;

      } catch(err) {
        if(err) {
          // alert("oops hit error in getRevenueCenters",err);
          t.$root.errorMessage("Oops", "Had a problem getting the revenue center listing");
        }
      }
      t.loading = false;
    },
    async getTenders() {
      var response;
      var t = this;
      try {
        response  = await TenderService.getTenders();
        t.tenders = response.data;

      } catch(err) {
        if(err) {
          // alert("oops hit error in getTenders",err);
          t.$root.errorMessage("Oops", "Had a problem getting the tender listing");
        }
        return;
      }
    },
    async loadTransactions() {
      let t = this;
      if(t.$root.transactionList && t.$root.transactionList.length > 0) {
        t.transactionList      = t.$root.transactionList
        t.beginDate            = t.$root.beginDate;
        t.endDate              = t.$root.endDate;
        t.transactionsMessage  = "(cached)"
        t.transactionListTotal = _.sumBy(t.transactionList, (trans) => { return trans.tenderAmount });
        t.transactionListCount = t.transactionList.length;
      } else {
        t.transactionsMessage = "";
        // await this.getTransactions(t.beginDate + " 00:00:00", t.endDate + " 23:59:00", 100000);
        await this.getTransactions(t.beginDate, t.endDate, 100000);
      }
    },
    async getTransactions(beginDate, endDate, maxTrans) {
      var response;
      var t = this;

      if(beginDate > endDate) {   // swap those dates if out of sequence
        let t     = beginDate;
        beginDate = endDate;
        endDate   = t;
      }

      t.loading             = true;
      t.transactionsMessage = "";
      try {
        let filters;

        if( this.hasFilter(false) ) {
console.log( "have filters" );
          filters = this.filters;
          // this.updateFilter = false;
        } else {
          filters = null;
        }

        response                   = await TransactionService.transactionList(beginDate, endDate, maxTrans, filters);
        this.transactionList       = response.data;
        this.$root.transactionList = response.data;
        this.$root.beginDate       = t.beginDate;
        this.$root.endDate         = t.endDate;

        this.transactionListTotal = _.sumBy(this.transactionList, (t) => { return t.tenderAmount });
        this.transactionListCount = this.transactionList.length;

        if( filters ) {
          this.applyFilter();
        }

      } catch(err) {
        if(err) {
          console.log(err);
          //alert("oops hit error in getTransactions", err);
          t.$root.errorMessage("Oops", err.response.data.message);
        }
      }
      t.loading = false;
    },
    async getTotals() {
      var response;
      var t = this;
      try {
        response = await TotalService.getTotals();
        t.totals = response.data;

      } catch(err) {
        if(err) {
          // alert("oops hit error in getTotals",err);
          t.$root.errorMessage("Oops", "Had a problem getting the totals listing");
        }
        return;
      }
    }

  }
};
</script>

<style language="scss" scoped>

.sf-text-control {
  color : red;
}

.filter-field-group {
  margin    : 1em auto auto 1em;
  display   : inline-block;
  font-size : 12px;
}

.filter-field-group .custom-radio {
  width : 9em;
}

.button {
  margin-right : 6px;
}

.float-right {
  float : right;
}

.btn-date-select {
  border-radius : .75em;
  width         : 100%;
  margin        : .2em;
  text-align    : center;
  border        : .5px solid black;
  background    : rgba(69, 194, 168, .4);
  color         : black;
  font-weight   : 600;
}

@media only screen and (max-width : 1310px) {
  table {
    font-size   : 10px !important;
    line-height : 12px !important;
  }
}

@media only screen and (max-width : 999px) {
  table {
    font-size   : 10px !important;
    line-height : 12px !important;
  }
}

@media print {

  @page {
    size   : 8.5in 11in;
    margin : 2cm
  }

  .main-background {
    height : auto;
  }

  nav-bar, notifications, content-footer, mobile-menu, side-bar {
    display : none;
  }

  .body-class {
    /* This is likely NOT the best way to mange this, but it's suitable for the moment*/
    font-size : 8pt;
  }

  body {
    margin           : 0;
    width            : 8.5in;
    color            : #000;
    background-color : #fff;
    height           : auto;
  }

  h1 {
    page-break-before : always;
    break-before      : always;
  }

  .print-container {
    position  : static;
    top       : 0;
    left      : 0;
    display   : block;
    height    : 100%;
    width     : 8.5in;
    max-width : 100%;
  }

  header, footer, aside, nav, form, iframe, .menu, .hero, .adslot {
    display : none !important;
  }

  table {
    font-size   : 10px !important;
    line-height : 0px !important;
  }

  .noprint {
    display : none !important;
  }

}

</style>
