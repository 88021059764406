///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  signup(signupData) {
    const response = Api().post('/signup', signupData);
    return response;
  },

  login(credentials) {
    const response = Api().post('/login', credentials);
    return response;
  },

  logout(credentials) {
    const response = Api().post('/logout', credentials);
    return response;
  },

  // This just takes the current user details (since we have a token) and reauthorize; creating a new
  // token and returning the proper user details.
  authorize() {
    const response = Api().post("/authorize");
    return response;
  },

  forgotPassword(credentials) {
    const response = Api.post("/forgotPassword", credentials);
    return response;
  }

}
