///-------------------------------------------------------------------------------------------------
// summary:	Procedure/Knowledge base service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  getCategories(  category = null) {
    return Api().get("/api/1/procedureCategories" , { params: { filter: category }});
  },
  getDepartments(  department = null) {
    return Api().get("/api/1/procedureDepartments" , { params: { filter: department }});
  },
  getProcedures( department = null , category = null) {
    return Api().get("/api/1/procedures", {params: {category: category, department: department }});
  },
  getProcedure( id ) {
    return Api().get(`/api/1/procedure/${id}`);
  },
  addProcedure(procedure) {
    return Api().post("/api/1/procedure", procedure);
  },
  saveProcedure(procedure) {
    return Api().patch("/api/1/procedure", procedure );
  },

  search( search ) {
    return Api().get(`/api/1/proceduresSearch/${encodeURIComponent(search)}`);
  }

}
