<template>
  <div>
    <b-form-textarea 
      ref="editorWindow" 
      :value="value"
      :readonly="readonly"
      :disabled="disabled"
      v-bind="$attrs"
      @change="onChange"
      @input.native="$emit('input', $event.target.value)"
    />
    
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  props       : {
     "value"   : { type: String, default: null  },
     "disabled": { type: Boolean, default: false  },
     "readonly": { type: Boolean, default: false  }
  },
  components: {
  },
  mounted() {
    console.log( "CodeEditorCard mounted", this.code );
    // this.value = this.code;
  },
  data() {
    return {
      // value: null,

    }
  },
  computed: {
   
  },
  methods: {
    onChange( e ) {
      console.log( "codeEditorCard() onChange", e );
      this.$emit( "change" , this.value );
    }
  }
};
</script>

<style lang="scss" scoped >

</style>
