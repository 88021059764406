<template>
  <table class="table">
    <thead>
      <slot name="columns">
        <th v-for="(header,index) in headers" :key="index" >{{header}}</th>
      </slot>
    </thead>
    <tbody>
    <tr v-for="(item,index) in data" :key="index" v-on:click="selectRow(item)" >
      <slot :row="item">
        <td v-for="(column,index) in columns" :key="index" v-if="hasValue(item, column)" >
          <!-- {{itemValue(item, column)}} -->
          <span v-html="itemValue(item,column)"></span>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>

import moment from "moment-timezone";   // find the docs here: https://momentjs.com/timezone/
import numeral from "numeral";          // find the docs here: http://numeraljs.com/
//import bImg from 'bootstrap-vue/es/components/image/img';
// Vue.component('b-img', bImg);

// fixme: The above v-html tag was a work-around to get the image tag to come in as HTML and not text; this is currently
//        only an issue in the Badge listing so perhaps this component should go back to being "generic" and move the
//        code into the badges.vue template.  I need to noodle this a little to see the best option.

export default {
  name: "l-table",
  components: {
    //bImg
  },
  props: {
    headers: Array,
    columns: Array,
    data: Array
  },
  methods: {
    hasValue(item, column) {
      let c = column.split(":");
      return item[c[0]] !== "undefined";
    },
    itemValue(item, column) {
      let c = column.split(":");
      let directive = c[1];

      if( c.length > 1 ) {
        if( directive == "isactive") {
          return item[c[0]] ? "<i class='fa fa-user is-active'></i>" : "<i class='fa fa-user-o is-inactive'></i>";
        }
        if( directive == "date"){
          return moment(item[c[0]]).tz( "America/Chicago").format('DD-MMM-YY hh:mm a');
        }
        if( directive == "money") {
          return numeral( item[c[0]] ).format( "0,0.00");
        }
        if( directive == "image") {
          if( item[c[0]]) {
            return `<img style='height:48px;border-radius:40%;' src='${item[c[0]]}'/>`;
          } else {
            return "";
          }
        }
      }
      return item[c[0]];
    },
    selectRow( item ) {
      this.$emit( "rowClicked" , item );
    }
  }
};
</script>
<style  scoped >

.is-inactive {
  color: indianred;;
}

.is-active {
  color: green;
}
</style>
