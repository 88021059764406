///-------------------------------------------------------------------------------------------------
// summary:	Kiosk service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';


export default {

  transactionPost(transaction) {
    const response = Api().post('/api/1/pos/postTransaction', transaction);
    return response;
  },
  postReceipt(receiptRecord) {
    const response = Api().post("/api/1/pos/postReceipt", receiptRecord);
    return response;
  }

}

