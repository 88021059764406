<template>
    <card>
<!--      <div>-->
<!--        <b-button v-if="!isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="done()">Done</b-button>-->
<!--        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-success" style="float:left;margin-right:1em;" @click="save()">Save</b-button>-->
<!--        <b-button v-if="isChanged" class="btn btn-sm btn-fill btn-warning" style="float:left;margin-right:1em;" @click="cancel()">Cancel</b-button>-->
<!--        <i class="fa fa-plus-square show-hand float-right" @click="makeNewRevenueCenter()"></i>-->
<!--        <h4 slot="header" class="card-title">-->
<!--          Revenue Centers-->
<!--        </h4>-->
<!--      </div>-->
<!--      <hr>-->

      <h4 slot="header" class="card-title">Revenue Centers<i class="fa fa-plus-square show-hand float-right" @click="makeNewRevenueCenter()"></i></h4>
      <div>
        <hr>

        <b-table striped hover head-variant="dark" responsive="sm" sort-by="revenueCenterId" class="show-hand" :items="revenueCenters" :fields="columns"  @row-clicked="editRevenueCenter($event)">

          <template v-slot:cell(active)="row">
            <i v-if="row.item.active===true" class="fa fa-check" style="color:green;"></i>
            <i v-else class="fa fa-times" style="color:red;"></i>
          </template>

          <template v-slot:cell(actions)="row">
            <i class="fa fa-trash float-left" v-b-popover.hover.top="'Remove This Item'" @click.stop="removeRevenueCenter(row.item._id)"></i>
          </template>
<!--          <template v-slot:cell(revenueCenterId)="row">-->
<!--            <div class="show-hand" @click="editRevenueCenter(row.item.revenueCenterId)">{{row.item.revenueCenterId}}</div>-->
<!--          </template>-->
<!--          <template v-slot:cell(title)="row">-->
<!--            <div class="show-hand" @click="editRevenueCenter(row.item.revenueCenterId)">{{row.item.title}}</div>-->
<!--          </template>-->

        </b-table>
          <b-modal
              v-if="revenueCenter"
              v-model="isAdding"
              :title="modalTitle"
              size="lg"
              header-bg-variant="info"
              header-text-variant="light"
              footer-bg-variant="info"
              footer-text-variant="light">
            <b-container fluid>
              <b-form-group horizontal breakpoint="lg" :label="revenueCenter.revenueCenterId + ' : ' + revenueCenter.title" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">

                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Active" label-for="active">
                  <b-checkbox id="active" type="checkbox" v-model="revenueCenter.active" required></b-checkbox>
                </b-form-group>


                <b-form-group v-if="!isEditing" horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="ID" label-for="revenueCenterId">
                  <b-form-input id="revenueCenterId" v-if="!isEditing" style="width:10em;" type="text" v-model="revenueCenter.revenueCenterId" required placeholder="Revenue Center Id"></b-form-input>
                  <!--                  <b-form-input id="revenueCenterId" v-else readonly type="text" v-model="revenueCenter.revenueCenterId" required placeholder="Revenue Center Id"></b-form-input>-->
                </b-form-group>

                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Title" label-for="title">
                  <b-form-input id="title" type="text" v-model="revenueCenter.title" required placeholder="Title"></b-form-input>
                </b-form-group>


                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Allow Manual Transaction" label-for="allowManual">
                  <b-checkbox id="allowManual" type="checkbox" v-model="revenueCenter.options.isAllowManualTransaction" ></b-checkbox>
                </b-form-group>

                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Allow Meal Calendar" label-for="allowManual">
                  <b-checkbox id="allowMealCalendar" type="checkbox" v-model="revenueCenter.options.isAllowMealCalendar" ></b-checkbox>
                </b-form-group>

                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Allow On Menu Master List Selections" label-for="allowMenuMaster">
                  <b-checkbox id="allowMenuMaster" type="checkbox" v-model="revenueCenter.options.isAllowMenuMasterList" ></b-checkbox>
                </b-form-group>

                <b-form-group horizontal label-cols-sm="4" label-cols-lg="4" label-class="text-sm-right" label="Notes" label-for="rc-notes">
                  <textarea id="rc-notes" style="width:100%;" :rows="4" v-model="revenueCenter.notes" ></textarea>
                </b-form-group>


                <!--<b-form-group horizontal  label-cols-sm="4" label-cols-lg="3" label-class="text-sm-right" label="Chart Slot" label-for="chartSlot">
    <b-form-input id="chartSlot" type="number" :min="0" :max="2" v-model="revenueCenter.chartSlot" required ></b-form-input>
  </b-form-group>-->

              </b-form-group>
            </b-container>
            <div slot="modal-footer" class="w-100 mt-3">
              <b-btn v-if="!isEditing" size="sm" class="float-right btn-fill" variant="success" @click="addRevenueCenter( revenueCenter );">
                Add Revenue Center
              </b-btn>
              <b-btn v-if="isEditing" size="sm" class="float-right btn-fill" variant="success" @click="updateRevenueCenter( revenueCenter );">
                Update Revenue Center
              </b-btn>
              <b-btn size="sm" class="float-right btn-fill mr-1" variant="danger" @click="isAdding=false">
                Cancel
              </b-btn>
          </div>
        </b-modal>

        <hr>
        <b-button class="btn btn-success btn-fill float-right" @click="$router.go(-1)">
          done
        </b-button>
        <!-- <b-button class="btn btn-danger btn-fill float-right" @click="$router.go(-1)">
          Cancel
        </b-button> -->
      </div>
      <div class="clearfix"></div>
      <br/>
    </card>
</template>

<script>
import SfInput        from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LTable from "/src/components/UIComponents/Table.vue";
import RevenueCenterService from "/src/Services/RevenueCenterService";
// import moment from "moment-timezone";
import Card from "/src/components/UIComponents/Cards/Card.vue";

export default {
  components: {
    LTable,
    Card,
    SfInput
  },
  data() {
    return {
      isChanged: false,
      saveDisabled  : true,
      changeCount   : 0,
      modalTitle    : "Adding New Interface",
      isAdding      : false,
      isEditing     : false,
      revenueCenter : null, // {revenueCenterId: 0 ,title: "", active:true},
      revenueCenters: [],
      columns       : [
        "actions",
        { key: "active" , sortable: true},
        { key: "revenueCenterId" , sortable: true},
        { key: "title", sortable: true }
        // { key: "chartSlot" , sortable: true }
      ]
    };
  },
  watch: {
    revenueCenters: {
      handler(old) {
        this.changeCount++; // fixme: there are some internal things going on in the watcher that I prob need to fix.
        if (this.changeCount < 2) {
          return;
        }
        this.saveDisabled = false;
      }
    }
  },
  methods: {
    makeNewRevenueCenter() {

      this.revenueCenter = Object.assign({} , {
        active: true,
        revenueCenterId: "",
        revenueCenterTitle: "",
        options: {
          isAllowManualTransaction: true,
          isAllowMealCalendar: false
        }
      })

      // this.revenueCenter = revenueCenter;

      this.isAdding   = true;
      this.isEditing  = false;
      this.modalTitle = "Adding Revenue Center";

    },
    async addRevenueCenter() {
      let t = this;
      let response;

      this.isEditing = false;
      this.isAdding  = false;

      try {
        response = await RevenueCenterService.addRevenueCenter( this.revenueCenter );
        this.getRevenueCenters();
      }catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Revenue Center");
      }
    },
    async editRevenueCenter( item ) {
      // console.log( "erc()" , item );
      let id = item.revenueCenterId;
      let t = this;
      await t.getRevenueCenter(id);
      t.modalTitle = "Editing Revenue Center";
      t.isAdding   = true;
      t.isEditing  = true;
    },
    async getRevenueCenter( id ) {
      let t = this;
      let response;

      try {
        response            = await RevenueCenterService.getRevenueCenter( id );  // NOTE: gets by the Revenue Center ID and NOT the _id
        t.revenueCenter = response.data;
      } catch (err) {
        console.log("getRevenueCenter() error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the revenue center");
        }
      }
    },
    async getRevenueCenters() {
      let t = this;
      let response;

      try {
        response            = await RevenueCenterService.getRevenueCenters( true );
        this.revenueCenters = response.data;
      } catch (err) {
        console.log("getRevenueCenters error: ", err);
        if (err.response) {
          if (err.response.data) {
            t.$root.errorMessage("Oops", err.response.data.message);
          }
        } else {
          t.$root.errorMessage("Oops", "Had an error getting the revenue centers");
        }
        return;
      }
    },
    async updateRevenueCenter( revenueCenter ) {
      let t = this;
      let response;

      try {
        response = await RevenueCenterService.updateRevenueCenter( revenueCenter );
        this.getRevenueCenters();
        this.isEditing = false;
        this.isAdding  = false;
      }catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Save Revenue Center");
      }
    },
    async removeRevenueCenter( id ) {
      let t      = this;
      let rcName = "";
      let rcNumber = 0;
      let response;

      this.revenueCenters.forEach(function(item, index) {
        if (item._id.toString() === id.toString()) {
            rcName   = item.title;
            rcNumber = item.revenueCenterId;
        }
      });

      if( ! await this.$root.confirm( "Removing " + rcNumber.toString() + " :: " + rcName +" ?")){
        return;
      };

      try {
        response = await RevenueCenterService.removeRevenueCenter( id );
      } catch( err ) {
        if (err.response) {
          console.log("error response ", err.response);
          t.$root.errorMessage("Oops", err.response.data.message);
        } else if (err.request) {
          console.log("Error Request ", err.request);
        } else {
          console.log("message only ", err.message);
        }
        t.$root.errorMessage("Oops", "Unable to Remove Revenue Center");
      }
      t.getRevenueCenters();
    }
  },
  mounted: function() {
    this.getRevenueCenters();
  }
};
</script>

<style lang="scss">
</style>
