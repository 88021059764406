///-------------------------------------------------------------------------------------------------
// summary:	stats service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getList() {
    const response = Api().get(`/api/1/templates`);
    return response;
  },
  get(id) {
    const response = Api().get(`/api/1/template/${encodeURI(id)}`);
    return response;
  },
  add(template) {
    const response = Api().post(`/api/1/template`, template);
    return response;
  },
  update(template) {
    const response = Api().patch(`/api/1/template`, template);
    return response;
  },
  delete(id) {
    const response = Api().delete(`/api/1/template/${encodeURI(id)}`);
    return response;
  }
  
}
