///-------------------------------------------------------------------------------------------------
// summary:	Ticket service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {
  getTicketStatuses() {
    const response = Api().get(`/api/1/ticketStatuses`);
    return response;
  },
  getTicketTypes() {
    const response = Api().get(`/api/1/ticketTypes`);
    return response;
  },
  getTicketPriorities() {
    const response = Api().get(`/api/1/ticketPriorities`);
    return response;
  },
  getTicketTypeSummary() {
    const response = Api().get(`/api/1/ticketTypeSummary`);
    return response;
  },
  getTicketSummaryList(ticketTypes) {
    const response = Api().get(`/api/1/ticketSummaryList` , {params: { ticketTypes: ticketTypes }});
    return response;
  },
  getTicketStatusList(ticketTypes,ticketNumber = null) {
    let tn = null;
    if( ticketNumber ) {
      tn = ticketNumber.join(",");
    }
    const response = Api().get(`/api/1/ticketStatusList` , {params: { ticketTypes: ticketTypes , ticketNumber: tn }});
    return response;
  },

  getTicketList( ids ) {
    const response = Api().get(`/api/1/tickets/all`,  {params: { ids: ids.join(","), closed: 'both' }});
    return response;
  },
  getTickets( ticketType , assignedToId = null, status = null) {
    const response = Api().get(`/api/1/tickets/${ticketType}`,  {params: { assignedToId: assignedToId, status: status }});
    return response;
  },
  getTicketTags(ticketTypes) {
    const response = Api().get(`/api/1/ticketTags/${ticketTypes}` );
    return response;
  },
  getTicketsByProject( projectId ) {
    const response = Api().get(`/api/1/ticketsByProject/${projectId}`);
    return response;
  },
  /*

   let assignedIds = req.query.assignedIds || "";
   let tag         = req.query.tag || "";
   let closed      = req.query.closed || "false";
   let ticketType  = req.query.ticketType;
   let beginDate   = Moment( req.query.beginDate || "1980-01-01").tz(req.tenantUser.tenant.TZ).startOf('day').toDate();
   let endDate     = Moment( req.query.endDate ).tz(req.tenantUser.tenant.TZ).endOf('day').toDate();

   */
  getTicketsByAssigned( id, ticketType , beginDate = null , endDate = null, closed ) {
    const response = Api().get(`/api/1/ticketsByAssigned`,  {params: { assignedIds: id, ticketType: ticketType, closed: closed, beginDate: beginDate, endDate: endDate  }});
    return response;
  },
  getTicketsByStatus( ticketType , status = null, beginDate = new Date()-7, endDate = new Date(), openClose = 'open') {
    const response = Api().get(`/api/1/tickets/${ticketType}`,  {params: { closed: 'both', status: status, beginDate: beginDate, endDate: endDate , openClose:openClose}});
    return response;
  },
  getTicketsPastDue( ticketType ) {
    const response = Api().get(`/api/1/tickets/${ticketType}`,  {params: { pastDue: true }});
    return response;
  },
  getTicketsByDate( ticketType  = "" , closed = 'both', beginDate = new Date()-7, endDate = new Date() , tags = []) {
    if( ticketType === "" ) {
      ticketType = "all";
    }
    const response = Api().get(`/api/1/tickets/${ticketType}`, {params: { beginDate: beginDate, endDate: endDate, closed:closed,tags: tags.join(",") }});
    return response;
  },
  getTicketsByClient(clientId) {
    const response = Api().get(`/api/1/ticketsByClient/${clientId}`);
    return response;
  },
  getTicketsByUnit(unitId) {
    const response = Api().get(`/api/1/ticketsByUnit/${unitId}`);
    return response;
  },
  getTicketsByBuilding(buildingId) {
    const response = Api().get(`/api/1/ticketsByBuilding/${buildingId}`);
    return response;
  },
  getTicketByNumber( ticketNumber ) {
    const response = Api().get(`/api/1/ticketByNumber/${ticketNumber}`);
    return response;
  },
  getTicketIdByNumber( ticketNumber ) {
    const response = Api().get(`/api/1/ticketIdByNumber/${ticketNumber}`);
    return response;
  },
  getMyTickets( ticketType ) {
    const response = Api().get(`/api/1/myTickets/${ticketType}`);
    return response;
  },
  getTicket(id) {
    const response = Api().get(`/api/1/ticket/${id}`);
    return response;
  },
  saveTicket(ticket) {
    const response = Api().patch(`/api/1/ticket` , ticket );
    return response;
  },
  addTicket(ticket , autoAssign = true) {
    const response = Api().post(`/api/1/ticket` , ticket ,  {params: { autoAssign: autoAssign }});
    return response;
  },
  deleteTicket(id) {
    const response = Api().delete(`/api/1/ticket/${id}`);
    return response;
  },
  addTicketAction(id , action) {
    const response = Api().post(`/api/1/ticketAction/${id}` , action );
    return response;
  },
  addTicketComment(id , comment) {
    const response = Api().post(`/api/1/ticketComment/${id}` , comment );
    return response;
  },
  assignTicket(ticketId , userId ) {
    const response = Api().post(`/api/1/ticketAssign/${ticketId}/${userId}` , null );
    return response;
  },
  // -----------------------------[ Step Methods ]----------------------------------

  addStep(ticketId, step) {
    const response = Api().post(`/api/1/ticketStep/${ticketId}` , step );
    return response;
  },

  // --------------------------------------------------------------------------------------[ Project Methods ]------------------------------------

  addProjectComment(id , comment) {
    const response = Api().post(`/api/1/projectComment/${id}` , comment );
    return response;
  },
  getProjects( projectType = "" , options = {} ) {
    const response = Api().get(`/api/1/projects/list/${projectType}`, {params:{ all: options.all, isArchived: options.isArchived }});
    return response;
  },
  getProjectsTextValue( budgets = null, projectId = "") {
    const response = Api().get(`/api/1/projects/textValue/${projectId}` , {params: { budgets: budgets }});
    return response;
  },
  getProject(id) {
    const response = Api().get(`/api/1/project/${id}`);
    return response;
  },
  saveProject(project) {
    const response = Api().patch(`/api/1/project` , project );
    return response;
  },
  addProject(project) {
    const response = Api().post(`/api/1/project` , project);
    return response;
  },
  deleteProject(id) {
    const response = Api().delete(`/api/1/project/${id}`);
    return response;
  },

  //-----------------------------------------------------------------------------------------[ Budget Related ]--

  getBudgets( id = 'all' ) {
    const response = Api().get(`/api/1/project/budgets/${id}`);
    return response;
  },

  getBudgetDetails(  projectType = "" , options = {} ) {
    const response = Api().get(`/api/1/projects/budgetSteps/${projectType}`, {params:{ all: options.all, isArchived: options.isArchived }});
    return response;
  },

  //-----------------------------[ Vendor Related Methods ]------------------------------------

  getVendorTasks(vendorId, ticketType = 'all' , closed=null ) {
    const response = Api().get(`/api/1/ticketTasksForVendor/${ticketType}` , {params: { vendorId: vendorId, closed: closed }});
    return response;
  },

}
