<template>
  <div class="card shadow" v-on:click="select">
    <img  :src="domain.logo" class="images">
    <div class="card-text">
      <span>{{ domain.tenantName }}</span>
      <span>{{ domain.domain }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    "domain" // domain OBJECT
  ],
  data() {
    return {
      nothing: ""
    };
  },
  computed: {
  },
  methods: {
    select() {
      this.$emit("selectDomain", this.domain.domain);
    }
  }
};
</script>

<style lang="scss" scoped>

/*******
  #TENANT-CARD
 */

  .card {
    display:inline-block;
    float:left;
    width:280px;
    height: 180px;
    padding: .5em;
    border: 2px white solid;
    border-radius:1em;
    text-align:center;
    margin:.5em;
    transition:all 0.3s ease;

    img {
     padding:.25em;
     max-width: 90%;
     max-height:70%;
     object-fit:scale-down;
    }

    .card-text {
      span:first-child {
        font-size: 1.25em;
        overflow:hidden;
        text-shadow: -6px 8px 12px rgba(163,163,163,0.7);
      }
      span:nth-child(2)::before {
        white-space:pre;
        content: "\A";
      }
      span:nth-child(2) {
        border-top:1px solid lightgray;
        font-style:italic;
        color:lightgray;
      }
    }
  }

  .card:hover {
    border: 2px gray solid;
    /*border-radius:1em;*/
    /*margin:0;*/
    background:#f0f0f0;
    opacity:1;
    box-shadow: inset 0 0 0 5px gray;
    -webkit-transform: rotateZ(-3deg);
    -ms-transform: rotateZ(-3deg);
    transform: rotateZ(-3deg);
  }


</style>
