<template>
  <div class="content">
    <div class="container-fluid" style="">
      <card class="card shadow" style="">
        <template slot="header">
          <div>
            <div v-if="$root.isAdmin()" style="float:right;" class="show-hand">
            <b-dropdown style="margin-right:1em;" no-caret class="gear-button" >
              <template slot="button-content">
                <i class="fa fa-gear"></i>
              </template>
              <b-dropdown-item><router-link class="dropdown-item"  to="/portal/portalUploadStudentFile">Upload Student File</router-link></b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item><router-link class="dropdown-item"  to="/admin/rotationConfig">Setup Rotations</router-link></b-dropdown-item>
              <b-dropdown-item><router-link class="dropdown-item"  to="/admin/sites">Setup Sites & Classrooms</router-link></b-dropdown-item>
            </b-dropdown>
            </div>
            <h3 class="">Portal Management</h3>
          </div>
          <hr>
        </template>
        <div class="row">
          <div v-if="$root.isFeature('portal')" class="col-md-5 col-sm-6">
            <h4>Accounts & Lists</h4>
            <ul class="no-bullets">
            <!-- :to="{name: 'pendingAccounts', icon: 'fa fa-money', path: '/portal/pendingAccounts'}" -->
              <li><b-link  :to="{name: 'portalAuthorizationsTeachers', path: '/portal/portalAuthorizationsTeachers'}"><i class="fa fa-graduation-cap  mr-2"></i>Teachers</b-link></li>
              <li><b-link  :to="{name: 'portalAuthorizations', path: '/portal/portalAuthorizations'}"><i class="fa fa-hourglass-1 mr-2"></i>Master Registration Lists</b-link></li>
              <li><b-link :to="{name: 'pendingAccounts', path: '/portal/pendingAccounts'}"><i class="fa fa-hourglass-3 mr-2"></i>In-Process/Incomplete Registrations</b-link></li>
            </ul>
          </div>
          <div class="col-md-5 col-sm-6">
            <h4>General Functions</h4>
            <ul class="no-bullets">
              <li><b-link :to="{name: 'newsManagement', path: '/portal/newsManagement'}"><i class="fa fa-newspaper-o mr-2"></i>NEWS Management</b-link></li>
              <li><b-link :to="{name: 'faqManagement', path: '/portal/faqManagement'}"><i class="fa fa-question mr-2"></i>FAQ Management</b-link></li>
            </ul>
          </div>
          <div class="col-md-5">
            <h4>Menu Management</h4>
            <ul class="no-bullets" >
              <li><b-link :to="{name: 'orderCalendar', path: '/portal/orderCalendar'}"><i class="fa fa-calendar mr-2"></i>Order-Calendar Management</b-link></li>
              <li><b-link :to="{name: 'menuItemMaster', path: '/portal/menuItemMaster'}"><i class="fa fa-list-alt mr-2"></i>Menu Item Master List</b-link></li>
            </ul>
          </div>
          <div v-if="$root.isFeature('portal')" class="col-md-5 col-sm-6">
            <h4>Menu Lists & Reports</h4>
            <ul class="no-bullets" >
              <li><b-link :to="{name: 'portalOrdersSummary', path: '/portal/ordersSummary'}"><i class="fa fa-first-order mr-2"></i>Production Summary</b-link></li>
              <li><b-link :to="{name: 'portalOrders', path: '/portal/orders'}"><i class="fa fa-first-order mr-2"></i>Orders Listing</b-link></li>
            </ul>
          </div>

        </div>
      </card>

    </div>
  </div>
</template>
<script>
  import Card from '/src/components/UIComponents/Cards/Card.vue';
  // import Tasks from "/src/Services/TaskService.js";

  export default {
    components: {
      Card
    },
    data () {
      return {
        tasks: null,
        type: ['', 'info', 'success', 'warning', 'danger'],
        notifications: {
          topCenter: false
        }
      }
    },
    methods: {

      async doSetup() {
        await this.$root.alert( "Not Implemented" , "warning" );
      },

      editSites() {
        this.$router.go("/admin/sites");
      },

      notifyVue (verticalAlign, horizontalAlign) {
        const notification = {
          template: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`
        }
        const color = Math.floor((Math.random() * 4) + 1)
        this.$notifications.notify(
          {
            component: notification,
            icon: 'nc-icon nc-app',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: this.type[color]
          })
      }
    }
  }

</script>
<style lang="scss">

  .no-bullets {
    list-style-type: none;
  }

  .gear-button {

    .dropdown-menu {
      .dropdown-item {
      }
    }
    button {
      font-size:1.5em;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;

    }

    .btn-secondary.dropdown-toggle {
      background:white !important;
      color:#545b62 !important;
    }

  }

</style>
