<template>
  <div class="contact-card">

    <div class="left-section" :style="leftTabStyle" @click.stop="leftSelect()">
      <img :src="myGravatar(contact.email)" alt="Profile Picture" @click.stop="enableEdit()">
    </div>
    <div class="right-section" :class="isSelected ? 'selected' : 'unselected'" @click.stop="rightSelect()">

      <div class="tool-section" style="color:lightgray;">
        <i v-if="isLinked" class="fas fa-link"></i>
        <i v-if="isAllowDelete" class="fas fa-trash mr-3 show-hand" style="font-size:.8em;" v-b-popover.hover.top="'Remove'" @click="remove(index)" ></i>
        <i v-if="isAllowEdit" class="fas fa-gear mr-2 show-hand"  v-b-popover.hover.top="'Edit'" @click="enableEdit()" ></i>
        <i v-if="!isMore && isAllowMore && !isInlineNotes" class="fas fa-ellipsis-h show-hand" :style="contact.notes ? 'color:black;' : ''" style="width:1em;" v-b-popover.hover.top="'More'" @click="more()"></i>
        <i v-if="isMore && isAllowMore && !isInlineNotes" class="fas fa-ellipsis-v show-hand" style="width:1em;" v-b-popover.hover.top="'More'" @click="more()"></i>
        <div v-if="isShowTime" class="tool-section-info"> ({{ moment().tz(contact.TZ|| $root.TZ).format( "hh:mm a") }})</div>
      </div>

      <div class="data-title">
        <div>
          <span v-if="contact.name">{{ contact.name }}</span><span v-if="contact.alias" > ({{ contact.alias }})</span>
        </div>
        <div>
          <span>{{ contact.contactType }}</span><span v-if="contact.title"> | {{ contact.title }}</span>
        </div>
      </div>
      <div v-if="!isMore" class="data-section">
        <div v-if="contact.phone" class="info-item">
          <i class="fas fa-phone"></i> <a :href="'tel:'+contact.phone" >{{ contact.phone }}</a>
        </div>
        <div v-if="contact.cell" class="info-item">
          <i class="fas fa-mobile-alt"></i> <a :href="'tel:'+contact.cell" >{{ contact.cell }}</a>
        </div>
        <div v-if="contact.email" class="info-item">
          <i class="fas fa-envelope"></i> <a :href="'mailto:'+contact.email">{{ contact.email }}</a>
        </div>
        <div v-if="contact.website" class="info-item">
          <i class="fas fa-globe"></i>
          <a :href="formatWebLink(contact.website)" target="_blank">{{ formatWebLink(contact.website) }}</a>
        </div>
        <div v-if="hasAddress" class="info-item">
          <i class="fas fa-map-marker-alt"></i>
          <div style="line-height:1rem;font-size:.9rem;">
            <div v-if="contact.address1">{{ contact.address1 }}</div>
            <div v-if="(contact.city+contact.state+contact.zip).length>0"><span>{{ contact.city }}</span><span>, {{ contact.state }}</span><span>  {{ contact.zip }}</span></div>
          </div>
        </div>
        <div v-if="isInlineNotes  || !hasAnyInfo" class="data-notes-inline">
          {{ contact.notes }}
        </div>

      </div>
      <div v-if="isMore" class="data-section">
        <div class="data-notes">
          {{ contact.notes }}
        </div>
      </div>
    </div>

    <contact-edit-card
      v-if="isEdit"
      :contact="contact"
      @change="contactChanged($event)"
      @done="isEdit=false;"
    />

  </div>
</template>
<script>

import Currency from "currency.js";
import Moment   from "moment";
import gravatar from "gravatar";
import Numeral  from "numeral";
import ContactEditCard from "./ContactEditCard.vue";

export default {
  name: "comment-card",
  components: {
    ContactEditCard
  },
  props: {
    contact      : {type: Object, default: () => { }},
    index        : {type: Number, default: 0},
    iconPlaceholder  : {type: String, default: "retro"},
    isSelected   : {type: Boolean, default: false},
    isShowTime   : {type: Boolean, default: true},
    isAllowEdit  : {type: Boolean, default: true},
    isAllowDelete: {type: Boolean, default: true},
    isAllowMore  : {type: Boolean, default: true},
    isInlineNotes: {type: Boolean, default: false },
    noborder     : {type: Boolean, default: false},
    contactTypes : {
      type: Array, default: () => {
        return [
          {value: "primary", text: "Primary"},
          {value: "contact", text: "Contact"},
          {value: "customer", text: "Customer"},
          {value: "attendee", text: "Attendee"},
          {value: "employee", text: "Employee"},
          {value: "sales", text: "Sales"},
          {value: "technical", text: "Technical"},
          {value: "prospect", text: "Prospect"},
          {value: "proposal", text: "Proposal"},
          {value: "vendor", text: "Vendor"},
          {value: "relative", text: "Relative"},
          {value: "billing", text: "Billing"},
          {value: "shipping", text: "Shipping"},
          {value: "mailing", text: "Mailing"},
          {value: "newsletter", text: "Newsletter"},
          {value: "portal", text: "Portal"},
          {value: "other", text: "Other"}
        ]
      }
    },
  },
  data() {
    return {
      isEdit: false,
      isMore: false,
      isDataChanged: false,
    };
  },
  mounted() {

  },
  activated() {
    // if(!this.contact || this.contact.name.toLowerCase() === 'new' || this.contact.name.length === 0) {    // force new contact into edit mode.
    //   this.isDataChanged = true;
    //   // this.isExpand      = true;
    //   this.isEdit        = true;
    // }
  },
  computed: {
    hasAddress() {
      let allAddress = this.contact.address1 + this.contact.city + this.contact.state + this.contact.zip;
      return (allAddress.length > 0);
    },
    hasAnyInfo() {
      if( this.hasAddress ) return true;
      let allInfo = this.contact.phone + this.contact.cell + this.contact.email + this.contact.website;
      return (allInfo.length > 0);
    },
    leftTabStyle() {
      let ct = this.contact.contactType;
      switch( ct ) {
        case 'primary': return 'background:#ff7b00;'; break;
        case 'contact': return 'background:#007bff;'; break;    // the breaks are not required, but if we have other styles happening we may be adding.
        case 'customer': return 'background:#880011;'; break;
        case 'attendee': return 'background:#7b7700;'; break;
        case 'sales': return 'background:#33007b;'; break;
        case 'prospect': return 'background:salmon;'; break;
        case 'proposal': return 'background:firebrick;'; break;
        case 'relative': return 'background:#33cc44;'; break;
        case 'technical': return 'background:black;'; break;
        case 'shipping': return 'background:orange;'; break;
        case 'billing': return 'background:green;'; break;
        case 'mailing': return 'background:blue;'; break;
        case 'newsletter': return 'background:grey;'; break;
        case 'portal': return 'background:teal;'; break;
        case 'other': return 'background:maroon;'; break;
        case 'vendor': return 'background:purple;'; break;
        case 'employee': return 'background:#337733;'; break;
        default: return 'background:#007bff;'; break;
      }
      return 'lime'; // last ditch effort.
    },
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    numeral(x) {
      return Numeral(x);
    },
    currency(c) {
      return Currency(c);
    },
    more() {
      this.isMore = !this.isMore;
    },
    enableEdit() {
      if( this.enableEdit ) {   // if we are allowed to edit, then we can edit.
        this.isEdit = true;
      }
    },
    contactChanged( contact ) {
      this.contact = contact;
      this.isEdit=false;
      this.dataChanged();
      this.save();
    },
    myGravatar( email = this.email ) {
      // d=identicon
      // d=monsterid
      // d=mm
      // d=retro
      // d=robohash
      // d=wavatar
      // d=blank
      // d=mp
      // d=404
      //
      // return gravatar.url( email, {s: '80', r: 'pg', d: 'mm'}, true);
      return gravatar.url( email, {s: '80', r: 'pg', d: this.iconPlaceholder}, true);
    },
    formatWebLink(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      return url;
    },
    leftSelect() {
      console.log("Click left", this.index );
      this.$emit('left-select', this.index);
    },
    rightSelect() {
      this.$emit('right-select', this.index);
    },
    remove(index) {
      this.isEdit = false;        // let's make sure we're not editing.
      if(!this.contact._id) {     // this requires a save first.
        this.$root.warningMessage( 'Remove Contact Warning', `OOPS : No ID found for this record. Please SAVE your changes first and try again. Seems this is a new record.` );
        return;
      }
      this.$emit('remove', this.contact._id);
    },
    save() {
console.log("hit save()" );
      this.isEdit = false;
      if(this.isDataChanged) {
        this.$emit(`change`, {contact: this.contact, index: this.index});
        this.isDataChanged = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>

/* CSS for Contact Card */
.contact-card {
  line-height: .8;
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  background:#f1f1f1;
  overflow: hidden;
  min-width: 500px;     // total size is fixed; look at left and right section size
  width: 500px;
  min-height: 200px;
  margin:.25em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.contact-card .selected {
  background: rgba(0, 128, 0, 0.1);
}

.contact-card .unselected {
  //background: white;
}

.contact-card .left-section {
  flex: 0 0 100px;      // works with card and right section size
  background-color: #007bff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: 10%;
}

.contact-card .left-section img {
  position: relative;
  left: 25%;
  border-radius: 50%;
  //padding: 10px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  //background: white;
  border: 6px solid white; /* Add this line to create a white border */
}

.contact-card .right-section {
  flex: 0 0 400px;      // look at left section size
  padding: 1rem 1rem 1rem 2rem;
}

.contact-card .right-section .tool-section {
  float:right;
  //position:relative;
  //right:10px;
  //top:10px;
}

.tool-section i:hover {
  color:black;
}

.tool-section .tool-section-info {
  color:black;
  margin-top:.75em;
  font-weight:100;
  font-size:.8em;
}

.tool-section .tool-section-info:hover {
  font-weight:900;
  //font-size: 1.5rem;
}

.contact-card .right-section .data-title {
  font-weight: bold;
  //font-size: 1.5rem;
  //margin-bottom: 10px;
  div:first-child {
    //font-weight: bold;
    font-size: 1.1rem;
    line-height:1rem;
  }
  div:nth-child(2) {
    margin-top: .35rem;
    font-weight: 100;
    font-size: .8rem;
    text-transform:capitalize;
  }
}

.contact-card .data-section {
  margin: 1em 0 0 1em;
  font-weight:500;
}

.data-notes {
  margin:0;
  padding:0;
  line-height:1.25rem;
  font-weight:500;
  font-size: .9rem;
  white-space:pre-line;
  //max-height: calc(90vh - 100px);
  //max-height: calc(90vh);
  width:100%;
  max-height:120px;
  overflow:auto;
  //background:lime;
}

.data-notes-inline {
  margin:0;
  padding:0;
  line-height:1.25rem;
  font-weight:500;
  font-size: .9rem;
  white-space:pre-line;
  //max-height: calc(90vh - 100px);
  //max-height: calc(90vh);
  width:100%;
  //background:lime;
}

.contact-card .info-item {
  i {
    font-size: .8rem;
    width: .8rem;
  }
}

.contact-card .right-section .info-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-card .right-section .info-item i {
  margin-right: 10px;
}

.contact-card .right-section .active {
  color: #28a745;
  font-weight: bold;
}

.contact-card .right-section .inactive {
  color: #dc3545;
  font-weight: bold;
}

.input-group-text {
  width: 80px;
}

.contact-input-edit {
  font-size: .8em;
  padding: 0;
  margin: 0;
  height: 1.25em;
  line-height: 1em;
}

.card-table {
  font-size: .8em;
  line-height: 70%;
  margin-top: 5px;
  background: #f0f0f0;
  border-radius: 1em;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  backdrop-filter: blur(3px); /* Adjust the blur effect as needed */
  z-index: 999; /* Ensure it's above other content */
}

/** =====================
 * Responsive
 ========================*/
@media only screen and (max-width: 766px) {

}

</style>

