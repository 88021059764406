///-------------------------------------------------------------------------------------------------
// summary:	stats service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {

  getScheduleCategories( filter ) {
    const response = Api().get(`/api/1/scheduleCategories`, { params: { filter: filter }});
    return response;
  },
  getScheduleItems( category ) {
    const response = Api().get(`/api/1/scheduleItems/${encodeURI(category)}`);
    return response;
  },
  addScheduleItem( scheduleItem ) {
    const response = Api().post(`/api/1/scheduleItem` , scheduleItem );
    return response;
  },
  saveScheduleItem( scheduleItem ) {
    const response = Api().patch(`/api/1/scheduleItem` , scheduleItem );
    return response;
  },
  deleteScheduleItem( id ) {
    const response = Api().delete(`/api/1/scheduleItem/${encodeURI(id)}`);
    return response;
  }

}
