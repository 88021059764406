<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <edit-profile-form>
          </edit-profile-form>
        </div>
        <div class="col-md-4">
          <user-card>
          </user-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditProfileForm from "./EditProfileForm.vue";
import UserCard from "./UserCard.vue";

export default {
  components: {
    EditProfileForm,
    UserCard
  }
};
</script>

<style>
</style>
