<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <tender-form>
          </tender-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TenderForm from "./TendersForm.vue";

export default {
  components: {
    TenderForm
  }
};
</script>

<style>
</style>
