///-------------------------------------------------------------------------------------------------
// summary:	Payment Provider service class
///-------------------------------------------------------------------------------------------------

import Api from '@/Services/Api';

export default {
  getPaymentProviders() {
    const response = Api().get(`/api/1/paymentProviders`);
    return response;
  },
  getPaymentProvider(id) {
    const response = Api().get(`/api/1/paymentProvider/${id}`);
    return response;
  },
  // liveSearch(search = "a" , limit = 5) {
  //   const response = Api().get(`/api/1/vendorSearch/${encodeURIComponent(search)}`, { params: { limit: limit} });
  //   return response;
  // },
  addPaymentProvider( provider) {
    const response = Api().post(`/api/1/paymentProvider`, provider);
    return response;
  },
  updatePaymentProvider( provider) {
    const response = Api().patch(`/api/1/paymentProvider`, provider);
    return response;
  },
  deletePaymentProvider(id) {
    const response = Api().delete(`/api/1/paymentProvider/${id}`);
    return response;
  }
}
