<template>
  <div class="content container-fluid">
    <b-row class="row">
      <b-col class="col-12" >
        <BadgeCard v-if="badge.badgeNumber"
                   :badge="badge"
                   :account="account"
                   :editIcon="true"
                   :showDone = "!enableTransaction"
                   @done="done($event)"
                   @editAccount="editAccount"
          >
        </BadgeCard>
      </b-col>
    </b-row>
    <b-row class="row" >
      <template v-if="!enableTransaction">
        <b-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3" v-for="(total,index) in totals" :key="index">
          <BadgeBalanceCard
            :t=total
            :isShowTransactionButton="$root.isInRole('transactions-manual')"
            @manualTransaction ="manualTransaction($event)"
            :isActive="_.findIndex(account.totalClass, {totalClassId: total.totalClassId})>= 0"
          />
        </b-col>
      </template>
      <template v-if="enableTransaction && account">
        <ManualTransactionForm
          :postingAccount="account"
          :postingTotal="postingTotal"
          @cancel="cancelTransaction"
          @posted="postedTransaction"
        />
      </template>
    </b-row>
    <b-row v-if="!enableTransaction">
      <!-- <b-col class="col" style="width:100%;"> -->
        <TransactionList style="background-color:rgba(10,80,30,.1);margin:1em;border-radius:1em;overflow:auto;" :transactionList="transactionList" @transactionSelect="showTransaction" class="show-hand"></TransactionList>
        <b-button class="btn btn-fill btn-success btn-sm ml-4" @click="done" variant="success">Done</b-button>
      <!-- </b-col> -->
    </b-row>
  </div>
</template>
<script>

  import AccountService     from "/src/Services/AccountService";
  import TransactionService from "/src/Services/TransactionService";

  import Moment           from "moment-timezone"; // find the docs here: https://momentjs.com/timezone/
  import numeral          from "numeral"; // find the docs here: http://numeraljs.com/
  import BadgeBalanceCard from "/src/components/UIComponents/Cards/BadgeBalanceCard.vue";
  import BadgeCard        from "/src/components/UIComponents/Cards/BadgeCard.vue";
  import Card             from "/src/components/UIComponents/Cards/Card.vue";
  import ManualTransactionForm  from "/src/components/UIComponents/Cards/ManualTransactionForm.vue";
  import TransactionList  from "/src/components/UIComponents/Cards/TransactionList.vue";
  import LTable           from "/src/components/UIComponents/Table.vue";

  var t           = this;
var badgeNumber = "";
var accountNumber = "";

export default {
  props: {
    badgeNumber,
    accountNumber
  },
  components: {
    LTable,
    Card,
    BadgeCard,
    BadgeBalanceCard,
    TransactionList,
    ManualTransactionForm
  },
  mounted() {
    let badgeNumber   = decodeURIComponent(this.badgeNumber);
    let accountNumber = decodeURIComponent(this.accountNumber);
    this.getBadgeData( badgeNumber, accountNumber);
  },
  data() {
    return {
      title            : "",
      message          : "Please enter your credentials",
      searchString     : "",
      sdate            : "",
      startDate        : new Date("01-01-2018"),
      endDate          : new Date(),
      enableTransaction: false,
      postingTotal     : {},
      account          : {},
      badge            : {badgeNumber: " ", badgeType: "", alias: "", active: true},
      transactionList  : [],
      totals           : []                                                           // have to define some dummy data to not throw a run-time error before the data arrives. (there is prob a directive for this)
    };
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    numeral( x ) {
      return numeral(x);
    },
    done() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/app/overview');
    },
    showTransaction(id) {
      this.$router.push(`/app/transaction/${id}`);
    },
    editAccount() {
      this.$router.push(`/app/accountEdit/${encodeURIComponent(this.badge.accountNumber)}`);
    },
    manualTransaction(total) {
      this.enableTransaction = true;
      this.postingTotal = total;
    },
    async postedTransaction( trans ) {
      this.enableTransaction = false
      await this.getBadgeData( trans.badgeNumber, trans.accountNumber );
    },
    cancelTransaction( ) {
      this.enableTransaction = false;
    },
    async getBadgeData(badgeNumber,accountNumber) {

      try {
        let [rAccount, rTransactions] = await Promise.all([
          AccountService.getAccountTotals( badgeNumber, accountNumber , true ),
          TransactionService.transactionListByAccount(accountNumber)
        ]);

        this.transactionList = rTransactions.data;        // rTrans.data;

        if( rAccount.data.account.badge.length > 1 ) {    // tag the transactions belonging to THIS badge
          this.transactionList.forEach( function ( t ){   // might be a way to do this inside the table but not sure how
            if( t.badgeNumber === badgeNumber ) {
              t._rowVariant = "success";
            }
          });
        }

        // console.log( rAccount, rTransactions );

        this.account             = rAccount.data.account;
        this.badge               = this.account.badge[this.account.badgeIndex];  //  rAccountTotals.data.account.badgeIndex ];
        this.badge.accountNumber = this.account.accountNumber;
        this.badge.accountType   = this.account.accountType;
        this.totals              = rAccount.data.totals;

      } catch (error) {
        console.log( error.message);
        await this.$root.alert("getBadgeData() "+ error.message, "error");
      }
    }
  }
};
</script>
<style>


</style>
