<template>
  <div>
    <template v-if="!isLoading">
      <div class="show-hand noprint" style="float:right;font-size:1.5em;padding-right:2em;">
        <i v-if="enableDelete" class="fa fa-trash mr-2" v-b-popover.hover.top="deletePrompt" @click="handleDelete"></i>
        <i v-if="enableClone && !isChanged" class="fa fa-copy mr-2" v-b-popover.hover.top="clonePrompt" @click="clone"></i>
        <i v-if="enablePrint" class="fa fa-print" v-b-popover.hover.top="printPrompt" @click="print"></i>
      </div>

      <span v-if="enableAdd" class="show-hand" style="position:relative;float:right;top:4px;font-size:1.5em;padding-right:.5em;" v-b-popover.hover.top="addHelpPrompt" @click="add"><i :class="addIcon"></i></span>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="save">{{ savePrompt }}</b-button>
      <b-button v-if="isChanged" class="btn btn-sm btn-fill" variant="warning" style="border-radius:.75em;float:left;margin-right:1em;" @click="cancel">{{
          cancelPrompt
        }}
      </b-button>
      <b-button v-if="!isChanged && enableDone" class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="done">{{ donePrompt }}</b-button>
      <i v-if="enableEditIcon" class="fa fa-pencil-square show-hand" style="position:relative;display:inline-block;font-size:1.5em;padding-right:1.5em;top:-2px;" v-b-popover.hover.top="enableEditPrompt" @click="edit"></i>
    </template>
    <template v-if="isLoading">
      <h3>Loading...</h3>
    </template>
    <slot name="title">
      <h3 v-if="!prefix" class="card-title" style="display:inline;text-transform:capitalize;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{
          title
        }}<span v-if="suffix" style="margin-left:1em;font-size:.7em;">{{ suffix }}</span></h3>
      <h3 v-if="prefix" class="card-title" style="display:inline;text-transform:capitalize;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{ prefix }}
        <span style="font-size:.8em;font-weight:800;">{{ title }}</span>
        <span v-if="suffix" style="margin-left:1em;font-size:.7em;">{{ suffix }}</span>
      </h3>
    </slot>
    <slot name="sub-subtitle">
      <p class="card-subtitle" style="">{{ titleHelp }}</p>
    </slot>
  </div>
</template>
<script>
export default {
  name : 'page-title-card',
  props: {
    prefix          : {type: String, default: null},
    suffix          : {type: String, default: null},
    title           : {type: String, default: "Card Title"},
    titleHelp       : {type: String, default: ""},
    isChanged       : {type: Boolean, default: false},
    isLoading       : {type: Boolean, default: false},
    enableClone     : {type: Boolean, default: false},
    enablePrint     : {type: Boolean, default: false},
    enableEditIcon  : {type: Boolean, default: false},
    enableEditPrompt: {type: String, default: "Edit this record"},
    enableDone      : {type: Boolean, default: true},
    donePrompt      : {type: String, default: "Done"},
    savePrompt      : {type: String, default: "Save"},
    cancelPrompt    : {type: String, default: "Cancel"},
    clonePrompt     : {type: String, default: "Clone this record"},
    printPrompt     : {type: String, default: ""},
    deletePrompt    : {type: String, default: "Delete this record"},
    enableAdd       : {type: Boolean, default: false},
    enableDelete    : {type: Boolean, default: false},
    addHelpPrompt   : {type: String, default: "Add New"},
    addIcon         : {type: String, default: "fa fa-plus-square"}
  },
  data() {
    return {
      searchFilter: "",
      isEditMode  : false
    }
  },
  methods: {
    click() {
      this.$emit("click");
    },
    done() {
      this.$emit("done");
    },
    save() {
      this.$emit("save");
    },
    handleDelete() {
      this.$emit("delete");
    },
    edit() {
      this.isEditMode = !this.isEditMode;
      this.$emit("edit", this.isEditMode);
    },
    cancel() {
      this.$emit("cancel");
    },
    clone() {
      this.$emit("clone");
    },
    print() {
      this.$emit("print");
    },
    add() {
      this.$emit("add");
    },

  }
}
</script>
<style>
</style>
