<template>
  <div class="" :class="{disabled: disabled}">
    <sf-input v-if="label.indexOf('|')<0"  :id="id" v-bind="$attrs" :label="label" :type="type" v-model.trim="data" @blur="onBlur" @focus="onFocus" @input="onInput" @change="onChange"></sf-input>
    <sf-input v-else                       :id="id" v-bind="$attrs" :label="label.split('|')[ 0 ]" type="list" :options="label.split('|')[ 1 ].split(',')" v-model.trim="data" @blur="onBlur" @focus="onFocus" @input="onInput" @change="onChange"></sf-input>
    <span>
      <slot></slot>
    </span>
  </div>
</template>
<script>

import SfInput from "/src/components/UIComponents/Inputs/SFormInput.vue";

export default {
  inheritedAttrs: true,
  name          : 'ExtendableInput',
  components    : {
    SfInput
  },
  props: {
    id      : {type: String, default: null },
    label   : {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    value   : {type: String, default: ''},
    type    : {type: String, default: 'text'}
  },
  data() {
    return {
      data     : null
    }
  },
  computed: {},
  mounted() {
    // console.log("ExtendableInput created" , this.label);
    if( this.value ) {
      this.data = this.value;
    } else {
      if( this.label.indexOf('|') >= 0 ) {
        let selections = this.label.split('|')[ 1 ].split(',');
        if( selections.length === 1) {
          this.data = this.value = selections[0];
          this.$emit('input', selections[0]);
        } else {
          this.data = this.value || "";
        }
      } else {
        this.data = this.value || "";
      }
    }
    // this.data = this.value || "";
  },
  methods: {
    onInput(e) {
      // console.log("ei oninput", this.label);
      // if(this.isListBox) {    // this is a list box, don't send this
      //   return;
      // }
      this.$emit('input', e)
    },
    onChange(e) {
      // console.log("ei onchange", this.label);
      // if(this.isListBox) {   // this is a list box, don't send this
      //   return;
      // }
      this.$emit('change', e)
    },
    onBlur(e) {
      // console.log("ei onblur", this.label);
      this.$emit('blur', e)
    },
    onFocus(e) {
      // console.log("ei onfocus", this.label);
      this.$emit('focus', e)
    }

  },
}
</script>
