<template>
  <div>
    <b-card v-if="badge && account" class="shadow" style="border-radius:1em;">
      <!--<template slot="header">-->
      <p class="card-category">{{badge.accountType || "" }} :: {{badge.accountNumber || "none"}} </p>
      <template v-if="badge.badgeNumber===''">
        <h4 class="card-title">Notice</h4>
        <hr>
        <p>This badge/account combination can not be found.  This typically happens when changing badge numbers on accounts that have existing transactions connected to the previous (old) badge number.</p>
        <p>This is not an error and the account still has the correct balance.</p>
      </template>
      <template v-else>
        <h4 class="card-title show-hand"  @click="editAccount">
          <i v-if="editIcon===true" class="fa fa-pencil-square mr-2"  v-b-popover.hover.top="'Edit Account'"></i>
          <div style="position:relative;top:-10px;height:96px;width:96px;border-radius:50%;float:right;overflow:hidden;">
            <img v-if="badge.pictureUrl" @click="showPicture" style="width:100%" :src="badge.pictureUrl">
          </div>
          <span style="color:orange;" class="small" v-if="!badge.active || !account.active">(inactive) </span>{{badge.alias || ""}} <span style="font-size:.8em;" v-if="(''+badge.firstName+badge.lastName).trim()>''">({{badge.firstName}} {{badge.lastName}})</span>
        </h4>
      </template>
      <hr />
      <!--<b-button v-if="showTransactionButton===true" class="btn btn-fill btn-warning btn-sm" style="position:absolute;right:10px;margin:0;padding:.5em;font-size:.6em;" @click="applyTransaction" >{{transactionButtonPrompt}}</b-button>-->
      <b-button v-if="showDone" class="btn btn-fill btn-success btn-sm" @click="done" variant="success">Done</b-button>
      <!--</template>-->
      <template slot="text">
      </template>
      <template slot="footer">
      </template>
    </b-card>

    <b-modal ref="pictureDialog"  hide-footer hide-header size="sm" >
      <h4>{{badge.alias}}</h4>
      <img style="border-radius:2em;" width="100%" :src="badge.pictureUrl"/>
      <br>
      <br>
      <b-btn class="btn btn-success btn-fill" variant="outline-success" @click="hideModal">Done</b-btn>
    </b-modal>

  </div>
</template>
<script>

  import Card from "/src/components/UIComponents/Cards/Card.vue";

  export default {
    name: "BadgeCard",
    props: {
      badge: {type: Object, default: () => {} },
      account: { type: Object, default: () => { } },
      editIcon: { type: Boolean, default: true },
      showDone: { type: Boolean, default: true }

      //showTransactionButton: { type: Boolean, default: false },
      //transactionButtonPrompt: { type: String, default: 'Apply Transaction'}
    },
    components: {
      Card
    },
    data() {
      return {
      }
    },
    methods: {
      done() {
        this.$emit('done','');
      },
      showPicture() {
        // console.log( "showPicture()");
        this.$refs.pictureDialog.show();
      },
      hideModal() {
        this.$refs.pictureDialog.hide();
      },
      applyTransaction() {
        this.$emit("applyTransaction");
      },
      editAccount() {
        this.$emit("editAccount", this.badge.accountNumber);
      }
    }
  }

</script>
<style>
</style>
