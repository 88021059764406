<template>
  <div class="w-100 h-100">
    <prism-editor 
      :disabled="disabled" 
      v-bind="$attrs" 
      class="my-prism-editor" 
      :class="cclass" 
      :style="cstyle" 
      v-model="value" 
      :highlight="highlighter" 
      line-numbers
      @change="fieldChanged"
      @input.native="$emit('input', $event.target.value)"
    >
    </prism-editor>
  </div>
</template>
<script>

  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

  export default {
    name    : 'code-editor',
    components: {
      PrismEditor
    },
    props   : {
      cclass: { type: String, default: '' },
      cstyle: { type: String, default: '' },
      disabled: [ Boolean, String ],
      value   : [ String, Object ],
    },
    data() {
      return {
        myData: ''
      }
    },
    computed: {
    },
    created() {
      this.cbId = Math.random().toString(16).slice(2)
    },
    methods: {
      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },
      fieldChanged( ) {
        this.$emit('change', this.value);
      },
    }
  }
</script>
<style lang="scss" scoped>

  .my-prism-editor {
    border-radius:.5em;
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  ::v-deep .prism-editor__textarea:focus {
    outline: none;
  }

</style>