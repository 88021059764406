<template>
  <div style="margin:0;padding:0;">
    <h4>{{ title }}</h4>
    <div class="row" v-if="postingAccount && postingTotal">
      <div class="col-lg-12 col-xl-6">
        <b-form v-if="revenueCenters && isAllowLookup" @click.stop="">
          <live-search
            style="width:100%;margin-bottom:1.5em;"
            :searchItem="accountSearchField"
            :searchMethod="accountSearch"
            valueField="badgeId"
            displayField="accountTitle"
            @select="assignAccount($event)"
          >
            <template v-slot:list-item="suggestion">
              <div style="border-bottom:1px solid gray;margin:0;">
                <span style="font-weight:600;" v-if="suggestion.badgeTitle">{{ suggestion.badgeTitle }}</span><span v-if="suggestion.accountTitle">/{{suggestion.accountTitle}}</span><span v-if="suggestion.roomNumber">/ Room: {{ suggestion.roomNumber }}</span>
                <span v-if="suggestion.accountNumber"> :: {{ suggestion.accountNumber }}</span>
                <div v-if="suggestion.badge" style="margin:0 0 0 2em;"> {{ suggestion.badge[ 0 ].firstName }} {{ suggestion.badge[ 0 ].lastName }}</div>
              </div>
            </template>
          </live-search>

          <div v-if="account && account.badge">
            <sf-input v-model="account.accountNumber" type="text" label="Account Number" readonly />
            <sf-input v-model="account.accountTitle" type="text" label="Account Title" readonly />
            <template v-if="account.badge.alias">
              <sf-input v-model="account.badge.alias" type="text" label="Badge Title" readonly />
            </template>
            <template v-else>
              <sf-input v-model="account.badge[0].alias" type="text" label="Badge Title" readonly />
            </template>

            <hr>
            <sf-input v-model="transaction.totalClassId" type="list" :options="totalOptions" label="Transaction Profile" @change="verifyAccountHasTotalClass()" />
            <hr>

            <div v-if="transaction.totalClassId">
              <sf-input v-model="isPayment" type="list" :options="transactionTypeOptions()" label="Transaction Type" />
              <sf-input v-model="transaction.inputDate" type="date" label="Date" />
              <sf-input v-model="transaction.inputTime" type="time" label="Time" />
              <sf-input v-model="transaction.revenueCenterId" type="list" :options="revenueCenterOptions" label="Revenue Center" />
              <sf-input v-model="transaction.tenderNumber" type="list" :options="tenderOptions" label="Tender Key" />
              <sf-input v-model="transaction.title" type="text" label="Legend" />
<!--            </template>-->

              <template v-if="transactionSource === 'helpdesk-ticket'">
                <sf-input v-model="step.labor" type="currency" label="Labor" @change="stepTotal()"/>
                <sf-input v-model="step.parts" type="currency" label="Parts"  @change="stepTotal()"/>
                <sf-input v-model="step.supplies" type="currency" label="Supplies"  @change="stepTotal()" />
                <sf-input v-model="step.expenses" type="currency" label="Expenses"  @change="stepTotal()"/>
                <sf-input v-model="step.outside" type="currency" label="Outside"  @change="stepTotal()"/>
                <hr>
                <sf-input readonly v-model="step.total" type="currency" label="Total" />

              </template>

              <template v-if="transactionSource !== 'helpdesk-ticket'">
                <sf-input v-model="transaction.total" type="currency" label="Total" />
              </template>

              <sf-input v-model="transaction.reference" type="textarea" :lines=4 label="Reference" />
            </div>
          </div>
        </b-form>
        <div v-if="errorMessage" style="text-align:center;color:red;font-weight:700;">{{errorMessage}}</div>
        <div style="position:relative;bottom:0;display:inline-block;margin-top:1em;">
          <b-button class="btn btn-sm btn-fill btn-warning mr-2" @click="cancel">Cancel</b-button>
<!--          <b-button class="btn btn-sm btn-fill btn-success mr-2" @click="postTransaction()" :disabled="!isOkToPost()">Post Transaction</b-button>-->
          <b-button class="btn btn-sm btn-fill btn-success mr-2" @click="postTransaction()" v-if="isOkToPost()">Post Transaction</b-button>
        </div>
      </div>
      <div class="col" style="display:inline-block;">
        <div style="border:1px solid gray;border-radius:.5em;text-align:center;max-width:600px;">
          <div style="font-family:'Courier New', Courier, monospace;white-space:pre-wrap;font-size:1em;margin:auto;">
            {{makeReceipt(transaction)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


/*

  This component is a form for entering manual transactions.
  The initial ideas was to allow this module to handle about any type of manual
  transaction, but this thing is now growing

  todo: modularize/componentize this code.

  Some ticket types:

  1.  Help Desk Ticket      "helpdesk-ticket"
  2.  Manual Transaction    "transaction"
  3.

*/

  import PosService           from "/src/Services/PosService";
  import TicketService        from "/src/Services/TicketService";
  import RevenueCenterService from "/src/Services/RevenueCenterService";
  import TenderService        from "/src/Services/TenderService";
  import AccountService       from "/src/Services/AccountService";
  import Currency             from "currency.js";
  import Moment               from "moment-timezone";
  import SfInput              from "/src/components/UIComponents/Inputs/SFormInput.vue";
  import LiveSearch           from "../Inputs/LiveSearch.vue";
  import Card                 from './Card.vue';

  export default {
    name      : 'manual-transaction-form',
    props: {
      title                       : {type: String, default: "Manual Transaction"},

      postingAccount              : {type: Object, default: null},        // todo: refine these and identify how each is used.
      postingTotal                : {type: Object, default: null},
      postingTicket               : {type: Object, default: null},        // if this is "helpdesk" related, this is the ticket reference.
      postingStep                 : {type: Object, default: null},

      accountClasses              : {type: String, default: "all"},           // what classes do we restrict too when we enable lookup.

      transactionSource           : {type: String, default: 'transaction'},   // 'ticket'
      transactionOwnerId          : {type: String},                           // this is the "base" owner, in case of a ticket, it's the ticket _id;

      accountLookupMethod         : {type: Function},
      acceptedRevenueCenters      : {type: Array, default: () => []},   // revenue centers, tenders, and total classes we "allow' to post here.
      acceptedTenderNumbers       : {type: Array, default: () => []},
      acceptedTotalClasses        : {type: Array, default: () => []},
      isAllowLookup               : {type: Boolean, default: true},     // can we lookup an account?
      isAllowTransactionTypeSelect: {type: Boolean, default: true},     // can we selection a transaction type? (payment/charge)
      defaultTransactionType      : {type: String, default: "payment"}  // what we start with; disable selection and this is the ONLY thing allowed.
    },
    components: {
      LiveSearch,
      Card,
      SfInput
    },
    computed  : {
      revenueCenterOptions() {
        let r = this.revenueCenters.filter((r) => this.acceptedRevenueCenters.indexOf(r.revenueCenterId)>=0).map(r => { return {text: r.revenueCenterId.toString() + " : " + r.title, value: r.revenueCenterId}});
        return r;
      },
      tenderOptions() {
        let t = this.tenders.filter((r) => this.acceptedTenderNumbers.indexOf(r.tenderNumber)>=0).map(r => { return {text: r.tenderNumber.toString() + " : " + r.title, value: r.tenderNumber}});
        return t;
      },
      totalOptions() {
        // NOTE: The account totalClassId is a integer, but our acceptedTotalClasses is an integer.
        //       these got out of sync (int/string) from the beginning - one day may go through and fix this.  (but not today) :)
        if( !this.account || !this.account.totalClass ) {
          return [];
        }
        let t = this.account.totalClass.filter((r) => this.acceptedTotalClasses.indexOf(r.totalClassId.toString())>=0).map(r => { return {text: r.totalClassId.toString() + " : " + r.title, value: r.totalClassId}});
        return t;
      },

    },
    data() {
      return {
        searchString      : "",
        errorMessage      : "",
        isPayment         : 'true',
        account           : null,
        accountSearchField: "",
        accountSearchType : "",
        revenueCenters    : [],
        tenders           : [],
        step: {
          hours   : 0,    // not currently referenced, but it is assigned in the posting.
          labor   : 0,
          parts   : 0,
          supplies: 0,
          expenses: 0,
          outside : 0,
          total   : 0
        },
        transaction    : {
          accountNumber    : this.postingAccount.accountNumber,
          badgeNumber      : this.postingAccount.badgeNumber,
          totalClassId     : this.postingTotal.totalClassId,
          isPayment        : true,
          title            : this.title,    // our modal title is reflected here.
          revenueCenterId  : 0,
          tenderNumber     : 0,
          inputDate        : Moment().format("YYYY-MM-DD"),
          inputTime        : Moment().format("HH:mm"),
          transactionDate  : null,
          checkNumber      : Moment().format("YYMMDDHHmmss"),
          transactionNumber: Moment().format("YYMMDDHHmmss"),
          employeeNumber   : 0,
          employeeName     : this.$root.user.alias,
          workstationNumber: this.$root.user.currentIP.split(".")[ 3 ],
          cashierNumber    : 0,
          reference        : "",
          total            : 0.00,
          tax              : [ 0, 0, 0, 0 ],
          previousPay      : 0.00,
          taxable          : 0,
          nonTaxable       : 0,
          discount         : 0,
          serviceCharge    : 0
        },
        postingTemplate: {        // this template is the staring default basis for our posting record; we replace fields from the transaction data
          accountNumber: "",      // at some point, we could get this from the DB so we can change this over time.
          badgeNumber  : "",
          revenueCenter: 0,
          tenderNumber : 0,
          tenderAmount : 0,
          tenderQty    : 1,
          totalClassId : 0,
          title        : 'sale',
          isPayment    : true,
          isMealPlan   : false,
          transactionDate: null,

          servingPeriod      : 0,    // Not used currently
          transactionNumber  : "",   // Our "next" transaction number
          checkNumber        : "",
          coverCount         : 1,    // Default to 1 since "most" transactions are for one person.
          transactionEmployee: 0,    // Who is ringing this tranaction
          checkEmployee      : 0,    // for now, same as transactionEmployee
          terminalNumber     : 0,
          source             : "iCare Manual",

          comment         : "",
          transactionTotal: 0,   // The AMOUNT of this tranactions; all discounts/service charges applied.  transPost will ensure this balances with all the other totals

          salesItemizer: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],   // 16 sales itemizers
          taxItemizer  : [ 0, 0, 0, 0 ],
          discount     : 0,       // discounts are represented as a negative value
          serviceCharge: 0,       // service charges are represented as positive values

          previousPay: 0,         // when split tender, this will be the amount of "all previous" tenders.

          transactionSource: this.transactionSource,    // take these from the props until we replace them somewhere downline.
          transactionOwnerId: this.transactionOwnerId,

          items  : [ // include items with the LAST payment that closes the transaction
            //{
            //  itemType    : { type: String, default: "item", enum: ["item", "tender", "discount", "service", "tax", "reference"] },
            //  itemNumber  : Number,   // the user-readable number
            //  title       : String,   // menu item name
            //  qty         : Number,
            //  price       : Number,
            //  taxable     : Boolean,
            //  sequence    : Number,
            //  reference   : String,
            //}
          ],
          receipt: []

        },
      }
    },
    async created() {
      await Promise.all([
                          this.getTenders(),
                          this.getRevenueCenters()
                        ])

    },
    mounted() {
      let now = Moment().tz(this.$root.TZ).startOf('minute').subtract(Moment().minute() % 30, 'minutes');
      this.transaction.inputTime = now.format('HH:mm');
      this.isPayment = this.defaultTransactionType === "payment" ? 'true' : 'false';
    },
    methods   : {
      gs(x) {
        return JSON.stringify(x, null, 2);
      },
      cancel() {
        this.$emit("cancel");
      },
      moment(d) {
        return Moment(d);
      },
      showErrorMessage(message) {
        this.errorMessage = message;
      },
      verifyAccountHasTotalClass() {
        // console.log( "verifyAccountHasTotalClass", this.account );
        // if( ! this.account.totalClassId.includes( this.transaction.totalClassId )) {
        //   this.transaction.totalClassId = 0;
        //   this.showErrorMessage("This account does not have this total profile");
        // }
      },
      stepTotal() {   // this fires with each change of the "step", but NOT if there are not steps, so stuffing the transaction keeps this in sync.
        this.step.total = Currency(this.step.labor).add( this.step.parts).add( this.step.supplies).add( this.step.expenses).add(this.step.outside).value;
        this.transaction.total = this.step.total;
        return this.step.total;
      },
      async accountSearch(searchString) {

        // if(this.accountSearchType === "") {
        //   await this.$root.alert("Select an Account Type first");
        //   return;
        // }

        try {
          this.isResult    = false;
                                      // the other one uses accountList, but not sure the difference -- testing.
          let response;
          if( this.accountLookupMethod ) {
            response = await this.accountLookupMethod( this.accountClasses, searchString, 10 );    // if dev is passing in a method, must confirm to this format.
          } else {
            response = await AccountService.liveSearch2(this.accountClasses, searchString, 10);
          }
          this.accountList = response.data;
          this.isResult    = true;
          return this.accountList;
        } catch(error) {
          console.log(error.message);
        }
        return null;
      },
      async assignAccount(item) {

        if(!item || !item._id) {
          return;
        }

        try {
          let response = await AccountService.getBadge(item.badgeNumber, item.accountNumber );
          this.account = response.data;

          if(response.status === 200) {                           // if we were successful at creating the transaction, then we tag the step with the ID of the tranaction.
            this.transaction.accountNumber = item.accountNumber;  // assign from what we're asking for;
            this.transaction.badgeNumber   = item.badgeNumber;
          }

        } catch(error) {
          await this.$root.alert(error.message);
        }

      },
      makeReceipt(transaction) {
        let paymentMessage
        if( transaction.isPayment ) {
          paymentMessage = 'Ticket Payment';
        } else {
          paymentMessage = 'Ticket Charge';
        }

        if( this.postingTicket ) {
          paymentMessage += ` For Workorder# ${this.postingTicket.ticketNumber}\n${this.postingTicket.title}\n\n${this.postingTicket.description}`;
        }

        // ${this.$root.tenant.tenantName}

        let r =
`${this.title}
===========================================
  Emp: ${transaction.employeeName}
  Chk: ${transaction.checkNumber}
 Date: ${Moment.tz(transaction.inputDate + " " + transaction.inputTime, this.$root.TZ).format("LLLL")}
===========================================

   Revenue Center
     ${this.revenueCenter(transaction.revenueCenterId)}

   Tender
     ${this.tender(transaction.tenderNumber)}

  ${transaction.title} ${Currency(transaction.total)}

  ${paymentMessage}

===========================================
${transaction.reference}
`;

        return r;
      },
      isOkToPost() {
        if( this.transaction.revenueCenterId === 0 || this.transaction.tenderNumber === 0 ) {
          return false;
        }
        return true;
      },
      buildPostingStep( isPayment ) {

        let accountInfo =
        `Account #: ${this.account.accountNumber}
                   ${this.account.accountTitle}
        `

        if(this.account.badge.lastName) {
          accountInfo += `           ${this.account.badge.firstName} ${this.account.badge.lastName}`;
        } else {
          accountInfo += `           ${this.account.badge[ 0 ].firstName} ${this.account.badge[ 0 ].lastName}`;
        }

        accountInfo += `\n           ----------------------------------------\n${this.transaction.reference}`;

        let nextStep = this.postingTicket.steps.map( s => s.sequence ).reduce( (a,b) => Math.max(a,b), 0) + 1;

        let step = {
          "stepType" : "transaction",
          "isPayment": isPayment,
          // "assignedTo": "",
          "category"     : "Payment",
          "scheduledDate": Moment.tz(this.transaction.inputDate + " " + this.transaction.inputTime, this.$root.TZ).toDate(),
          "vendorName"   : "",
           "stepId": nextStep,
          // "dependsOn": "1",
          "sequence": nextStep,
          // "status": "",
          "complete"     : true,
          "completedAt"  : Moment().tz( this.$root.TZ).toDate(),    // the completed is the REAL date we did this.
          "completedBy"  : this.$root.user.alias,
          "completedById": this.$root.user._id,
          "billed"       : true,
          "paid"         : true,
          // "indentLevel": 0,
          "title"                : this.title,
          "description"          : accountInfo,
          "hours"                : this.step.hours,   // hours are hours regardless.(well, I think)
          "labor"                : isPayment ? -this.step.labor : this.step.labor,    // payments negate the values.
          "parts"                : isPayment ? -this.step.parts : this.step.parts,
          "supplies"             : isPayment ? -this.step.supplies : this.step.supplies,
          "expenses"             : isPayment ? -this.step.expenses : this.step.expenses,
          "outside"              : isPayment ? -this.step.outside : this.step.outside,
          "estimatedHours"       : 0,
          "estimatedLabor"       : 0,
          "estimatedParts"       : 0,
          "estimatedSupplies"    : 0,
          "estimatedExpenses"    : 0,
          "estimatedOutsideTotal": 0,
          // "note": "",
          // "_id": ObjectId("660dc3b2c6222083ccb631ce")
        };

        return step;
      },
      buildPostingItem( isPayment ) {

        let trans = this.transaction       // the FULL transaction record

        let item = Object.assign({}, this.postingTemplate)    // let's make a NEW object that we can POST to the api

        item.isPayment           = isPayment;
        item.accountNumber       = trans.accountNumber
        item.badgeNumber         = trans.badgeNumber
        item.revenueCenter       = trans.revenueCenterId
        item.tenderNumber        = trans.tenderNumber
        item.tenderAmount        = trans.total
        item.tenderQty           = 1
        item.totalClassId        = trans.totalClassId
        item.title               = trans.title
        item.transactionNumber   = trans.transactionNumber
        item.checkNumber         = trans.checkNumber
        item.transactionEmployee = trans.employeeNumber
        // item.checkEmployee       = trans.employeeNumber
        // item.servingEmployee     = trans.employeeName

        item.transactionDate = Moment.tz(trans.inputDate + " " + trans.inputTime, this.$root.TZ ).toDate(); //.format( "YYYY-MM-DD HH:mm" )

        item.workstationNumber   = trans.workstationNumber
        item.wksId               = trans.workstationNumber
        item.cashierNumber       = trans.cashierNumber
        item.isPayment           = isPayment

        item.transactionTotal = trans.total
        item.salesItemizer    = [ trans.taxable + trans.nonTaxable, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]   // 16 sales itemizers
        item.taxItemizer      = trans.tax
        item.discount         = trans.discount
        item.serviceCharge    = trans.serviceCharge
        item.previousPay      = trans.previousPay

        item.transactionSource  = this.transactionSource;
        item.transactionOwnerId = this.transactionOwnerId;

        if( this.postingTicket ) {      // if we have a ticket, don't matter the transaction source, capture the ticket number
          item.reference = this.postingTicket.ticketNumber;
          // item.transactionOwnerId = this.ticket._id;
        }

        if(item.tenderAmount >= trans.total) {
          item.items = Object.assign({}, trans.items)
        }

        item.receipt.push(this.makeReceipt(trans));      // do this towards the end so we have all the item details in place

        this.postingItem = item;
        return this.postingItem;

      },
      async postTransaction() {

        if(!await this.$root.confirmYesNo("Are you ready to post this transaction?")) {
          return;
        }

        if( this.transaction.total ===  0) {
          if(!await this.$root.confirmYesNo("Post a 0.00 transaction?")) {
            return;
          }
        }

        if( this.transaction.revenueCenterId === 0 || this.transaction.tenderNumber === 0 ) {
          await this.$root.alert( "Please choose a valid Revenue Center and Tendering Number" );
          return;
        }

        let isPayment = this.isPayment === 'true' ? true : false;

        this.loading = true;

        let step     = this.buildPostingStep(isPayment);    // posting a payment to the ticket, posts a CHARGE to the user
        let posting  = this.buildPostingItem(!isPayment);

        let auth;

        try {
          let response;

          response = await PosService.transactionPost(posting);   // not sure why calling from this service instead of the tranaction service -
          auth     = response.data;

          if(response.status !== 200) {                   // if we were successful at creating the transaction, then we tag the step with the ID of the tranaction.
            this.loading = false;
            // console.log( "response", JSON.stringify( response, null, 2 ) );
            return this.showErrorMessage(response.message);
          }

          if( this.transactionSource === 'helpdesk-ticket' ) {
            if( auth.transactionDetail ) {
              step.transactionId = auth.transactionDetail._id;
            }

            let stepResponse  = await TicketService.addStep(this.postingTicket._id, step );   // now add the payment to the ticket.
          }

          this.$emit('posted', posting);

        } catch(error) {
          console.log(error.message);
          this.showErrorMessage(error.message);   // shows on the modal form at the bottom, NOT popup.
        }

        this.loading = false;

      },
      revenueCenter(id) {
        var t = this;
        var i;

        if(t.revenueCenters.length > 0) {
          for(i = 0; i < t.revenueCenters.length; i++){
            if(t.revenueCenters[ i ].revenueCenterId == id) {  // needs to be a loose equality check
              return "" + id + " : " + t.revenueCenters[ i ].title;
            }
          }
        }

        return "" + id
      },
      tender(id) {
        var t = this;
        var i;

        if(t.tenders.length > 0) {
          for(i = 0; i < t.tenders.length; i++){
            if(t.tenders[ i ].tenderNumber == id) {  // needs to e a loose equality check
              return "" + id + " : " + t.tenders[ i ].title;
            }
          }
        }

        return "" + id
      },
      total(id) {
        var t = this;
        var i;

        if(t.postingTotals.length > 0) {
          for(i = 0; i < t.postingTotals.length; i++){
            if(t.postingTotals[ i ].totalClassId == id) {    // needs to be a loose equality check
              return "" + id + " : " + t.postingTotals[ i ].title;
            }
          }
        }

        return "" + id
      },
      transactionTypeOptions() {
        if (this.isAllowTransactionTypeSelect) {
          return [
            {text: "Payment", value: true},
            {text: "Charge", value: false}
          ];
        } else {
          if (this.defaultTransactionType === "payment") {
            return [{text: "Payment", value: true}];
          } else {
            return [{text: "Charge", value: false}];
          }
        }
      },
      async getRevenueCenters() {
        var response;
        var t = this;
        try {
          response         = await RevenueCenterService.getRevenueCenters();
          t.revenueCenters = response.data;

        } catch(err) {
          if(err) {
            alert(err.message);
            t.$root.errorMessage("Oops", err.message);
          }
        }

      },
      async getTenders() {
        var response;
        var t = this;
        try {
          response  = await TenderService.getTenders();
          t.tenders = response.data;

        } catch(err) {
          if(err) {
            alert(
              "oops hit error in getTenders",
              err
            );
            t.$root.errorMessage("Oops", "Had a problem getting the tender listing");
          }
        }
      }
    }
  }

</script>
<style>

  .page-body {
    border-radius : 1em;

  }

</style>
