<template>
  <div class="show-hand" @click="cardClicked">
    <div class="stats-card-shadow" :style="cCardStyle">
      <slot name="header" >
        <div :style="headerStyle">
          <div v-if="isEnableFilter()">
            <input id="status-widget" style="float:right;border-radius:1em;border:0 solid black;padding:0 .5em 0 1em;"  placeholder="Filter..." type="search" v-model="filter" @input="$emit('filter', filter)" />
          </div>
          <h3 style="margin:0;padding:0;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;" :style="titleStyle">{{ title }}</h3>
          <p style="margin:-.4em 0 0 0;padding:0;font-size:.9em;">{{ subTitle }}</p>
        </div>
      </slot>
      <slot name="body" style="" :style="cCardBodyStyle">
        <ul style="list-style:none;margin:.5em 0 0 0;font-size:1em;font-weight:100;overflow:auto;">
          <li v-for="(item,index) in items" :key="index" >
            <span v-if="item.icon" ><i :class="item.icon" style="font-weight:100;font-size:1.1em;margin-right:.5em;" :style="item.iconStyle"></i></span>
            <span  :style="cItemStyle( item )">{{ item.title }}</span>
          </li>
        </ul>
      </slot>
    </div>
  </div>

</template>

<script>

export default {
  name: 'status-widget',
  components: {
  },
  props: {
    title        : {type: String, default: ""},
    subTitle     : {type: String, default: ""},
    headerStyle  : {type: String, default: "margin:0 0 .5em 0;color:black;text-align:center;h3{ background:lime;}"},
    items        : {type: Array, default: () => []},
    fill         : {type: Boolean, default: false},
    chartColor   : {type: String, default: "#ff9933"},
    cardStyle    : {type: String, default: "margin:0 0em 20px 0;padding:1em 1em 1em 1em;border:0px solid black;border-radius:1em;"},
    cardBodyStyle: {type: String, default: "padding:.05em;border-radius:1em"},
    itemStyle    : {type: String, default: "color:white;"},
    titleStyle   : {type: String, default: ""},
    enableFilter : {type: Boolean, default: false},
    filter       : {type: String, default: "" }

  },
  data() {
    return {
    }
  },
  computed: {
    cCardStyle() {
      // this needs some work - would like these to scale like the charts, but yikes that's NOT an obvious thing...
      return `${this.cardStyle};background:${this.chartColor};`;
    },
    cCardBodyStyle() {
      return `${this.cardBodyStyle};background:${this.chartColor};`;
    }
  },
  methods: {
    isEnableFilter() {
      return this.enableFilter;
    },
    cItemStyle( item ) {
      return `${this.itemStyle}${item.itemStyle}`;
    },
    cardClicked() {
      this.$emit( "click" , this.title );
    },

  }
};
</script>
<style scoped>
  #status-widget::placeholder {
    color:lightgray;
  }
</style>
