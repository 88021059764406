<template>
  <div>
    <slot name="pre-header">
    </slot>
    <div style="width:100%;">
      <span v-if="enableUpload" class="show-hand" style="position:relative;float:right;top:4px;font-size:1.5em;padding-right:.5em;" v-b-popover.hover.top="uploadHelpPrompt" @click="upload()"><i :class="uploadIcon"></i></span>
      <span v-if="enableExport" class="show-hand" style="position:relative;float:right;font-size:1.5em;padding-right:1em;"><i class="fa fa-file-excel "  v-b-popover.hover.top="'Export this list as CSV'" @click="csvExport()"></i></span>
      <!-- <b-input type="search" :style="(searchFilter) ? 'background:pink;' : ''" style="width:225px;float:right;margin-right:2em;margin-bottom:.25em;border:1px black solid; border-radius:1em;" v-model="searchFilter" :placeholder="searchFilterPrompt"></b-input> -->

      <b-input-group style="width:225px;float:right;margin-right:2em;margin-bottom:.25em;">
        <b-form-input type="text" :style="(searchFilter) ? 'background:pink;' : ''" style="border-radius:1em 0 0 1em;" v-model="searchFilter" :placeholder="searchFilterPrompt"></b-form-input>
        <b-input-group-append>
          <b-input-group-text @click="searchFilter=''" style="border-radius:0 1em 1em 0;">
            <i class="fa fa-times" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>

      <b-button v-if="enableDone" class="btn btn-sm btn-fill" variant="success" style="border-radius:.75em;float:left;margin-right:1em;" @click="done()">{{ donePrompt }}</b-button>
      <span v-if="enableAdd" class="show-hand" @dragover.prevent="canDrop=true" @dragend="canDrop=false" @dragenter="canDrop=true" @dragleave="canDrop=false" style="position:relative;float:left;top:0;font-size:1.5em;padding-right:.5em;" v-b-popover.hover.top="addHelpPrompt" @click="add()" @drop.prevent="onDrop($event)"><i :class="addIcon"></i></span>

      <h3 class="card-title" :class="(canDrop && enableDrop) ? 'can-drop' : ''">{{ title }}
        <span v-if="subTitle" style="margin-left:1em;font-size:.7em;font-weight:bold;color:gray;">{{ subTitle }}</span>
      </h3>

      <slot name="subtitle">
        <p class="card-subtitle">{{ titleHelp }}</p>
      </slot>

      <div v-if="items && items.length>0">
          <div class="noprint" v-if="enablePaging" style="text-align:center;width:100%;margin-top:.5em;margin-bottom:-.75em;">
            <div style="display:flex;flex-direction:row;vertical-align:middle;">
              <b-pagination
                style="width:400px;"
                size="sm"
                class="mt-0 mb-0"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                aria-controls="my-table">
              </b-pagination>
              <div class="noprint" style="width:250px;font-size:.8em;">
                <sf-input class="noprint text-right" label="Pg/Size" :labelCols="2" v-model="perPage" type="list" cstyle="position:relative;top:-.25em;width:6em;" :options="[5,10,25,50,100,250,500,9999]" @change="savePerPage"></sf-input>
              </div>
              <p style="font-size:.8em;margin-left:2em;font-weight:bold;padding-top:.25em">Current Page: {{ currentPage }}/{{ Math.floor((items.length-1) / perPage) +1 }} </p>
              <span style="font-size:.8em;margin-left:2em;padding-top:.25em">({{items.length}} Total Rows)</span>
            </div>
          </div>
        <b-table
          style="width:100%;"
          striped
          sm
          hover
          :tbody-transition-props="transProps"
          :head-variant="headVariant"
          primary-key="indexId"
          :per-page="perPage"
          :current-page="currentPage"
          :rows="items.length"
          :sticky-header="stickyHeader"
          :foot-clone="hasFooter()"
          :style='tableStyle'
          :thClass="thClass"
          :class="tableClass"
          sort-by="title"
          :filter="searchFilter"
          :items="items"
          :fields="fields"
          @row-clicked="rowClicked($event)"
          >

          <template v-slot:cell(action)="row">
            <i v-if="enableDelete" class="fa fa-trash show-hand mr-1" @click.stop="remove(row)" v-b-popover.hover.top="deleteHelpPrompt"></i>
            <i v-if="enableEdit" class="fa fa-edit show-hand mr-1" @click.stop="edit(row.item)" v-b-popover.hover.top="'Edit this item'"></i>
            <i v-if="enableShowActive && row.item.active" class="ml-2 fa fa-check show-hand" style="color:green;"></i>
            <i v-if="enableShowActive && !row.item.active" class="ml-2 fa fa-times show-hand" style="color:red;"></i>
            <slot name="action" v-bind="row">
            </slot>
          </template>

          <!--          <template #custom-foot="items">-->
          <!--            <b-tr>-->
          <!--              <b-td colspan="100%">-->
          <!--                stuff-->
          <!--              </b-td>-->
          <!--            </b-tr>-->
          <!--          </template>-->

        </b-table>
      </div>

      <div v-else style="width:100%;text-align:left;">
        <h4 v-if="noDataPrompt">{{ noDataPrompt }}</h4>
      </div>

    </div>
    <slot name="post-header">
    </slot>
  </div>
</template>
<script>

import exportLib from "@/Lib/dataExportLib";
import Currency  from "currency.js";
import SfInput   from "/src/components/UIComponents/Inputs/SFormInput.vue";

export default {
  name      : 'list-card',
  components: {
    SfInput
  },
  props     : {
    enableDone        : {type: Boolean, default: false},
    enableShowActive  : {type: Boolean, default: false},
    enablePaging      : {type: Boolean, default: false},
    donePrompt        : {type: String, default: "Done"},
    title             : {type: String, default: "List"},
    headVariant       : {type: String, default: "dark"},
    tableStyle        : {type: String, default: "margin-top:.5em;min-height:700px;overflow-x:auto;"},
    subTitle          : {type: String, default: ""},
    titleHelp         : {type: String, default: ""},
    searchFilterPrompt: {type: String, default: "Filter list"},
    items             : {type: Array, default: () => []},
    fields            : {type: Array, default: () => []},
    stickyHeader      : {type: String, default: 'true'},
    enableAdd         : {type: Boolean, default: false},
    enableEdit        : {type: Boolean, default: false},
    enableDrop        : {type: Boolean, default: false},
    enableDelete      : {type: Boolean, default: false},
    enableUpload      : {type: Boolean, default: false},
    enableExport      : {type: Boolean, default: false},
    enableTotalRow    : {type: Boolean, default: true},
    addHelpPrompt     : {type: String, default: "Add New"},
    deleteHelpPrompt  : {type: String, default: "Remove this item"},
    addIcon           : {type: String, default: "fa fa-plus-square"},
    uploadHelpPrompt  : {type: String, default: "Upload List"},
    uploadIcon        : {type: String, default: "fa fa-upload"},
    noDataPrompt      : {type: String, default: "No data to display"},
    // tableClass        : {type: String, default: ""},
    tableClass        : {type: String, default: "steelBlueCols"},
    thClass           : {type: String, default: ""}
  },
  data() {
    return {
      searchFilter: "",
      canDrop     : false,
      currentPage : 1,
      perPage     : 99999,
      transProps  : {
          name: 'flip-list'
        },
    }
  },
  mounted() {
    if( this.enablePaging ) {
      this.getPerPage();
    }
  },
  methods: {
    hasFooter() {
      return false;
    },
    USD(v) {
      return Currency(v, {precision: 2, symbol: ' '}).format();
    },
    savePerPage() {
      localStorage.setItem( "perPage" , this.perPage );
    },
    getPerPage() {
      if( localStorage.getItem( "perPage" )) {
        this.perPage = localStorage.getItem( 'perPage' );
        if( this.perPage <= 0 ) {
          this.perPage = 10;
        }
      }
    },
    rowClicked(data) {
      this.$emit("click", data);
    },
    done() {
      this.$emit("done");
    },
    add() {
      this.$emit("add");
    },
    sum(field) {
      // USD(_.sumBy( projects, 'projectBudget'))
      return USD(0.00).format();
    },
    onDrop(event) {
      if(this.enableDrop) {
        this.$emit("drop", event);
      }
    },
    remove(data) {
      this.$emit("delete", data);
    },
    edit( data ) {
      this.$emit( "edit" , data );
    },
    upload() {
      this.$emit("upload");
    },
    makeCamelCase( s ) {
      return s;
    },
    csvExport() {
      exportLib.csvExportAskFilename( this.items, this.fields);
    }

  }
}
</script>
<style lang="scss" >

.can-drop {
  background: black;
  color: white;
}

table.steelBlueCols {
  border: 4px solid #555555;
  background-color: #EEEEEE;
  width: 400px;
  text-align: center;
  border-collapse: collapse;
}

table.steelBlueCols tbody tr:nth-child(even) {
  background-color: #ddd;
}

table.steelBlueCols td, table.steelBlueCols th {
  padding: 5px 10px;
}

table.steelBlueCols tbody td {
  // font-size: 12px;
  // font-weight: bold;
  color: #000000;
}

table.steelBlueCols tbody tr:hover {
  background: #BBB;
}

table.steelBlueCols thead {
  background: #27556a;
  // border-bottom: 10px solid #398AA4;
}

table.steelBlueCols thead th {
  // font-size: 15px;
  font-size: 1.1em;
  // font-weight: bold;
  font-weight: 800;
  color: #FFFFFF;
  // border-left: 2px solid #398AA4;
}

table.steelBlueCols thead th:first-child {
  border-left: none;
}

table.steelBlueCols tfoot {
  // font-size: 13px;
  font-size: 1.1em;
  font-weight: bold;
  color: #FFFFFF;
  background: #27556a;
}

</style>
